import React from 'react';
import { Box, IconButton, Tooltip, Chip, alpha, styled } from '@mui/material';
import { MarkEmailUnread, Refresh } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ExploreIcon from '@mui/icons-material/Explore';
import { DerivedInboxType, INBOX_LABELS, InboxType, SOURCE_TYPE } from 'lib/redux/linkedin/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useLinkedInConversations } from 'lib/redux/linkedin/conversations/list-hook';
import { useLinkedInNavigate } from 'lib/redux/linkedin/navigate/hook';
import { useLinkedInMetadata } from 'lib/redux/linkedin/metadata/hook';
import { Typography as MuiTypography } from '@mui/material';
import { setSelectedTags } from 'lib/redux/linkedin/navigate/slice';
import { Typography } from 'interfaces/typography';

const StyledTagChip = styled(Chip)(({ theme }) => ({
  height: 24,
  fontSize: '0.75rem',
  backgroundColor: 'transparent',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  color: theme.palette.text.secondary,
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderColor: alpha(theme.palette.primary.main, 0.4),
    color: theme.palette.primary.main,
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  '& .MuiChip-label': {
    padding: '0 8px',
  }
}));

export const ConversationListHeader: React.FC = () => {
  const { loadConversations, sourceOptions, isLoading } = useLinkedInConversations();
  const userInbox = useSelector((state: RootState) => state.linkedinSelected.derivedInboxType); 
  const inboxType = useSelector((state: RootState) => state.linkedinSelected.inboxType);
  const { toSource, toBackendInbox } = useLinkedInNavigate();
  const { salesNavigatorClient } = useSelector((state: RootState) => state.linkedinClients);
  const { allTags } = useLinkedInMetadata();
  const dispatch = useDispatch();
  const selectedTags = useSelector((state: RootState) => state.linkedinSelected.selectedTags);

  const hasSalesNavigatorAccess = salesNavigatorClient ? salesNavigatorClient.hasAccess : false;

  const handleSourceToggle = (source: SOURCE_TYPE) => {
    const newSources = new Set(sourceOptions);
    if (newSources.has(source)) {
      newSources.delete(source);
    } else {
      newSources.add(source);
    }
    toSource(Array.from(newSources));
  };

  const toggleUnreadFilter = () => {
    if (inboxType === InboxType.UNREAD) {
      toBackendInbox(InboxType.ALL);
    } else {
      toBackendInbox(InboxType.UNREAD);
    }
  };

  const handleTagClick = (tag: string) => {
    if (selectedTags.includes(tag)) {
      dispatch(setSelectedTags(selectedTags.filter(t => t !== tag)));
    } else {
      dispatch(setSelectedTags([...selectedTags, tag]));
    }
  };

  const handleRefresh = () => {
    loadConversations.reload();
  };

  const commonIconButtonStyles = {
    width: 28,
    height: 28,
    transition: 'all 0.1s ease-out',
    '&:hover': {
      backgroundColor: 'action.hover',
      transform: 'scale(1.05)',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  };

  const commonIconStyles = {
    fontSize: '1.15rem',
  };

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: allTags.size > 0 ? 1.5 : 0 }}>
        <Typography 
          variant="h3"
          color="text.primary"
        >
          {INBOX_LABELS[userInbox] || 'Messages'}
        </Typography>
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <Tooltip title="Refresh messages">
            <IconButton
              onClick={handleRefresh}
              size="small"
              disabled={isLoading}
              sx={{ 
                ...commonIconButtonStyles,
                color: 'text.secondary',
                '& .MuiSvgIcon-root': {
                  animation: isLoading ? 'spin 1s linear infinite' : 'none',
                },
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(0deg)',
                  },
                  '100%': {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            >
              <Refresh sx={commonIconStyles} />
            </IconButton>
          </Tooltip>
          <Tooltip title={inboxType === InboxType.UNREAD ? "Show all messages" : "Show unread only"}>
            <IconButton
              onClick={toggleUnreadFilter}
              size="small"
              sx={{ 
                ...commonIconButtonStyles,
                backgroundColor: inboxType === InboxType.UNREAD ? 'primary.main' : 'transparent',
                color: inboxType === InboxType.UNREAD ? 'white' : 'text.secondary',
                '&:hover': {
                  backgroundColor: inboxType === InboxType.UNREAD ? 'primary.dark' : 'action.hover',
                  transform: 'scale(1.05)',
                },
              }}
            >
              <MarkEmailUnread sx={commonIconStyles} />
            </IconButton>
          </Tooltip>
          {salesNavigatorClient && hasSalesNavigatorAccess && (
            <>
              <Box sx={{ width: '1px', height: 20, bgcolor: 'divider', mx: 0.5 }} />
              <Tooltip title="LinkedIn DMs">
                <IconButton
                  onClick={() => handleSourceToggle(SOURCE_TYPE.CHAT)}
                  size="small"
                  sx={{ 
                    ...commonIconButtonStyles,
                    backgroundColor: sourceOptions.includes(SOURCE_TYPE.CHAT) ? 'primary.main' : 'transparent',
                    color: sourceOptions.includes(SOURCE_TYPE.CHAT) ? 'white' : 'text.secondary',
                    '&:hover': {
                      backgroundColor: sourceOptions.includes(SOURCE_TYPE.CHAT) ? 'primary.dark' : 'action.hover',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  <LinkedInIcon sx={commonIconStyles} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sales Navigator">
                <IconButton
                  onClick={() => handleSourceToggle(SOURCE_TYPE.SALES_NAVIGATOR)}
                  size="small"
                  sx={{ 
                    ...commonIconButtonStyles,
                    backgroundColor: sourceOptions.includes(SOURCE_TYPE.SALES_NAVIGATOR) ? 'primary.main' : 'transparent',
                    color: sourceOptions.includes(SOURCE_TYPE.SALES_NAVIGATOR) ? 'white' : 'text.secondary',
                    '&:hover': {
                      backgroundColor: sourceOptions.includes(SOURCE_TYPE.SALES_NAVIGATOR) ? 'primary.dark' : 'action.hover',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  <ExploreIcon sx={commonIconStyles} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>

      {allTags.size > 0 && (
        <Box sx={{ 
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.75,
          maxWidth: '100%'
        }}>
          {Array.from(allTags).map((tag) => (
            <StyledTagChip
              key={tag}
              label={tag}
              onClick={() => handleTagClick(tag)}
              className={selectedTags.includes(tag) ? 'selected' : ''}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}; 