import { EXTENSION_ID, MESSAGE_TYPES } from "cfg/endpoints"
import { linkedInProfileCacheKey } from "cfg/localstorage"
import {
  LINKEDIN_CONVERSATION_PARTICIPANT_TYPE, LINKEDIN_MESSAGE_TYPE,
  LINKEDIN_MINI_PROFILE_TYPE, LINKEDIN_OWNPROFILE_TYPE, LINKEDIN_PROFILE_TYPE, LINKEDIN_CONNECTION_TYPE,
  LINKEDIN_VECTOR_IMAGE_TYPE, LINKEDIN_VECTOR_ARTIFACT_TYPE,
  LinkedInConversation, LinkedInConversationList, LinkedInMessage, LinkedInUpload,
  LinkedInConversationParticipant, LinkedInNormalizedParticipant,
  LinkedInOwnProfile, LinkedInProfile, LinkedInConnection, LinkedInFriendUpdate, LinkedInFriendUpdateType,
  LinkedInPicture, LinkedInPictureArtifact,
} from "interfaces/linkedin"
import { LINKEDIN_SALES_MESSAGE_TYPE, LINKEDIN_SALES_PROFILE_TYPE, LinkedInSalesContactSearchResults, LinkedInSalesConversation, LinkedInSalesConversationList, LinkedInSalesCreateMessageResponse, LinkedInSalesMessage, LinkedInSalesProfile } from "interfaces/linkedin-salesnavigator"
import { logInfo, sleep } from "core"

//////////////
// Settings //
//////////////

const _LOG_SCOPE = '[Trellus][LinkedIn integration]'
const LINKEDIN_DOT_COM = 'https://www.linkedin.com'
const LINKEDIN_REALTIME_HOST = 'https://realtime.www.linkedin.com'
const CONVERSATIONS_PER_PAGE = 25
const CONVERSATIONS_PER_PAGE_SALES_NAVIGATOR = 20
const SALES_NAVIGATOR_PAGE_INSTANCE = 'urn:li:page:d_sales2_inbox_conversations_index;i8XHoyyiSQuUhWyl8Dw+Mg=='
const SALES_NAVIGATOR_CONTRACT_CHOOSER_PAGE_INSTANCE = 'urn:li:page:d_sales2_contract_chooser;nrEuHLjhQ6ey9Z/LSG5ptQ=='
const SALES_NAVIGATOR_THREAD_PAGE_INSTANCE = 'urn:li:page:d_sales2_inbox_thread;zBEPIRARRH+kI6hPb3ut9A=='
const SALES_NAVIGATOR_CONVERSATION_DECORATION = [
  '(id,restrictions,archived,unreadMessageCount,nextPageStartsAt,totalMessageCount,',
   'messages*(id,type,contentFlag,deliveredAt,lastEditedAt,subject,body,footerText,blockCopy,attachments,author,systemMessageContent),',
   'participants*~fs_salesProfile(entityUrn,firstName,lastName,fullName,degree,profilePictureDisplayImage))',
].join('')
const SALES_NAVIGATOR_MESSAGE_DECORATION = '%28id%2Ctype%2Cauthor%2Cattachments%2CcontentFlag%2CdeliveredAt%2Csubject%2Cbody%2CfooterText%2CblockCopy%2CsystemMessageContent%29'
const CONNECTIONS_PAGE_INSTANCE = 'urn:li:page:d_flagship3_people_connections;yRswXdw+QnaNEtCZsKJFgQ=='
const VERSION_VOYAGER_WEB = '1.13.19496'
const VERSION_LIGHTHOUSE_WEB = '2.0.2489'
const MYNETWORK_HEADERS = {
  "Accept": "*/*",
  "X-Li-Application-Instance": "TMNaptGBQayr0+QQhFGVZg==",
  "X-Li-Application-Version": "0.2.109",
  "X-Li-Initial-Url": "/mynetwork/grow/",
  "X-Li-Layout-Tree": "[\"c1d5ed298a18d67baedaf8998cd67dc7\",\"7c72ce0150466c5bbd89fdad2b18d46e\",\"a15eca777c146d37da0475b8f19e5d56\"]",
  "X-Li-Rsc-Stream": "true",
}
const MYNETWORK_PAGINATE_HEADERS = {
  "Accept": "*/*",
  "Content-Type": "application/json",
  "X-Li-Application-Instance": "EEj6CcSXTqOPCotamjke7w==",
  "X-Li-Application-Version": "0.2.130",
  "X-Li-Page-Instance-Tracking-Id": "u/i2OhPvSvO7H+hPm/OL6Q==",
  "X-Li-Rsc-Stream": "true",
}
const REALTIME_QUERY_MAP = JSON.stringify({
  "topicToGraphQLQueryParams": {
    "conversationsBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.441c03c3f294c7b672b2feb65067caa3", "variables": {}, "extensions": {}},
    "conversationsTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.441c03c3f294c7b672b2feb65067caa3", "variables": {}, "extensions": {}},
    "conversationDeletesBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.282abe5fa1a242cb76825c32dbbfaede", "variables": {}, "extensions": {}},
    "conversationDeletesTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.282abe5fa1a242cb76825c32dbbfaede", "variables": {}, "extensions": {}},
    "messageReactionSummariesBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.ba93ee1e426e02f0616cdc375ea8a035", "variables": {}, "extensions": {}},
    "messageReactionSummariesTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.ba93ee1e426e02f0616cdc375ea8a035", "variables": {}, "extensions": {}},
    "messageSeenReceiptsBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.6848ca49032983a615be1d37bb545179", "variables": {}, "extensions": {}},
    "messageSeenReceiptsTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.6848ca49032983a615be1d37bb545179", "variables": {}, "extensions": {}},
    "messagesBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.fc1135038175599c85c72de9647a8c36", "variables": {}, "extensions": {}},
    "messagesTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.fc1135038175599c85c72de9647a8c36", "variables": {}, "extensions": {}},
    "replySuggestionBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.412964c3f7f5a67fb0e56b6bb3a00028", "variables": {}, "extensions": {}},
    "replySuggestionTopicV2": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.412964c3f7f5a67fb0e56b6bb3a00028", "variables": {}, "extensions": {}},
    "typingIndicatorsBroadcastTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.8a3db3519f616215d85657da21c07605", "variables": {}, "extensions": {}},
    "typingIndicatorsTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.8a3db3519f616215d85657da21c07605", "variables": {}, "extensions": {}},
    "messagingSecondaryPreviewBannerTopic": {"queryId": "voyagerMessagingDashRealtimeDecoration.a0228e467df9f2ebd7c4c96d06b7c1f8", "variables": {}, "extensions": {}},
    "reactionsTopic": {"queryId": "liveVideoVoyagerSocialDashRealtimeDecoration.b8b33dedca7efbe34f1d7e84c3b3aa81", "variables": {}, "extensions": {}},
    "commentsTopic": {"queryId": "liveVideoVoyagerSocialDashRealtimeDecoration.9eed56392e681683996f77e403981621", "variables": {}, "extensions": {}},
    "reactionsOnCommentsTopic": {"queryId": "liveVideoVoyagerSocialDashRealtimeDecoration.0a181b05b3751f72ae3eb489b77e3245", "variables": {}, "extensions": {}},
    "socialPermissionsPersonalTopic": {"queryId": "liveVideoVoyagerSocialDashRealtimeDecoration.170bf3bfbcca1da322e34f34f37fb954", "variables": {}, "extensions": {}},
    "liveVideoPostTopic": {"queryId": "liveVideoVoyagerFeedDashLiveUpdatesRealtimeDecoration.f6cb0d6fa726c9c0c1ffc6f152ee8da8", "variables": {}, "extensions": {}},
    "generatedJobDescriptionsTopic": {"queryId": "voyagerHiringDashRealtimeDecoration.58501bc70ea8ce6b858527fb1be95007", "variables": {}, "extensions": {}},
    "messageDraftsTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.ea999d7db28b647af21f47b0c9093d83", "variables": {}, "extensions": {}},
    "conversationDraftsTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.e5f056134f6bdb3c9d1eb66015f236c4", "variables": {}, "extensions": {}},
    "messageDraftDeletesTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.e3e4c7121838321a42752b55f487a73e", "variables": {}, "extensions": {}},
    "conversationDraftDeletesTopic": {"queryId": "voyagerMessagingDashMessengerRealtimeDecoration.5288036a2c0e63f49d34ee32b139976c", "variables": {}, "extensions": {}},
    "eventToastsTopic": {"queryId": "voyagerEventsDashProfessionalEventsRealtimeResource.6b42abd3511e267e84a6765257deea50", "variables": {}, "extensions": {}},
    "coachStreamingResponsesTopic": {"queryId": "voyagerCoachDashGaiRealtimeDecoration.28d3ae97945d2900b6805f3e20bc2ae8", "variables": {}, "extensions": {}},
    "realtimeSearchResultClustersTopic": {"queryId": "voyagerSearchDashRealtimeDecoration.5fadaa2da272132c7bb6368786eec086", "variables": {}, "extensions": {}},
    "memberVerificationResultsPersonalTopic": {"queryId": "voyagerTrustDashVerificationRealTimeDecoration.78d27e95dba97623a3f209212e7ecbf3", "variables": {}, "extensions": {}},
  }
})
const REALTIME_RECIPE_MAP = JSON.stringify({
  "inAppAlertsTopic": "com.linkedin.voyager.dash.deco.identity.notifications.InAppAlert-51",
  "professionalEventsTopic": "com.linkedin.voyager.dash.deco.events.ProfessionalEventDetailPage-58",
  "tabBadgeUpdateTopic": "com.linkedin.voyager.dash.deco.notifications.RealtimeBadgingItemCountsEvent-1",
  "topCardLiveVideoTopic": "com.linkedin.voyager.dash.deco.video.TopCardLiveVideo-9",
})
const NEW_MESSAGE_SUBSCRIPTION_QUERY_ID = 'voyagerCoachDashGaiRealtimeDecoration.28d3ae97945d2900b6805f3e20bc2ae8'

/////////////
// Helpers //
/////////////

/**
 * Deduplicate an array by the value of key().
 *
 * @param items - an array to deduplicate
 * @param key - if `key()` evaluates to the same string on multiple members of `items`, only the last is kept.
 *
 * @remarks
 * Later items overwrite earlier items.
 * The output order may be random.
 */
export function uniqueBy<T>(items: T[], key: (item: T) => string): T[] {
  return Object.entries(Object.fromEntries(items.map(x => [key(x), x]))).map(pair => pair[1])
}


////////////////////
// High-level API //
////////////////////

export class LinkedInNotLoggedIn extends Error {
  name = 'LinkedInNotLoggedIn'
  kind = 'LINKEDIN_NOT_LOGGED_IN'
}

export class LinkedInClassesUsageError extends Error {
  name = 'LinkedInClassesUsageError'
}

export class LinkedInChat {
  _api: VoyagerAPI = new VoyagerAPI()
  _finished: boolean = false
  _realtimeSessionId: string = crypto.randomUUID()
  onNewMessage?: (message: LinkedInMessage) => void

  /**
   * Initialize tokens and IDs for connecting to the API.
   *
   * @remarks
   * Call this first. If unsuccessful, may throw any of:
   * - Error(message="MISSING_SITE_PERMISSIONS")
   * - LinkedInNotLoggedIn (above)
   * - Error(message=any message that fetch() or JSON.parse() can throw)
   * - Error(message="No response") if fetch() is broken
   * - Error(message=response content as text) if server returns an HTTP error code
   * - Error(message="No JSON response") if fetch() succeeded but response JSON-decodes to a falsy value
   * - DOMException(name="QuotaExceededError") if localStorage is too full to cache user's profile
   */
  async start() {
    await this._api.start()
    this._startRealtime()
  }

  /**
   * Return whether start() has been called
   */
  get started(): boolean { return this._api.csrfToken !== null && this._api.selfUrn !== null }

  /**
   * Clean up. In particular, tell this._startRealtime() to exit after its next message (about 30s).
   */
  stop() { this._finished = true }

  /**
   * Get the mailbox URN.
   *
   * @remarks
   * Most useful for comparing to the hostIdentityUrn of conversation participants to find which one is the user.
   */
  get selfUrn(): string | null { return this._api.selfUrn }

  /**
   * load up to `CONVERSATIONS_PER_PAGE` conversations in a mailbox, optionally starting from where a previous result left off
   *
   * @param mailboxName - probably 'INBOX' but see also the observed values in LinkedInConversation.categories
   * @param nextCursor - omit to start from the beginning, or the .nextCursor of a previous return value to get the next page of results
   */
  async listConversations(mailboxName: string = 'INBOX', nextCursor: string | null = null): Promise<LinkedInConversationList> {
    if (!this._api.selfUrn) {
      throw new LinkedInNotLoggedIn('Voyager API not started or unable to read user id')
    }
    const queryOptions = {
      userUrn: this._api.selfUrn,
      categoryName: mailboxName,
      count: CONVERSATIONS_PER_PAGE,
      nextCursor: nextCursor,
    }
    let convoResponse = await this._api.getMessengerConversationsByCategoryQuery(queryOptions)
    return {
      conversations: convoResponse.elements.map(_ingestConversation),
      mailboxName,
      nextCursor: convoResponse.metadata.nextCursor,
      continues: convoResponse.metadata.nextCursor !== null,
    }
  }

  /**
   * List unread conversations from all mailboxes
   *
   * @param nextCursor - omit to start from the beginning, or the .nextCursor of a previous return value to get the next page of results
   */
  async listUnreadConversations(nextCursor?: string): Promise<LinkedInConversationList> {
    if (!this._api.selfUrn) {
      throw new LinkedInNotLoggedIn('Voyager API not started or unable to read user id')
    }
    const response = await this._api.getMessengerConversationsBySearchReadState({userUrn: this._api.selfUrn, read: false, nextCursor})
    return {
      conversations: response.elements.map(_ingestConversation),
      mailboxName: null,
      nextCursor: response.metadata.nextCursor,
      continues: response.metadata.nextCursor !== null,
    }
  }

  /**
   * Get recent updates to conversations.
   *
   * @param nextCursor - pass the .nextCursor of a previous return value.
   * @returns a LinkedInConversationList including 1 most recent message from each of the 20 most recent conversations,
   *          with all participants on each. This may re-download messages already received in a previous call,
   *          and may also skip messages if a thread received multiple messages since the previous call.
   *
   * @remarks
   * TODO switch to connecting to /realtime/connect?rc=1 for push updates instead of polling
   * which doesn't appear to be a websocket because the backend wants custom headers
   * which makes inspecting the traffic harder.
   */
  async getConversationUpdates(nextCursor?: string | null): Promise<LinkedInConversationList> {
    if (!this._api.selfUrn) {
      throw new LinkedInNotLoggedIn('Voyager API not started or unable to read user id')
    }
    const response = await this._api.getMessengerConversationsBySyncToken({
      userUrn: this._api.selfUrn,
      syncToken: nextCursor,
    })
    return {
      conversations: response.elements.map(_ingestConversation),
      mailboxName: null,
      nextCursor: response.metadata.newSyncToken,
      continues: true,
    }
  }

  /**
   * load up to 20 more messages at the start of convo.messages
   *
   * @param convo - a LinkedInConversation to expand
   * @returns a copy of `convo` with more messages
   */
  async loadOlderMessages(convo: LinkedInConversation): Promise<LinkedInConversation> {
    let response: any;
    if (convo._loadOlderMessagesPrevCursor === null) {
      return convo
    } else if (convo._loadOlderMessagesPrevCursor === undefined) {
      const oldestTimestamp
        = convo.messages.length > 0
        ? convo.messages.reduce((a, b) => a.deliveredAt < b.deliveredAt ? a : b).deliveredAt
        : new Date();
      response = await this._api.getMessengerMessagesByAnchorTimestamp({
        conversationEntityUrn: convo.entityUrn,
        deliveredAt: oldestTimestamp,
        countBefore: 20,
        countAfter: 0,
      })
    } else {
      response = await this._api.getMessengerMessagesByConversation({
        conversationEntityUrn: convo.entityUrn,
        prevCursor: convo._loadOlderMessagesPrevCursor,
      })
    }
    const newMessages: LinkedInMessage[] = response.elements.map((m: any) => _parseTimestampFields(m, ['deliveredAt']))
    const allMessages = convo.messages.concat(newMessages)
    return {
      ...convo,
      _loadOlderMessagesPrevCursor: response.metadata.prevCursor,
      messages: uniqueBy(allMessages, m => m.entityUrn).sort((a, b) => a.deliveredAt.getTime() - b.deliveredAt.getTime()),
    }
  }

  /**
   * load new messages at the end of convo.messages
   *
   * @param convo - a LinkedInConversation to expand
   * @returns a copy of `convo` with more messages
   */
  async loadNewerMessages(convo: LinkedInConversation): Promise<LinkedInConversation> {
    const response = await this._api.getMessengerMessagesBySyncToken({
      conversationEntityUrn: convo.entityUrn,
      syncToken: convo._loadNewerMessagesSyncToken,
    })
    const newMessages: LinkedInMessage[] = response.elements.map((m: any) => _parseTimestampFields(m, ['deliveredAt']) as LinkedInMessage)
    const allMessages = convo.messages.concat(newMessages)
    return {
      ...convo,
      _loadNewerMessagesSyncToken: response.metadata.newSyncToken,
      messages: uniqueBy(allMessages, m => m.entityUrn).sort((a, b) => a.deliveredAt.getTime() - b.deliveredAt.getTime()),
    }
  }

  /**
   * search among your contacts for recipients to send a message to.
   *
   * @param text - a string to search for
   * @returns an array of LinkedInNormalizedParticipants matching the search string
   *
   * @remarks
   * headers and return format didn't fit into the pattern of the other GraphQL captures so this doesn't use _getGraphQL.
   */
  async searchRecipients(text: string): Promise<LinkedInNormalizedParticipant[]> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    const response = await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/graphql?variables=(keyword:${_encodeVariable(text)},types:List(CONNECTIONS,GROUP_THREADS,PEOPLE,COWORKERS))&queryId=voyagerMessagingDashMessagingTypeahead.e09637f52e3f8bc7d1f2d38b0e1671c9`,
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Pem-Metadata': 'Voyager - Messaging - Typeahead=compose',
      },
    )
    // filter removes non-user returns that Ajinkya saw but Andrew couldn't replicate
    return response.included.filter((x: any) => (x.firstName || x.lastName) && x.entityUrn).map((x: any) => {
      const profilePicture = x.profilePicture?.displayImageReferenceResolutionResult?.vectorImage
      return {
        name: `${x.firstName} ${x.lastName}`,
        tagline: x.headline,
        image: profilePicture ? {
          ...profilePicture,
          artifacts: (profilePicture.artifacts ?? []).map((a: Record<string, any>) => _parseTimestampFields(a, ['expiresAt'])),
        } : null,
        hostIdentityUrn: x.entityUrn,
        entityUrn: 'urn:li:msg_messagingParticipant:' + x.entityUrn,
        url: null,
      }
    })
  }

  async getConversationById(conversationId: string): Promise<LinkedInConversation> {
    const response = await this._api.getMessengerConversationsByIds({conversationEntityUrn: conversationId})
    return _ingestConversation(response[0])
  }

  async getConversationsByIds(conversationIds: string[]): Promise<LinkedInConversation[]> {
    if (!this._api.selfUrn) {
      throw new LinkedInNotLoggedIn('Voyager API not started or unable to read user id')
    }
    const response = await this._api.getMultipleMessengerConversationsByIds({conversationEntityUrns: conversationIds})
    return response.map(_ingestConversation)
  }

  /**
   * Search existing conversations for a string.
   *
   * @param text - string to search for. Will match both participant names and message contents.
   * @param nextCursor - omit to start from the beginning, or the .nextCursor of a previous return value to get the next page of results
   */
  async searchConversations(text: string, nextCursor?: string): Promise<LinkedInConversationList> {
    if (!this._api.selfUrn) {
      throw new LinkedInNotLoggedIn('Voyager API not started or unable to read user id')
    }
    const response = await this._api.getMessengerConversationsBySearchText({userUrn: this._api.selfUrn, keyword: text, nextCursor})
    return {
      conversations: response.elements.map(_ingestConversation),
      mailboxName: null,
      nextCursor: response.metadata.nextCursor,
      continues: response.metadata.nextCursor !== null,
    }
  }

  /**
   * Find existing conversations with exactly these recipients, excluding yourself
   *
   * @param recipientUrns - returned conversations' participants will have these as their hostIdentityUrns
   */
  async getConversationsByRecipients(recipientUrns: string[]): Promise<LinkedInConversation[]> {
    if (!this._api.selfUrn) {
      throw new LinkedInNotLoggedIn('Voyager API not started or unable to read user id')
    }
    const response = await this._api.getMessengerConversationsByRecipients({userUrn: this._api.selfUrn, recipientUrns})
    return response.elements.map(_ingestConversation)
  }

  /**
   * Start a new thread with a message to a list of recipients.
   *
   * @param text - message to send
   * @param toUrns - send to participants with these hostIdentityUrns
   * @param attachments - optional array of File objects to attach.
   *                      File objects can be found in .files on an <input type="file">
   *                      or in .dataTransfer.files on a drop event.
   */
  sendToNewThread(text: string, toUrns: string[], attachments?: File[]): Promise<LinkedInMessage> {
    for (const u of toUrns) {
      if (u.startsWith('urn:li:fs_sales')) {
        throw new LinkedInClassesUsageError(`Ordinary LinkedInChat cannot send to Sales Navigator recipient ${u}`)
      }
    }
    return this._sendMessage(text, toUrns, undefined, attachments)
  }

  /**
   * Send a message to an existing thread.
   *
   * @param text - message to send
   * @param conversationEntityUrn - send to the conversation that has this entityUrn
   * @param attachments - optional array of File objects to attach
   */
  sendToConversation(text: string, conversationEntityUrn: string, attachments?: File[]): Promise<LinkedInMessage> {
    if (!conversationEntityUrn.startsWith('urn:li:msg_conversation:')) {
      throw new LinkedInClassesUsageError(`Blocked send to what doesn't look like a chat conversation URN: ${conversationEntityUrn}`)
    }
    return this._sendMessage(text, undefined, conversationEntityUrn, attachments)
  }

  private async _sendMessage(text: string, toUrns?: string[], conversationEntityUrn?: string, attachments?: File[]): Promise<LinkedInMessage> {
    if (!this._api.csrfToken || !this._api.selfUrn) {
      throw new Error('VoyagerAPI not started')
    }
    const uploads = await Promise.all((attachments ?? []).map(f => this._uploadFile(f)))
    const response = await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/voyagerMessagingDashMessengerMessages?action=createMessage`,
      {
        'Accept': 'application/json',
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      JSON.stringify({
        dedupeByClientGeneratedToken: false,
        hostRecipientUrns: toUrns,
        mailboxUrn: this._api.selfUrn,
        trackingId: Array.from(crypto.randomUUID().replaceAll('-', '').matchAll(/../g)).map(g => String.fromCharCode(parseInt(g[0], 16))).join(''),
        message: {
          body: {
            attributes: [],
            text: text,
          },
          originToken: crypto.randomUUID(),
          renderContentUnions: uploads.map(u => {return {file: u}}),
          conversationUrn: conversationEntityUrn,
        },
      }),
    )
    return {
      _type: LINKEDIN_MESSAGE_TYPE,
      // ids
      backendUrn: response.value.backendUrn,
      backendConversationUrn: response.value.backendConversationUrn,
      entityUrn: response.value.entityUrn,
      conversation: { entityUrn: response.value.conversationUrn },
      // defining properties
      subject: response.value.subject ?? null,
      body: response.value.body,
      footer: response.value.footer ?? null,
      sender: {
        _type: LINKEDIN_CONVERSATION_PARTICIPANT_TYPE,
        hostIdentityUrn: this._api.selfUrn,
        entityUrn: response.value.senderUrn,
      },
      messageBodyRenderFormat: response.value.messageBodyRenderFormat,
      // attachments and poll options
      renderContent: response.value.renderContentUnions,
      reactionSummaries: [],
      // peripheral state
      actor: null,
      deliveredAt: new Date(response.value.deliveredAt),
    }
  }

  /**
   * Upload a file.
   *
   * @param file - a File object from an <input type="file"> (.files) or a drop event (.dataTransfer.files)
   *
   * @internal until we decide we want to decouple uploading from sending messages
   */
  private async _uploadFile(file: File): Promise<LinkedInUpload> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    return await _uploadFileImpl(file, this._api.csrfToken, `${LINKEDIN_DOT_COM}/voyager/api/voyagerVideoDashMediaUploadMetadata?action=upload`, {
      'Accept': 'application/vnd.linkedin.normalized+json+2.1',
      'Content-Type': 'application/json; charset=UTF-8',
    })
  }

  /**
   * Change the subject line of a conversation.
   *
   * @param conversationEntityUrn - modify the conversation that has this entityUrn
   * @param newTitle - the new subject line
   *
   * @remarks
   * This will fail if conversation.disabledFeatures.some(f => f.disabledFeature === "RENAMED_CONVERSATION")
   */
  async setConversationTitle(conversationEntityUrn: string, newTitle: string): Promise<void> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/voyagerMessagingDashMessengerConversations/${encodeURIComponent(conversationEntityUrn)}`,
      {
        'Accept': 'application/json',
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      JSON.stringify({
        patch: {
          "$set": {
            title: newTitle,
          },
        },
      }),
      {expectEmpty: true},
    )
    // nothing to return; the above endpoint returns HTTP 204 No Content on success
  }

  /**
   * Mark a conversation as read or unread.
   *
   * @param conversationEntityUrn - mark the conversation that has this entityUrn
   * @param read - if true, mark as read. if false, mark as unread.
   */
  async setConversationReadState(conversationEntityUrn: string, read: boolean): Promise<void> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    const response = await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/voyagerMessagingDashMessengerConversations?ids=List(${_encodeVariable(conversationEntityUrn)})`,
      {
        'Accept': 'application/json',
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      JSON.stringify({
        entities: {
          [conversationEntityUrn]: {
            patch: {
              "$set": {
                read: read,
              },
            },
          },
        },
      }),
    )
    if (response?.results?.[conversationEntityUrn]?.status !== 204) {
      throw new Error(Object.entries(response.errors).map(([k, v]) => `${k}=${v}`).join(', '))
    }
  }

  /**
   * Mark all conversations as seen.
   *
   * @remarks
   * Andrew has not checked whether "seen" and "read" are different concepts.
   *
   * @experimental
   */
  async markAllAsSeen(): Promise<void> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/voyagerMessagingDashMessagingBadge?action=markAllMessagesAsSeen`,
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'Content-Type': 'application/json; charset=UTF-8',
      },
      JSON.stringify({until: Math.round(new Date().getTime())}),
      {expectEmpty: true},
    )
    // nothing to return; the above endpoint returns HTTP 200 Ok with empty response text on success
  }

  /**
   * Add an emoji reaction to a message.
   *
   * @param messageEntityUrn - react to the message that has this entityUrn
   * @param emoji - a 1-codepoint string identifying the emoji to react with
   *
   * @remarks
   * This will fail if conversation.disabledFeatures.some(f => f.disabledFeature === "REACTIONS")
   */
  async addEmojiReaction(messageEntityUrn: string, emoji: string): Promise<void> {
    return await this._setEmojiReaction(messageEntityUrn, emoji, true)
  }

  /**
   * Remove a previously added emoji reaction.
   *
   * @param messageEntityUrn - "unreact" to the message that has this entityUrn
   * @param emoji - a 1-codepoint string identifying the emoji to remove
   *
   * @remarks
   * This will fail if conversation.disabledFeatures.some(f => f.disabledFeature === "REACTIONS")
   */
  async removeEmojiReaction(messageEntityUrn: string, emoji: string): Promise<void> {
    return await this._setEmojiReaction(messageEntityUrn, emoji, false)
  }

  private async _setEmojiReaction(messageEntityUrn: string, emoji: string, isAdd: boolean): Promise<void> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/voyagerMessagingDashMessengerMessages?action=${isAdd ? 'reactWithEmoji' : 'unreactWithEmoji'}`,
      {
        'Accept': 'application/json',
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      JSON.stringify({messageUrn: messageEntityUrn, emoji}),
      {expectEmpty: true},
    )
    // nothing to return; the endpoint returns HTTP 200 Ok with empty response text on success
  }

  /**
   * Get up to 40 connections, starting with the most recently accepted friend request.
   *
   * @param skip - skip this many results first, for pagination. Probably a multiple of 40.
   */
  async getRecentConnections(skip: number = 0): Promise<LinkedInConnection[]> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    const response = await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/voyager/api/relationships/dash/connections?` + [
        'decorationId=com.linkedin.voyager.dash.deco.web.mynetwork.ConnectionListWithProfile-16',
        'count=40',
        'q=search',
        'sortType=RECENTLY_ADDED',
        `start=${skip}`,
      ].join('&'),
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Page-Instance': CONNECTIONS_PAGE_INSTANCE,
      },
    )
    const profilesByUrn: Map<string, LinkedInProfile[]> = new Map(response.included.filter((x: any) => x.$type === LINKEDIN_PROFILE_TYPE).map((p: any) => {
      // backend probably overlays some sort of badge on the original profile pic if they're hiring or jobseeking
      const rawProfilePicture = p.profilePicture?.displayImageWithFrameReferenceUnion ?? p.profilePicture?.displayImageReference
      return [p.entityUrn, {
        ...p,
        offers: p.profilePicture?.frameType ?? null,
        picture: rawProfilePicture ? {
          ...rawProfilePicture,
          digitalMediaAsset: p.profilePicture.displayImageUrn,
          artifacts: (rawProfilePicture.artifacts ?? []).map((a: Record<string, any>) => _parseTimestampFields(a, ['expiresAt'])),
        } : null,
      }]
    }))
    return response.included.filter((c: any) => c.$type === LINKEDIN_CONNECTION_TYPE && profilesByUrn.has(c.connectedMember)).map((c: any) => ({
      createdAt: _coerceToTimestamp(c.createdAt),
      to: profilesByUrn.get(c.connectedMember),
    }))
  }

  /**
   * Get up to 10 friend updates (job changes and anniversaries), starting with most recent.
   *
   * @param skip - skip this many results first, for pagination. Probably a multiple of 10.
   * @param kind - filter to a specific type of update.
   */
  async getRecentFriendUpdates(skip: number = 0, kind: LinkedInFriendUpdateType = LinkedInFriendUpdateType.ALL): Promise<LinkedInFriendUpdate[]> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    const nurturePill = {
      [LinkedInFriendUpdateType.ALL]: 'All',
      [LinkedInFriendUpdateType.BIRTHDAY]: 'Birthday',
      [LinkedInFriendUpdateType.WORK_ANNIVERSARY]: 'WorkAnniversary',
      [LinkedInFriendUpdateType.JOB_CHANGE]: 'JobChange',
      [LinkedInFriendUpdateType.EDUCATION_PROP]: 'Education',
    }[kind]
    const paginateArgs = {
      "$type": "proto.sdui.actions.requests.RequestedArguments",
      "payload": {
        "nurturePill": nurturePill,
        "startIndex": skip,
        "highlightedUrns": [],
        "useDestinationNavigation": true,
        "useNavGraph2": false
      },
      "requestedStateKeys": [],
    }
    const response: ExtensionFetchSuccess = skip <= 0 ? await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/flagship-web/mynetwork/catch-up/${{
        [LinkedInFriendUpdateType.ALL]: 'all',
        [LinkedInFriendUpdateType.BIRTHDAY]: 'birthday',
        [LinkedInFriendUpdateType.WORK_ANNIVERSARY]: 'work_anniversaries',
        [LinkedInFriendUpdateType.JOB_CHANGE]: 'job_changes',
        [LinkedInFriendUpdateType.EDUCATION_PROP]: 'education',
      }[kind]}/`,
      MYNETWORK_HEADERS,
      undefined,
      {returnRaw: true},
    ) : await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/flagship-web/rsc-action/actions/pagination?sduiid=com.linkedin.sdui.pagers.mynetwork.addaNurtureCards`,
      {
        ...MYNETWORK_PAGINATE_HEADERS,
        "X-Li-Anchor-Page-Key": "d_flagship3_nurture_" + {
          [LinkedInFriendUpdateType.ALL]: 'all',
          [LinkedInFriendUpdateType.BIRTHDAY]: 'birthday',
          [LinkedInFriendUpdateType.WORK_ANNIVERSARY]: 'work_anniversary',
          [LinkedInFriendUpdateType.JOB_CHANGE]: 'job_change',
          [LinkedInFriendUpdateType.EDUCATION_PROP]: 'education',
        }[kind],
      },
      JSON.stringify({
        "clientArguments": {
          ...paginateArgs,
          "states": []
        },
        "paginationRequest": {
          "$type": "proto.sdui.actions.requests.PaginationRequest",
          "pagerId": "com.linkedin.sdui.pagers.mynetwork.addaNurtureCards",
          "payload": paginateArgs.payload,
          "requestedArguments": paginateArgs,
          "trigger": {
            "$case": "itemDistanceTrigger",
            "itemDistanceTrigger": {
              "$type": "proto.sdui.actions.requests.ItemDistanceTrigger",
              "preloadDistance": 2
            }
          },
          "retryCount": 2
        }
      }),
      {returnRaw: true},
    )
    const fragments: [string, string, any][] = []
    for (const line of response.text.split('\n')) {  // the response appears to be a newline-separated list
      const groups = line.match(/^([^:]*):(.*)$/)?.slice(1)  // of lines consisting of a label, a colon, and a payload
      if (!groups) { continue }  // ...or blank lines
      const [num, contentJson] = groups
      if (contentJson.startsWith('I')) { continue }  // the payload might be "I" followed by a json array (probably an include directive)
      try {
        fragments.push([num, contentJson, JSON.parse(contentJson)])  // or a json array
      } catch (e) {}
    }
    const numToProfilePicture: Record<string, LinkedInPicture> = {}
    const profileCardNumToPictureNum: Record<string, string> = {}
    const seenUpdateUrns: Set<string> = new Set()
    const updates: Omit<LinkedInFriendUpdate, 'userName'>[] = []
    const congratulateButtonNumToUpdate: Record<string, Omit<LinkedInFriendUpdate, 'userName'>> = {}
    const userIdToName: Record<string, string> = {}
    for (const [num, contentJson, content] of fragments) if (content instanceof Array) for (const item of content) if (item instanceof Object) {
      // profile pictures
      if (String(item.a11yText).endsWith('s profile picture')) {
        numToProfilePicture[num] = {
          $type: LINKEDIN_VECTOR_IMAGE_TYPE,
          rootUrl: item.renderPayload?.rootUrl ?? '',
          digitalmediaAsset: item.renderPayload?.assetUrn,
          artifacts: (item.renderPayload?.imageRenditions ?? []).map((r: any): LinkedInPictureArtifact => ({
            $type: LINKEDIN_VECTOR_ARTIFACT_TYPE,
            width: r.width,
            height: r.height,
            fileIdentifyingUrlPathSegment: r.suffixUrl,
          }))
        }
      }
      // profile cards
      if (item.viewTrackingSpecs?.viewName === 'nurture-card-profile-view') {
        for (const child of item.children) {
          if (
            child instanceof Object
            && child.triggers instanceof Array
            && typeof child.children === 'string'
            && child.children.startsWith('$L')
          ) {
            profileCardNumToPictureNum[num] = child.children.slice(2)
          }
        }
      }
      // string match before doing recursive search
      if (contentJson.includes('actorHeadline') || contentJson.includes('profileId')) {
        for (const update of recurseFilterMap(item, (key, value) => {
          // about the update, scraped from the suggestion to send a congratulatory message
          if (
            key === 'params'
            && value instanceof Object
            && 'actorHeadline' in value
            && 'recipientNonIterableId' in value
            && 'messageSentBinding' in value
            && ('activityUrn' in value || 'propUrn' in value)
          ) {
            const kindStr = String(value.messageSentBinding).match(/^urn:li:prop:\(([^,]*),/)?.[1]
            const kind = kindStr && Object.keys(LinkedInFriendUpdateType).includes(kindStr) ? (kindStr as LinkedInFriendUpdateType) : undefined
            return {
              truncate: true,
              emit: kind ? {
                activityUrn: String(value.activityUrn ?? value.propUrn),
                kind,
                description: String(value.actorHeadline),
                userId: String(value.recipientNonIterableId),
              } : undefined,
            }
          }
          // about the user, scraped from the buttons to control whether you keep receiving notifications
          if (
            key === 'params'
            && value instanceof Object
            && 'firstName' in value
            && 'lastName' in value
            && 'profileId' in value
          ) {
            userIdToName[String(value.profileId)] = `${String(value.firstName).trim()} ${String(value.lastName).trim()}`
            return {truncate: true}
          }
          return {}
        })) {
          if (!seenUpdateUrns.has(update.activityUrn)) {
            seenUpdateUrns.add(update.activityUrn)
            updates.push(update)
            congratulateButtonNumToUpdate[num] = update
          }
        }
      }
    }
    // attach profile pictures to updates
    for (const [_, contentJson, content] of fragments) if (contentJson.includes('"nurture-card"')) {
      for (const nurtureCard of recurseFilterMap(content, (key, value) => {
        if (value instanceof Object && value.viewTrackingSpecs?.viewName === "nurture-card") {
          return {truncate: true, emit: value.children}
        }
        return {}
      })) {
        let targetUpdate: Omit<LinkedInFriendUpdate, 'userName'> | null = null
        let profilePicture: LinkedInPicture | null = null
        for (const str of recurseFilterMap(nurtureCard, (_, v2) => typeof v2 === 'string' ? {emit: v2} : {})) {
          const ref = str.match(/^[$][A-Z](.*)/)?.[1]
          if (!ref) { continue }
          if (ref in congratulateButtonNumToUpdate) { targetUpdate = congratulateButtonNumToUpdate[ref] }
          if (ref in profileCardNumToPictureNum) { profilePicture = numToProfilePicture[profileCardNumToPictureNum[ref]] }
        }
        if (targetUpdate && profilePicture) { targetUpdate.userPicture = profilePicture }
      }
    }
    // attach usernames to updates
    const result = []
    for (const update of updates) {
      const userName = userIdToName[update.userId]
      if (!userName) { continue }
      result.push({
        ...update,
        userName,
      })
    }
    return result
  }

  /**
   * Long-running task to listen for realtime events like inbound replies.
   */
  private async _startRealtime() {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    // we need a member id and a connection ("client") id to subscribe to DMs
    const memberUrnResponse = await _makeRequest(
      this._api.csrfToken,
      [
        `${LINKEDIN_DOT_COM}/psettings/policy/notices?types=COMMENTS_VISIBILITY&types=TRACK_LIKE_FOR_PERSONALIZATION`,
        'types=SHARING_POST_VISIBILITY_FOR_PERSONALIZATION&types=POST_VISBILE_ON_GROUP&types=EASY_APPLY_PHONE_NUMBER',
        'types=RESUME_UPLOAD&types=MESSAGE_CONTROL&types=SMART_REPLIES&types=MANAGE_CONTACT_PAGE&types=MY_NETWORK_PAGE',
        'types=REGISTRATION_FLOW_PROFILE_VISIBILITY&types=EDIT_FEED_ACTIVITY&types=SAVE_SEARCH_FOR_PERSONALIZATION',
        'types=FIELDS_VISIBLE_ON_PROFILE&types=PUBLIC_VISIBILITY_ON_PROFILE&types=LOCATION_VISIBLE_ON_PROFILE',
        'types=CONTROL_PROFILE_PHOTO&types=KEEPING_ORIGINAL_PHOTO_ON_PROFILE&types=CONTROL_DOWNLOADABLE_PROFILE_SECTIONS',
        'types=CONTACT_INFO_VISIBILITY&types=CONTROL_PROFILE_VISIBILITY'
      ].join('&'),
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Requested-With': 'XMLHttpRequest',  // lol lies
      }
    )
    const memberUrn = String(memberUrnResponse?.content?.member)
    const memberId = memberUrn.match(/^urn:li:member:(.*)/)?.[1]
    if (!memberId) {
      logInfo(`${_LOG_SCOPE} Couldn't get LinkedIn member ID number to receive DMs`)
      return
    }
    let clientId: string | null = null
    // set up heartbeating and sync
    await this._sendRealtimeHeartbeat(true)
    const skewCorrectionMs = (await this._getRealtimeTimestamp()).getTime() - new Date().getTime()
    const heartbeatInterval = setInterval(() => this._sendRealtimeHeartbeat(false), 60000)

    try {
      for await (const event of _subscribeToEventStreamWithReconnection(
        this._api.csrfToken,
        `${LINKEDIN_DOT_COM}/realtime/connect?rc=1`,
        {
          'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
          'X-Li-Query-Accept': 'application/graphql',
          'X-Li-Query-Map': REALTIME_QUERY_MAP,
          'X-Li-Recipe-Accept': 'application/vnd.linkedin.normalized+json+2.1',
          'X-Li-Recipe-Map': REALTIME_RECIPE_MAP,
          'X-Li-Realtime-Session': this._realtimeSessionId,
        },
      )) {
        const eventData = JSON.parse(event.data)
        // once we get a client id, subscribe to inbound personal messages
        if (eventData['com.linkedin.realtimefrontend.ClientConnection']) {
          clientId = eventData['com.linkedin.realtimefrontend.ClientConnection'].id
          const subscription = '(' + [
              `clientConnectionId:${clientId}`,
              `topic:urn%3Ali-realtime%3AmessageStreamingUpdatesBroadcastTopic%3Aurn%3Ali%3AmessagingMailbox%3Aurn%3Ali%3AcoachMailbox%3Aurn%3Ali%3Amember%3A${memberId}`,
            ].join(',') + ')'
          const subscribeResponse = await _makeRequest(
            this._api.csrfToken,
            `${LINKEDIN_DOT_COM}/realtime/realtimeFrontendSubscriptions?ids=List(${subscription})`,
            {
              'Accept': '*/*',
              'Content-Type': 'text/plain;charset=UTF-8',
              'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
              'X-Li-Query-Accept': 'application/graphql',
              'X-Li-Query-Map': REALTIME_QUERY_MAP,
              'X-Li-Recipe-Accept': 'application/vnd.linkedin.normalized+json+2.1',
              'X-Li-Recipe-Map': REALTIME_RECIPE_MAP,
              'X-Li-Realtime-Session': this._realtimeSessionId,
            },
            JSON.stringify({
              entities: {[subscription]: {
                authToken: 'any',
                graphQLQueryParams: {queryId: NEW_MESSAGE_SUBSCRIPTION_QUERY_ID}
              }}
            }),
            {method: 'PUT'},
          )
          if (subscribeResponse?.results?.[subscription]?.status !== 200) {
            logInfo(`${_LOG_SCOPE} Failed to subscribe to incoming messages: ${JSON.stringify(subscribeResponse)}`)
          }
        }
        // run callbacks when we get subscribed events
        if (eventData['com.linkedin.realtimefrontend.DecoratedEvent']) {
          const decoratedEvent = eventData['com.linkedin.realtimefrontend.DecoratedEvent'].payload
          const decoration = decoratedEvent?.data?.doDecorateMessageMessengerRealtimeDecoration?.result
          if (decoration?._type === LINKEDIN_MESSAGE_TYPE) {
            this.onNewMessage?.(_ingestMessage(decoration))
          }
        }
        // insert temporary logging here when looking for new kinds of event stream payloads
        if (this._finished) { break }
      }
      clearInterval(heartbeatInterval)
    } catch (e) {
      clearInterval(heartbeatInterval)
      throw e
    }
  }

  private async _sendRealtimeHeartbeat(isFirst: boolean = false) {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/realtime/realtimeFrontendClientConnectivityTracking?action=sendHeartbeat`,
      {
        'Accept': '*/*',
        'Content-Type': 'text/plain;charset=UTF-8',
        'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Query-Accept': 'application/graphql',
        'X-Li-Query-Map': REALTIME_QUERY_MAP,
        'X-Li-Recipe-Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Recipe-Map': REALTIME_RECIPE_MAP,
        'X-Li-Realtime-Session': this._realtimeSessionId,
      },
      JSON.stringify({
        isFirstHeartBeat: isFirst,
        isLastHeartBeat: false,
        realtimeSessionId: this._realtimeSessionId,
        mpName: 'voyager-web',
        mpVersion: VERSION_VOYAGER_WEB,
        clientId: 'voyager-web',
      }),
      {expectEmpty: true},
    )
  }

  private async _getRealtimeTimestamp(): Promise<Date> {
    if (!this._api.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    const response = await _makeRequest(
      this._api.csrfToken,
      `${LINKEDIN_DOT_COM}/realtime/realtimeFrontendTimestamp`,
      {
        'Accept': '*/*',
        'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Query-Accept': 'application/graphql',
        'X-Li-Query-Map': REALTIME_QUERY_MAP,
        'X-Li-Recipe-Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Recipe-Map': REALTIME_RECIPE_MAP,
        'X-Li-Realtime-Session': this._realtimeSessionId,
      },
    )
    return new Date(response.timestamp)
  }
}

/////////////////////////
// Sales Navigator API //
/////////////////////////

export class LinkedInSalesNavigatorChat {
  _csrfToken: string | null = null
  _salesNavigatorIdentity: string | null = null
  _finished: boolean = false
  _realtimeSessionId: string = crypto.randomUUID()  // yeah LinkedIn's own frontend generates this randomly too

  onNewMessage?: (message: LinkedInSalesMessage) => void

  /**
   * Initialize tokens and IDs for connecting to the Sales Navigator API.
   *
   * @remarks
   * Call this first. If unsuccessful, may throw any of:
   * - Error(message="MISSING_SITE_PERMISSIONS")
   * - LinkedInNotLoggedIn (above)
   * - LinkedInAPIError(message=any message that fetch() or JSON.parse() can throw)
   */
  async start() {
    this._csrfToken = await _getCsrfToken()
    this._salesNavigatorIdentity = await _getLoggedInSalesNavigatorIdentity(this._csrfToken)

    this._startRealtime()
  }

  /**
   * Clean up. In particular, tell this._startRealtime() to exit after its next message (about 30s).
   */
  stop() {
    this._finished = true
  }

  /**
   * Return whether start() has been called
   */
  get started(): boolean { return this._csrfToken !== null }

  /**
   * Whether the logged in user has Sales Navigator access.
   */
  get hasAccess(): boolean { return this._salesNavigatorIdentity !== null }

  /**
   * Load up to 20 conversations in one Sales Navigator mailbox
   *
   * @param filterName - 'INBOX', 'UNREAD', 'ARCHIVED', 'SENT', 'INMAIL_PENDING', 'INMAIL_ACCEPTED', or 'INMAIL_DECLINED'.
   *                      Probably 'INBOX', because it seems to contain all the others.
   * @param pageStartsAt - omit for the most recent, or the .nextPageStartsAt of a previous return value to get the next page of results
   */
  async listConversations(filterName: string = 'INBOX', pageStartsAt: Date = new Date()): Promise<LinkedInSalesConversationList> {
    return await this._getSalesNavigatorConversations({filterName, pageStartsAt})
  }

  /**
   * List unread Sales Navigator conversations.
   *
   * @param pageStartsAt - omit for the most recent, or the .nextPageStartsAt of a previous return value to get the next page of results
   */
  async listUnreadConversations(pageStartsAt: Date = new Date()): Promise<LinkedInSalesConversationList> {
    return await this._getSalesNavigatorConversations({filterName: 'UNREAD', pageStartsAt})
  }

  /**
   * Search for conversations matching a text string in one Sales Navigator mailbox
   *
   * @param searchText - string to search for
   * @param pageStartsAt - omit for the most recent, or the .nextPageStartsAt of a previous return value to get the next page of results
   */
  async searchConversations(searchText: string, pageStartsAt: Date = new Date()): Promise<LinkedInSalesConversationList> {
    return await this._getSalesNavigatorConversations({searchText, pageStartsAt})
  }

  /**
   * Search for conversations in one Sales Navigator mailbox with exactly these recipients, excluding yourself
   *
   * @param recipientUrns - recipient URNs of the form 'urn:li:fs_salesProfile:(xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx_xxxxxx,NAME_SEARCH,xxxx)', not compatible with regular chat
   * @param pageStartsAt - omit for the most recent, or the .nextPageStartsAt of a previous return value to get the next page of results
   */
  async getConversationsByRecipients(recipientUrns: string[], pageStartsAt: Date = new Date()): Promise<LinkedInSalesConversationList> {
    return await this._getSalesNavigatorConversations({recipientUrns, pageStartsAt})
  }

  private async _getSalesNavigatorConversations(options: {filterName?: string, searchText?: string, recipientUrns?: string[], pageStartsAt?: Date}): Promise<LinkedInSalesConversationList> {
    const {filterName = 'INBOX', searchText: search = null, recipientUrns = null, pageStartsAt = new Date()} = options
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    const url = `${LINKEDIN_DOT_COM}/sales-api/salesApiMessagingThreads?` + [
      search ? `keyword=${_encodeVariable(search)}` : recipientUrns ? `recipients=List(${recipientUrns.map(_encodeVariable).join(',')})` : null,
      `decoration=${_encodeVariable(SALES_NAVIGATOR_CONVERSATION_DECORATION)}`,
      `count=${CONVERSATIONS_PER_PAGE_SALES_NAVIGATOR.toString()}`,
      search ? null : recipientUrns ? null : `filter=${_encodeVariable(filterName)}`,
      `pageStartsAt=${Math.round(pageStartsAt.getTime()).toString()}`,
      `q=${search ? 'keyword' : recipientUrns ? 'recipients' : 'filter'}`,
    ].filter(x => x !== null).join('&')
    const response = await _makeRequest(this._csrfToken, url.toString(), {
      'Accept': 'application/vnd.linkedin.normalized+json+2.1',
      'X-Li-Identity': this._salesNavigatorIdentity,
      'X-Li-Page-Instance': SALES_NAVIGATOR_PAGE_INSTANCE,
    }, undefined, {claimToBeLighthouse: true})
    const participantsByEntityUrn: Map<string, LinkedInSalesProfile> = new Map(
      response.included
      .filter((x: any) => x.$type === LINKEDIN_SALES_PROFILE_TYPE)
      .map((x: LinkedInSalesProfile) => [x.entityUrn, x]))
      return {
      conversations: response.data.elements.map((c: any) => _ingestSalesNavigatorConversation(c, participantsByEntityUrn)),
      filterName: filterName,
      nextPageStartsAt: response.data.elements.length > 0 ? new Date(Math.min(...response.data.elements.map((c: any) => c.nextPageStartsAt))) : pageStartsAt,
      continues: response.data.paging.total !== 0,
    }
  }

  /**
   * load a single LinkedInSalesConversation by thread id
   *
   * @param threadId - unescaped `id` of the LinkedInSalesConversation to retrieve.
   */
  async getConversationById(threadId: string): Promise<LinkedInSalesConversation> {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    const url = `${LINKEDIN_DOT_COM}/sales-api/salesApiMessagingThreads/${_encodeVariable(threadId)}?` + [
      `decoration=${_encodeVariable(SALES_NAVIGATOR_CONVERSATION_DECORATION)}`,
      'count=1',
      'messageCount=10',
    ].join('&')
    const response = await _makeRequest(
      this._csrfToken,
      url,
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Identity': this._salesNavigatorIdentity,
        'X-Li-Page-Instance': SALES_NAVIGATOR_THREAD_PAGE_INSTANCE,
      },
      undefined,
      {claimToBeLighthouse: true},
    )
    const participantsByEntityUrn: Map<string, LinkedInSalesProfile> = new Map(
      response.included
      .filter((x: any) => x.$type === LINKEDIN_SALES_PROFILE_TYPE)
      .map((x: LinkedInSalesProfile) => [x.entityUrn, x]))
    return _ingestSalesNavigatorConversation(response.data, participantsByEntityUrn)
  }

  /**
   * load up to 10 more messages at the start of convo.messages
   *
   * @param convo - a LinkedInSalesConversation to expand
   * @returns a copy of `convo` with more messages
   *
   * @remarks
   * Will be a no-op if convo.messages.length >= convo.totalMessageCount.
   */
  async loadOlderMessages(convo: LinkedInSalesConversation): Promise<LinkedInSalesConversation> {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }

    if (!convo.messages.length) {
      const update = await this.getConversationById(convo.id)
      return {
        ...update,
        participants: uniqueBy(update.participants.concat(convo.participants), p => p.entityUrn),
        messages: uniqueBy(update.messages.concat(convo.messages), m => m.id).sort((a, b) => a.deliveredAt.getTime() - b.deliveredAt.getTime()),
      }
    } else {
      const earliestDeliveredAtMs = Math.round(Math.min(...convo.messages.map(m => m.deliveredAt).filter(t => t).map(t => t.getTime())))
      const url = `${LINKEDIN_DOT_COM}/sales-api/salesApiMessagingThreads/${convo.id}/messages?` + [  // LinkedIn doesn't urlencode the "==" at the end of convo.id either
        `decoration=${SALES_NAVIGATOR_MESSAGE_DECORATION}`,
        'count=10',
        `deliveredBefore=${earliestDeliveredAtMs - 1}`,
      ].join('&')
      const response = await _makeRequest(
        this._csrfToken,
        url,
        {
          'Accept': 'application/vnd.linkedin.normalized+json+2.1',
          'X-Li-Identity': this._salesNavigatorIdentity,
          'X-Li-Page-Instance': SALES_NAVIGATOR_THREAD_PAGE_INSTANCE,
        },
        undefined,
        {claimToBeLighthouse: true},
      )
      const newParticipants = response.included.filter((x: any) => x.$type === LINKEDIN_SALES_PROFILE_TYPE)
      const newMessages: LinkedInSalesMessage[] = response.data.elements.map((m: any) => _ingestSalesNavigatorMessage(m, convo.id))
      return {
        ...convo,
        participants: uniqueBy(convo.participants.concat(newParticipants), p => p.entityUrn),
        messages: uniqueBy(newMessages.concat(convo.messages), m => m.id).sort((a, b) => a.deliveredAt.getTime() - b.deliveredAt.getTime()),
      }
    }
  }

  /**
   * search your network for recipients to contact in Sales Navigator.
   *
   * @param text - a string to search for
   * @returns a LinkedInSalesContactSearchResults, containing separate arrays of matched people and companies
   */
  async searchRecipients(text: string): Promise<LinkedInSalesContactSearchResults> {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    const response = await _makeRequest(
      this._csrfToken,
      `${LINKEDIN_DOT_COM}/sales-api/salesApiGlobalTypeahead/${_encodeVariable(text)}?suggestedFiltersEnabled=true&decorationId=com.linkedin.sales.deco.desktop.common.DecoratedGlobalTypeaheadResults-6`,
      {
        'Accept': '*/*',
        'X-Li-Identity': this._salesNavigatorIdentity,
        'X-Li-Page-Instance': SALES_NAVIGATOR_PAGE_INSTANCE,
      },
      undefined,
      {claimToBeLighthouse: true},
    )
    return {
      people: Object.values(response.suggestedMemberHitsResolutionResults),
      companies: Object.values(response.suggestedCompanyHitsResolutionResults),
    }
  }


  /**
   * Start a new thread with a message to a list of recipients.
   *
   * @param text - message to send
   * @param toUrns - send to LinkedInSalesProfile, LinkedInSalesCompanyProfile, or LinkedInSalesMemberProfile having entityUrn in this list.
   *                 NOT compatible with participant hostIdentityUrns from ordinary LinkedInChat!
   * @param attachments - optional array of File objects to attach.
   *                      File objects can be found in .files on an <input type="file">
   *                      or in .dataTransfer.files on a drop event.
   */
  sendToNewThread(text: string, toUrns: string[], attachments?: File[]): Promise<LinkedInSalesCreateMessageResponse> {
    for (const u of toUrns) {
      if (u.startsWith('urn:li:fsd_')) {
        throw new LinkedInClassesUsageError(`LinkedInSalesNavigatorChat cannot send to ordinary chat recipient ${u}`)
      }
    }
    return this._sendMessage(text, toUrns, undefined, attachments)
  }

  /**
   * Send a message to an existing thread.
   *
   * @param text - message to send
   * @param threadId - send to the LinkedInSalesConversation that has this id
   * @param attachments - optional array of File objects to attach
   */
  sendToConversation(text: string, threadId: string, attachments?: File[]): Promise<LinkedInSalesCreateMessageResponse> {
    if (threadId.startsWith('urn:li:msg_conversation:')) {
      throw new LinkedInClassesUsageError(`LinkedInSalesNavigatorChat cannot send to ordinary chat thread ${threadId}`)
    }
    return this._sendMessage(text, undefined, threadId, attachments)
  }

  async _sendMessage(text: string, toUrns?: string[], threadId?: string, attachments?: File[]): Promise<LinkedInSalesCreateMessageResponse> {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    const uploads = await Promise.all((attachments ?? []).map(f => this._uploadFile(f)))
    const response = await _makeRequest(
      this._csrfToken,
      `${LINKEDIN_DOT_COM}/sales-api/salesApiMessageActions?action=createMessage`,
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'Content-Type': 'application/json',
        'X-Li-Identity': this._salesNavigatorIdentity,
        'X-Li-Page-Instance': SALES_NAVIGATOR_THREAD_PAGE_INSTANCE,
      },
      JSON.stringify({
        createMessageRequest: {
          body: text,
          trackingId: Array.from(crypto.randomUUID().replaceAll('-', '').matchAll(/../g)).map(g => String.fromCharCode(parseInt(g[0], 16))).join(''),
          copyToCrm: false,
          attachments: uploads.map(u => u.assetUrn),
          threadId: threadId,
          recipients: toUrns,
        },
      }),
      {claimToBeLighthouse: true},
    )
    return response.data.value
  }

  /**
   * Upload a file to Sales Navigator.
   *
   * @param file - a File object from an <input type="file"> (.files) or a drop event (.dataTransfer.files)
   *
   * @internal until we decide we want to decouple uploading from sending messages
   */
  private async _uploadFile(file: File): Promise<LinkedInUpload> {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    return await _uploadFileImpl(file, this._csrfToken, `${LINKEDIN_DOT_COM}/sales-api/salesApiMediaUploadMetadata?action=upload`, {
      'Accept': '*/*',
      'Content-Type': 'text/plain;charset=UTF-8',
      'X-Li-Identity': this._salesNavigatorIdentity,
      'X-Li-Page-Instance': SALES_NAVIGATOR_PAGE_INSTANCE,
    })
  }

  /**
   * Mark a Sales Navigator conversation as read or unread.
   *
   * @param threadId - mark the conversation that has this id
   * @param read - if true, mark as read. if false, mark as unread.
   */
  async setConversationReadState(threadId: string, read: boolean): Promise<void> {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    await _makeRequest(
      this._csrfToken,
      `${LINKEDIN_DOT_COM}/sales-api/salesApiMessagingThreads/${_encodeVariable(threadId)}?action=${read ? 'markAsRead' : 'markAsUnread'}`,
      {
        'Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Identity': this._salesNavigatorIdentity,
        'X-Li-Page-Instance': SALES_NAVIGATOR_THREAD_PAGE_INSTANCE,
      },
      undefined,
      {method: 'POST', expectEmpty: true, claimToBeLighthouse: true},
    )
  }

  /**
   * Long-running task to listen for realtime events like inbound replies.
   */
  private async _startRealtime() {
    if (!this._csrfToken || !this._salesNavigatorIdentity) {
      throw new Error('No Sales Navigator access')
    }
    // Realtime in Sales Navigator requires the extension to strip origin from requests to LINKEDIN_REALTIME_HOST,
    // so gate this on extension version. If we ever expose the extension id to linkedin, they're likely to add us
    // to an extension detector that works by probing for static files under chrome-extension://${extensionId}/
    // (you can see this as a barrage of failed requests on initial load of a linkedin page).
    if (!chrome?.runtime) { return }
    const extensionInfoResponse = await chrome.runtime.sendMessage(EXTENSION_ID, {'type': MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_REQUEST_EXTENSION_API_KEY_AND_EXTENSION_INFO})
    if (!(_versionCompare(extensionInfoResponse.extensionVersion.split('.').map((x: string) => parseInt(x)), [1, 5, 6, 6]) > 0)) {
      logInfo(`${_LOG_SCOPE} Extension version ${extensionInfoResponse.extensionVersion} does not support Sales Navigator realtime replies; disabling`)
      return
    }
    // realtime responses to Sales Navigator threads require the user's salesInboxRealtimeIdentity so get that
    const pageDataResponse = await _makeRequest(
      this._csrfToken,
      `${LINKEDIN_DOT_COM}/sales-api/salesApiNavChrome?decoration=%28` + [
        'twoStepAuthEnabled', 'linkedInMailboxCount',
        'member~fs_salesProfile%28entityUrn%2CprofilePictureDisplayImage%2CpictureInfo%28croppedImage%29%29',
        'salesInboxRealtimeIdentity',  // this is the only one we need; the rest is just to blend in with regular traffic
        'salesMailboxCount', 'unreadMessagesCount',
      ].join('%2C') + '%29&salesNavSandbox=false',
      {
        "Accept": "*/*",
        "X-Li-Identity": this._salesNavigatorIdentity,
        "X-Li-Page-Instance": SALES_NAVIGATOR_PAGE_INSTANCE,
        "X-Li-Pem-Metadata": "Sales Navigator - Nav Chrome Endpoint=access-nav-chrome",
      },
      undefined,
      {claimToBeLighthouse: true},
    )
    const realtimeIdentity: string = pageDataResponse?.salesInboxRealtimeIdentity
    if ('string' !== typeof realtimeIdentity) {
      logInfo(`${_LOG_SCOPE} Couldn't find salesInboxRealtimeIdentity token to connect to Sales Navigator realtime; message receiving will be unreliable.`)
      return
    }
    // set up heartbeating and sync
    await this._sendRealtimeHeartbeat(realtimeIdentity, true)
    const skewCorrectionMs = (await this._getRealtimeTimestamp(realtimeIdentity)).getTime() - new Date().getTime()
    const heartbeatInterval = setInterval(() => this._sendRealtimeHeartbeat(realtimeIdentity, false), 60000)

    try {
      for await (const event of _subscribeToEventStreamWithReconnection(
        this._csrfToken,
        `${LINKEDIN_REALTIME_HOST}/realtime/connect?rc=1`,
        {
          'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
          'X-Li-Identity': realtimeIdentity,
          'X-Li-Realtime-Session': this._realtimeSessionId,
        },
        {claimToBeLighthouse: true},
      )) {
        const eventData = JSON.parse(event.data)
        if (eventData['com.linkedin.realtimefrontend.DecoratedEvent']) {
          const decoratedEvent = eventData['com.linkedin.realtimefrontend.DecoratedEvent'].payload
          for (const item of decoratedEvent?.included ?? []) {
            if (item.$type === LINKEDIN_SALES_MESSAGE_TYPE && this.onNewMessage) {
              const threadId = decoratedEvent?.data?.value?.threadUrn ?? (
                item.id.slice(0, 2) + item.id.slice(34))  // fragile fallback to extract thread id from message id
              this.onNewMessage(_ingestSalesNavigatorMessage(item, threadId))
            }
          }
        }
        // insert temporary logging here when looking for new kinds of event stream payloads
        if (this._finished) { break }
      }
      clearInterval(heartbeatInterval)
    } catch (e) {
      clearInterval(heartbeatInterval)
      throw e
    }
  }

  private async _sendRealtimeHeartbeat(realtimeIdentity: string, isFirst: boolean = false) {
    if (!this._csrfToken) {
      throw new Error('No Sales Navigator access')
    }
    await _makeRequest(
      this._csrfToken,
      `${LINKEDIN_REALTIME_HOST}/realtime/realtimeFrontendClientConnectivityTracking?action=sendHeartbeat`,
      {
        'Accept': '*/*',
        'Content-Type': 'text/plain;charset=UTF-8',
        'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Identity': realtimeIdentity,
        'X-Li-Realtime-Session': this._realtimeSessionId,
      },
      JSON.stringify({
        isFirstHeartBeat: isFirst,
        isLastHeartBeat: false,
        realtimeSessionId: this._realtimeSessionId,
        mpName: 'lighthouse-web',
        mpVersion: VERSION_LIGHTHOUSE_WEB,
        clientId: 'lighthouse-web',
      }),
      {expectEmpty: true, claimToBeLighthouse: true},
    )
  }

  private async _getRealtimeTimestamp(realtimeIdentity: string): Promise<Date> {
    if (!this._csrfToken) {
      throw new Error('No Sales Navigator access')
    }
    const response = await _makeRequest(
      this._csrfToken,
      `${LINKEDIN_REALTIME_HOST}/realtime/realtimeFrontendTimestamp`,
      {
        'Accept': '*/*',
        'X-Li-Accept': 'application/vnd.linkedin.normalized+json+2.1',
        'X-Li-Identity': realtimeIdentity,
        'X-Li-Realtime-Session': this._realtimeSessionId,
      },
      undefined,
      {claimToBeLighthouse: true},
    )
    return new Date(response.timestamp)
  }
}

/////////////////////////////
// Sales Navigator helpers //
/////////////////////////////

async function _getLoggedInSalesNavigatorIdentity(csrfToken: string): Promise<string | null> {
  const initialIdentity = await _getSalesNavigatorIdentity(csrfToken)
  if (!initialIdentity) { return null }
  if (await _identityHasSalesNavigatorAccess(csrfToken, initialIdentity)) { return initialIdentity }

  // if no access, attempt the login handshake
  await _autoLoginSalesNavigator(csrfToken, initialIdentity)

  // ...then try again
  const newIdentity = await _getSalesNavigatorIdentity(csrfToken)
  return (newIdentity && await _identityHasSalesNavigatorAccess(csrfToken, newIdentity)) ? newIdentity : null
}

async function _getSalesNavigatorIdentity(csrfToken: string): Promise<string | null> {
  const response = await _makeRequest(
    csrfToken,
    `${LINKEDIN_DOT_COM}/sales-api/salesApiPrimaryIdentity`,
    {
      'Accept': '*/*',
      'X-Li-Page-Instance': SALES_NAVIGATOR_PAGE_INSTANCE,
    },
    undefined,
    {claimToBeLighthouse: true},
  )
  return response.primaryIdentity
}

async function _identityHasSalesNavigatorAccess(csrfToken: string, salesNavigatorIdentity: string): Promise<boolean> {
  try {
    const response = await _makeRequest(
      csrfToken,
      `${LINKEDIN_DOT_COM}/sales-api/salesApiAccess`,
      {
        'Accept': '*/*',
        'X-Li-Identity': salesNavigatorIdentity,
        'X-Li-Page-Instance': SALES_NAVIGATOR_PAGE_INSTANCE,
      },
      undefined,
      {claimToBeLighthouse: true},
    )
    return !!response.hasContractInboxAccess
  } catch (e) {
    // without access, e.message will be a JSON-encoded string '{"code": "SALES_SEAT_REQUIRED", "serviceErrorCode": some_integer, "status": 403}'
    // and we could check for that but really any error here implies we're probably in uncharted territory where we shouldn't make more requests
    return false
  }
}

// Reproduce the login handshake as observed on Ajinkya's account.
// If successful, the next call to _getSalesNavigatorIdentity should return a different result than the initial identity passed in here.
// The mechanism is probably the Set-Cookie headers on /checkpoint/enterprise/login/XXXXXXXXX
async function _autoLoginSalesNavigator(csrfToken: string, salesNavigatorIdentity: string): Promise<void> {
  // find unique license
  const licensesResponse = await _makeRequest(
    csrfToken,
    `${LINKEDIN_DOT_COM}/sales-api/salesApiIdentity?q=findLicensesByCurrentMember&includeRecentlyInactiveDueToOverallocation=true`,
    {
      'Accept': '*/*',
      'X-Li-Identity': salesNavigatorIdentity,
      'X-Li-Page-Instance': SALES_NAVIGATOR_CONTRACT_CHOOSER_PAGE_INSTANCE,
    },
    undefined,
    {claimToBeLighthouse: true},
  )
  const licenses = licensesResponse.elements.filter((x: any) => !!x.agnosticIdentity)
  if (licenses.length !== 1) { return }  // we don't know how to choose from multiple licenses

  // get login URL
  const authResponse = await _makeRequest(
    csrfToken,
    `${LINKEDIN_DOT_COM}/sales-api/salesApiAgnosticAuthentication`,
    {
      'Accept': '*/*',
      'Content-Type': 'text/plain;charset=UTF-8',
      'X-Li-Identity': salesNavigatorIdentity,
      'X-Li-Page-Instance': SALES_NAVIGATOR_CONTRACT_CHOOSER_PAGE_INSTANCE,
    },
    JSON.stringify({
      viewerDeviceType: 'DESKTOP',
      identity: {
        name: licenses[0].name,
        agnosticIdentity: licenses[0].agnosticIdentity,
      }
    }),
    {returnRaw: true, claimToBeLighthouse: true},
  )
  const authHeaders = new Headers(authResponse.headers)
  const authLocation = authHeaders.get('location')
  if (!authLocation) { return }
  const authURL = new URL(authLocation)
  if (!authURL.pathname.startsWith('/checkpoint/enterprise/login/')) { return }

  // hit the login URL (sets some cookies before the redirect)
  const loginResponse = await _makeRequest(
    csrfToken,
    authLocation,
    {
      'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
      'X-Li-Lang': undefined,
      'X-Li-Page-Instance': undefined,
      'X-Li-Track': undefined,
      'X-Restli-Protocol-Version': undefined,
    },
    undefined,
    {returnRaw: true, claimToBeLighthouse: true},
  )
  const loginURL = new URL(loginResponse.url)  // get the final redirected-to URL

  // hit the enterprise auth URL (sets some more cookies)
  const enterpriseAuthParams = new URLSearchParams(Array.from(loginURL.searchParams.entries()).filter(([key, _v]) => key !== '_bprMode' && key !== 'salesRedirect'))
  if (!enterpriseAuthParams.has('accountId')) { return }  // check that this looks like auth params before sending them
  await _makeRequest(
    csrfToken,
    `${LINKEDIN_DOT_COM}/sales-api/salesApiEnterpriseAuthentication?${enterpriseAuthParams.toString()}`,
    {
      'Accept': '*/*',
      'X-Li-Page-Instance': SALES_NAVIGATOR_CONTRACT_CHOOSER_PAGE_INSTANCE,
    },
    undefined,
    {claimToBeLighthouse: true},
  )
}

////////////////////
// Shared helpers //
////////////////////

function _loadCachedProfile(csrfToken: string): LinkedInOwnProfile | null {
  const storedCache = window.localStorage.getItem(linkedInProfileCacheKey)
  if (!storedCache) { return null }
  return JSON.parse(storedCache)?.[csrfToken] ?? null
}

async function _reloadProfile(csrfToken: string): Promise<LinkedInOwnProfile> {
  const rawProfile = await _makeRequest(
    csrfToken,
    `${LINKEDIN_DOT_COM}/voyager/api/me`,
    {'Accept': 'application/vnd.linkedin.normalized+json+2.1'},
  )
  // most of the useful fields are on this MiniProfile object so find it and flatten them onto the root
  const miniProfile = rawProfile['included'].find((x: any) => x['$type'] === LINKEDIN_MINI_PROFILE_TYPE && x['dashEntityUrn'])
  if (!miniProfile) { throw new Error('Found no included MiniProfiles with non-blank dashEntityUrn') }
  const result = {
    '$type': LINKEDIN_OWNPROFILE_TYPE,
    premiumSubscriber: rawProfile['data']['premiumSubscriber'],
    ...miniProfile,
    picture: {
      ...miniProfile.picture,
      artifacts: (miniProfile.picture.artifacts ?? []).map((a: Record<string, any>) => _parseTimestampFields(a, ['expiresAt'])),
    },
  }
  window.localStorage.setItem(linkedInProfileCacheKey, JSON.stringify({[csrfToken]: result}))
  return result
}

async function _uploadFileImpl(file: File, csrfToken: string, allocationUrl: string, allocationHeaders: Record<string, string>): Promise<LinkedInUpload> {
  const allocationResponse = await _makeRequest(csrfToken, allocationUrl, allocationHeaders,
    JSON.stringify({
      mediaUploadType: file.type.startsWith('image/') ? "MESSAGING_PHOTO_ATTACHMENT" : "MESSAGING_FILE_ATTACHMENT",
      fileSize: file.size,
      filename: file.name,
    }),
  )
  const singleUploadUrl = allocationResponse?.value?.singleUploadUrl || allocationResponse?.data?.value?.singleUploadUrl;
  await _makeRequest(
    csrfToken,
    singleUploadUrl,
    {
      'Accept': '*/*',
      'Content-Type': file.type,
    },
    file,
    {method: 'PUT', expectEmpty: true},
  )
  const assetUrn = allocationResponse?.value?.urn || allocationResponse?.data?.value?.urn;
  return {
    assetUrn,
    byteSize: file.size,
    mediaType: file.type,
    name: file.name,
    url: URL.createObjectURL(file),
  }
}

/**
 * Compare version numbers by lexicographical order---that is, as a succession of tiebreakers where blank always loses.
 * Return negative if a < b, positive if a > b, 0 if equal.
 */
function _versionCompare(a: number[], b: number[]): number {
  const end = Math.min(a.length, b.length)
  for (let i = 0; i < end; ++i) {
    const cmp = a[i] - b[i]
    if (cmp !== 0) { return cmp }
  }
  return a.length - b.length
}

///////////////////
// Parsing layer //
///////////////////

function _coerceToTimestamp(timestamp: number | null | Date): Date | null {
  return timestamp || timestamp === 0 ? new Date(timestamp) : null
}

function _parseTimestampFields(item: Record<string, any>, timestampFields: string[]): Record<string, any> {
  return {
    ...item,
    ...Object.fromEntries(timestampFields.map(name => [name, _coerceToTimestamp(item[name])])),
  }
}

function _ingestConversation(rawConversation: any): LinkedInConversation {
  return {
    // parse date fields on the conversation
    ..._parseTimestampFields(rawConversation, ['createdAt', 'lastActivityAt', 'lastReadAt']),
    // flatten message list
    messages: rawConversation.messages?.elements.map(_ingestMessage) ?? [],
  } as LinkedInConversation
}

function _ingestMessage(m: any): LinkedInMessage {
  return {
    ...m,
    deliveredAt: _coerceToTimestamp(m.deliveredAt),
    renderContent: m.renderContent.map((c: any) => {return {
      ...c,
      video: c.video ? _parseTimestampFields({
        ...c.video,
        progressiveStreams: (c.video.progressiveStreams ?? []).map((s: any) => {return {
          ...s,
          streamingLocations: s.streamingLocations.map((l: any) => _parseTimestampFields(l, ['expiresAt']))
        }}),
      }, ['liveStreamCreatedAt', 'liveStreamEndedAt']) : null,
    }}),
    reactionSummaries: m.reactionSummaries.map((r: any) => _parseTimestampFields(r, ['firstReactedAt']))
  }
}

function _ingestSalesNavigatorConversation(c: any, participantsByEntityUrn: Map<string, LinkedInSalesProfile>): LinkedInSalesConversation {
  const participantUrns: string[] = c.participants
  return {
    $type: c.$type,
    nextPageStartsAt: _coerceToTimestamp(c.nextPageStartsAt),
    totalMessageCount: c.totalMessageCount,
    unreadMessageCount: c.unreadMessageCount,
    archived: c.archived,
    messages: c.messages.map((m: any) => _ingestSalesNavigatorMessage(m, c.id)),
    participantsResolutionResults: c.participantsResolutionResults,
    id: c.id,
    participants: participantUrns.map(p => participantsByEntityUrn.get(p)).filter((p): p is LinkedInSalesProfile => p !== undefined),
  }
}

function _ingestSalesNavigatorMessage(rawMessage: any, threadId: string): LinkedInSalesMessage {
  return {
    attachments: rawMessage.attachments.map((a: any) => {return {
      assetUrn: a.assetUrn,
      byteSize: a.size,
      mediaType: a.mediaType,
      name: a.name,
      url: a.downloadUrl,
    }}),
    author: rawMessage.author,
    type: rawMessage.type,
    body: rawMessage.body,
    deliveredAt: new Date(rawMessage.deliveredAt),
    $type: rawMessage.$type,
    id: rawMessage.id,
    threadId: threadId,
  }
}

export function normalizeParticipant(p: LinkedInConversationParticipant | LinkedInSalesProfile): LinkedInNormalizedParticipant {
  if ('$type' in p) {
    return {
      hostIdentityUrn: p.entityUrn,
      entityUrn: p.entityUrn,
      name: p.fullName,
      tagline: null,
      image: p.profilePictureDisplayImage,
      url: null,
    }
  } else {
    const pt = p.participantType
    const memberName = `${pt.member?.firstName?.text ?? ""} ${pt.member?.lastName?.text ?? ""}`.trim()
    return {
      hostIdentityUrn: p.hostIdentityUrn,
      entityUrn: p.entityUrn,
      name: memberName.length ? memberName : (pt.organization?.name?.text ?? pt.custom?.name?.text ?? 'unknown'),
      tagline: pt.member?.headline?.text ?? pt.organization?.tagline?.text ?? null,
      image: pt.member?.profilePicture ?? pt.organization?.logo ?? pt.custom?.image ?? null,
      url: pt.member?.profileUrl ?? pt.organization?.pageUrl ?? null,
    }
  }
}

/**
 * Walk an array/object tree in depth-first order, sometimes yielding callback results.
 *
 * @param root - the object to start walking
 * @param callback - Given the (key, value) pair, optionally emit a value and/or truncate recursion.
 *                   The key will be '' for the root object and decimal strings for array entries.
 *
 * @remarks
 * We could have composed this as 3 operations:
 *   1. recurse, where the callback returns only whether to truncate recursion
 *   2. filter, where the callback returns only whether to emit a value
 *   3. map, where the callback returns the value to emit
 * In our use cases, every input that passes the filter needs to truncate recursion,
 * and constructing the value to emit without errors is a filter condition.
 * So having it as one function buys us the ability to express these overlaps,
 * at the cost of having to think about all 3 operations at the same time.
 */
function* recurseFilterMap<T>(
  root: Object | any[],
  callback: (key: string, value: any) => {truncate?: boolean, emit?: T},
): Generator<T, void, void> {
  const todo: [string, any][] = [['', root]]
  while (todo.length) {
    const [key, value] = todo.pop()!
    const result = callback(key, value)
    if (result.emit !== undefined) {
      yield result.emit
    }
    if (!result.truncate && value instanceof Object) {
      todo.push(...Object.entries(value))
    }
  }
}

////////////////////////////////////////
// Engine for running GraphQL queries //
////////////////////////////////////////

class VoyagerAPI {
  csrfToken: string | null = null
  selfUrn: string | null = null
  salesNavigatorIdentity: string | null = null

  // get cookies and enough of your own profile to be able to run any other queries
  async start() {
    // this line may throw:
    // - Error with .message = "MISSING_SITE_PERMISSIONS"
    // - LinkedInNotLoggedIn
    this.csrfToken = await _getCsrfToken()
    // this line may throw:
    // - DOMException(name=QuotaExceededError) if we filled localStorage
    // - anything if the backend doesn't like the request or isn't reachable
    const cachedProfile = _loadCachedProfile(this.csrfToken) ?? await _reloadProfile(this.csrfToken)
    this.selfUrn = cachedProfile.dashEntityUrn
  }

  // Plumbing used by public API below
  async _getGraphQL(queryId: string, queryVariables: string): Promise<any> {
    if (!this.csrfToken) {
      throw new Error('VoyagerAPI not started')
    }
    const url = new URL(`${LINKEDIN_DOT_COM}/voyager/api/voyagerMessagingGraphQL/graphql`)
    url.searchParams.set('queryId', queryId)
    const bakedUrl = `${url}&variables=(${queryVariables})`
    return await _makeRequest(this.csrfToken, bakedUrl, {'Accept': 'application/graphql'})
  }
  // GraphQL query definitions follow...

  async getMessengerConversationsByRecipients(variables: {userUrn: string, recipientUrns: string[]}): Promise<any> {
    const {userUrn, recipientUrns} = variables
    const queryVariables = [
      `mailboxUrn:${_encodeVariable(userUrn)}`,
      `recipients:List(${recipientUrns.map(_encodeVariable).join(",")})`,
    ].join(',')
    const response = await this._getGraphQL('messengerConversations.09995bad1c3b51bdf60c10a49ae874f0', queryVariables)
    return response.data.messengerConversationsByRecipients
  }

  async getMessengerConversationsBySearchText(variables: {
    userUrn: string,
    keyword: string,
    count?: number,
    mailboxes?: string[],
    nextCursor?: string | null | undefined,
  }): Promise<any> {
    const {userUrn, keyword, count = 20, mailboxes = ['INBOX', 'SPAM', 'ARCHIVE'], nextCursor = null} = variables
    const queryVariables = [
      mailboxes?.length ? `categories:List(${mailboxes.map(_encodeVariable).join(",")})` : null,
      `count:${count}`,
      'firstDegreeConnections:false',
      `mailboxUrn:${_encodeVariable(userUrn)}`,
      `keywords:${_encodeVariable(keyword)}`,
      nextCursor ? `nextCursor:${_encodeVariable(nextCursor)}` : null,
    ].filter(x => x !== null).join(',')
    const response = await this._getGraphQL('messengerConversations.419b5a52d6d0baf3b4f3f1297cc6cc6e', queryVariables)
    return response.data.messengerConversationsBySearchCriteria
  }

  async getMessengerConversationsBySearchReadState(variables: {
    userUrn: string,
    read: boolean,
    count?: number,
    mailboxes?: string[],
    nextCursor?: string | null | undefined,
  }): Promise<any> {
    const {userUrn, read, count = 20, mailboxes = ['INBOX', 'SPAM', 'ARCHIVE'], nextCursor = null} = variables
    const queryVariables = [
      mailboxes?.length ? `categories:List(${mailboxes.map(_encodeVariable).join(",")})` : null,
      `count:${count}`,
      'firstDegreeConnections:false',
      `mailboxUrn:${_encodeVariable(userUrn)}`,
      `read:${read}`,
      nextCursor ? `nextCursor:${_encodeVariable(nextCursor)}` : null,
    ].filter(x => x !== null).join(',')
    const response = await this._getGraphQL('messengerConversations.92f86fde05044b524f36d62da0497760', queryVariables)
    return response.data.messengerConversationsBySearchCriteria
  }

  async getMessengerConversationsBySyncToken(variables: {userUrn: string, syncToken?: string | null | undefined}): Promise<any> {
    const {userUrn, syncToken = null} = variables
    const queryVariables = [
      `mailboxUrn:${_encodeVariable(userUrn)}`,
      syncToken ? `syncToken:${_encodeVariable(syncToken)}` : null,
    ].filter(x => x !== null).join(',')
    const response = await this._getGraphQL('messengerConversations.25a5224224a4e57a5cdb928d35e01722', queryVariables)
    return response.data.messengerConversationsBySyncToken
  }

  async getMultipleMessengerConversationsByIds(variables: {conversationEntityUrns: string[]}): Promise<any> {
    const {conversationEntityUrns} = variables
    const queryVariables = [
      `conversationIds:List(${conversationEntityUrns.map(_encodeVariable).join(",")})`,
      `count:${conversationEntityUrns.length}`,
    ].join(',')
    const response = await this._getGraphQL('messengerConversations.de56cd0a24c154a3f2d711b221b0ce78', queryVariables)
    return response.data.messengerConversationsByIds
  }

  // load one conversation.
  // conversationUrn appears to be of the form `urn:li:msg_conversation(${userUrn},${messagingThreadId})`
  async getMessengerConversationsByIds(variables: {conversationEntityUrn: string}): Promise<any> {
    const {conversationEntityUrn} = variables
    const queryVariables = [
      `conversationIds:List(${_encodeVariable(conversationEntityUrn)})`,
      'count:1',
    ].join(',')
    const response = await this._getGraphQL('messengerConversations.de56cd0a24c154a3f2d711b221b0ce78', queryVariables)
    return response.data.messengerConversationsByIds
  }

  // used to load a conversation right after creating it by sending a message
  async getMessengerConversationsById(variables: {conversationEntityUrn: string, count?: number}): Promise<any> {
    const {conversationEntityUrn, count = 20} = variables
    const queryVariables = [
      `messengerConversationsId:${_encodeVariable(conversationEntityUrn)}`,
      `count:${count}`,
    ].join(',')
    const response = await this._getGraphQL('messengerConversations.a8a84cb23d3ed3288afe569e6e1e2798', queryVariables)
    return response.data.messengerConversationsById
  }

  async getMessengerConversationsByCategoryQuery(variables: {
    userUrn: string,
    categoryName: string,
    count?: number,
    nextCursor?: string | null | undefined,
  }): Promise<any> {
    const {userUrn, categoryName, count = 25, nextCursor = null} = variables
    const queryVariables = [
      `query:(predicateUnions:List((conversationCategoryPredicate:(category:${_encodeVariable(categoryName)}))))`,
      `count:${count}`,
      `mailboxUrn:${_encodeVariable(userUrn)}`,
      nextCursor ? `nextCursor:${_encodeVariable(nextCursor)}` : null,
    ].filter(x => x !== null).join(',')
    const response = await this._getGraphQL('messengerConversations.e75894f1094ac55cbd53d2482301efdc', queryVariables)
    return response.data.messengerConversationsByCategoryQuery
  }

  async getMessengerMessagesByAnchorTimestamp(variables: {
    conversationEntityUrn: string,
    deliveredAt: Date | number,  // Date or ms since epoch
    countBefore?: number,
    countAfter?: number,
  }): Promise<any> {
    const {conversationEntityUrn, deliveredAt, countBefore = 20, countAfter = 0} = variables
    const queryVariables = [
      `deliveredAt:${Math.round(+deliveredAt)}`,
      `conversationUrn:${_encodeVariable(conversationEntityUrn)}`,
      `countBefore:${countBefore}`,
      `countAfter:${countAfter}`,
    ].join(',')
    const response = await this._getGraphQL('messengerMessages.4088d03bc70c91c3fa68965cb42336de', queryVariables)
    return response.data.messengerMessagesByAnchorTimestamp
  }

  async getMessengerMessagesByConversation(variables: {
    conversationEntityUrn: string,
    prevCursor: string,
    count?: number,
  }): Promise<any> {
    const {conversationEntityUrn, prevCursor, count = 20} = variables
    const queryVariables = [
      `conversationUrn:${_encodeVariable(conversationEntityUrn)}`,
      `count:${count}`,
      `prevCursor:${prevCursor}`,
    ].join(',')
    const response = await this._getGraphQL('messengerMessages.34c9888be71c8010fecfb575cb38308f', queryVariables)
    return response.data.messengerMessagesByConversation
  }

  async getMessengerMessagesBySyncToken(variables: {conversationEntityUrn: string, syncToken?: string | null | undefined}): Promise<any> {
    const {conversationEntityUrn, syncToken = null} = variables
    const queryVariables = [
      `conversationUrn:${_encodeVariable(conversationEntityUrn)}`,
      syncToken ? `syncToken:${_encodeVariable(syncToken)}` : null,
    ].filter(x => x !== null).join(',')
    const response = await this._getGraphQL('messengerMessages.0c1bd47e37c67578e99250a711f0c18e', queryVariables)
    return response.data.messengerMessagesBySyncToken
  }

  async getIdentityDashProfilesByMemberIdentity(variables: {userId: string}): Promise<any> {
    const {userId} = variables
    const response = await this._getGraphQL('voyagerIdentityDashProfiles.2531a1a7d1d5530ad1834e0012bf7d50', `vanityName:${_encodeVariable(userId)}`)
    return response.data.identityDashProfilesByMemberIdentity
  }

  async getMessagingDashMessagingTypeaheadByTypeaheadKeyword(variables: {keyword: string}): Promise<any> {
    const {keyword} = variables
    const queryVariables = [
      `keyword:${_encodeVariable(keyword)}`,
      'types:List(CONNECTIONS)',
    ].join(',')
    // I have no idea why this one comes back in a format unlike the others:
    // - targets of references are in .included at the top level
    // - the main payload is in .data.data.messagingDashMessagingTypeaheadByTypeaheadKeyword
    return await this._getGraphQL('voyagerMessagingDashMessagingTypeahead.e09637f52e3f8bc7d1f2d38b0e1671c9', queryVariables)
  }
}

function _encodeVariable(v: string) {
  return encodeURIComponent(v).replaceAll('(', '%28').replaceAll(')', '%29')
}

/////////////////////////
// HTTP Plumbing layer //
/////////////////////////

export class LinkedInAPICallError extends Error {
  name = 'LinkedInAPICallError'
}

type ExtensionFetchRequest = {
  url: string
  method?: string
  headers?: Record<string, string | undefined>
  body?: string | Blob
  credentials?: string
  mode?: string
  referrerPolicy?: string
}

type ExtensionFetchSuccess = {
  ok: true
  headers: [string, string][]
  url: string
  status: number
  text: string
}

type ExtensionFetchFailure = {
  ok: false
  errors?: string[]
  headers?: [string, string][]
  url?: string
  status?: number
  text?: string
}

async function _fetchViaExtension(request: ExtensionFetchRequest): Promise<ExtensionFetchSuccess | ExtensionFetchFailure> {
  const sendableRequest: Record<string, any> = {...request}
  if (request.body instanceof Blob) {
    sendableRequest.body = Array.from(new Uint8Array(await request.body.arrayBuffer()))
  }
  return await chrome.runtime.sendMessage(EXTENSION_ID, {'type': MESSAGE_TYPES.EMIT_FETCH, ...sendableRequest})
}

async function _makeRequest(
  csrfToken: string,
  url: string,
  headers: Record<string, string | undefined> = {},
  postBody?: string | Blob,
  options: {
    method?: string
    expectEmpty?: boolean
    returnRaw?: boolean
    claimToBeLighthouse?: boolean
  } = {}
) {
  const request: ExtensionFetchRequest = {
    url: url,
    method: options.method ?? (postBody === undefined ? 'GET' : 'POST'),
    headers: {
      ..._makeBaseHeaders(options.claimToBeLighthouse),
      'Csrf-Token': csrfToken,
      ...headers,
    },
    credentials: 'include',
    mode: 'cors',
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: postBody,
  }
  const callResult = await _fetchViaExtension(request)
  if (!callResult.ok) {
    throw new LinkedInAPICallError(callResult.errors?.join('; ') ?? callResult.text)
  }
  return options.returnRaw ? callResult
    : options.expectEmpty && callResult.text.length === 0 ? null
    : JSON.parse(callResult.text)
}

async function* _subscribeToEventStreamWithReconnection(
  csrfToken: string,
  url: string,
  headers: Record<string, string | undefined> = {},
  options: {
    claimToBeLighthouse?: boolean,
    initialRetryDelayMs?: number,
  } = {},
): AsyncGenerator<any, void, void> {
  let {initialRetryDelayMs: retryDelay = 5000, claimToBeLighthouse} = options
  while (true) {
    try {
      for await (const event of _subscribeToEventStream(csrfToken, url, headers, {claimToBeLighthouse})) {
        retryDelay = options.initialRetryDelayMs ?? 5000  // reset retryDelay when we successfully get a message
        yield event
      }
    } catch (e) {
      if (e instanceof ExtensionEventStreamError) {
        logInfo(`${_LOG_SCOPE} ${e.name}: ${e.message}`)
        if (e.kind.startsWith('HTTP4')) {
          throw e  // I guess we're doing something wrong so don't spam
        }
      } else if (!(e instanceof ExtensionEventStreamDisconnected)) {
        throw e
      }
      logInfo(`${_LOG_SCOPE} realtime feed disconnected; reconnecting...`)
      await sleep(retryDelay)
      retryDelay *= 2  // exponential backoff
    }
  }
}

function _subscribeToEventStream(
  csrfToken: string,
  url: string,
  headers: Record<string, string | undefined> = {},
  options: {
    claimToBeLighthouse?: boolean,
  } = {},
): ExtensionEventStream {
  return new ExtensionEventStream({
    url: url,
    method: 'GET',
    headers: {
      ..._makeBaseHeaders(options.claimToBeLighthouse),
      'Csrf-Token': csrfToken,
      ...headers,
    },
    credentials: 'include',
    mode: 'cors',
    referrerPolicy: 'strict-origin-when-cross-origin',
  })
}

export class ExtensionEventStreamDisconnected extends Error {
  name = 'ExtensionEventStreamDisconnected'
}

export class ExtensionEventStreamError extends Error {
  name = 'ExtensionEventStreamError'
  kind: string
  constructor(kind: string, message: string) {
    super(`${kind}: ${message}`)
    this.kind = kind
  }
}

class ExtensionEventStream {
  _port: chrome.runtime.Port
  _nexts: {resolve: (value: IteratorResult<any, void>) => void, reject: (reason?: any) => void}[] = []
  _buffer: any[] = []
  _disconnected: boolean = false
  _lastError: any = null

  constructor(request: ExtensionFetchRequest) {
    const port = this._port = chrome.runtime.connect(EXTENSION_ID)
    true && (async () => {
      const sendableRequest: Record<string, any> = {...request}
      if (request.body instanceof Blob) {
        sendableRequest.body = Array.from(new Uint8Array(await request.body.arrayBuffer()))
      }
      port.postMessage({'type': MESSAGE_TYPES.SUBSCRIBE_EVENT_STREAM, ...sendableRequest})
    })()
    port.onDisconnect.addListener(() => this.disconnect())
    port.onMessage.addListener((message, port) => {
      if ('error' in message) {
        this._lastError = new ExtensionEventStreamError(message.error, message.errorMessage)
        this.disconnect()
      } else if (message['disconnected']) {
        this.disconnect()
      } else {
        this._buffer.push(message)
        if (this._nexts.length) {
          const headSize = Math.min(this._buffer.length, this._nexts.length)
          const head = this._buffer.splice(0, headSize)
          this._nexts.splice(0, headSize).forEach(({resolve, reject}, i) => resolve({value: head[i], done: false}))
        }
      }
    })
  }

  next(): Promise<IteratorResult<any, void>> {
    return new Promise((resolve, reject) => {
      if (this._buffer.length) {
        resolve({value: this._buffer.shift(), done: false})
      } else if (this._lastError !== null) {
        reject(this._lastError)
      } else if (this._disconnected) {
        reject(new ExtensionEventStreamDisconnected('disconnected'))
      } else {  // this._buffer is empty so we're allowed to push to this._nexts
        this._nexts.push({resolve, reject})
      }
    })
  }

  disconnect() {
    this._disconnected = true
    this._port.disconnect()  // no-op if already disconnected
  }

  [Symbol.asyncIterator]() {
    return this
  }

  async return(value: any): Promise<IteratorResult<any, void>> {
    this.disconnect()
    return {value: value, done: true}
  }

  async throw(exception: any): Promise<IteratorResult<any, void>> {
    this.disconnect()
    return {value: exception, done: true}
  }
}

async function _getCsrfToken(): Promise<string> {
  const cookieResult = await chrome.runtime.sendMessage(EXTENSION_ID, {'type': MESSAGE_TYPES.GET_LINKEDIN_COOKIES})
  if (!cookieResult.success) {
    throw new Error(cookieResult.errors?.join('; '))
  }
  return _extractCsrfTokenFromCookies(cookieResult.cookies)
}

interface ChromeCookie {
  name: string
  value: string
}

function _extractCsrfTokenFromCookies(cookies: ChromeCookie[]): string {
  const jsessionidCookies = cookies.filter(c => c.name === 'JSESSIONID' && c.value.startsWith('"'))
  let lastError = null
  for (const cookie of jsessionidCookies) {
    try {
      return JSON.parse(cookie.value)
    } catch (e) {
      lastError = e
    }
  }
  throw new LinkedInNotLoggedIn(
    lastError === null
    ? 'No cookies; you probably need to log in to LinkedIn first'
    : `Only unparseable cookies found; last attempt: ${lastError}`
  )
}

function _makeBaseHeaders(claimToBeLighthouse: boolean = false): Record<string, string> {
  return {
    'Priority': 'u=1, i',
    'Accept-Language': 'en-US,en;q=0.9',
    'Sec-Fetch-Dest': 'empty',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'same-origin',
    'X-Li-Lang': 'en_US',
    'X-Li-Page-Instance': 'urn:li:page:d_messaging_index;7EG2lEpiQaKR8UIvl/W82Q==',
    'X-Li-Track': JSON.stringify(
      claimToBeLighthouse ? {
        'clientVersion': VERSION_LIGHTHOUSE_WEB,
        'mpVersion': VERSION_LIGHTHOUSE_WEB,
        'osName': 'web',
        'timezoneOffset': (-new Date().getTimezoneOffset() || 0) / 60,
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'deviceFormFactor': 'DESKTOP',
        'mpName': 'lighthouse-web',
      } : {
        'mpName': 'voyager-web',
        'osName': 'web',
        'mpVersion': VERSION_VOYAGER_WEB,
        'clientVersion': VERSION_VOYAGER_WEB,
        'deviceFormFactor': 'DESKTOP',
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'timezoneOffset': (-new Date().getTimezoneOffset() || 0) / 60,
      }
    ),
    'X-Restli-Protocol-Version': '2.0.0',
  }
}
