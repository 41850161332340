import {Button, CalendarCell, CalendarGrid, DateInput, DateRangePicker, DateSegment, Dialog, Group, Heading, Label, Popover, RangeCalendar} from 'react-aria-components';
import { CalendarDate, getLocalTimeZone } from '@internationalized/date';
import { RangeValue } from "@react-types/shared";
import { defaultTheme, Provider as AdobeProvider} from '@adobe/react-spectrum';

export default {
  title: 'React Aria Components'
};

export type DateRangeProps = {
  start: Date,
  end: Date,
  onValueUpdate: (start: Date, end: Date) => void
  updateEndDateToMidnight?: boolean 
  variant?: string
  fontSize?: string
  minWidth?: boolean
}

export function CalendarComponent(props: DateRangeProps) {
  return (
    <AdobeProvider theme={defaultTheme} UNSAFE_className={"w-full"}>
    <DateRangePicker
    style={{
      fontFamily: 'Calibri,Arial,Helvetica,sans-serif',
      backgroundColor: 'white'
    }}
    value={{
      start: new CalendarDate(props.start.getFullYear(), props.start.getMonth() + 1, props.start.getDate()),
      end: new CalendarDate(props.end.getFullYear(), props.end.getMonth() + 1, props.end.getDate()),
    }}
    onChange={(value: RangeValue<CalendarDate> | null) => {
        if (!value) return 
        if (value.start.year < 2021 || value.end.year < 2021) return 
        const startDate = value.start.toDate(getLocalTimeZone())
        const endDate = value.end.toDate(getLocalTimeZone())
        if (startDate.getTime() > endDate.getTime()) return
        props.onValueUpdate(startDate, endDate)
      }}
      isRequired={true}>
      <Group style={{
        display: 'inline-flex',
        padding: '2px 4px',
        borderRadius: '5px',
        border: '0.5px solid lightgrey',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '2.5px',
        minWidth: '160px',
        width: '100%',
        backgroundColor: 'white',
        color: 'black'
        }}>
        <div style={{
          'width': '100%',
          'display': 'flex',
          'justifyContent': 'space-around',
          backgroundColor: 'white',
          color: 'black'
        }} >
          <DateInput slot="start" style={{
            display: 'inline-flex'
            }}>
            {segment => <DateSegment segment={segment}
            className={'focus:text-white focus:bg-gray-700 focus:outline-none focus:rounded-sm focus:caret-transparent'}
            style={{
              'padding': '0 1px',
              'fontVariantNumeric': 'tabular-nums',
              'textAlign': 'center',
              'fontFamily': 'Calibri,Arial,Helvetica,sans-serif',
              'fontSize': props.fontSize ?? undefined,
              'display': 'flex',
              'alignItems': 'center',
            }} />}
          </DateInput>
          <span aria-hidden="true" style={{padding: '0 4px', color: 'black'}}>–</span>
          <DateInput slot="end" style={{display: 'inline-flex'}}>
            {segment => <DateSegment segment={segment} 
            className={'focus:text-white focus:bg-gray-700 focus:outline-none focus:rounded-sm focus:caret-transparent'}
            style={{
              'padding': '0 1px',
              'fontVariantNumeric': 'tabular-nums',
              'textAlign': 'center',
              'fontFamily': 'Calibri,Arial,Helvetica,sans-serif',
              'fontSize': props.fontSize ?? undefined,
              'display': 'flex',
              'alignItems': 'center',
              color: 'black'
            }}/>}
          </DateInput>
        </div>
        <Button>🗓</Button>
      </Group>
      <Popover
        placement="bottom start"
        style={{
          background: 'Canvas',
          color: 'CanvasText',
          padding: 20,
          borderRadius: '10px',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
        }}>
        <Dialog>
          <RangeCalendar style={{
            width: 200,
            fontFamily: 'Calibri,Arial,Helvetica,sans-serif',
            fontSize: '14px'
            }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button slot="previous">&lt;</Button>
              <Heading style={{flex: 1, textAlign: 'center'}} />
              <Button slot="next">&gt;</Button>
            </div>
            <CalendarGrid style={{width: '100%'}}>
              {date => <CalendarCell date={date} style={({isSelected, isOutsideMonth}) => 
              ({display: isOutsideMonth ? 'none' : '', 
              textAlign: 'center', 
              cursor: 'default', 
              background: isSelected ? 'lightgrey' : ''})} />}
            </CalendarGrid>
          </RangeCalendar>
        </Dialog>
      </Popover>
    </DateRangePicker>
    </AdobeProvider>
    )
}