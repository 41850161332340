import { Loader } from "components/Loader"
import { NumberManagementAdmin } from "./NumberManagementAdmin"
import { NumberManagementIndividual } from "./NumberManagementIndividual"
import { connect } from "react-redux"
import { RootState } from "store"
import { clearCache, SERVICE_CALLS } from "extension-helpers"
import { useEffect } from "react"
type NumberRegistrationProps = {
    isAdmin: boolean | null
}

function NumberRegistrationImpl({ isAdmin }: NumberRegistrationProps) {
    useEffect(() => {
        clearCache(SERVICE_CALLS.PARALLEL_SETTINGS)
        clearCache(SERVICE_CALLS.AUTODIALER_INFO)
        clearCache(SERVICE_CALLS.USER_INFO)
    }, []);
    if (isAdmin === null) return <Loader />
    return isAdmin ? <NumberManagementAdmin /> : <NumberManagementIndividual />
}

const ReduxWrapped = connect((state: RootState) => {
    return {
        isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
    }
  })(NumberRegistrationImpl)
  
  export { ReduxWrapped as NumberRegistration}
  