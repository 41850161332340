import { useCallback, useEffect, useRef, useState } from 'react';
import { VAPI_LIST_KEY } from 'cfg/vapi';
import { VapiClient } from "@vapi-ai/server-sdk";
import { Call } from "@vapi-ai/server-sdk/api"

const POLL_INTERVAL = 5000; // 5 seconds

export const useVapi = (assistantId: string | null) => {
  const clientRef = useRef<VapiClient | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [allBotCalls, setAllBotCalls] = useState<Call[]>([]);
  const [lastCreatedAt, setLastCreatedAt] = useState<string | null>(null);
  const [earliestCreatedAt, setEarliestCreatedAt] = useState<string | null>(null);
  const [hasMorePastCalls, setHasMorePastCalls] = useState(true);
  const lastPollResultRef = useRef<{[key: string]: Call}>({});
  
  // Improved updateCalls function to prevent unnecessary updates
  const updateCalls = useCallback((calls: Call[]) => {
    if (calls.length === 0) return;
    
    // Check if any call has actually changed
    let hasChanges = false;
    const currentCallsMap = {...lastPollResultRef.current};
    
    // Check for new or updated calls
    for (const call of calls) {
      const existingCall = currentCallsMap[call.id];
      if (!existingCall || 
          JSON.stringify(existingCall) !== JSON.stringify(call)) {
        hasChanges = true;
        currentCallsMap[call.id] = call;
      }
    }
    
    // Only update state if there are actual changes
    if (hasChanges) {
      lastPollResultRef.current = currentCallsMap;
      
      setAllBotCalls((prevCalls) => {
        // Create a map of existing calls for faster lookup
        const prevCallsMap = new Map(prevCalls.map(call => [call.id, call]));
        
        // Update existing calls and add new ones
        for (const call of calls) {
          prevCallsMap.set(call.id, call);
        }
        
        // Convert map back to array and sort by createdAt (newest first)
        return Array.from(prevCallsMap.values())
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      });
    }
  }, []);

  // Initialize client with VAPI_LIST_KEY
  useEffect(() => {
    clientRef.current = new VapiClient({ token: VAPI_LIST_KEY });
    setIsReady(true);
  }, []); // Empty deps since key is constant

  const refreshCalls = useCallback(async () => {
    if (!clientRef.current) return;

  }, []);

  const fetchPastCalls = useCallback(async () => {
    if (!clientRef.current) return;
    if (!assistantId) return;
    const calls = await clientRef.current.calls.list({ 
        assistantId: assistantId,
        createdAtLt: earliestCreatedAt ? earliestCreatedAt : undefined });
    if (calls.length > 0) {
        setHasMorePastCalls(true);
    } else {
        setHasMorePastCalls(false);
    }
    updateCalls(calls);
  }, [earliestCreatedAt, assistantId, updateCalls]);

  const pollForActiveCalls = useCallback(async () => {
    if (!clientRef.current) return;
    if (!assistantId) return;

    const pollCalls = async () => {
      if (!clientRef.current) return;
      try {
        const calls = await clientRef.current.calls.list({
            assistantId: assistantId,
            createdAtGt: lastCreatedAt ? lastCreatedAt : undefined,
        });
        if (calls.length > 0) {
            setLastCreatedAt(calls[0].createdAt);
            setEarliestCreatedAt((prev) => {
                const earliest = calls[calls.length - 1].createdAt;
                if (!prev || new Date(earliest) < new Date(prev)) {
                    return earliest;
                }
                return prev;
            });
        }
        updateCalls(calls);
      } catch (error) {
        console.error('Error polling for active calls:', error);
      }
    };

    // Initial poll
    await pollCalls();

    // Set up interval
    const intervalId = setInterval(pollCalls, POLL_INTERVAL);

    // Return cleanup function
    return () => clearInterval(intervalId);
  }, [assistantId, lastCreatedAt, updateCalls]);

  const placeCall = useCallback(async (params: {
    name: string;
    phoneNumber: string;
    customInstructions?: string;
    address?: string;
    metadata?: Record<string, any>;
  }) => {
    if (!assistantId) return null;
    if (!clientRef.current) return null;

    // Format phone number to E.164 format if not already
    let formattedPhoneNumber = params.phoneNumber;
    
    // Remove any non-digit characters
    formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, '');
    
    // Add +1 country code if not already present (assuming US numbers)
    if (!formattedPhoneNumber.startsWith('1')) {
      formattedPhoneNumber = '1' + formattedPhoneNumber;
    }
    
    // Add the + prefix
    formattedPhoneNumber = '+' + formattedPhoneNumber;

    // Extract first name from the full name
    const firstName = params.name.split(" ")[0];

    // Create variable values with all information
    const variableValues: Record<string, string> = {
      customInstructions: params.customInstructions ?? "N/A",
      fullName: params.name,
      firstName: firstName
    };
    
    // Add address to variable values if provided
    if (params.address) {
      variableValues.address = params.address;
      variableValues.customer_address = params.address;
    }

    const customer: {[key: string]: string} = {
      name: firstName,
      number: formattedPhoneNumber,
    }

    try {
      const call = await clientRef.current.calls.create({
        assistantId: assistantId,
        phoneNumberId: 'f3ad906c-74e7-4c15-9786-6cbd157ec624',
        name: firstName,
        customer: customer,
        assistantOverrides: {
            name: firstName,
            variableValues: variableValues
        }
      }, {
      });
      updateCalls([call]);
      return call;
    } catch (error) {
      console.error('Error placing call:', error);
      return null;
    }
  }, [assistantId, updateCalls]);

  return {
    pollForActiveCalls,
    placeCall,
    refreshCalls,
    fetchPastCalls,
    allBotCalls,
    updateCalls,
    hasMorePastCalls,
    isReady,
  };
};