export enum BusinessIndustry {
    AUTOMOTIVE = 'AUTOMOTIVE',
    AGRICULTURE = 'AGRICULTURE',
    BANKING = 'BANKING',
    CONSUMER = 'CONSUMER',
    EDUCATION = 'EDUCATION',
    ENGINEERING = 'ENGINEERING',
    ENERGY = 'ENERGY',
    OIL_AND_GAS = 'OIL_AND_GAS',
    FAST_MOVING_CONSUMER_GOODS = 'FAST_MOVING_CONSUMER_GOODS',
    FINANCIAL = 'FINANCIAL',
    FINTECH = 'FINTECH',
    FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
    GOVERNMENT = 'GOVERNMENT',
    HEALTHCARE = 'HEALTHCARE',
    HOSPITALITY = 'HOSPITALITY',
    INSURANCE = 'INSURANCE',
    LEGAL = 'LEGAL',
    MANUFACTURING = 'MANUFACTURING',
    MEDIA = 'MEDIA',
    ONLINE = 'ONLINE',
    RAW_MATERIALS = 'RAW_MATERIALS',
    REAL_ESTATE = 'REAL_ESTATE',
    RELIGION = 'RELIGION',
    RETAIL = 'RETAIL',
    JEWELRY = 'JEWELRY',
    TECHNOLOGY = 'TECHNOLOGY',
    TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
    TRANSPORTATION = 'TRANSPORTATION',
    TRAVEL = 'TRAVEL',
    ELECTRONICS = 'ELECTRONICS',
    NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
}

export enum BusinessRegistrationAuthority {
    DUNS = 'DUNS',
    EIN = 'EIN',
    CCN = 'CCN',
}

export enum OperationsRegion {
    AFRICA = 'AFRICA',
    ASIA = 'ASIA',
    EUROPE = 'EUROPE',
    LATIN_AMERICA = 'LATIN_AMERICA',
    USA_AND_CANADA = 'USA_AND_CANADA',
}

export interface CustomerProfileLinks {
    customer_profiles_entity_assignments: string;
    customer_profiles_evaluations: string;
    customer_profiles_channel_endpoint_assignment: string;
}

export interface BundleErrorItem {
    code: number;
}

export interface CustomerProfile {
    sid: string;
    account_sid: string;
    policy_sid: string;
    friendly_name: string;
    status: 'draft' | 'pending-review' | 'in-review' | 'twilio-rejected' | 'twilio-approved'
    email: string;
    date_created: string;
    date_updated: string;
    url: string;
    links: CustomerProfileLinks;
    valid_until?: string;
    status_callback?: string;
    errors?: BundleErrorItem[];
}

export function jsonSafeToCustomerProfile(json: any): CustomerProfile {
    return {
        sid: json.sid,
        account_sid: json.account_sid,
        policy_sid: json.policy_sid,
        friendly_name: json.friendly_name,
        status: json.status,
        email: json.email,
        date_created: json.date_created,
        date_updated: json.date_updated,
        url: json.url,
        links: json.links,
        valid_until: json.valid_until,
        status_callback: json.status_callback,
        errors: json.errors
    }
}

export interface PostalAddress {
    sid: string;
    account_sid: string;
    date_created: string;
    date_updated: string;
    friendly_name?: string;
    customer_name: string; // max 64 bytes
    street: string;
    street_secondary?: string;
    city: string;
    region: string;
    postal_code: string;
    iso_country: string;
    uri: string;
    emergency_enabled: boolean;
    validated: boolean;
    verified: boolean;
}

export function jsonSafeToPostalAddress(json: any): PostalAddress {
    return {
        sid: json.sid,
        account_sid: json.account_sid,
        date_created: json.date_created,
        date_updated: json.date_updated,
        friendly_name: json.friendly_name,
        customer_name: json.customer_name,
        street: json.street,
        street_secondary: json.street_secondary,
        city: json.city,
        region: json.region,
        postal_code: json.postal_code,
        iso_country: json.iso_country,
        uri: json.uri,
        emergency_enabled: json.emergency_enabled,
        validated: json.validated,
        verified: json.verified
    }
}

export interface SupportingDocument {
    sid: string;
    account_sid: string;
    friendly_name: string;
    status: string; // 'draft' | 'pending-review' | 'in-review' | 'twilio-rejected' | 'twilio-approved'
    type: string;
    attributes: Record<string, any>;
    date_created: string;
    date_updated: string;
    url: string;
    mime_type?: string;
    }

export function jsonSafeToSupportingDocument(json: any): SupportingDocument | null {
    if (!json) return null
    return {
        sid: json.sid,
        account_sid: json.account_sid,
        friendly_name: json.friendly_name,
        status: json.status,
        type: json.type,
        attributes: json.attributes,
        date_created: json.date_created,
        date_updated: json.date_updated,
        url: json.url,
        mime_type: json.mime_type
    }
}

export interface BusinessInfoAttributes {
    business_name: string;
    business_identity: string;
    business_type: string;
    business_industry: BusinessIndustry;
    business_registration_identifier: BusinessRegistrationAuthority;
    business_registration_number: string;
    business_regions_of_operation: OperationsRegion;
    website_url: string;
    social_media_profile_urls: string;
}

export function jsonSafeToBusinessInfoAttributes(json: any): BusinessInfoAttributes {
    return {
        business_name: json.business_name,
        business_identity: json.business_identity,
        business_type: json.business_type,
        business_industry: json.business_industry,
        business_registration_identifier: json.business_registration_identifier,
        business_registration_number: json.business_registration_number,
        business_regions_of_operation: json.business_regions_of_operation,
        website_url: json.website_url,
        social_media_profile_urls: json.social_media_profile_urls
    }
}

export interface BusinessInfo {
    sid: string;
    account_sid: string;
    date_created: string;
    date_updated: string;
    url: string;
    friendly_name: string;
    type: string;
    attributes: BusinessInfoAttributes;
}

export function jsonSafeToBusinessInfo(json: any): BusinessInfo {
    return {
        sid: json.sid,
        account_sid: json.account_sid,
        date_created: json.date_created,
        date_updated: json.date_updated,
        url: json.url,
        friendly_name: json.friendly_name,
        type: json.type,
        attributes: json.attributes
    }
}

export interface AuthorizedRepresentativeAttributes {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    business_title: string;
    job_position: string;
}

export function jsonSafeToAuthorizedRepresentativeAttributes(json: any): AuthorizedRepresentativeAttributes {
    return {
        first_name: json.first_name,
        last_name: json.last_name,
        email: json.email,
        phone_number: json.phone_number,
        business_title: json.business_title,
        job_position: json.job_position 
    }
}

export interface AuthorizedRepresentative {
    sid: string;
    account_sid: string;
    date_created: string;
    date_updated: string;
    url: string;
    friendly_name: string;
    type: string;
    attributes: AuthorizedRepresentativeAttributes;
}

export function jsonSafeToAuthorizedRepresentative(json: any): AuthorizedRepresentative {
    return {
        sid: json.sid,
        account_sid: json.account_sid,
        date_created: json.date_created,
        date_updated: json.date_updated,
        url: json.url,
        friendly_name: json.friendly_name,
        type: json.type,
        attributes: json.attributes
    }
}

export enum VoiceIntegrityUseCase {
    IDENTIFY_AND_VERIFICATION = 'Identify & Verification',
    ASSET_MANAGEMENT = 'Asset Management',
    LEAD_GENERATION = 'Lead Generation',
    INTELLIGENT_ROUTING = 'Intelligent Routing',
    APPOINTMENT_SCHEDULING = 'Appointment Scheduling',
    CUSTOMER_SUPPORT = 'Customer Support',
    SELF_SERVICE = 'Self-Service',
    AUTOMATED_SUPPORT = 'Automated Support',
    APPOINTMENT_REMINDERS = 'Appointment Reminders',
    EMPLOYEE_NOTIFICATIONS = 'Employee Notifications',
    DELIVERY_NOTIFICATIONS = 'Delivery Notifications',
    EMERGENCY_NOTIFICATIONS = 'Emergency Notifications',
    CONTACTLESS_DELIVERY = 'Contactless Delivery',
    ORDER_NOTIFICATIONS = 'Order Notifications',
    SERVICE_ALERTS = 'Service Alerts',
    PURCHASE_CONFIRMATION = 'Purchase Confirmation',
    MASS_ALERTS = 'Mass Alerts',
    FRAUD_ALERTS = 'Fraud Alerts',
    CONTACT_TRACING = 'Contact Tracing',
    LEAD_MANAGEMENT = 'Lead Management',
    LEAD_NURTURING = 'Lead Nurturing',
    TELEMARKETING = 'Telemarketing',
    MARKETING_EVENTS = 'Marketing Events',
    REWARDS_PROGRAM = 'Rewards Program',
    LEAD_ALERTS = 'Lead Alerts',
    LEAD_DISTRIBUTION = 'Lead Distribution',
    ABANDONED_CART = 'Abandoned Cart',
    CALL_TRACKING = 'Call Tracking',
    OUTBOUND_DIALER = 'Outbound Dialer',
    CLICK_TO_CALL = 'Click to Call',
    PHONE_SYSTEM = 'Phone System',
    MEETINGS_COLLABORATION = 'Meetings/Collaboration',
    TELEHEALTH = 'Telehealth',
    DISTANCE_LEARNING = 'Distance Learning',
    SHIFT_MANAGEMENT = 'Shift Management',
    FIELD_NOTIFICATIONS = 'Field Notifications',
    DATING_SOCIAL = 'Dating/Social',
    REMOTE_APPOINTMENTS = 'Remote appointments',
    GROUP_MESSAGING = 'Group Messaging',
    EXAM_PROCTORING = 'Exam Proctoring',
    TUTORING = 'Tutoring',
    THERAPY_INDIVIDUAL_GROUP = 'Therapy (Individual+Group)',
    PHARMACY = 'Pharmacy',
    FIRST_RESPONDER = 'First Responder',
    SURVEY_RESEARCH = 'Survey/Research',
}


export interface VoiceIntegrityAttributes {
    use_case: VoiceIntegrityUseCase;
    business_employee_count: string;
    average_business_day_call_volume: string;
    notes: string;
}

export interface VoiceIntegrityInfo {
    sid: string;
    account_sid: string;
    date_created: string;
    date_updated: string;
    url: string;
    friendly_name: string;
    type: string;
    attributes: VoiceIntegrityAttributes;
}

export function jsonSafeToVoiceIntegrityInfo(json: any): VoiceIntegrityInfo {
    return {
        sid: json.sid,
        account_sid: json.account_sid,
        date_created: json.date_created,
        date_updated: json.date_updated,
        url: json.url,
        friendly_name: json.friendly_name,
        type: json.type,
        attributes: json.attributes 
    }
}


export interface CustomerProfileData {
    profile: CustomerProfile;
    address?: PostalAddress;
    business_info?: BusinessInfo;
    authorized_representative?: AuthorizedRepresentative;
    voice_integrity_info?: VoiceIntegrityInfo;
}


export function jsonSafeToCustomerProfileData(json: any): CustomerProfileData | null {
    if (!json) return null
    console.log(`jsonSafeToCustomerProfileData json: ${json}`, json.voice_integrity_info)
    return {
        profile: jsonSafeToCustomerProfile(json.profile),
        address: json.address ? jsonSafeToPostalAddress(json.address) : undefined,
        business_info: json.business_info ? jsonSafeToBusinessInfo(json.business_info) : undefined,
        authorized_representative: json.authorized_representative ? jsonSafeToAuthorizedRepresentative(json.authorized_representative) : undefined,
        voice_integrity_info: json.voice_integrity_info ? jsonSafeToVoiceIntegrityInfo(json.voice_integrity_info) : undefined,
    }
}

export const AUTHORIZED_REPRESENTATIVE_JOB_POSITIONS = [
    'Director',
    'GM',
    'VP',
    'CEO',
    'CFO',
    'General Counsel',
] as const;

export type AuthorizedRepresentativeJobPosition = typeof AUTHORIZED_REPRESENTATIVE_JOB_POSITIONS[number];

export const BUSINESS_TYPES = [
    'Sole Proprietorship',
    'Partnership',
    'Limited Liability Corporation',
    'Corporation',
    'Co-operative',
    'Non-profit',
] as const;

export type BusinessType = typeof BUSINESS_TYPES[number];

