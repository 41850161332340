import React, { useState, useEffect, KeyboardEvent } from 'react';
import { Box, Skeleton, Chip, alpha, TextField, Link } from '@mui/material';
import { useLinkedInConversationData } from '../../../../lib/redux/linkedin/conversations/data-hook';
import { renderSimplifiedConversationName } from '../../../../lib/redux/linkedin/helpers';
import { ConversationHeaderToolbar } from './ConversationHeaderToolbar';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { updateNote } from '../../../../lib/redux/linkedin/metadata/slice';
import { ConversationId, SOURCE_TYPE } from '../../../../lib/redux/linkedin/types';
import { pictureToUrl } from '../../../../lib/redux/linkedin/helpers';
import { Group as GroupIcon } from '@mui/icons-material';
import { Typography } from 'interfaces/typography';
import { styled } from '@mui/material/styles';
import { setProfilePanelOpen } from '../../../../lib/redux/linkedin/navigate/slice';
import { ReduxSafeConversation } from 'lib/redux/linkedin/conversations/chat-slice';
import { ReduxSafeSalesConversation } from 'lib/redux/linkedin/conversations/sales-nav-slice';

const NameTypography = styled(Typography)`
  user-select: none;
`;

const ProfileLink = styled(Link)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  opacity: 0.8,
  marginTop: 2,
  display: 'inline-block',
  userSelect: 'all'
}));

export const ConversationHeader: React.FC<{ conversationId: ConversationId }> = React.memo(({ conversationId }) => {
  const { getConversationMetadataForId } = useLinkedInConversationData();
  const conversationState = useSelector((state: RootState) => state.linkedinConversationStates.states[conversationId.id]);
  const dispatch = useDispatch();
  const [localNote, setLocalNote] = useState('');

  useEffect(() => {
    setLocalNote(conversationState?.note || '');
  }, [conversationState?.note]);

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalNote(e.target.value);
  };

  const saveNote = () => {
    const trimmedNote = localNote.trim();
    dispatch(updateNote({
      conversationId: conversationId,
      note: trimmedNote || null
    }));
  };

  const handleNoteKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur();
      saveNote();
    }
  };

  const selectedConversationMetaData = getConversationMetadataForId(conversationId);
  const isLoading = !selectedConversationMetaData;
  const hasTags = conversationState?.tags && conversationState.tags.length > 0;

  const { name, isGroup } = selectedConversationMetaData ? renderSimplifiedConversationName(conversationId, selectedConversationMetaData) : { name: '', isGroup: false };
  
  const getFirstParticipantInfo = () => {
    if (!selectedConversationMetaData) return { picture: null, profileUrl: null, headline: null };
    
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
      const chatConversation = selectedConversationMetaData as ReduxSafeConversation
      const participant = chatConversation.conversationParticipants?.find((p: any) => 
        p.participantType?.member?.distance !== 'SELF'
      );
      return {
        picture: participant?.participantType?.member?.profilePicture,
        profileUrl: participant?.participantType?.member?.profileUrl,
        headline: participant?.participantType?.member?.headline?.text || null
      };
    } else {
      const salesConversation = selectedConversationMetaData as ReduxSafeSalesConversation
      const participant = salesConversation.participants?.find((p: any) => p.degree !== 0);
      return {
        picture: participant?.profilePictureDisplayImage,
        profileUrl: null,
        headline: null
      };
    }
  };

  const { picture, profileUrl, headline } = getFirstParticipantInfo();
  const isOneOnOne = !isGroup && !(selectedConversationMetaData as any)?.groupChat;
  const isChat = conversationId.sourceType === SOURCE_TYPE.CHAT;

  const renderProfilePicture = () => {
    if (isLoading) return null;

    const isGroupChat = isChat && (selectedConversationMetaData as any).groupChat;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isGroup && isGroupChat ? (
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              bgcolor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0
            }}
          >
            <GroupIcon sx={{ color: 'white', fontSize: 24 }} />
          </Box>
        ) : picture ? (
          <img 
            src={pictureToUrl(picture)}
            alt={name}
            style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            referrerPolicy="no-referrer"
          />
        ) : (
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              bgcolor: isGroup ? 'primary.main' : 'grey.300',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0
            }}
          >
            {isGroup ? (
              <GroupIcon sx={{ color: 'white', fontSize: 24 }} />
            ) : (
              <Typography 
                variant="caption" 
                style={{ color: isGroup ? 'white' : 'inherit', userSelect: 'all' }}
              >
                {name.split(' ')[0][0]}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ 
      p: '10px 16px',
      borderBottom: '1px solid',
      borderColor: 'divider',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      position: 'relative'
    }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
          <Box sx={{ width: 40 }}>
            <Skeleton variant="circular" width={40} height={40} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ mb: 0.5 }} />
            <Skeleton variant="text" width="30%" height={16} />
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Skeleton variant="circular" width={28} height={28} />
            <Skeleton variant="circular" width={28} height={28} />
            <Skeleton variant="circular" width={28} height={28} />
          </Box>
        </Box>
      ) : (
        <>
          {/* First row: Profile picture, Name, Actions */}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            <Box sx={{ width: 40 }}>
              {renderProfilePicture()}
            </Box>
            <Box sx={{ flex: 1 }}>
              <NameTypography variant="h3">
                {name}
              </NameTypography>
              {isOneOnOne && isChat && headline && (
                <ProfileLink>
                  {headline}
                </ProfileLink>
              )}
            </Box>
            <ConversationHeaderToolbar profileUrl={profileUrl || undefined} />
          </Box>

          {/* Tags and Note row */}
          <Box sx={{ 
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            minHeight: 32,
          }}>
            {/* Only render tags container if there are tags */}
            {hasTags && conversationState?.tags && conversationState.tags.length > 0 && (
              <Box sx={{ 
                display: 'flex',
                gap: 0.75,
                alignItems: 'center',
                flexWrap: 'wrap',
                height: '100%',
              }}>
                {conversationState.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    size="small"
                    sx={{
                      height: 24,
                      fontSize: '0.75rem',
                      backgroundColor: theme => alpha(theme.palette.primary.main, 0.08),
                      color: 'primary.main',
                      '& .MuiChip-label': {
                        px: 1,
                        lineHeight: '24px',
                      }
                    }}
                  />
                ))}
              </Box>
            )}

            {/* Note field */}
            <TextField
              placeholder="Add note..."
              value={localNote}
              onChange={handleNoteChange}
              onBlur={saveNote}
              onKeyDown={handleNoteKeyDown}
              variant="standard"
              size="small"
              fullWidth
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontSize: '0.875rem',
                  color: '#666666',
                  opacity: 0.8,
                  cursor: 'text',
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    opacity: 1
                  },
                  '&.Mui-focused': {
                    opacity: 1
                  },
                  transition: 'opacity 0.2s ease'
                }
              }}
              sx={{
                flex: 1,
                '& input': {
                  padding: 0,
                  height: '24px',
                  lineHeight: '24px',
                },
                '& input::placeholder': {
                  color: '#666666',
                  opacity: 0.8
                }
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
});

ConversationHeader.displayName = 'ConversationHeader'; 