export const SERVICES_HOSTNAME: string = 'api.trellus.ai'
export const AUGMENTATION_HOSTNAME: string = 'aug-1.trellus.ai'
export const REPORT_HOSTNAME = 'report-1.trellus.ai'
export const LOGGING_HOSTNAME = 'log-1.trellus.ai'
export const MULTIDIALER_HOSTNAME = 'parallel.trellus.ai'
export const SALES_FLOOR_HOSTNAME = 'floor.trellus.ai'
export const PARALLEL_HOSTNAME = 'parallel.trellus.ai'
export const RELAY_HOSTNAME = 'relay.trellus.ai'

export const DIAL_SERVER_BACKUP = 'dial-1.trellus.ai'

// realtime endpoints
export const SUBSCRIBE_CLIENT_ENDPOINT = 'subscribe-client';
export const FINISH_SESSION_ENDPOINT = 'finish-session';
export const SET_LANGUAGE = 'set-language';
export const SET_PROSPECT_RECORDED = 'set-prospect-recorded';

// services endpoints
export const GET_METRIC_SUMMARY: string = 'get-metric-summary-v2'
export const GET_METRIC_DETAILS: string = 'get-metric-details'
export const METRIC_DETAILS_V3: string = 'metric-details-v3'
export const METRIC_DETAILS_V4: string = 'metric-details-v4'
export const METRIC_DETAILS_V5: string = 'metric-details-v5'
export const GET_SUMMARY_DATA_ENDPOINT: string = 'get-summary-data'
export const LIST_USER_SESSIONS_ENDPOINT: string = 'list-user-sessions'
export const SESSION_LIST_V3: string = 'session-list-v3'
export const GET_SESSION_SUMMARIES: string = 'get-session-summaries'
export const NUMBER_HEALTH: string = 'number-health'
export const AREA_CODE_CONNECT_RATE: string = 'area-code-connect-rate'

export const GET_SESSION_DATA_ENDPOINT: string = 'get-session-data-v2'
export const GET_SESSION_DATA_V3: string = 'get-session-data-v3'
export const GET_USER_TRIGGERS_ENDPOINT = 'get-user-triggers'
export const SAVE_USER_TRIGGERS_ENDPOINT = 'save-user-triggers'
export const GET_HEALTH_TABLE_ENDPOINT: string = 'get-health-table'
export const GET_HEALTH_SERIES_ENDPOINT: string = 'get-health-series'
export const GET_NAMED_PERSONS: string = 'get-named-persons'
export const SUBMIT_NOTES_ENDPOINT: string = 'submit-notes'
export const GET_USER_DISPOSITIONS: string = 'get-user-dispositions'
export const PROSPECT_INFO_CHOICES: string = 'prospect-info-choices'
export const DISPOSITION_CHOICES: string = 'disposition-choices'
export const GET_USER: string = 'get-user'
export const GET_TEAM: string = 'get-team'
export const FRONTEND_LOGS: string = 'frontend-logs'

export const AUTODIALER_SETTINGS = 'autodialer_settings'
export const AUTODIALER_MAPPINGS = 'autodialer_mappings'
export const AUTODIALER_MAPPING_OPTIONS = 'autodialer_mapping_options'

export const MULTIDIALER ='multidialer'
export const MULTIDIALER_HOST = 'multidialer-host'

export const SIGNUP_USER_ENDPOINT = 'signup-user'
export const SIGNIN_USER_ENDPOINT = 'signin-user'
export const PASSWORD_RESET_REQUEST = 'request-reset-password'
export const PASSWORD_RESET = 'reset-password'

export const UPDATE_DISPLAY_ENDPOINT = 'update-display'
export const SUBMIT_TRACKER = 'submit-tracker'
export const REQUEST_TEXT_SUMMARY_ENDPOINT = 'request-text-summary'
export const REQUEST_EMAIL_ENDPOINT = 'request-email'
export const REQUEST_GEN_COACHING_ENDPOINT = 'request-gen-coaching'
export const REQUEST_AGENT_SUMMARY = 'request-agent-summary'
export const REQUEST_CUSTOM_SCORE = 'request-custom-score'
export const STAR_SESSION = 'star-session'
export const SESSION_REVIEW = 'session-review'
export const SETTINGS: string = 'settings'
export const SCOPES_V2 = 'scopes_v2'
export const GET_VISIBLE_ACCOUNTS = 'get-visible-accounts'
export const GET_ADMIN_WRITE_TARGETS = 'get-admin-write-targets'
export const COMMENTS = 'comments'
export const SESSION_STATUSES = 'session-statuses'
export const SUBTEAM_VIEW = 'subteam-view'
export const SUBTEAM = 'subteam'
export const SUBTEAM_MEMBER = 'subteam-member'
export const USER_GROUP = 'user-group'
export const USER_GROUP_MEMBERSHIP = 'user-group-membership'
export const EXTERNAL_ACCOUNT = 'external-account'
export const EXTERNAL_ACCOUNT_LINK = 'external-account-link'

export const LEADERBOARD_AVATAR = 'leaderboard-avatar'
export const LEADERBOARD_ROOM = 'leaderboard-room' 
export const LEADERBOARD_MEMBERSHIP = 'leaderboard-membership'
export const LEADERBOARD_DATA = 'leaderboard-data'

export const KEYWORD = 'keyword'
export const KEYWORD_PHRASE = 'keyword-phrase'
export const KEYWORD_GROUP = 'keyword-group'
export const KEYWORD_GROUP_MEMBERSHIP = 'keyword-group-membership'

export const CONDITIONS = 'conditions'
export const PLAYS = 'plays'

export const BACKTEST_CONDITION = 'backtest-condition'

export const AUDIO_LINKS = 'get-audio-links'
export const AUDIO_ENDPOINT = 'audio'
export const SUBSCRIPTION_CREATION = 'subscription_creation'
export const PRICES = 'prices'
export const CAN_UPGRADE = 'can_upgrade'

export const EMAIL_VALUE_PROP = 'email-value-prop'
export const REQUEST_PROSPECT_EMAIL_GEN = 'request-prospect-email-gen'
export const REQUEST_PROSPECT_EMAIL_GEN_REVISION = 'request-prospect-email-revision'
export const REQUEST_PROSPECT_LINKEDIN_GEN = 'request-prospect-linkedin-gen'
export const REQUEST_PROSPECT_LINKEDIN_REVISION = 'request-prospect-linkedin-revision'
export const AI_MANY_CALL_ANALYSIS = 'ai-many-call-analysis'
export const AI_MANY_CALL_ANALYSIS_V2 = 'ai-many-call-analysis-v2'
export const CUSTOM_DEFINITIONS = 'custom-definitions'
export const REQUEST_CUSTOM_METRIC = 'request-custom-metric'
export const SERVICES_WEATHER = 'services-weather'

export const SAVED_REPORTS = 'saved-reports'

// login type
export const PASSWORD_LOGIN = 'PASSWORD_LOGIN'

// for internal tool to update users via trellus-web
export const USER_ADMIN = 'user_admin'
export const TEAM_ADMIN = 'team_admin'
export const SCOPE_ADMIN = 'scope_admin'
export const DOMAIN_ADMIN = 'domain_admin'
export const SEATS = 'seats'


// parallel dialer things
export const TWILIO_NUMBERS = 'twilio-numbers'
export const TWILIO_NUMBERS_ADMIN = 'twilio-numbers-admin'
export const TWILIO_TOKEN = 'twilio-token'
export const SHAKEN_STIR = 'shaken-stir'
export const VOICE_INTEGRITY = 'voice-integrity'
export const MULTIDIALER_ADMIN = 'multidialer-admin'
export const MULTIDIALER_SETTINGS = 'multidialer-settings'
export const RECORDED_GREETINGS = 'recorded-greetings'

export const BOT_EMOTIONS = 'bot-emotions'
export const GLOBAL_BOTS = 'global-bots'
export const PROSPECT_BOTS = 'prospect-bots'
export const TEAM_BOTS = 'team-bots'
export const BOT_CALLS = 'bot-calls'
export const BOT_CALL = 'bot-call'
export const BOT_CALL_ANALYSIS = 'bot-call-analysis'
export const BOT_GENERATOR = 'bot-generator'
export const BOT_SKILL_GENERATOR = 'bot-skill-generator'
export const GLOBAL_BOT_SKILLS = 'global-bot-skills'
export const TEAM_BOT_SKILLS = 'team-bot-skills'

export const SNIPPETS = 'snippets'

// relay endpoints
export const RELAY_USER_STATUS = 'relay-user-status'
export const RELAY_SUBSCRIBE = 'relay-subscribe'
export const RELAY_PERMISSION_RESOURCE = 'relay-permission-resource'
export const RELAY_SESSION_STATUS = 'relay-session-status'
export const RELAY_SESSION_INVITATION = 'relay-session-invitation'

export const RT_WORKER_AGENT = 'agent'

// salesfloor endpoints 
export const SALES_FLOOR_ROOMS = 'sales-floor-rooms'
export const SALES_FLOOR_ROOM_MEMBERS = 'sales-floor-room-members'

export enum OAUTHLOGIN_TYPES {
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  MICROSOFT_LOGIN = 'MICROSOFT_LOGIN'
}

export const prospectPartyCode = 0
export const repPartyCode = 1


export const EXT_MANAGER_KEY = Object.freeze({
  MANAGER_TYPE: 'manager_type',
  MANAGER_COLLECTION_GROUP: 'manager_collection_group',
  UUID: 'manager_uuid',
  IN_IFRAME: 'in_iframe',
  IFRAME_URL: 'iframe_url',
  INJECTED: 'injected',
})

export const TRANSIT_MESSAGE_KEYS = Object.freeze({
  TYPE: 'type',
  SOURCE_INFO: 'source_info',
  TARGET_INFO: 'target_info',
  MESSAGE_INFO: 'message_info',
})


// naming convention: source_to_destination_...
// DIALER = src/ext/dialer-content
// INJECTED = src/ext/dialer-injected
// APP = src/ext/app-content
// BACKGROUND = src/ext/background
// EXTERNAL = external page app.trellus.ai
export const MESSAGE_TYPES = Object.freeze({
  EXTERNAL_TO_BACKGROUND_REQUEST_EXTENSION_API_KEY_AND_EXTENSION_INFO: 'EXTERNAL_TO_BACKGROUND_REQUEST_EXTENSION_API_KEY_AND_EXTENSION_INFO',
  // from app content script to(external) app page
  APP_TO_EXTERNAL_CHECK_IS_LOADED: 'APP_TO_EXTERNAL_CHECK_IS_LOADED',
  APP_TO_EXTERNAL_OPEN_LINKEDIN: 'APP_TO_EXTERNAL_OPEN_LINKEDIN',
  APP_TO_EXTERNAL_CLOSE_LINKEDIN: 'APP_TO_EXTERNAL_CLOSE_LINKEDIN',
  APP_TO_EXTERNAL_SET_EXTENSION_INFO: 'APP_TO_EXTERNAL_SET_EXTENSION_INFO',
  APP_TO_EXTERNAL_START_COACHING: 'APP_TO_EXTERNAL_START_COACHING',
  APP_TO_EXTERNAL_END_COACHING: 'APP_TO_EXTERNAL_END_COACHING',
  APP_TO_EXTERNAL_END_CALL: 'APP_TO_EXTERNAL_END_CALL',
  APP_TO_EXTERNAL_CHECK_FOR_TRANSCRIPT: 'APP_TO_EXTERNAL_CHECK_FOR_TRANSCRIPT',
  APP_TO_EXTERNAL_NOTIFY_COACHING: 'APP_TO_EXTERNAL_NOTIFY_COACHING', 
  APP_TO_EXTERNAL_FIELD_UPDATES: 'APP_TO_EXTERNAL_FIELD_UPDATES', 
  APP_TO_EXTERNAL_REQUEST_CALL_INFO: 'APP_TO_EXTERNAL_REQUEST_CALL_INFO',
  APP_TO_EXTERNAL_FLOW_ACTION: 'APP_TO_EXTERNAL_FLOW_ACTION',
  APP_TO_EXTERNAL_CALL_STATUS_ACK: 'APP_TO_EXTERNAL_CALL_STATUS_ACK',
  APP_TO_EXTERNAL_HIGHLIGHT_COACHING_TAB: 'APP_TO_EXTERNAL_HIGHLIGHT_COACHING_TAB',

  LINKEDIN_TO_PARENT_CAN_SHOW: 'LINKEDIN_TO_PARENT_CAN_SHOW',

  // from external app page to app content script
  EXTERNAL_TO_APP_IS_LOADED: 'EXTERNAL_TO_APP_IS_LOADED',
  EXTERNAL_TO_APP_HAS_TRANSCRIPT: 'EXTERNAL_TO_APP_HAS_TRANSCRIPT', 
  EXTERNAL_TO_APP_CLOSE_LINKEDIN: 'EXTERNAL_TO_APP_CLOSE_LINKEDIN',

  EXTERNAL_TO_LITE_MODE_IFRAME_IS_ACTIVE: 'EXTERNAL_TO_LITE_MODE_IFRAME_IS_ACTIVE', 
  EXTERNAL_TO_LITE_MODE_UPDATE_DISPLAY_METRICS: 'EXTERNAL_TO_LITE_MODE_UPDATE_DISPLAY_METRICS',
  EXTERNAL_TO_LITE_MODE_UPDATE_SUGGESTION: 'EXTERNAL_TO_LITE_MODE_UPDATE_SUGGESTION',
  LITE_MODE_TO_EXTERNAL_CAN_RECEIVE: 'LITE_MODE_TO_EXTERNAL_CAN_RECEIVE', 

  // from (external) page to background
  EXTERNAL_TO_BACKGROUND_SET_REALTIME_ENABLED: 'EXTERNAL_TO_BACKGROUND_SET_REALTIME_ENABLED',
  EXTERNAL_TO_BACKGROUND_SET_NEXT_OR_CURRENT_CALL_IS_DISABLED: 'EXTERNAL_TO_BACKGROUND_SET_NEXT_OR_CURRENT_CALL_IS_DISABLED', 
  EXTERNAL_TO_BACKGROUND_SET_API_KEY: 'EXTERNAL_TO_BACKGROUND_SET_API_KEY',
  EXTERNAL_TO_BACKGROUND_TEXT_SUMMARY: 'EXTERNAL_TO_BACKGROUND_TEXT_SUMMARY',  // paste summary into notes box
  EXTERNAL_TO_BACKGROUND_SHOW_SUGGESTION: 'EXTERNAL_TO_BACKGROUND_SHOW_SUGGESTION',
  EXTERNAL_TO_BACKGROUND_CALL_FINALIZATION: 'EXTERNAL_TO_BACKGROUND_CALL_FINALIZATION',
  EXTERNAL_TO_BACKGROUND_SET_IS_AUTHORIZATION_ONLY: 'EXTERNAL_TO_BACKGROUND_SET_IS_AUTHORIZATION_ONLY' ,
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_AUTHORIZATION_ONLY_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_AUTHORIZATION_ONLY_SETTING' ,
  EXTERNAL_TO_BACKGROUND_SET_IS_TRANSCRIPTION_ONLY: 'EXTERNAL_TO_BACKGROUND_SET_IS_TRANSCRIPTION_ONLY',
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_TRANSCRIPTION_ONLY_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_TRANSCRIPTION_ONLY_SETTING' ,
  EXTERNAL_TO_BACKGROUND_REQUEST_POPUP_DIRECTION_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_POPUP_DIRECTION_SETTING',
  EXTERNAL_TO_BACKGROUND_SET_POPUP_DIRECTION_IS_RIGHT: 'EXTERNAL_TO_BACKGROUND_SET_POPUP_DIRECTION_IS_RIGHT',
  EXTERNAL_TO_BACKGROUND_POPOUT_COACHING_WIDGET: 'EXTERNAL_TO_BACKGROUND_POPOUT_COACHING_WIDGET',
  EXTERNAL_TO_BACKGROUND_POPIN_COACHING_WIDGET: 'EXTERNAL_TO_BACKGROUND_POPIN_COACHING_WIDGET', 
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_LITE_MODE_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_LITE_MODE_SETTING',
  EXTERNAL_TO_BACKGROUND_SET_IS_LITE_MODE: 'EXTERNAL_TO_BACKGROUND_SET_IS_LITE_MODE', 
  EXTERNAL_TO_BACKGROUND_SET_IS_AGGRESSIVE_MODE: 'EXTERNAL_TO_BACKGROUND_SET_IS_AGGRESSIVE_MODE',
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_AGGRESSIVE_MODE_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_AGGRESSIVE_MODE_SETTING',
  EXTERNAL_TO_BACKGROUND_SET_IS_FLOW_STATE_SETTING: 'EXTERNAL_TO_BACKGROUND_SET_IS_FLOW_STATE_SETTING',
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_FLOW_STATE_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_FLOW_STATE_SETTING',
  EXTERNAL_TO_BACKGROUND_CALL_STATUS: 'EXTERNAL_TO_BACKGROUND_CALL_STATUS', 
  EXTERNAL_TO_BACKGROUND_UPDATE_EXISTING_LISTS: 'EXTERNAL_TO_BACKGROUND_UPDATE_EXISTING_LISTS', 
  EXTERNAL_TO_BACKGROUND_GET_EXISTING_LISTS: 'EXTERNAL_TO_BACKGROUND_GET_EXISTING_LISTS',
  EXTERNAL_TO_BACKGROUND_SET_IS_EMBEDDED_COACHING_ENABLED: 'EXTERNAL_TO_BACKGROUND_SET_IS_EMBEDDED_COACHING_ENABLED',
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_EMBEDDED_COACHIG_ENABLED: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_EMBEDDED_COACHIG_ENABLED',
  EXTERNAL_TO_APP_GET_URL: 'EXTERNAL_TO_APP_GET_URL',
  EXTERNAL_TO_BACKGROUND_AUTO_PAUSE: 'EXTERNAL_TO_BACKGROUND_AUTO_PAUSE',
  EXTERNAL_TO_BACKGROUND_HIGHLIGHT_TAB: 'EXTERNAL_TO_BACKGROUND_HIGHLIGHT_TAB',
  EXTERNAL_TO_BACKGROUND_CLEAR_CACHE: 'EXTERNAL_TO_BACKGROUND_CLEAR_CACHE',

  EXTERNAL_TO_BACKGROUND_SET_IS_EMBEDDED_COACHING_NO_HIGHLIGHT: 'EXTERNAL_TO_BACKGROUND_SET_IS_EMBEDDED_COACHING_NO_HIGHLIGHT',
  EXTERNAL_TO_BACKGROUND_REQUEST_IS_EMBEDDED_COACHING_NO_HIGHLIGHT: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_EMBEDDED_COACHING_NO_HIGHLIGHT',

  EXTERNAL_TO_BACKGROUND_REQUEST_IS_DATA_SEARCH_ENABLED_SETTING: 'EXTERNAL_TO_BACKGROUND_REQUEST_IS_DATA_SEARCH_ENABLED_SETTING',
  EXTERNAL_TO_BACKGROUND_SET_IS_DATA_SEARCH_ENABLED: 'EXTERNAL_TO_BACKGROUND_SET_IS_DATA_SEARCH_ENABLED',
  GET_LINKEDIN_COOKIES: 'GET_LINKEDIN_COOKIES',
  EMIT_API_CALL: 'EMIT_API_CALL',
  EMIT_FETCH: 'EMIT_FETCH',
  SUBSCRIBE_EVENT_STREAM: 'SUBSCRIBE_EVENT_STREAM',
  HAS_LINKEDIN_ACCESS: 'HAS_LINKEDIN_ACCESS',

  // from (pre call content script) to others
  PRE_CALL_TO_EXTERNAL_SCRAPED_DATA: 'PRE_CALL_TO_EXTERNAL_SCRAPED_DATA',
  PRE_CALL_TO_EXTERNAL_AGENT_REQUIRES_PERMISSION: 'PRE_CALL_TO_EXTERNAL_AGENT_REQUIRES_PERMISSION',
  PRE_CALL_TO_EXTERNAL_AGENT_PERMISSION_GRANTED: 'PRE_CALL_TO_EXTERNAL_AGENT_PERMISSION_GRANTED',
  PRE_CALL_TO_EXTERNAL_AGENT_TYPE_URL_UPDATE: 'PRE_CALL_TO_EXTERNAL_AGENT_TYPE_URL_UPDATE',
  PRE_CALL_TO_EXTERNAL_PAGE_IS_CLOSED: 'PRE_CALL_TO_EXTERNAL_PAGE_IS_CLOSED',
  PRE_CALL_TO_EXTERNAL_SWITCH_TAB: 'PRE_CALL_TO_EXTERNAL_SWITCH_TAB',
  PRE_CALL_TO_EXTERNAL_AGENT_TYPE_STATUS_UPDATE: 'PRE_CALL_TO_EXTERNAL_AGENT_TYPE_STATUS_UPDATE',
  PRE_CALL_TO_EXTERNAL_IS_LOADED: 'PRE_CALL_TO_EXTERNAL_IS_LOADED',
  PRE_CALL_TO_EXTERNAL_UPDATE_NUMBER: 'PRE_CALL_TO_EXTERNAL_UPDATE_NUMBER',
  PRE_CALL_TO_EXTERNAL_UPDATE_NUMBERS: 'PRE_CALL_TO_EXTERNAL_UPDATE_NUMBERS',
  PRE_CALL_TO_EXTERNAL_UPDATE_PROSPECT_IDENTIFIER: 'PRE_CALL_TO_EXTERNAL_UPDATE_PROSPECT_IDENTIFIER',
  PRE_CALL_TO_EXTERNAL_UPDATE_EMAIL_INPUT: 'PRE_CALL_TO_EXTERNAL_UPDATE_EMAIL_INPUT',
  PRE_CALL_TO_EXTERNAL_REQUEST_EMAIL: 'PRE_CALL_TO_EXTERNAL_REQUEST_EMAIL',
  PRE_CALL_TO_EXTERNAL_REFRESH_TRANSCRIPTS: 'PRE_CALL_TO_EXTERNAL_REFRESH_TRANSCRIPTS',
  PRE_CALL_TO_EXTERNAL_GET_CONVERSATION: 'PRE_CALL_TO_EXTERNAL_GET_CONVERSATION',
  
  EXTERNAL_TO_PRE_CALL_IS_LOADED: 'EXTERNAL_TO_PRE_CALL_IS_LOADED',
  EXTERNAL_TO_PRE_CALL_HAS_TRANSCRIPT: 'EXTERNAL_TO_PRE_CALL_HAS_TRANSCRIPT',
  EXTERNAL_TO_PRE_CALL_EXPAND_WINDOW_SIZE: 'EXTERNAL_TO_PRE_CALL_EXPAND_WINDOW_SIZE',
  EXTERNAL_TO_PRE_CALL_MINIMIZE_WINDOW_SIZE: 'EXTERNAL_TO_PRE_CALL_MINIMIZE_WINDOW_SIZE',
  EXTERNAL_TO_PRE_CALL_INITIATE_LINKEDIN_SCRAPE: 'EXTERNAL_TO_PRE_CALL_INITIATE_LINKEDIN_SCRAPE',
  EXTERNAL_TO_PRE_CALL_HAS_DATA_ALREADY: 'EXTERNAL_TO_PRE_CALL_HAS_DATA_ALREADY',
  EXTERNAL_TO_PRE_CALL_GET_DATA_FOR_PROSPECT: 'EXTERNAL_TO_PRE_CALL_GET_DATA_FOR_PROSPECT',

  TRELLUS_ROUTER_PASSING: 'TRELLUS_ROUTER_PASSING',
})

export const EXTENSION_ID = "enhpjjojmnlnaokmppkkifgaonfojigl"
export const CHROME_EXTENSION_LINK = 'https://chrome.google.com/webstore/detail/trellus/enhpjjojmnlnaokmppkkifgaonfojigl'

export const MINIMUM_EXTENSION_VERSION_FOR_NEW_LOGIN_FLOW = [1, 1, 0, 4]
export const MINIMUM_EXTENSION_VERSION_FOR_LITE_MODE = [1, 1, 1, 7]
export const MINIMUM_EXTENSION_VERSION_FOR_AUTHORIZATION_SETTING = [1,1,2,9]
export const MINIMUM_EXTENSION_VERSION_FOR_TRANSCRIPTION_SETTING = [1,1,3,1]
export const MINIMUM_EXTENSION_VERSION_FOR_SELF_SERVE = [1,3,6,7]
export const CHROME_EXTENSION_START_PAGE = 'chrome-extension://'+ EXTENSION_ID + '/start_page.html'
export const CHROME_EXTENSION_ACTIVATE_PAGE = 'chrome-extension://'+ EXTENSION_ID + '/pages/activate_dialers.html'
export const CHROME_EXTENSION_EVERYWHERE_ACTIVATE_PAGE = 'chrome-extension://'+ EXTENSION_ID + '/pages/activate_everywhere.html'
export const SUGGEST_FEATURE_PAGE = 'https://forms.gle/cgAxxKv4QFmJqM9x6'
export const DEMO_VIDEO = 'https://youtu.be/u2Qm9tWwc2A'

export const STARRED_LABEL = '__starred__'
export const STARRED_DISPOSITION = 'starred'
export const TRELLUS_NOTE_SOURCE = 'TRELLUS'


export const FLOW_STATE_ACTIONS = Object.freeze({
  INITIATE_CALL: 'INITIATE_CALL',
  END_CALL: 'END_CALL'
})
