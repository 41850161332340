import { useEffect, useState } from 'react'
import { AlertTriangle, Info, Plus, Check, ChevronRight } from 'lucide-react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { ShakenStirRegistration } from 'components/ShakenStirRegistration';
import { CustomerProfileData, VoiceIntegrityInfo, VoiceIntegrityUseCase } from 'interfaces/twilio';
import { AuthorizationInfo } from 'components/Authentication/utils';
import { convertFromReduxSafeUserState } from 'lib/redux/store';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { UserDataResult, TeamResult } from 'interfaces/services';
import { getServicesManager } from 'services';

export type STIRSHAKENData = {
  name: string;
  type: string;
  industry: string;
  businessRegistrationAuthority: string;
  businessRegistrationNumber: string;
  regionsOfOperation: string;
  websiteURL: string;
  streetAddress: string;
  city: string;
  stateOrRegion: string;
  postalCode: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  jobLevel: string;
}

type NumberRegistrationComponentProps = {
  authorizationInfo: AuthorizationInfo | null
  user: UserDataResult | null
  team: TeamResult | null
  isAdmin: boolean
}

// Initial form state for voice integrity registration
const initialVoiceIntegrityForm = {
  use_case: VoiceIntegrityUseCase.LEAD_GENERATION,
  business_employee_count: "0",
  average_business_day_call_volume: "0",
  notes: ""
}

function NumberRegistrationComponentImpl({ authorizationInfo, user, team, isAdmin }: NumberRegistrationComponentProps) {
  const [showStirShakenForm, setShowStirShakenForm] = useState(false)
  const [showCallerRegistrationForm, setShowCallerRegistrationForm] = useState(false)
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false)
  const [stirShakenData, setStirShakenData] = useState<CustomerProfileData | null>(null)
  const [voiceIntegrityInfo, setVoiceIntegrityInfo] = useState<VoiceIntegrityInfo | null>(null)
  const [voiceIntegrityForm, setVoiceIntegrityForm] = useState(initialVoiceIntegrityForm)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [voiceIntegritySubmitSuccess, setVoiceIntegritySubmitSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    if (!user?.team_id) return  // not `if (user)` because it confuses the exhaustive-deps eslint checker
    
    setIsLoading(true)
    
    // First fetch STIR/SHAKEN data
    getServicesManager().getCustomerProfileData(user.team_id)
      .then((data) => {
        setStirShakenData(data)
        
        // Only fetch voice integrity data if STIR/SHAKEN is approved
        if (data?.profile?.status === 'twilio-approved') {
          return getServicesManager().getVoiceIntegrityStatus(user.team_id)
            .then((voiceData) => {
              setVoiceIntegrityInfo(voiceData)
              if (voiceData?.attributes) {
                // If we have existing data, populate the form
                setVoiceIntegrityForm({
                  use_case: voiceData.attributes.use_case,
                  business_employee_count: voiceData.attributes.business_employee_count,
                  average_business_day_call_volume: voiceData.attributes.average_business_day_call_volume,
                  notes: voiceData.attributes.notes
                })
              }
              return voiceData
            })
        }
        return null
      })
      .catch(error => console.error("Error fetching registration data:", error))
      .finally(() => setIsLoading(false))
      
  }, [user?.team_id])  // useEffect runs if Object.is(old, new) is false which doesn't recurse into Objects
  
  const getStatusColor = (status: string) => {
    if (status === 'pending-review') return 'yellow'
    if (status === 'in-review') return 'blue'
    if (status === 'draft') return 'gray'
    if (status === 'twilio-rejected') return 'red'
    return 'green'
  }

  const getStatusText = (status: string) => {
    if (status === 'pending-review') return 'Pending Review'
    if (status === 'in-review') return 'In Review'
    if (status === 'draft') return 'Draft'
    if (status === 'twilio-rejected') return 'Rejected'
    return 'Approved'
  }

  const renderStirShakenStatus = (status: string) => {
    const color = getStatusColor(status)
    return <span className={`text-${color}-500`}>{getStatusText(status)}</span>
  }

  const isStirShakenApproved = stirShakenData?.profile?.status === 'twilio-approved'
  // Check both sources to determine if Step 2 is completed
  const isCallerRegistrationCompleted = (stirShakenData?.voice_integrity_info !== null && stirShakenData?.voice_integrity_info !== undefined) || 
                                       (voiceIntegrityInfo !== null && voiceIntegrityInfo !== undefined)

  // Handle form input changes
  const handleVoiceIntegrityFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setVoiceIntegrityForm(prev => ({
      ...prev,
      [name]: value
    }))
  }

  // Handle form submission
  const handleVoiceIntegritySubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!user?.team_id) return

    setIsSubmitting(true)
    try {
      const result = await getServicesManager().updateVoiceIntegrityStatus(
        user.team_id,
        voiceIntegrityForm.use_case as VoiceIntegrityUseCase,
        parseInt(voiceIntegrityForm.business_employee_count),
        parseInt(voiceIntegrityForm.average_business_day_call_volume),
        voiceIntegrityForm.notes
      )

      if (result) {
        setVoiceIntegritySubmitSuccess(true)
        
        // Poll for updated status after 2 seconds
        setTimeout(async () => {
          if (user?.team_id) {
            // Refresh the data after submission
            const updatedData = await getServicesManager().getCustomerProfileData(user.team_id)
            setStirShakenData(updatedData)
            const updatedVoiceIntegrity = await getServicesManager().getVoiceIntegrityStatus(user.team_id)
            setVoiceIntegrityInfo(updatedVoiceIntegrity)
          }
          
          // Close the form after successful submission
          setTimeout(() => {
            setShowCallerRegistrationForm(false)
          }, 1500)
        }, 2000)
      }
    } catch (error) {
      console.error("Error submitting voice integrity data:", error)
    } finally {
      setIsSubmitting(false)
    }
  }

//Error code 18601 - The association between business name and website cannot be verified
// Error code 18602 - The Business ID you provided could not be verified
// Error code 18603 - The address could not be verified
// Error code 18604 - Unable to verify Authorized representative #1
// Error code 18605 - Unable to verify Authorized representative #2.
// Error code 18606 - The Email domain doesn't match the website domain
// Error code 18607 - Customer Profile or Trust Product is not eligible to be copied
// Error code 18608 - Customer Profile or Trust Product is already copied
// Error code 18609 - Ineligible Business Registration Authority
  const errorCodeToMessage = (errorCode: string) => {
    switch (errorCode) {
      case '18601': return 'The association between business name and website cannot be verified'
      case '18602': return 'The Business ID you provided could not be verified'
      case '18603': return 'The address could not be verified'
      case '18604': return 'Unable to verify Authorized representative #1'
      case '18605': return 'Unable to verify Authorized representative #2'
      case '18606': return 'The Email domain doesn\'t match the website domain'
      case '18607': return 'Customer Profile or Trust Product is not eligible to be copied'
      case '18608': return 'Customer Profile or Trust Product is already copied'
      case '18609': return 'Ineligible Business Registration Authority'
      default: return 'Your STIR/SHAKEN information could not be verified - please resubmit'
    }
  }

  return (
    <div className="bg-white shadow-md border border-gray-200 rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-6 text-gray-800 flex items-center justify-between">
        <span className="flex items-center">
          Number Registration
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <button className="ml-2 text-gray-400 hover:text-gray-600 transition-colors">
                  <Info size={16} />
                </button>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="bg-gray-800 text-white p-2.5 rounded-md text-sm max-w-xs shadow-lg">
                  Complete these steps to register your phone numbers and improve call delivery rates.
                  <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </span>
      </h2>

      <div className="space-y-8">
        {/* Step 1: STIR/SHAKEN Registration */}
        <div className="relative">
          {/* Progress line */}
          {isStirShakenApproved && (
            <div className="absolute left-[23px] top-12 h-full w-1 bg-green-500 rounded-full z-0" style={{ height: 'calc(100% - 12px)' }}></div>
          )}
          
          <div className="flex items-start relative z-10">
            {/* Step indicator */}
            <div className={`flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center mr-4 shadow-sm transition-all ${isStirShakenApproved ? 'bg-green-100 text-green-600 border-2 border-green-500' : 'bg-blue-100 text-blue-600 border-2 border-blue-500'}`}>
              {isStirShakenApproved ? <Check size={18} /> : <span className="font-semibold text-lg">1</span>}
            </div>
            
            {/* Step content */}
            <div className="flex-grow">
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-6">
                <div className="flex-grow">
                  <h3 className="text-base font-medium mb-2 flex items-center text-gray-800">
                    STIR/SHAKEN Registration
                    {!stirShakenData && !isLoading && <AlertTriangle className="ml-2 text-yellow-500" size={16} />}
                  </h3>
                  
                  {isLoading ? (
                    <div className="flex items-center space-x-2 py-4">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-600"></div>
                      <span className="text-sm text-gray-600">Loading registration data...</span>
                    </div>
                  ) : !submittedSuccessfully && stirShakenData ? (
                    <div className="flex flex-col gap-3">
                      <p className="text-sm text-gray-700">
                        Status: <span className={`font-medium ${stirShakenData.profile.status === 'twilio-approved' ? 'text-green-600' : stirShakenData.profile.status === 'twilio-rejected' ? 'text-red-600' : 'text-blue-600'}`}>
                          {getStatusText(stirShakenData.profile.status)}
                        </span>
                      </p>
                      
                      {isAdmin && stirShakenData.profile.status === 'twilio-rejected' && (
                        <button
                          onClick={() => setShowStirShakenForm(true)}
                          className="bg-blue-600 text-white px-4 py-2 text-sm rounded-md hover:bg-blue-700 transition duration-200 flex items-center w-fit shadow-sm"
                        >
                          <Plus size={16} className="mr-1.5" />
                          Resubmit STIR/SHAKEN Info
                        </button>
                      )}
                    </div>
                  ) : submittedSuccessfully ? (
                    <div className="bg-green-50 border border-green-200 rounded-md p-3">
                      <p className="text-sm text-green-700 font-medium">STIR/SHAKEN information submitted successfully</p>
                      <p className="text-xs text-green-600 mt-1">Check back later to see the status</p>
                    </div>
                  ) : (
                    <div>
                      <p className="mb-3 text-sm text-gray-600 leading-relaxed">You haven't submitted STIR/SHAKEN information yet. This is important for call authentication and preventing your calls from being marked as spam. {!isAdmin ? 'You can request an admin to add STIR/SHAKEN information for you.' : ''}</p>
                      {isAdmin && (
                        <button
                          onClick={() => setShowStirShakenForm(true)}
                          className="bg-blue-600 text-white px-4 py-2 text-sm rounded-md hover:bg-blue-700 transition duration-200 flex items-center w-fit shadow-sm"
                        >
                          <Plus size={16} className="mr-1.5" />
                          Add STIR/SHAKEN Info
                        </button>
                      )}
                    </div>
                  )}
                </div>
                
                {/* Verification Issues - Now on the right side with improved styling */}
                {!submittedSuccessfully && stirShakenData && stirShakenData.profile.status === 'twilio-rejected' && stirShakenData.profile.errors && stirShakenData.profile.errors.length > 0 && (
                  <div className="p-4 bg-red-50 border border-red-200 rounded-lg md:max-w-xs w-full shadow-sm">
                    <h3 className="text-red-700 text-sm font-medium mb-2 flex items-center">
                      <AlertTriangle size={16} className="mr-2" />
                      Verification Issues
                    </h3>
                    <div className="space-y-2">
                      {stirShakenData.profile.errors.map((error, index) => (
                        <p key={`error-${index}`} className="text-red-600 text-xs flex items-start bg-red-100 p-2 rounded">
                          <span className="mr-1.5 mt-0.5 text-red-500">•</span>
                          {errorCodeToMessage(error.code.toString())}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Step 2: Free Caller Registration */}
        <div className="relative">
          <div className="flex items-start relative z-10">
            {/* Step indicator */}
            <div className={`flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center mr-4 shadow-sm transition-all ${!isStirShakenApproved ? 'bg-gray-100 text-gray-400 border-2 border-gray-300' : isCallerRegistrationCompleted ? 'bg-green-100 text-green-600 border-2 border-green-500' : 'bg-blue-100 text-blue-600 border-2 border-blue-500'}`}>
              {isCallerRegistrationCompleted ? <Check size={18} /> : <span className="font-semibold text-lg">2</span>}
            </div>
            
            {/* Step content */}
            <div className="flex-grow">
              <h3 className={`text-base font-medium mb-2 ${!isStirShakenApproved ? 'text-gray-400' : 'text-gray-800'}`}>
                Free Caller Registration
              </h3>
              
              {isLoading ? (
                <div className="flex items-center space-x-2 py-4">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-600"></div>
                  <span className="text-sm text-gray-600">Loading registration data...</span>
                </div>
              ) : isStirShakenApproved ? (
                isCallerRegistrationCompleted ? (
                  <div className="bg-green-50 border border-green-200 rounded-md p-3">
                    <p className="text-sm text-green-700 font-medium">Caller information registered successfully</p>
                    {voiceIntegrityInfo && (
                      <div className="mt-2">
                        <div className="flex flex-wrap gap-x-6 text-sm">
                          <p>Use Case: <span className="text-green-600">{voiceIntegrityInfo.attributes.use_case}</span></p>
                          <p>Employee Count: <span className="text-green-600">{voiceIntegrityInfo.attributes.business_employee_count}</span></p>
                          <p>Daily Call Volume: <span className="text-green-600">{voiceIntegrityInfo.attributes.average_business_day_call_volume}</span></p>
                        </div>
                      </div>
                    )}
                    {isAdmin && (
                      <button
                        onClick={() => setShowCallerRegistrationForm(true)}
                        className="mt-3 bg-blue-600 text-white px-3 py-1.5 text-xs rounded-md hover:bg-blue-700 transition duration-200 flex items-center w-fit shadow-sm"
                      >
                        Update Registration
                      </button>
                    )}
                  </div>
                ) : (
                  <div>
                    <p className="mb-3 text-sm text-gray-600 leading-relaxed">Register your business information with carriers to improve call delivery rates.</p>
                    <button
                      onClick={() => setShowCallerRegistrationForm(true)}
                      className="bg-blue-600 text-white px-4 py-2 text-sm rounded-md hover:bg-blue-700 transition duration-200 flex items-center w-fit shadow-sm"
                    >
                      <Plus size={16} className="mr-1.5" />
                      Register Caller Information
                    </button>
                  </div>
                )
              ) : (
                <div className="bg-gray-50 border border-gray-200 rounded-md p-3">
                  <p className="text-sm text-gray-500">Complete STIR/SHAKEN registration first to unlock this step.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showStirShakenForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
              <ShakenStirRegistration
                  apiKey={authorizationInfo?.apiKey ?? ''}
                  teamId={user?.team_id ?? ''}
                  teamName={(team?.team === 'NO_TEAM_ASSIGNED' ? user?.user_name : team?.team) ?? user?.user_name ?? ''}
                  onCancel={() => setShowStirShakenForm(false)}
                  onSuccess={() => {
                    setShowStirShakenForm(false)
                    setSubmittedSuccessfully(true)
                    // Refresh the data after submission
                    if (user?.team_id) {
                      getServicesManager().getCustomerProfileData(user.team_id).then((data) => {
                        setStirShakenData(data)
                      })
                    }
                  }}
                />
          </div>
        </div>
      )}

      {showCallerRegistrationForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Free Caller Registration</h2>
              <button 
                onClick={() => setShowCallerRegistrationForm(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            
            {voiceIntegritySubmitSuccess ? (
              <div className="bg-green-50 border border-green-200 rounded-md p-4 mb-4">
                <p className="text-green-700 font-medium">Registration successful!</p>
                <p className="text-green-600 text-sm mt-1">Your caller information has been registered.</p>
              </div>
            ) : (
              <form onSubmit={handleVoiceIntegritySubmit} className="space-y-6">
                <div>
                  <label htmlFor="use_case" className="block text-sm font-medium text-gray-700 mb-1">
                    Use Case
                  </label>
                  <select
                    id="use_case"
                    name="use_case"
                    value={voiceIntegrityForm.use_case}
                    onChange={handleVoiceIntegrityFormChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                  >
                    {Object.values(VoiceIntegrityUseCase).map((useCase) => (
                      <option key={useCase} value={useCase}>
                        {useCase}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label htmlFor="business_employee_count" className="block text-sm font-medium text-gray-700 mb-1">
                    Business Employee Count
                  </label>
                  <input
                    type="number"
                    id="business_employee_count"
                    name="business_employee_count"
                    value={voiceIntegrityForm.business_employee_count}
                    onChange={handleVoiceIntegrityFormChange}
                    min="0"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="average_business_day_call_volume" className="block text-sm font-medium text-gray-700 mb-1">
                    Average Business Day Call Volume
                  </label>
                  <input
                    type="number"
                    id="average_business_day_call_volume"
                    name="average_business_day_call_volume"
                    value={voiceIntegrityForm.average_business_day_call_volume}
                    onChange={handleVoiceIntegrityFormChange}
                    min="0"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
                    Additional Notes
                  </label>
                  <textarea
                    id="notes"
                    name="notes"
                    value={voiceIntegrityForm.notes}
                    onChange={handleVoiceIntegrityFormChange}
                    rows={4}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setShowCallerRegistrationForm(false)}
                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200 flex items-center justify-center min-w-[150px] ${isSubmitting ? 'opacity-90 cursor-not-allowed' : ''}`}
                  >
                    {isSubmitting ? (
                      <div className="flex items-center justify-center">
                        <div className="w-5 h-5 mr-2">
                          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                        </div>
                        <span>Submitting...</span>
                      </div>
                    ) : (
                      'Submit Registration'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const ReduxWrapped = connect((state: RootState) => {
  return {
      user: convertFromReduxSafeUserState(state.user),
      team: state.team.value,
      authorizationInfo: state.authenticationInfo.value,
      isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : false,
  }
})(NumberRegistrationComponentImpl)

export { ReduxWrapped as NumberRegistrationComponent }
// For backward compatibility
export { ReduxWrapped as STIRSHAKENComponent } 