import React from 'react'
import { Typography } from "interfaces/typography"
import { GoToHeader } from './GoToHeader'
import { CallDifficultyMode, CALL_DIFFICULTY_TO_HUMAN_READABLE, CallLanguage, Bot, BotCall } from 'interfaces/db'
import { EMAIL_INPUT_DATA } from './interfaces'
import { VAPIClient, constructAsssistantOption } from '../SynthProspect/api/vapi'
import { getServicesManager } from '../../services'
import { VAPI_CLIENT_KEY } from '../../cfg/vapi'
import { PaymentSubscriptionOption, PaymentSubscriptionOptionToPaymentLink, SubscriptionType } from '../../interfaces/services'
import { connect } from 'react-redux'
import { RootState } from '../../store'
import { UserDataResult, TeamResult } from '../../interfaces/services'
import { convertFromReduxSafeUserState } from '../../lib/redux/store'
import { TRELLUS_TEAM_ID } from '../../cfg/const'
import { ConnectedProps } from 'react-redux'

type PracticeBotViewProps = {
    onBack: () => void
    isConstructing?: boolean
    selectedDifficulty: CallDifficultyMode
    onDifficultyChange: (difficulty: CallDifficultyMode) => void
    selectedLanguage: CallLanguage
    onLanguageChange: (language: CallLanguage) => void
    isSearching: boolean
    prospectData: EMAIL_INPUT_DATA
    bot: Bot | null
}

type PracticeBotViewState = {
    isCallActive: boolean
    constructionTimeElapsed: number
    transcripts: {role: string, transcript: string}[]
    isInitializingCall: boolean
    activeBotCall: BotCall | null
    showUpgradeModal: boolean
    audioInputs: MediaDeviceInfo[]
    audioOutputs: MediaDeviceInfo[]
    selectedInputId: string
    selectedOutputId: string
    vapiClient: VAPIClient | null
    lastCallStartTime: number | null
    showAudioMenu: boolean
}

// Add a mapping for human-readable language names
const LANGUAGE_TO_HUMAN_READABLE: Record<CallLanguage, string> = {
    [CallLanguage.ENGLISH]: 'English',
    [CallLanguage.SPANISH]: 'Spanish',
    [CallLanguage.FRENCH]: 'French',
    [CallLanguage.DUTCH]: 'Dutch',
    [CallLanguage.GERMAN]: 'German'
}

const mapStateToProps = (state: RootState) => ({
    user: convertFromReduxSafeUserState(state.user),
    team: state.team.value,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class PracticeBotViewImpl extends React.Component<PracticeBotViewProps & PropsFromRedux, PracticeBotViewState> {
    private timerInterval: NodeJS.Timeout | null = null;
    private audioContext: AudioContext | null = null;
    private audioAnalyser: AnalyserNode | null = null;
    private mediaStream: MediaStream | null = null;
    private animationFrame: number | null = null;
    private messagesEndRef = React.createRef<HTMLDivElement>();
    constructor(props: PracticeBotViewProps & PropsFromRedux) {
        super(props)
        this.state = {
            isCallActive: false,
            constructionTimeElapsed: 0,
            transcripts: [],
            isInitializingCall: false,
            activeBotCall: null,
            showUpgradeModal: false,
            audioInputs: [],
            audioOutputs: [],
            selectedInputId: '',
            selectedOutputId: '',
            vapiClient: null,
            lastCallStartTime: null,
            showAudioMenu: false
        }

        // Bind methods
        this.handleStartCall = this.handleStartCall.bind(this);
        this.handleEndCall = this.handleEndCall.bind(this);
        this.initializeVapiClient = this.initializeVapiClient.bind(this);
        this.loadAudioDevices = this.loadAudioDevices.bind(this);
        this.handleSelectAudioInput = this.handleSelectAudioInput.bind(this);
        this.handleSelectAudioOutput = this.handleSelectAudioOutput.bind(this);
    }

    private async loadAudioDevices() {
        try {
            // First request microphone permission
            await navigator.mediaDevices.getUserMedia({ audio: true })
            
            // Then enumerate devices
            const devices = await navigator.mediaDevices.enumerateDevices()
            const inputs = devices.filter(d => d.kind === 'audioinput')
            const outputs = devices.filter(d => d.kind === 'audiooutput')
            
            this.setState({ audioInputs: inputs, audioOutputs: outputs })

            // Set default devices if not already set
            if (!this.state.selectedInputId && inputs.length > 0) {
                const defaultInput = inputs.find(d => d.deviceId === 'default') || inputs[0]
                this.setState({ selectedInputId: defaultInput.deviceId })
            }

            if (!this.state.selectedOutputId && outputs.length > 0) {
                const defaultOutput = outputs.find(d => d.deviceId === 'default') || outputs[0]
                this.setState({ selectedOutputId: defaultOutput.deviceId })
            }
        } catch (error) {
            console.error('Error loading audio devices:', error)
        }
    }

    componentDidMount() {
        // Initialize VAPI client and load audio devices immediately
        this.initializeVapiClient().catch(error => {
            console.error('Failed to initialize VAPI client:', error);
        });
        this.loadAudioDevices();

        // Add device change listener
        navigator.mediaDevices.addEventListener('devicechange', this.loadAudioDevices)

        if (this.props.isConstructing) {
            this.startConstructionTimer();
        }

        // Add click outside handler to close audio menu
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps: Readonly<PracticeBotViewProps & PropsFromRedux>, prevState: Readonly<PracticeBotViewState>) {
        if (prevProps.isConstructing !== this.props.isConstructing) {
            if (this.props.isConstructing) {
                this.startConstructionTimer();
            } else {
                this.stopConstructionTimer();
            }
        }

        // Start visualization when call becomes active
        if (!prevState.isCallActive && this.state.isCallActive) {
            this.startAudioVisualization();
        }
        // Stop visualization when call ends
        if (prevState.isCallActive && !this.state.isCallActive) {
            this.stopAudioVisualization();
        }
    }

    componentWillUnmount() {
        this.stopConstructionTimer();
        this.cleanupCall();
        this.stopAudioVisualization();
        // Remove device change listener
        navigator.mediaDevices.removeEventListener('devicechange', this.loadAudioDevices)
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private async initializeVapiClient() {
        try {
            if (this.state.vapiClient) {
                // Clean up existing client if it exists
                await this.state.vapiClient.endCall().catch(console.error);
            }

            console.log('Initializing VAPI client...');
            const client = new VAPIClient(VAPI_CLIENT_KEY);

            if (!client) {
                throw new Error('Failed to create VAPI client');
            }

            // Add audio debugging
            client.setEventHandlers({
                onMessage: (response) => {
                    console.log('VAPI message received:', response);
                    // Log audio-related events
                    if (response.transcript && response.role && response.transcriptType === 'final') {
                        this.setState(prevState => {
                            // If we have previous transcripts and the last one has the same role
                            if (prevState.transcripts.length > 0 && 
                                prevState.transcripts[prevState.transcripts.length - 1].role === response.role) {
                                // Create new array with all but last entry
                                const newTranscripts = prevState.transcripts.slice(0, -1);
                                // Add combined entry
                                return {
                                    transcripts: [...newTranscripts, {
                                        role: response.role as string,
                                        transcript: prevState.transcripts[prevState.transcripts.length - 1].transcript + ' ' + response.transcript
                                    }]
                                };
                            }
                            // Otherwise add as new entry
                            return {
                                transcripts: [...prevState.transcripts, {
                                    role: response.role as string,
                                    transcript: response.transcript as string
                                }]
                            };
                        }, () => {
                            // Callback after state update to ensure scrolling happens
                            this.scrollToBottom();
                        });
                    }
                },
                onError: (error) => {
                    console.error('VAPI error:', error)
                    this.handleEndCall()
                }
            });

            // Set initial audio output device if available
            if (this.state.selectedOutputId) {
                console.log('Setting initial audio output device:', this.state.selectedOutputId);
                await client.setOutputDeviceAsync(this.state.selectedOutputId);
            }

            this.setState({ vapiClient: client });
            console.log('VAPI client initialized successfully');
        } catch (error) {
            console.error('Error initializing VAPI client:', error);
            throw error;
        }
    }

    private async handleStartCall() {
        console.log('Starting call...', { 
            vapiClient: this.state.vapiClient, 
            bot: this.props.bot,
            selectedOutputId: this.state.selectedOutputId,
            selectedInputId: this.state.selectedInputId
        });
        if (!this.state.vapiClient || !this.props.bot) {
            console.error('Cannot start call: VAPI client or bot not available');
            // Try to reinitialize VAPI client if it's not available
            if (!this.state.vapiClient) {
                try {
                    await this.initializeVapiClient();
                } catch (error) {
                    console.error('Failed to reinitialize VAPI client:', error);
                    return;
                }
            }
            return;
        }

        // Prevent duplicate calls within 2 seconds
        const now = Date.now();
        if (this.state.lastCallStartTime && now - this.state.lastCallStartTime < 2000) {
            console.log('Preventing duplicate call');
            return;
        }
        this.setState({ lastCallStartTime: now });

        this.setState({ isInitializingCall: true, transcripts: [] });

        try {
            console.log('Initiating synth session...');
            const call = await getServicesManager().initiateSynthSession(
                this.props.bot.bot_id,
                this.props.selectedDifficulty
            );

            if (typeof call === 'string') {
                console.error('Access error:', call);
                this.setState({ 
                    isInitializingCall: false,
                    showUpgradeModal: true
                });
                return;
            }

            if (!call) {
                console.error('Failed to start call: No call object returned');
                this.setState({ isInitializingCall: false });
                return;
            }

            console.log('Starting VAPI call...', call);
            await this.state.vapiClient.startCall(
                constructAsssistantOption(
                    this.props.bot,
                    [], // No emotions for practice bot
                    call.bot_call_id,
                    this.props.selectedDifficulty,
                    this.props.selectedLanguage,
                    this.props.user?.autodialer_paid || this.props.team?.subscription_type === SubscriptionType.TEAM
                )
            );

            console.log('Call started successfully');
            this.setState({
                isCallActive: true,
                activeBotCall: call,
                isInitializingCall: false
            });
        } catch (error) {
            console.error('Error starting call:', error);
            this.setState({ isInitializingCall: false });
        }
    }

    private async handleEndCall() {
        try {
            if (this.state.vapiClient) {
                await this.state.vapiClient.endCall();
            }
        } catch (error) {
            console.error('Error ending call:', error);
        } finally {
            this.setState({
                isCallActive: false,
                activeBotCall: null
            });
        }
    }

    private async cleanupCall() {
        try {
            if (this.state.vapiClient) {
                await this.state.vapiClient.endCall();
                this.setState({ vapiClient: null });
            }
        } catch (error) {
            console.error('Error cleaning up call:', error);
        }
    }

    private startConstructionTimer() {
        this.setState({ constructionTimeElapsed: 0 });
        this.timerInterval = setInterval(() => {
            this.setState(prev => ({
                constructionTimeElapsed: prev.constructionTimeElapsed + 1
            }));
        }, 1000);
    }

    private stopConstructionTimer() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }

    private async handleSelectAudioInput(deviceId: string) {
        if (!this.state.vapiClient) return;
        try {
            await this.state.vapiClient.setInputDevicesAsync({ audioDeviceId: deviceId });
            this.setState({ selectedInputId: deviceId });
        } catch (error) {
            console.error('Error setting input device:', error);
            alert('Failed to set audio input device');
        }
    }

    private async handleSelectAudioOutput(deviceId: string) {
        if (!this.state.vapiClient) return;
        try {
            console.log('Setting audio output device:', deviceId);
            await this.state.vapiClient.setOutputDeviceAsync(deviceId);
            console.log('Successfully set audio output device');
            this.setState({ selectedOutputId: deviceId });
        } catch (error) {
            console.error('Error setting output device:', error);
            alert('Failed to set audio output device');
        }
    }

    private async playTestSound() {
        try {
            const audioContext = new AudioContext();
            const oscillator = audioContext.createOscillator();
            const gainNode = audioContext.createGain();
            
            oscillator.connect(gainNode);
            gainNode.connect(audioContext.destination);
            
            gainNode.gain.value = 0.1; // Very quiet
            oscillator.frequency.value = 440; // A4 note
            
            oscillator.start();
            setTimeout(() => {
                oscillator.stop();
                audioContext.close();
            }, 200);
        } catch (error) {
            console.error('Error playing test sound:', error);
        }
    }

    private renderUpgradeModal() {
        if (!this.state.showUpgradeModal) return null;
        const hasAlreadyPaid = this.props.user?.autodialer_paid || this.props.team?.subscription_type === SubscriptionType.TEAM

        return (
            <div 
                className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
                onClick={() => this.setState({ showUpgradeModal: false })}
            >
                <div 
                    className="bg-white rounded-lg p-6 max-w-md mx-4 shadow-xl"
                    onClick={e => e.stopPropagation()}
                >
                    <div className="flex justify-between items-start mb-4">
                        <h3 className="text-xl font-semibold text-gray-900">
                            {hasAlreadyPaid ? 'Additional Access Required' : 'Upgrade Required'}
                        </h3>
                        <button 
                            onClick={() => this.setState({ showUpgradeModal: false })}
                            className="text-gray-400 hover:text-gray-500 transition-colors"
                        >
                            ✕
                        </button>
                    </div>
                    <div className="mb-6">
                        <p className="text-gray-600 mb-4">
                            {hasAlreadyPaid 
                                ? 'Your current package has limited access to Practice Mode. Schedule a call with us to learn more about upgrading your package and unlocking AI-powered sales role-play capabilities.'
                                : "You've reached your practice call limit. Upgrade your subscription to continue practicing with AI-powered sales role-play."
                            }
                        </p>
                        {!hasAlreadyPaid && (
                            <div className="bg-blue-50 rounded-lg p-4">
                                <h4 className="text-blue-800 font-medium mb-2">Benefits of upgrading:</h4>
                                <ul className="text-blue-700 text-sm space-y-2">
                                    <li>• Additional practice calls</li>
                                    <li>• Unlimited power dial access</li>
                                    <li>• Performance analytics</li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => this.setState({ showUpgradeModal: false })}
                            className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                        >
                            {hasAlreadyPaid ? 'Close' : 'Maybe Later'}
                        </button>
                        {hasAlreadyPaid ? (
                            <a
                                href="https://calendly.com/ajinkya_nene/general-meeting"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                                onClick={() => this.setState({ showUpgradeModal: false })}
                            >
                                Schedule a Call
                            </a>
                        ) : (
                            <a
                                href={PaymentSubscriptionOptionToPaymentLink[PaymentSubscriptionOption.PARALLEL]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                                onClick={() => this.setState({ showUpgradeModal: false })}
                            >
                                Upgrade Now
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private stopAudioVisualization() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
            this.animationFrame = null;
        }
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach(track => track.stop());
            this.mediaStream = null;
        }
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }
    }

    private async startAudioVisualization() {
        try {
            this.stopAudioVisualization();

            this.mediaStream = await navigator.mediaDevices.getUserMedia({ 
                audio: { deviceId: this.state.selectedInputId }
            });
            
            this.audioContext = new AudioContext();
            const source = this.audioContext.createMediaStreamSource(this.mediaStream);
            this.audioAnalyser = this.audioContext.createAnalyser();
            
            this.audioAnalyser.fftSize = 256;
            source.connect(this.audioAnalyser);
            
            const canvas = document.getElementById('audioVisualizer') as HTMLCanvasElement;
            if (!canvas) return;
            
            const canvasCtx = canvas.getContext('2d');
            if (!canvasCtx) return;

            const drawVisualization = () => {
                if (!this.audioAnalyser || !canvasCtx) return;

                this.animationFrame = requestAnimationFrame(drawVisualization);
                
                const bufferLength = this.audioAnalyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                this.audioAnalyser.getByteTimeDomainData(dataArray);
                
                canvasCtx.fillStyle = 'rgb(249, 250, 251)';
                canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
                
                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = 'rgb(59, 130, 246)';
                canvasCtx.beginPath();
                
                const sliceWidth = canvas.width / bufferLength;
                let x = 0;
                
                for (let i = 0; i < bufferLength; i++) {
                    const v = dataArray[i] / 128.0;
                    const y = v * canvas.height / 2;
                    
                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    } else {
                        canvasCtx.lineTo(x, y);
                    }
                    
                    x += sliceWidth;
                }
                
                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                canvasCtx.stroke();
            };
            
            drawVisualization();
        } catch (error) {
            console.error('Error starting audio visualization:', error);
        }
    }

    private audioMenuRef = React.createRef<HTMLDivElement>();

    private handleClickOutside = (event: MouseEvent) => {
        if (this.audioMenuRef.current && !this.audioMenuRef.current.contains(event.target as Node)) {
            this.setState({ showAudioMenu: false });
        }
    }

    private scrollToBottom() {
        if (this.messagesEndRef.current) { this.messagesEndRef.current?.scrollIntoView({ 'behavior': "smooth" }) }
    }

    renderCallControls() {
        return (
            <div className="bg-white border-t border-gray-200 p-3">
                <div className="max-w-2xl mx-auto flex flex-col items-center">
                    {/* Audio visualizer - only shown during active call */}
                    {this.state.isCallActive && (
                        <div className="w-full mb-3">
                            <canvas 
                                id="audioVisualizer" 
                                className="w-full h-12 rounded-lg bg-gray-50/50 border border-gray-200/50"
                                width={600}
                                height={48}
                            />
                        </div>
                    )}

                    {/* Controls container */}
                    <div className="w-full flex flex-col items-center gap-2">
                        {/* Selectors row */}
                        <div className="w-full flex flex-wrap items-center justify-center gap-2">
                            <select
                                value={this.props.selectedDifficulty}
                                onChange={(e) => !this.state.isCallActive && this.props.onDifficultyChange(e.target.value as CallDifficultyMode)}
                                disabled={this.state.isCallActive || !this.props.bot}
                                className="min-w-[120px] px-3 py-1.5 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-700 cursor-pointer disabled:opacity-50"
                            >
                                {Object.entries(CALL_DIFFICULTY_TO_HUMAN_READABLE).map(([key, label]) => (
                                    <option key={key} value={key}>{label}</option>
                                ))}
                            </select>

                            <select
                                value={this.props.selectedLanguage}
                                onChange={(e) => !this.state.isCallActive && this.props.onLanguageChange(e.target.value as CallLanguage)}
                                disabled={this.state.isCallActive || !this.props.bot}
                                className="min-w-[120px] px-3 py-1.5 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-700 cursor-pointer disabled:opacity-50"
                            >
                                {Object.entries(LANGUAGE_TO_HUMAN_READABLE).map(([key, label]) => (
                                    <option key={key} value={key}>{label}</option>
                                ))}
                            </select>

                            {/* Audio Settings Button and Menu */}
                            <div className="relative" ref={this.audioMenuRef}>
                                <button
                                    onClick={() => this.setState(prev => ({ showAudioMenu: !prev.showAudioMenu }))}
                                    className={`
                                        px-3 py-1.5 rounded-md border border-gray-200 bg-white h-[34px] flex items-center
                                        ${(this.state.isCallActive || !this.props.bot) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}
                                    `}
                                    disabled={this.state.isCallActive || !this.props.bot}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>

                                {/* Audio Settings Dropdown */}
                                {this.state.showAudioMenu && (
                                    <div className="absolute right-0 bottom-[calc(100%+0.5rem)] w-64 bg-white rounded-lg shadow-lg border border-gray-200 p-3 z-50">
                                        <div className="space-y-3">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Microphone
                                                </label>
                                                <select
                                                    value={this.state.selectedInputId}
                                                    onChange={(e) => this.handleSelectAudioInput(e.target.value)}
                                                    disabled={this.state.isCallActive}
                                                    className="w-full px-2 py-1.5 text-sm rounded-md border border-gray-200 bg-white text-gray-700 cursor-pointer disabled:opacity-50"
                                                >
                                                    {this.state.audioInputs.map(device => (
                                                        <option key={device.deviceId} value={device.deviceId}>
                                                            {device.label?.split('(')[0] || 'Microphone'}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Speaker
                                                </label>
                                                <select
                                                    value={this.state.selectedOutputId}
                                                    onChange={(e) => this.handleSelectAudioOutput(e.target.value)}
                                                    className="w-full px-2 py-1.5 text-sm rounded-md border border-gray-200 bg-white text-gray-700 cursor-pointer"
                                                >
                                                    {this.state.audioOutputs.map(device => (
                                                        <option key={device.deviceId} value={device.deviceId}>
                                                            {device.label?.split('(')[0] || 'Speaker'}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Start/End Practice button */}
                        <div className="w-full flex justify-center">
                            <button
                                onClick={() => this.state.isCallActive ? this.handleEndCall() : this.handleStartCall()}
                                disabled={this.state.isInitializingCall || !this.props.bot || this.props.isConstructing}
                                className={`
                                    w-full max-w-[360px] px-4 py-2 rounded-lg flex items-center justify-center gap-2 
                                    transition-colors text-sm font-medium
                                    ${this.state.isCallActive 
                                        ? 'bg-red-500 hover:bg-red-600 text-white' 
                                        : !this.props.bot || this.props.isConstructing || this.state.isInitializingCall
                                            ? 'bg-gray-400 cursor-not-allowed text-white'
                                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                                    }
                                `}
                            >
                                {this.state.isInitializingCall ? (
                                    <>
                                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                        Initializing...
                                    </>
                                ) : this.state.isCallActive ? (
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        End Practice
                                    </>
                                ) : this.props.isConstructing ? (
                                    <>
                                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                        Constructing Bot...
                                    </>
                                ) : !this.props.bot ? (
                                    'No Bot Available'
                                ) : (
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M8 5v14l11-7z" />
                                        </svg>
                                        Start Practice
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderTranscriptArea() {
        if (this.props.isConstructing) {
            return (
                <div className="flex-1 flex items-center justify-center text-gray-500 h-full">
                    <div className="text-center space-y-4">
                        <div className="relative">
                            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto"/>
                            <div className="absolute inset-0 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div>
                            <Typography variant="h3" className="text-blue-600 mb-2">
                                Constructing Your Practice Bot
                            </Typography>
                            <Typography variant="mediumParagraph" className="text-gray-500">
                                Please wait while we analyze the prospect data and create a personalized practice bot...
                            </Typography>
                            {this.state.constructionTimeElapsed > 0 && (
                                <Typography variant="smallParagraph" className="text-gray-400 mt-2">
                                    Time elapsed: {this.state.constructionTimeElapsed}s
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        if (this.props.isSearching && !this.props.bot) {
            return (
                <div className="flex-1 flex items-center justify-center text-gray-500 h-full">
                    <div className="text-center">
                        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-3"/>
                        <Typography variant="mediumParagraph" className="text-gray-500">
                            Searching for existing bots...
                        </Typography>
                    </div>
                </div>
            );
        }

        if (this.state.transcripts.length > 0) {
            return (
                <div className="flex-1 overflow-y-auto px-4">
                    <div className="space-y-4 py-4">
                        {this.state.transcripts.map((msg, index) => (
                            <div 
                                key={index} 
                                className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                                ref={index === this.state.transcripts.length - 1 ? this.messagesEndRef : null}
                            >
                                <div className={`
                                    max-w-[80%] p-3 rounded-xl
                                    ${msg.role === 'user' 
                                        ? 'bg-blue-500/20 text-blue-900 rounded-tr-none' 
                                        : 'bg-gray-100 text-gray-800 rounded-tl-none'
                                    }
                                `}>
                                    <div className="text-xs opacity-60 mb-1">
                                        {msg.role === 'assistant' ? this.props.bot?.name : 'You'}
                                    </div>
                                    <div className="text-sm">
                                        {msg.transcript}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="flex-1 flex items-center justify-center text-gray-500 h-full">
                <div className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto mb-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                    <Typography variant="mediumParagraph" className="text-gray-500">
                        {!this.props.bot 
                            ? 'No practice bot available at the moment'
                            : 'Click "Start Practice" to begin a practice call'
                        }
                    </Typography>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="flex flex-col h-full bg-white">
                <GoToHeader
                    backMessage="Back to prospect"
                    onGoBack={this.props.onBack}
                />
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="p-4 bg-gradient-to-r from-purple-100 to-blue-100 border-b border-gray-200">
                        <Typography variant="h2" className="text-gray-800 mb-1">
                            {this.props.isConstructing ? 'Constructing Bot for ' : this.props.bot ? 'Practicing with ' : 'No Bot Available for '}{this.props.bot?.name ?? this.props.prospectData.prospect_name}
                        </Typography>
                        <Typography variant="mediumParagraph" className="text-gray-600">
                            {this.props.isConstructing 
                                ? 'Please wait while we construct your practice bot...'
                                : this.props.bot
                                    ? 'Speak naturally - the AI will respond as if they were the real prospect'
                                    : 'Please try again or visit our practice platform for a better experience'
                            }
                        </Typography>
                    </div>

                    {/* Main content area */}
                    <div className="flex-1 overflow-y-auto">
                        {this.renderTranscriptArea()}
                    </div>
                </div>
                {this.renderCallControls()}
                {this.renderUpgradeModal()}
            </div>
        )
    }
}

export const PracticeBotView = connector(PracticeBotViewImpl); 