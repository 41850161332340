import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography, Chip, Avatar, InputBase, Popper, Paper, List, ListItem, ListItemAvatar, ListItemText, ClickAwayListener } from '@mui/material';
import { Person } from '@mui/icons-material';
import { useLinkedInSearch } from '../../../../lib/redux/linkedin/search/hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import debounce from 'lodash/debounce';
import { addDraftParticipant, ConversationDraft, removeDraftParticipant } from '../../../../lib/redux/linkedin/new-conversation/slice';
import { LinkedInConversationParticipant, LinkedInNormalizedParticipant, LinkedInAttributedText } from 'interfaces/linkedin';
import { pictureToUrl } from '../../../../lib/redux/linkedin/helpers';
import { ConversationHeaderToolbar } from './ConversationHeaderToolbar';

export interface NewMessageHeaderProps {
  draftId: string;
  temporaryDraft?: Partial<ConversationDraft>;
}

export const NewMessageHeader: React.FC<NewMessageHeaderProps> = React.memo(({ draftId, temporaryDraft }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchContainerRef, setSearchContainerRef] = useState<HTMLDivElement | null>(null);
  const { performSearch, clearSearch, getSearchResults } = useLinkedInSearch();

  // Get the current draft from the selected messages
  const activeOption = useSelector((state: RootState) => state.linkedinSelected.activeOption);
  const drafts = useSelector((state: RootState) => state.linkedinNewConversation.drafts);
  const currentDraft = activeOption ? drafts[activeOption] : temporaryDraft;

  const dispatch = useDispatch();
  // Get search results
  const searchResults = useSelector((state: RootState) =>  searchQuery.trim() ? getSearchResults(state, searchQuery) : undefined);

  // Memoize the search function
  const handleSearch = useCallback(
    (query: string) => {
      if (query.trim()) {
        performSearch(query);
      } else {
        clearSearch(query);
      }
    },
    [performSearch, clearSearch]
  );

  // Create debounced search
  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 300),
    [handleSearch]
  );

  const handleSearchQueryChange = useCallback((newQuery: string) => {
    setSearchQuery(newQuery);
    debouncedSearch(newQuery);
  }, [debouncedSearch]);

  const createAttributedText = (text: string): LinkedInAttributedText => ({
    _type: 'com.linkedin.pemberly.text.AttributedText',
    text,
  });

  const handleParticipantSelect = useCallback((person: LinkedInNormalizedParticipant) => {
    dispatch(addDraftParticipant({
      draftId: draftId,
      participant: {
        hostIdentityUrn: person.hostIdentityUrn,
        entityUrn: person.entityUrn,
        participantType: {
          member: {
            _type: 'com.linkedin.messenger.MemberParticipantInfo',
            firstName: createAttributedText(person.name.split(' ')[0]),
            lastName: createAttributedText(person.name.split(' ').slice(1).join(' ')),
            profilePicture: person.image,
            profileUrl: person.url || null,
            headline: createAttributedText(person.tagline || ''),
            distance: 'DISTANCE_1',
            pronoun: null
          },
          organization: null,
          custom: null
        },
        _type: 'com.linkedin.messenger.MessagingParticipant'
      }
    }));
    setSearchQuery('');
  }, [dispatch, draftId]);

  const handleParticipantRemove = useCallback((participantUrn: string) => {
    dispatch(removeDraftParticipant({
      draftId: draftId,
      participantUrn
    }));
  }, [dispatch, draftId]);

  const getParticipantName = (participant: LinkedInConversationParticipant): string => {
    const firstName = participant.participantType?.member?.firstName?.text || '';
    const lastName = participant.participantType?.member?.lastName?.text || '';
    return `${firstName} ${lastName}`.trim();
  };

  const getParticipantImage = (participant: LinkedInConversationParticipant) => {
    return participant.participantType?.member?.profilePicture;
  };

  const open = Boolean(searchContainerRef) && searchQuery.trim().length > 0;

  return (
    <Box sx={{ 
      borderBottom: '1px solid',
      borderColor: 'divider',
      backgroundColor: 'background.paper',
      p: 1,
      pl: 2
    }}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        mb: currentDraft?.participants?.length ? 0.5 : 0
      }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">New Message</Typography>
        </Box>
        <ConversationHeaderToolbar isDraft={true}/>
      </Box>

      <Box
        ref={setSearchContainerRef}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderRadius: 1,
          minHeight: 40,
          pl: 1
        }}
      >
        {currentDraft?.participants?.map((participant) => {
          const image = getParticipantImage(participant);
          return (
            <Chip
              key={participant.hostIdentityUrn}
              avatar={
                <Avatar src={image ? pictureToUrl(image) : undefined}>
                  {getParticipantName(participant).charAt(0)}
                </Avatar>
              }
              label={getParticipantName(participant)}
              onDelete={() => handleParticipantRemove(participant.hostIdentityUrn)}
              size="small"
            />
          );
        })}
        <Box sx={{ flex: 1, minWidth: 100 }}>
          <InputBase
            placeholder={currentDraft?.participants?.length ? "Add more people..." : "Type a name..."}
            value={searchQuery}
            onChange={(e) => handleSearchQueryChange(e.target.value)}
            fullWidth
            sx={{ fontSize: '0.875rem' }}
          />
        </Box>
      </Box>

      <Popper
        open={open}
        anchorEl={searchContainerRef}
        placement="bottom-start"
        style={{ width: searchContainerRef?.clientWidth, zIndex: 1400 }}
      >
        <ClickAwayListener onClickAway={() => setSearchQuery('')}>
          <Paper 
            elevation={3}
            sx={{ 
              mt: 1,
              maxHeight: 300,
              overflow: 'auto'
            }}
          >
            {searchResults?.isLoading ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Searching...
                </Typography>
              </Box>
            ) : searchResults?.people && searchResults.people.length > 0 ? (
              <List dense>
                {searchResults.people.map((person) => (
                  <ListItem
                    key={person.entityUrn}
                    onClick={() => handleParticipantSelect(person)}
                    sx={{ 
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={person.image ? pictureToUrl(person.image) : undefined}>
                        <Person />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={person.name}
                      secondary={person.tagline}
                      primaryTypographyProps={{
                        variant: 'body2',
                        sx: { fontWeight: 500 }
                      }}
                      secondaryTypographyProps={{
                        variant: 'caption',
                        sx: {
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden'
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : searchQuery.trim().length > 0 && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  No results found
                </Typography>
              </Box>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}); 