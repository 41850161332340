import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ConversationId, InboxType, SOURCE_TYPE } from "../types";
import { useLinkedInConversationData } from "./data-hook";
import { ANALYZED_VERSION, ConversationExchangeState, updatePartialConversationState } from "../metadata/slice";
import { setAnalyzed, setAnalyzing, setInitialAnalyticsLoadAttempted } from "./analyze-slice";
import { SEC_TO_MS } from "cfg/const";
import { useLinkedInConversations } from "./list-hook";

export const useAnalyzeLinkedInConversations = () => {
    const dispatch = useDispatch();
    const chatConversations = useSelector((state: RootState) => state.linkedinChatConversations);
    const salesNavigatorConversations = useSelector((state: RootState) => state.linkedinSalesNavigatorConversations);
    const conversationStates = useSelector((state: RootState) => state.linkedinConversationStates);
    const { fetchSpecificLinkedInConversation } = useLinkedInConversationData();
    const analyzing = useSelector((state: RootState) => state.linkedinAnalyze.analyzing);
    const analyzed = useSelector((state: RootState) => state.linkedinAnalyze.analyzed);
    const salesClient = useSelector((state: RootState) => state.linkedinClients.salesNavigatorClient);
    const { loadConversations } = useLinkedInConversations();

    const hasMessages = useCallback((conversation: ConversationId) => {
        if (conversation.sourceType === SOURCE_TYPE.CHAT) {
          const conversationValue = chatConversations.conversations[conversation.id];
          if (!conversationValue) return false;
          return conversationValue.conversation?.messages?.length && conversationValue.conversation?.messages?.length > 0;
        } else {
          const conversationValue = salesNavigatorConversations.conversations[conversation.id];
          if (!conversationValue) return false;
          return conversationValue.conversation?.messages?.length && conversationValue.conversation?.messages?.length > 0;
        }
    }, [chatConversations, salesNavigatorConversations]);

    const getConversationState = useCallback((conversation: ConversationId) => {
        return conversationStates.states[conversation.id];
    }, [conversationStates]);

    const requiresAnalysis = useCallback((conversation: ConversationId) => {
        const state = getConversationState(conversation);
        if (!state) return true;
        if (!state.exchangeState) return true;
        if (state.isLastMessageFromRep === null || state.isLastMessageFromRep === undefined) return true;
        if (!conversation.lastActivity) return true;
        if (state.analyzed_version !== ANALYZED_VERSION) return true;
        if (state.lastAnalyzedAt && state.lastAnalyzedAt >= conversation.lastActivity) return false;
        return false;
    }, [conversationStates, chatConversations, salesNavigatorConversations]);

    const requiresFetchForAnalysis = useCallback((conversation: ConversationId) => {
        if (conversation.sourceType === SOURCE_TYPE.CHAT) return !chatConversations.conversationsLoaded.has(conversation.id);
        return !salesNavigatorConversations.conversationsLoaded.has(conversation.id);
    }, [chatConversations, salesNavigatorConversations]);

    const getExhangeType = useCallback((conversation: ConversationId): {
        isLastMessageFromRep: boolean;
        lastMessageFromRepAt: number | null;
        lastMessageFromProspectAt: number | null;
        exchangeState: ConversationExchangeState;
    } | null => {
        if (conversation.sourceType === SOURCE_TYPE.CHAT) {
            const conversationValue = chatConversations.conversations[conversation.id];
            if (!conversationValue) return null;
            const repParticipant = conversationValue.conversation?.conversationParticipants.find(x => x.participantType?.member?.distance === 'SELF');
            const isLastMessageFromRep = conversationValue.conversation?.messages[conversationValue.conversation?.messages?.length - 1]?.sender.entityUrn === repParticipant?.entityUrn;
            const lastMessageFromRep = conversationValue.conversation?.messages.filter((x) => x.sender.entityUrn === repParticipant?.entityUrn).sort((a, b) => b.deliveredAt.getTime() - a.deliveredAt.getTime())[0]
            let lastMessageFromRepAt = lastMessageFromRep?.deliveredAt.getTime();
            const lastMessageFromProspect = conversationValue.conversation?.messages.filter((x) => x.sender.entityUrn !== repParticipant?.entityUrn).sort((a, b) => b.deliveredAt.getTime() - a.deliveredAt.getTime())[0]
            let lastMessageFromProspectAt = lastMessageFromProspect?.deliveredAt.getTime();
            return {
                isLastMessageFromRep: isLastMessageFromRep,
                lastMessageFromRepAt: lastMessageFromRepAt ? lastMessageFromRepAt : null,
                lastMessageFromProspectAt: lastMessageFromProspectAt ? lastMessageFromProspectAt : null,
                exchangeState: Array.from(new Set((conversationValue.conversation?.messages ?? []).map((x) => x.sender.entityUrn).filter((x) => x))).length > 1 ? ConversationExchangeState.HAS_HAD_EXCHANGE : ConversationExchangeState.NO_EXCHANGE,
            };
        } else {
            const conversationValue = salesNavigatorConversations.conversations[conversation.id];
            if (!conversationValue) return null
            const repParticipant = conversationValue.conversation?.participants.find(x => x.degree === 0)
            const isLastMessageFromRep = conversationValue.conversation?.messages[conversationValue.conversation?.messages?.length - 1]?.author === repParticipant?.entityUrn;
            const lastMessageFromRep = conversationValue.conversation?.messages.filter((x) => x.author === repParticipant?.entityUrn).sort((a, b) => b.deliveredAt - a.deliveredAt)[0]
            let lastMessageFromRepAt = lastMessageFromRep?.deliveredAt
            const lastMessageFromProspect = conversationValue.conversation?.messages.filter((x) => x.author !== repParticipant?.entityUrn).sort((a, b) => b.deliveredAt - a.deliveredAt)[0]
            let lastMessageFromProspectAt = lastMessageFromProspect?.deliveredAt
            return {
                isLastMessageFromRep: isLastMessageFromRep,
                lastMessageFromRepAt: lastMessageFromRepAt ? lastMessageFromRepAt : null,
                lastMessageFromProspectAt: lastMessageFromProspectAt ? lastMessageFromProspectAt : null,
                exchangeState: Array.from(new Set((conversationValue.conversation?.messages ?? []).map((x) => x.author).filter((x) => x))).length > 1 ? ConversationExchangeState.HAS_HAD_EXCHANGE : ConversationExchangeState.NO_EXCHANGE,
            };
        }
    }, [chatConversations, salesNavigatorConversations]);

    useEffect(() => {
        // get all the elements from the sets
        const allConversationsLoaded = 
        [
            ...Array.from(chatConversations.conversationsLoaded).map(x => ({ id: x, sourceType: SOURCE_TYPE.CHAT, lastActivity: null })), 
            ...Array.from(salesNavigatorConversations.conversationsLoaded).map(x => ({ id: x, sourceType: SOURCE_TYPE.SALES_NAVIGATOR, lastActivity: null }))
        ];
        allConversationsLoaded.forEach((conversationId: ConversationId) => {
            if (analyzed[conversationId.id]) return;
            const exchangeInfo = getExhangeType(conversationId);
            if (exchangeInfo) {
                dispatch(updatePartialConversationState({ 
                    conversationId: conversationId, 
                    conversationState: { 
                        exchangeState: exchangeInfo.exchangeState,
                        isLastMessageFromRep: exchangeInfo.isLastMessageFromRep,
                        lastMessageFromRepAt: exchangeInfo.lastMessageFromRepAt,
                        lastMessageFromProspectAt: exchangeInfo.lastMessageFromProspectAt,
                        lastAnalyzedAt: new Date().getTime(),
                        analyzed_version: ANALYZED_VERSION
                    } 
                }));
            }
            dispatch(setAnalyzed({ conversationId: conversationId.id, isAnalyzed: true }));
            dispatch(setAnalyzing({ conversationId: conversationId.id, isAnalyzing: false }));
        });
    }, [chatConversations.conversationsLoaded, salesNavigatorConversations.conversationsLoaded, analyzed]);
    const analyzeConversation = useCallback(async (conversation: ConversationId) => {
        if (!requiresAnalysis(conversation)) {
            dispatch(setAnalyzed({ conversationId: conversation.id, isAnalyzed: true }));
            dispatch(setAnalyzing({ conversationId: conversation.id, isAnalyzing: false }));
            return;
        }
        const requiresFetch = requiresFetchForAnalysis(conversation);
        if (requiresFetch) { // don't double fetch
            fetchSpecificLinkedInConversation(conversation);
            setTimeout(() => {
                dispatch(setAnalyzing({ conversationId: conversation.id, isAnalyzing: false }));
                dispatch(setAnalyzed({ conversationId: conversation.id, isAnalyzed: true }));
            }, 8*SEC_TO_MS);
            return;
        }
         else {
            const exchangeInfo = getExhangeType(conversation);
            if (!exchangeInfo) {
                dispatch(setAnalyzed({ conversationId: conversation.id, isAnalyzed: true }));
                dispatch(setAnalyzing({ conversationId: conversation.id, isAnalyzing: false }));
                return;
            }
            dispatch(updatePartialConversationState({ 
                conversationId: conversation, 
                conversationState: { 
                    exchangeState: exchangeInfo.exchangeState, 
                    isLastMessageFromRep: exchangeInfo.isLastMessageFromRep,
                    lastMessageFromRepAt: exchangeInfo.lastMessageFromRepAt,
                    lastMessageFromProspectAt: exchangeInfo.lastMessageFromProspectAt,
                    lastAnalyzedAt: new Date().getTime(),
                    analyzed_version: ANALYZED_VERSION
                } 
            }));
            dispatch(setAnalyzing({ conversationId: conversation.id, isAnalyzing: false }));
            dispatch(setAnalyzed({ conversationId: conversation.id, isAnalyzed: true }));
        }
    }, [dispatch, requiresAnalysis, requiresFetchForAnalysis, fetchSpecificLinkedInConversation, getExhangeType, analyzing]);

    const analyzeConversations = useCallback(async (conversationIds: ConversationId[]) => {
        // filter everything already being analyzed
        const conversationsToAnalyze = conversationIds.filter((conversation) => !analyzing[conversation.id] && !analyzed[conversation.id])
        // mark all conversations as being analyzed
        const noRequiresAnalysis = conversationsToAnalyze.filter((conversation) => !requiresAnalysis(conversation));
        for (const conversation of noRequiresAnalysis) {
            dispatch(setAnalyzed({ conversationId: conversation.id, isAnalyzed: true }));
        }
        const requiresAnalysisIds = conversationsToAnalyze.filter((conversation) => requiresAnalysis(conversation));
        for (const conversation of requiresAnalysisIds) {
            dispatch(setAnalyzing({ conversationId: conversation.id, isAnalyzing: true }));
        }
        // do in batches of 10
        const BATCH_SIZE = 20;
        const BATCHES = Math.ceil(requiresAnalysisIds.length / BATCH_SIZE);  
        for (let i = 0; i < BATCHES; i++) {
            const batch = requiresAnalysisIds.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);
            await Promise.all(batch.map(analyzeConversation));
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
    }, [analyzed, analyzing, dispatch, chatConversations, salesNavigatorConversations, conversationStates]);

    // fetch conversations for up to a week ago

    const getLastMonday = () => {
        const today = new Date();
        const day = today.getDay();
        const diff = today.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(today.setDate(diff));
    }

    const requiresChatRefresh = useMemo(() => {
        const inboxState = chatConversations.inboxState[InboxType.ALL]
        const chatConversationAll = inboxState?.conversationIds
        if (!chatConversationAll) return true;
        const lastMonday = getLastMonday();
        return inboxState.continues &&  !chatConversationAll.some((conversation) => conversation.lastActivity && conversation.lastActivity < lastMonday.getTime());
    }, [chatConversations]);

    const requiresSalesNavigatorRefresh = useMemo(() => {
        if (!salesClient || !salesClient.hasAccess) return false
        const inboxState = salesNavigatorConversations.inboxState[InboxType.ALL]
        const salesNavigatorConversationAll = inboxState?.conversationIds
        if (!salesNavigatorConversationAll) return true;
        const lastMonday = getLastMonday();
        return inboxState.continues && !salesNavigatorConversationAll.some((conversation) => conversation.lastActivity && conversation.lastActivity < lastMonday.getTime());
    }, [salesNavigatorConversations, salesClient]);

    const requiresBackFill = useMemo(() => {
        return requiresChatRefresh || requiresSalesNavigatorRefresh;
    }, [requiresChatRefresh, requiresSalesNavigatorRefresh]);

    const backFillAnalytics = useCallback(async () => {
        dispatch(setInitialAnalyticsLoadAttempted(true));
        if (requiresChatRefresh) { loadConversations.fetchMoreChat() }
        if (requiresSalesNavigatorRefresh) { loadConversations.fetchMoreSalesNavigator() }
    }, [dispatch, requiresChatRefresh, requiresSalesNavigatorRefresh, loadConversations]);

    return {
        analyzeConversation,
        analyzeConversations,
        getExhangeType,
        hasMessages,
        backFillAnalytics,
        requiresBackFill
    }
}