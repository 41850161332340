import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { TrendingUp } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { useAnalyzeLinkedInConversations } from 'lib/redux/linkedin/conversations/analyzer';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface AnalyticsProps {
  isExpanded: boolean;
}

// Helper function to get last Monday's date
const getLastMonday = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(today.setDate(diff));
};

// Helper function to format date as MM/DD
const formatDate = (date: Date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}/${day}`;
};

export const Analytics: React.FC<AnalyticsProps> = ({ isExpanded }) => {
  const lastMonday = getLastMonday();
  const formattedDate = formatDate(lastMonday);
  const { requiresBackFill } = useAnalyzeLinkedInConversations();
  const chatConversations = useSelector((state: RootState) => state.linkedinChatConversations.conversations);
  const salesNavigatorConversations = useSelector((state: RootState) => state.linkedinSalesNavigatorConversations.conversations);
  const conversationStates = useSelector((state: RootState) => state.linkedinConversationStates.states);

  const stats = useMemo(() => {
    if (requiresBackFill) {
      return {
        prospects: '-',
        responses: '-',
      }
    }

    const conversationIdsSinceLastMonday = [
      ...Object.keys(chatConversations).filter((id) => {
        const conv = chatConversations[id].conversation;
        return conv?.lastActivityAt != null && conv.lastActivityAt > lastMonday.getTime();
      }),
      ...Object.keys(salesNavigatorConversations).filter((id) => {
        const conv = salesNavigatorConversations[id].conversation;
        return conv?.messages != null && conv.messages.some((message) => message.deliveredAt > lastMonday.getTime());
      }),
    ]

    const conversationStatesSinceLastMonday = conversationIdsSinceLastMonday.map((id) => conversationStates[id]);
    const prospectConversations: number[] = conversationIdsSinceLastMonday.map((id) => {
      const conversation = conversationStates[id];
      if (!conversation) return 0;
      return conversation.lastMessageFromRepAt && conversation.lastMessageFromRepAt > lastMonday.getTime() ? 1 : 0;
    });

    const responses: number[] = conversationIdsSinceLastMonday.map((id) => {
      const conversation = conversationStates[id];
      if (!conversation) return 0;
      return conversation.lastMessageFromProspectAt && conversation.lastMessageFromProspectAt > lastMonday.getTime() ? 1 : 0;
    })

    const numberOfProspects = prospectConversations.reduce((acc, curr) => acc + curr, 0);
    const numberOfResponses = responses.reduce((acc, curr) => acc + curr, 0);

    return {
      prospects: numberOfProspects,
      responses: numberOfResponses,
    }
  }, [chatConversations, salesNavigatorConversations, requiresBackFill])

  if (!isExpanded) return null;

  return (
    <Box sx={{ 
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      mb: 1,
      px: 1.5,
      pt: 1,
      pb: 0.75,
    }}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'flex-start',
        gap: 1.5,
      }}>
        <Box sx={{ 
          width: 28,
          height: 28,
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
          flexShrink: 0,
        }}>
          <TrendingUp sx={{ 
            fontSize: 16,
            color: 'primary.main',
          }} />
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 0.5,
          }}>
            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
              <Typography sx={{ 
                fontSize: '1.125rem', 
                fontWeight: 600,
                color: 'text.primary',
                lineHeight: 1
              }}>
                {stats.prospects}
              </Typography>
              <Typography sx={{ 
                fontSize: '0.75rem',
                color: 'text.secondary',
                lineHeight: 1
              }}>
                prospects messaged
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
              <Typography sx={{ 
                fontSize: '1.125rem', 
                fontWeight: 600,
                color: 'primary.main',
                lineHeight: 1
              }}>
                {stats.responses}
              </Typography>
              <Typography sx={{ 
                fontSize: '0.75rem',
                color: 'text.secondary',
                lineHeight: 1
              }}>
                prospects responded
              </Typography>
            </Box>
          </Box>

          <Typography 
            variant="caption" 
            sx={{ 
              fontSize: '0.65rem',
              color: 'text.secondary',
              opacity: 0.7,
              display: 'block',
              mt: 0.75,
              pl: 0.25
            }}
          >
            Data since {formattedDate}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}; 