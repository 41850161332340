import { Loader } from "../../components/Loader"
import { Typography, fontFamily } from "../../interfaces/typography"
import React from "react"
import { GoToHeader } from "../Embedded/GoToHeader"
import { AIProspectEmail, EMAIL_TONE_TYPE_TO_HUMAN_READABLE, EmailToneType, EmailValueProp } from "../../interfaces/db"
import { connect } from "react-redux"
import { RootState } from "../../store"
import { Alert, AlertType } from "../../components/Alert"
import { CustomEditInfo } from "../../interfaces/services"
import uuid from "react-uuid"

const EDIT_SVG_ICON = <svg width="" height="" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M416.668 340.234L266.668 490.234V533.331H309.764L459.764 383.331L416.668 340.234ZM440.238 316.664L483.335 359.76L526.431 316.664L483.335 273.568L440.238 316.664ZM400.001 733.331C215.906 733.331 66.668 584.092 66.668 399.997C66.668 215.902 215.906 66.6641 400.001 66.6641C584.096 66.6641 733.335 215.902 733.335 399.997C733.335 584.092 584.096 733.331 400.001 733.331ZM400.001 699.997C565.687 699.997 700.001 565.683 700.001 399.997C700.001 234.312 565.687 99.9974 400.001 99.9974C234.316 99.9974 100.001 234.312 100.001 399.997C100.001 565.683 234.316 699.997 400.001 699.997ZM495.12 238.212L561.786 304.879C568.295 311.388 568.295 321.94 561.786 328.449L328.453 561.783C325.327 564.908 321.088 566.664 316.668 566.664H250.001C240.797 566.664 233.335 559.202 233.335 549.997V483.331C233.335 478.91 235.091 474.671 238.216 471.546L471.549 238.212C478.058 231.704 488.611 231.704 495.12 238.212Z" fill="black"/>
</svg>

const COPY_SVG_ICON = <svg width="" height="" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M583.333 466.667H633.333C670.153 466.667 700 436.82 700 400V166.667C700 129.848 670.153 100 633.333 100H400C363.18 100 333.333 129.848 333.333 166.667V216.667M166.667 333.333H400C436.82 333.333 466.667 363.18 466.667 400V633.333C466.667 670.153 436.82 700 400 700H166.667C129.848 700 100 670.153 100 633.333V400C100 363.18 129.848 333.333 166.667 333.333Z" stroke="black" stroke-width="50" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const RIGHT_ARROW_SVG_ICON = <svg width="" height="" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M633.335 400L433.335 200M633.335 400L433.335 600M633.335 400H166.668" stroke="black" stroke-width="66.6667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export type EmailGenerationPageProps = {
    onGoBack: () => void
    email_value_props: EmailValueProp[] | null
    original_email: string | null
    conversational_history_emails: {[uuid: string]: string | null}
    can_request_conversational_history: boolean
    conversational_history: CustomEditInfo[]
    request_conversational_history_update: (info: CustomEditInfo) => void
}

export type EmailInfoState = {
    showCopiedAlert: boolean
    customInstruction: string
}

class ChatGPTGenerationPageImpl extends React.Component<EmailGenerationPageProps, EmailInfoState> {
    constructor(props: EmailGenerationPageProps) { 
        super(props) 
        this.state = {
            showCopiedAlert: false,
            customInstruction: ''
        }
    }

    splitEmail(emailText: string): {subject: string | null, body: string} {
        // Find the start of the subject line
        const subjectStartIndex = emailText.indexOf('Subject:');
        
        // If "Subject:" is found, proceed to extract the subject and body
        if (subjectStartIndex !== -1) {
            // Start after "Subject:"
            const start = subjectStartIndex + 'Subject:'.length;
            
            // Find the end of the subject line (newline character)
            const end = emailText.indexOf('\n', start);
            
            // Extract and trim the subject line
            const subjectLine = emailText.substring(start, end).trim();
            
            // Extract the body of the email starting after the subject line
            const body = emailText.substring(end).trim();
            
            return {
                subject: subjectLine,
                body: body
            };
        }
        
        // Return nulls if "Subject:" is not found
        return {
            subject: null,
            body: emailText.trim() // Treat the whole text as body if no subject is found
        };
    }

    renderSection(content: JSX.Element | null) {
        return <div className="w-full flex flex-col p-2" style={{
            border: '1px solid #E2E8F0',
            borderRadius: '6px',
        }}>
            {content}
        </div>
    }

    renderSubjectLine(subject: string) {
        return <div className="w-full flex flex-row gap-1 items-center">
            <Typography variant="largeParagraph" style={{'fontWeight': '650'}}>
                {subject}
        </Typography>
        </div>
    }

    renderEmailContent(content: string) {
        return <div className="w-full flex flex-col gap-1">
            {content.split('\n').map((line, index) => {
                // first line should left aligned, last line should be right aligned, everything else should start of with some tabbing
                return <Typography key={index} variant="largeParagraph" style={{
                    textAlign: 'left',
                    }}>
                    {line}
                </Typography>
            })}
        </div>
    }

    updateFormattedCopy(value: string) {
        try {
          if (window.top && document.location.ancestorOrigins 
            && document.location.ancestorOrigins.length > 0) {
                  const ancestorOrigin = document.location.ancestorOrigins[0]
                  if (ancestorOrigin.includes('salesloft')) {
                    return value.replace(/\n/g, '\n\n')
                  }
            }
        } catch (e) {
        }
        return value
    }

    renderControlButtons(email: string) {
        return <div className="w-full relative flex flex-end justify-end items-end">
            <div className="absolute" style={{
                top: '6px',
                right: '6px',
            }}>
            <div 
            onClick={() => {
                // Copy the text inside the text field
                try {
                    if (navigator && navigator.clipboard) {
                        // add an extra newline in between lines
                        const updatedEmail = this.updateFormattedCopy(this.splitEmail(email).body)
                        navigator.clipboard.writeText(updatedEmail)
                        this.setState({ showCopiedAlert: true })
                        setTimeout(() => {
                            this.setState({ showCopiedAlert: false })
                        }, 3000)
                    }
                } catch (e) {
                    console.error(e)
                }
            }}  
            className="cursor-pointer flex flex-row items-center justify-center opacity-80 hover:opacity-100" 
            style={{
                width: '18px',
                'height': '18px',
            }}>
                {COPY_SVG_ICON}
            </div>
            </div>
        </div>
    }

    renderEmailPage(prospect_email: string) {
        const {subject, body} = this.splitEmail(prospect_email)
        return <div className="w-full flex flex-col gap-1 flex-grow h-full p-2 pt-3">
            {subject ? this.renderSubjectLine(subject) : null}
            {this.renderEmailContent(body)}
        </div>
    }
    
    renderEmailValuePropUsed() {
        return <div className="w-full flex flex-row gap-1 items-center p-2 pr-6" style={{
            'backgroundColor': '#e8e8e8'
        }}>
            <div 
            onClick={
                // open a new tab at / email-settings
                () => {
                    window.open('/email-settings', '_blank')
                }
            }
            className="min-w-5 min-h-5 w-5 h-5 cursor-pointer flex flex-row justify-center items-center"> 
                {EDIT_SVG_ICON}
            </div>
            <Typography variant="largeParagraph" fontWeight={650} style={{
                whiteSpace: 'nowrap',
            }}>
                Pain point:
            </Typography>
            <Typography variant="largeParagraph" style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}>
                {this.props.email_value_props ? this.props.email_value_props[0].pain_point : ''}
            </Typography>

        </div>
    }

    renderOption(tone: EmailToneType) {
        return <div 
        onClick={() => {
            if (!this.props.can_request_conversational_history) return
            this.onSubmitCustomEdit(tone, null)
        }}
        className="flex items-center text-center
        p-1.5 select-none justify-center w-fit
        transition cursor-pointer hover:bg-slate-100 hover:text-white" style={{
            borderRadius: '5px',
            border: '0.25px solid lightgrey',
            whiteSpace: 'nowrap',
        }}>
            <Typography variant="largeParagraph">
                {EMAIL_TONE_TYPE_TO_HUMAN_READABLE[tone]}
            </Typography>
        </div>
    }

    renderOptionModifcationOptions() {
        return <div className="w-full flex flex-row gap-1 flex-wrap">
            {Object.values(EmailToneType).map((tone) => this.renderOption(tone))}
        </div>
    }

    onSubmitCustomEdit(custom_tone: EmailToneType | null, custom_instruction: string | null) {
        if (!this.props.can_request_conversational_history) return
        const edit: CustomEditInfo = {
            custom_tone: custom_tone,
            custom_instructions: custom_instruction,
            uuid: uuid()
        }
        this.props.request_conversational_history_update(edit)
    }

    submitCustomInstruction() {
        if (!this.props.can_request_conversational_history) return
        if (!this.state.customInstruction || this.state.customInstruction === null) return
        this.onSubmitCustomEdit(null, this.state.customInstruction)
        this.setState({customInstruction: ''})
    }


    renderCustomInstructionModificationOptions() {
        // TODO render an input with a button to press enter...
        // make the generate an arrow that shows up inside the input
        return <div className="w-full flex flex-col gap-2">
            <div className="w-full flex flex-row gap-2 items-center pr-5">
                <input
                value={this.state.customInstruction} 
                onChange={(e) => {
                    this.setState({customInstruction: e.target.value})
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') this.submitCustomInstruction()
                }}
                style={{
                    fontFamily: fontFamily,
                    fontSize: '14px',
                }}
                className="w-full p-1 pr-10" placeholder="Custom instruction" />
                <div className="absolute right-8">
                <div 
                onClick={(e) => {
                    e.stopPropagation()
                    this.submitCustomInstruction()
                }}
                className="w-7 cursor-pointer flex flex-row justify-center items-center p-1 opacity-80 hover:opacity-100" style={{
                    height: '25px',
                    border: '0.5px solid #5DCF77',
                    borderRadius: '5px',
                    backgroundColor: '#EFFAF1',
                }}>
                    {RIGHT_ARROW_SVG_ICON}
                </div>
                </div>
            </div>
            </div>
    }

    renderModificationOptions() {
        return <div className="w-full p-2 flex flex-col gap-1" 
        style={{
            opacity: this.props.can_request_conversational_history ? '1' : '0.5',
        }}>
            {this.renderOptionModifcationOptions()}
            {this.renderCustomInstructionModificationOptions()}
        </div>
    }

    renderEmailSection(AIProspectEmail: string | null) {
        if (!AIProspectEmail) return <div className="flex-grow h-full flex flex-col items-center justify-center"><Loader /></div>
        return <div className="w-full flex flex-col items-center">
            {this.renderControlButtons(AIProspectEmail)}
            {this.renderSection(this.renderEmailPage(AIProspectEmail))}
        </div>
    }

    renderConversationalButton(edit: CustomEditInfo) {
        return <div className="w-full flex flex-row justify-end" >
            <div className="w-fit flex flex-row gap-2 text-left flext-start p-4"
            style={{
                border: '0.5px solid #5DCF77',
                borderRadius: '5px',
                backgroundColor: '#EFFAF1',
                }}
            >
                <Typography variant="largeParagraph" fontWeight={650}>
                    {edit.custom_tone ? `Update style: ${EMAIL_TONE_TYPE_TO_HUMAN_READABLE[edit.custom_tone]}` : edit.custom_instructions ? edit.custom_instructions : ''}
                    </Typography>
            </div>
        </div>
    }

    renderConversation() {
        return <div className="w-full flex flex-col gap-2 p-2">
            {this.renderEmailSection(this.props.original_email)}
            {(this.props.conversational_history ?? []).map((edit) => {
                const email = this.props.conversational_history_emails[edit.uuid]
                if (email === null && edit.uuid in this.props.conversational_history_emails) return null
                return <div className="w-full flex flex-col gap-2">
                    {this.renderConversationalButton(edit)}
                    {this.renderEmailSection(email)}
                </div>
                })}
        </div>
    }

    render() {
        return <div className="w-full h-full flex flex-col">
            {this.state.showCopiedAlert ?<div className="relative">
                <div className="absolute w-full" style={{zIndex: '99999999999999'}}>
                    <Alert alertType={AlertType.STANDARD} bgColorTailwind="bg-green-600" stringToShow="Successfully copied" onCloseOrNo={() => this.setState({showCopiedAlert: false})}/>
                </div>
            </div> : null}
            <GoToHeader backMessage="Back" onGoBack={this.props.onGoBack.bind(this)}/>
            <div className="w-full flex-grow pl-0.5 pr-0.5 pt-1.5 overflow-x-hidden" style={{
                'backgroundColor': '#f7f7f7',
                }}>
                <div className="w-full h-full flex-grow">
                    {this.renderConversation()}
                </div>
            </div>
            <div className="h-fit sticky bottom-0">
                {this.renderModificationOptions()}
            </div>
        </div>    
    }
}

const mapStateToProps = (state: RootState) => {
    return {
      email_value_props: state.emailValueProps.value
    };
  };
  
  const ReduxWrapped = connect(mapStateToProps)(ChatGPTGenerationPageImpl)
  
  export { ReduxWrapped as ChatGPTGenerationPage}