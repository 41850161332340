import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BarChart2, FileText, Headphones, Play, HelpCircle, Settings, X, Dumbbell, LogOut, User, Crown } from 'lucide-react'
import { LinkedIn } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { PaymentSubscriptionOptionToPaymentLink, PaymentSubscriptionOption } from 'interfaces/services'
import { clearCache, SERVICE_CALLS } from 'extension-helpers'

export const SideBar: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const [divetStyle, setDivetStyle] = useState({})
  const navRef = useRef<HTMLElement>(null)
  const profileRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.user.value)
  const team = useSelector((state: RootState) => state.team.value)

  const isPaidUser = team?.subscription_type === 'TEAM' || user?.autodialer_paid

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const toggleProfile = () => setIsProfileOpen(!isProfileOpen)

  // Close profile dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleLogout = () => {
    navigate('/login')
  }

  const handleUpgrade = () => {
    window.open(PaymentSubscriptionOptionToPaymentLink[PaymentSubscriptionOption.PARALLEL], '_blank')
    clearCache(SERVICE_CALLS.USER_INFO)
    clearCache(SERVICE_CALLS.AUTODIALER_INFO)
  }

  const navItems = [
    { name: 'Analytics', icon: BarChart2, slugs: ['analytics', 'reports', 'dashboard', 'research', 'diagnostics', 'prime-time', 'number-health', 'geography', 'one-on-ones', ''] },
    { name: 'Transcripts', icon: FileText, slugs: ['transcripts'] },
    { name: 'Live', icon: Headphones, slugs: ['live'] },
    { name: 'Plays', icon: Play, slugs: ['plays'] },
    { name: 'Practice', icon: Dumbbell, slugs: ['practice'] },
    { name: 'LinkedIn', icon: LinkedIn, slugs: ['linkedin'] },
  ]
  const settingsSlugs = ['settings', 'autodialer-settings', 'subteams', 'client-accounts', 'keywords', 'user-management', 'email-settings', 'voicemail-recordings', 'number-registration', 'pages/upload']

  const getBasePath = (path: string) => {
    return '/' + path.split('/')[1].split('?')[0]
  }

  const isActive = (slugs: string[]) => {
    const currentBasePath = getBasePath(location.pathname)
    return slugs.some(slug => {
      const slugPath = slug === '' ? '/' : `/${slug}`
      return currentBasePath === slugPath
    })
  }

  useEffect(() => {
    const updateDivetPosition = () => {
      const activeElement = navRef.current?.querySelector(`[data-active="true"]`)
      if (!navRef.current || !activeElement) return
      if (activeElement) {
        const { left, width } = activeElement.getBoundingClientRect()
        const navLeft = navRef.current?.getBoundingClientRect().left
        setDivetStyle({
          left: `${left - navLeft + width / 2}px`,
          transition: 'left 0.3s ease-in-out'
        })
      }
    }

    updateDivetPosition()
    window.addEventListener('resize', updateDivetPosition)
    return () => window.removeEventListener('resize', updateDivetPosition)
  }, [location.pathname])

  return (
    <div className='pt-1.5 pb-1'>
      <>
      <nav ref={navRef} className="bg-gradient-to-r w-full from-blue-900 via-blue-800 to-blue-900 text-white px-6 py-3 flex items-center justify-between relative" style={{
        borderRadius: '8px'
      }}>
        {/* Selection Indicator */}
        <div 
          className="absolute w-0 h-0 border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent border-r-transparent border-b-white"
          style={{
            ...divetStyle,
            bottom: '0',
            transform: 'translateX(-50%)'
          }}
        />
        
        {/* Logo and Navigation Container */}
        <div className="flex items-center">
          {/* Logo */}
          <div className="mr-5 transition-transform hover:scale-105 select-none">
            <svg 
              height="28" 
              viewBox="0 0 40 50" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              className="text-blue-300"
              aria-hidden="true"
            >
              <path fillRule="evenodd" clipRule="evenodd" d="M26.2535 16.0368C26.2548 17.2236 26.2535 18.3853 26.2535 19.5721C28.7916 19.85 31.3296 20.1382 33.8677 20.4157C33.8698 22.7226 33.8677 21.2268 33.872 23.8423C33.8724 24.6006 33.3093 25.3524 32.4476 25.3325C29.8827 25.2658 27.3161 25.2104 24.7503 25.1507C24.7387 21.0536 24.7425 16.9557 24.7378 12.8591C24.7365 9.47312 21.4665 6.47671 18.2096 5.51888C12.1237 3.7283 6.08501 1.79317 0 0C0 2.93538 0 5.87119 0 8.80657C0 14.1692 5.67556 17.4474 10.7655 18.0126C14.0433 18.3762 17.3275 18.5909 20.6048 18.9458C20.6061 20.9826 20.6083 23.0186 20.6091 25.055C19.3241 25.0256 18.0395 24.9962 16.7553 24.9663C12.7803 24.8707 8.37332 27.5559 8.37419 31.5906C8.37722 37.4471 8.38284 43.304 8.38284 49.1609C11.6675 48.1979 14.9535 47.2323 18.2377 46.2671C21.4847 45.3149 24.7646 42.3085 24.7607 38.9325C24.7603 37.2125 24.7573 35.164 24.756 33.4444C24.7555 32.4121 24.7547 31.3798 24.7547 30.3484C23.374 30.4168 21.9924 30.3796 20.6117 30.4475C20.6117 33.2202 20.6173 36.3235 20.6173 39.0966C20.6173 40.5262 19.5578 42.0029 18.0408 42.376C16.654 42.718 15.2677 43.059 13.8818 43.4005C13.8814 39.3688 13.8758 35.3362 13.874 31.305C13.8732 29.9264 15.4053 28.9781 16.7562 28.9482C21.9868 28.8201 27.2191 28.6938 32.4498 28.5674C35.0294 28.5072 36.7836 26.2998 36.7801 23.9708C36.7771 21.8833 36.7767 19.7937 36.7715 17.7062C33.2656 17.1522 29.7602 16.5895 26.2535 16.0368ZM10.8646 13.5953C8.57458 13.2326 6.99393 12.086 6.99393 9.77479C6.99393 8.74814 6.99349 7.72193 6.99349 6.69615C10.6681 7.60117 14.341 8.50879 18.0148 9.41425C19.2557 9.72026 20.5979 11.0135 20.5983 12.2981C20.6018 13.3005 20.6018 14.1385 20.6026 15.14C17.3569 14.625 14.1103 14.1099 10.8646 13.5953Z" fill="currentColor"/>
            </svg>
            <span className="sr-only">Home</span>
          </div>

          {/* Navigation Items */}
          <div className="flex items-center space-x-2">
            {navItems.map((item) => {
              const active = isActive(item.slugs)
              return (
                <Link
                  key={item.name}
                  to={`/${item.slugs[0]}`}
                  data-active={active}
                  className={`relative px-3 py-2 rounded-md transition-all duration-200 ${
                    active 
                      ? 'text-white bg-blue-700/30' 
                      : 'text-blue-200 hover:text-white hover:bg-blue-700/20'
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <item.icon className={`w-5 h-5 ${active ? 'text-blue-300' : ''}`} aria-hidden="true" />
                    <span className="hidden [@media(min-width:900px)]:inline font-medium">{item.name}</span>
                    <span className="sr-only [@media(min-width:900px)]:hidden">{item.name}</span>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>

        {/* Right Icons */}
        <div className="flex items-center gap-2">
          <button 
            className="p-2 hover:bg-blue-700/20 rounded-md transition-all duration-200"
            onClick={openModal}
            aria-label="Get help"
          >
            <HelpCircle className="w-5 h-5" aria-hidden="true" />
          </button>
          <Link 
            to="/settings"
            data-active={isActive(settingsSlugs)}
            className={`p-2 rounded-md transition-all duration-200 ${
              isActive(['settings']) ? 'bg-blue-700/30' : 'hover:bg-blue-700/20'
            }`}
            aria-label="Settings"
          >
            <Settings className="w-5 h-5" aria-hidden="true" />
          </Link>
          <div className="relative" ref={profileRef}>
            <button
              className={`p-2 rounded-md transition-all duration-200 ${
                isProfileOpen ? 'bg-blue-700/30' : 'hover:bg-blue-700/20'
              }`}
              onClick={toggleProfile}
              aria-label="Profile"
            >
              <User className="w-5 h-5" aria-hidden="true" />
            </button>
            {isProfileOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg py-2 z-50">
                <div className="px-4 py-2 border-b border-gray-200">
                  <p className="text-sm font-medium text-gray-900">{user?.user_name}</p>
                  <div className="mt-1 flex items-center gap-1">
                    {isPaidUser ? (
                      <>
                        <Crown className="w-4 h-4 text-yellow-500" />
                        <span className="text-sm text-gray-600">Paid Account</span>
                      </>
                    ) : (
                      <button 
                        onClick={handleUpgrade}
                        className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
                      >
                        <Crown className="w-4 h-4" />
                        <span>Upgrade Account</span>
                      </button>
                    )}
                  </div>
                </div>
                <button
                  onClick={handleLogout}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                >
                  <LogOut className="w-4 h-4" />
                  <span>Log out</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full" role="dialog" aria-modal="true" aria-labelledby="modal-title">
            <div className="flex justify-between items-center p-6 border-b border-gray-200">
              <h2 id="modal-title" className="text-xl font-semibold text-gray-900">Need Help?</h2>
              <button 
                onClick={closeModal} 
                className="text-gray-400 hover:text-gray-500 hover:bg-gray-100 p-1 rounded-lg transition-colors"
                aria-label="Close modal"
              >
                <X className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <div className="p-6">
              <p className="text-gray-600 mb-4">
                If you need assistance or want to schedule a debugging session, please book a time slot using our Calendly link below.
              </p>
              <a 
                href="https://calendly.com/ajinkya_nene/debug" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="block w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white text-center py-2 px-4 rounded-lg hover:from-blue-700 hover:to-blue-800 transition-all duration-200 shadow-lg hover:shadow-xl"
              >
                Schedule Debugging Session
              </a>
            </div>
          </div>
        </div>
      )}
      </>
    </div>
  )
}