import React, { useState } from 'react';
import { Box, Avatar, IconButton, CircularProgress, Modal, Button, Fade, Paper } from '@mui/material';
import { LinkedInPicture, LinkedInAttachment } from '../../../../interfaces/linkedin';
import { pictureToUrl, getMessageAttachments } from '../../../../lib/redux/linkedin/helpers';
import { Typography } from '../../../../interfaces/typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import EmailIcon from '@mui/icons-material/Email';
import ReplayIcon from '@mui/icons-material/Replay';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThumbUpAlt, Favorite, InsertEmoticon, SentimentVerySatisfied, ThumbDown } from '@mui/icons-material';
import { useSendMessage } from 'lib/redux/linkedin/conversations/message-hook';
import { SOURCE_TYPE } from '../../../../lib/redux/linkedin/types';

interface MessageBubbleProps {
  message: string;
  participantName: string;
  participantImage?: LinkedInPicture;
  renderContent?: LinkedInAttachment[];
  isSelf?: boolean;
  timestamp?: string;
  isTemporary?: boolean;
  isGroupChat?: boolean;
  failed?: boolean;
  messageEntityUrn?: string;
  emojiReactions?: string[];
  sourceType?: SOURCE_TYPE;
  retryTemporaryMessage?: () => void;
  removeTemporaryMessageFromConvo?: () => void;
}

interface ExpandedMedia {
  url: string;
  name: string;
  type: 'image' | 'video';
  thumbnail?: string;
}

const ImageControls = ({ onDownload, onExpand, onClose, expanded = false }: { 
  onDownload: () => void;
  onExpand?: () => void;
  onClose?: () => void;
  expanded?: boolean;
}) => (
  <Box 
    className={expanded ? '' : 'media-controls'}
    sx={{
      position: 'absolute',
      top: 8,
      right: 8,
      display: 'flex',
      gap: 1,
      opacity: expanded ? 1 : 0,
      transition: 'opacity 0.2s',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      borderRadius: 1,
      padding: '4px'
    }}
  >
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onDownload();
      }}
      sx={{ color: 'white' }}
    >
      <FileDownloadIcon fontSize="small" />
    </IconButton>
    {!expanded && (
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onExpand?.();
        }}
        sx={{ color: 'white' }}
      >
        <ZoomOutMapIcon fontSize="small" />
      </IconButton>
    )}
    {expanded && (
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onClose?.();
        }}
        sx={{ color: 'white' }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    )}
  </Box>
);

const formatMessageContent = (text: string) => {
  // Email regex pattern - check for emails first
  const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
  // URL regex pattern with specific TLDs - but exclude email matches
  const urlPattern = /(?:https?:\/\/)?(?:www\.)?(?!(?:[a-zA-Z0-9._-]+@))[a-zA-Z0-9._-]+\.(?:com|org|net|edu|gov|mil|biz|info|io|ai|app|dev|cloud|me|co|uk|us|ca|au|de|fr|jp|cn|br|in|ru|nl|es|it|pl|se|no|fi|dk|ch|at|be|ie|nz)\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

  let lastIndex = 0;
  const parts: React.ReactNode[] = [];
  
  // First find and process emails
  text.replace(emailPattern, (match, email, offset) => {
    if (offset > lastIndex) {
      parts.push(text.slice(lastIndex, offset));
    }
    parts.push(
      <Box
        key={`email-${offset}`}
        component="a"
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          color: 'inherit',
          textDecoration: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
          px: 1,
          py: 0.5,
          mx: 0.5,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <EmailIcon sx={{ fontSize: '1rem', mr: 0.5, opacity: 0.7 }} />
        <span style={{ wordBreak: 'break-all' }}>{email}</span>
      </Box>
    );
    lastIndex = offset + match.length;
    return match;
  });

  // Then find and process URLs in the remaining text
  let remainingText = text.slice(lastIndex);
  lastIndex = 0;

  remainingText.replace(urlPattern, (match, offset) => {
    if (offset > lastIndex) {
      parts.push(remainingText.slice(lastIndex, offset));
    }
    const url = match.startsWith('http') ? match : `https://${match}`;
    parts.push(
      <Box
        key={`url-${offset}`}
        component="a"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          color: 'inherit',
          textDecoration: 'none',
          wordBreak: 'break-all',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      >
        {match}
        <LaunchIcon sx={{ fontSize: '0.8rem', ml: 0.5, opacity: 0.7 }} />
      </Box>
    );
    lastIndex = offset + match.length;
    return match;
  });

  // Add remaining text
  if (lastIndex < remainingText.length) {
    parts.push(remainingText.slice(lastIndex));
  }

  return (
    <Box component="span" sx={{ 
      display: 'inline',
      wordBreak: 'break-word',
      '& > *': { verticalAlign: 'middle' }
    }}>
      {parts}
    </Box>
  );
};

const QuickReactions: React.FC<{ 
  onReact: (emoji: string) => void,
  currentReactions?: string[],
}> = ({ onReact, currentReactions = [] }) => {
  const allEmojis = ['👍', '❤️', '😊', '👎'];
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      {allEmojis.map((emoji) => (
        <IconButton
          key={emoji}
          onClick={() => onReact(emoji)}
          size="small"
          aria-label={`React with ${emoji}`}
          sx={{
            padding: '2px',
            minWidth: '24px',
            height: '24px',
            backgroundColor: currentReactions?.includes(emoji) ? 'rgba(0,0,0,0.08)' : 'transparent',
            '&:hover': {
              backgroundColor: currentReactions?.includes(emoji) ? 'rgba(0,0,0,0.12)' : 'rgba(0,0,0,0.04)'
            }
          }}
        >
          <span style={{ fontSize: '14px', lineHeight: 1 }}>{emoji}</span>
        </IconButton>
      ))}
    </Box>
  );
};

export const MessageBubble: React.FC<MessageBubbleProps> = React.memo(({
  message,
  participantName,
  participantImage,
  renderContent,
  isSelf = false,
  timestamp,
  isTemporary = false,
  isGroupChat = false,
  failed = false,
  messageEntityUrn,
  emojiReactions,
  sourceType,
  retryTemporaryMessage,
  removeTemporaryMessageFromConvo
}) => {
  const [expandedMedia, setExpandedMedia] = useState<ExpandedMedia | null>(null);
  const [showReactions, setShowReactions] = useState(false);
  const { sendReaction, removeReaction } = useSendMessage();
  const attachments = getMessageAttachments(renderContent);
  const canShowEmojiReactions = sourceType === SOURCE_TYPE.CHAT;

  const handleDownload = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReaction = (emoji: string) => {
    if (messageEntityUrn) {
      if (emojiReactions?.includes(emoji)) {
        removeReaction(messageEntityUrn, emoji);
      } else {
        sendReaction(messageEntityUrn, emoji);
      }
      setShowReactions(false);
    }
  };

  // If no message and no attachments, show deleted message
  if (!message && (!attachments || attachments.length === 0)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSelf ? 'row-reverse' : 'row',
          gap: 1.5,
          mb: 3,
          width: '100%',
          pr: isSelf ? 0 : 4,
          pl: isSelf ? 4 : 0,
        }}
      >
        <Avatar
          src={participantImage ? pictureToUrl(participantImage) : undefined}
          sx={{ 
            width: 32, 
            height: 32, 
            flexShrink: 0,
            alignSelf: 'flex-start',
            mt: isGroupChat ? 2 : 0.5,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          {participantName.charAt(0)}
        </Avatar>
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            color: 'rgba(0, 0, 0, 0.6)',
            borderRadius: 2,
            p: 1.5,
            fontSize: '0.9rem',
            fontStyle: 'italic'
          }}
        >
          Message was deleted
        </Box>
      </Box>
    );
  }

  const renderAttachment = (attachment: ReturnType<typeof getMessageAttachments>[number]) => {
    switch (attachment.type) {
      case 'image':
        return (
          <Box sx={{ 
            position: 'relative', 
            maxWidth: 300, 
            '&:hover .media-controls': { opacity: 1 },
            display: 'inline-block'
          }}>
            <Box
              component="img"
              src={attachment.url}
              alt={attachment.name}
              referrerPolicy="no-referrer"
              sx={{
                width: '100%',
                height: 'auto',
                maxHeight: 200,
                objectFit: 'cover',
                borderRadius: 1,
                cursor: 'pointer',
                minWidth: 100,
                minHeight: 40
              }}
              onClick={() => setExpandedMedia({ url: attachment.url, name: attachment.name, type: 'image' })}
            />
            <Box 
              className="media-controls"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                m: 0.5,
                display: 'flex',
                gap: 0.5,
                opacity: 0,
                transition: 'opacity 0.2s',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                borderRadius: 1,
                padding: '2px',
                zIndex: 1,
                flexWrap: 'nowrap',
                minWidth: 'fit-content'
              }}
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload(attachment.url, attachment.name);
                }}
                sx={{ 
                  color: 'white',
                  padding: '4px',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                }}
              >
                <FileDownloadIcon sx={{ fontSize: 16 }} />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandedMedia({ url: attachment.url, name: attachment.name, type: 'image' });
                }}
                sx={{ 
                  color: 'white',
                  padding: '4px',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                }}
              >
                <ZoomOutMapIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Box>
          </Box>
        );
      case 'video':
        return (
          <Box sx={{ position: 'relative', maxWidth: 400, '&:hover .media-controls': { opacity: 1 } }}>
            <Box
              component="video"
              controls
              preload="none"
              poster={attachment.thumbnail}
              sx={{
                width: '100%',
                borderRadius: 1,
                maxHeight: 300,
                cursor: 'pointer'
              }}
              onClick={() => setExpandedMedia({ 
                url: attachment.url, 
                name: attachment.name, 
                type: 'video',
                thumbnail: attachment.thumbnail 
              })}
            >
              <source src={attachment.url} type="video/mp4" />
            </Box>
            <ImageControls 
              onDownload={() => handleDownload(attachment.url, attachment.name)}
              onExpand={() => setExpandedMedia({ 
                url: attachment.url, 
                name: attachment.name, 
                type: 'video',
                thumbnail: attachment.thumbnail 
              })}
            />
          </Box>
        );
      case 'audio':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgb(243, 242, 241)',
              borderRadius: 1,
              width: 'fit-content',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
          >
            <Box
              component="audio"
              controls
              preload="metadata"
              src={attachment.url}
              sx={{ 
                width: 240,
                height: 40,
                '&::-webkit-media-controls-panel': {
                  backgroundColor: 'transparent',
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center'
                },
                '&::-webkit-media-controls-play-button': {
                  backgroundColor: '#0a66c2',
                  borderRadius: '50%',
                  width: 28,
                  height: 28,
                  flexShrink: 0,
                  margin: '0 4px'
                },
                '&::-webkit-media-controls-timeline': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  borderRadius: 4,
                  height: 4,
                  flex: 1,
                  margin: '0 8px'
                },
                '&::-webkit-media-controls-current-time-display, &::-webkit-media-controls-time-remaining-display': {
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: '12px',
                  margin: '0 4px'
                },
                '&::-webkit-media-controls-volume-slider': {
                  display: 'none'
                },
                '&::-webkit-media-controls-mute-button': {
                  display: 'none'
                },
                '&::-webkit-media-controls-enclosure': {
                  width: '100%'
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                borderLeft: '1px solid rgba(0, 0, 0, 0.08)'
              }}
            >
              <IconButton
                size="small"
                onClick={() => handleDownload(attachment.url, attachment.name)}
                sx={{ 
                  padding: '6px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <FileDownloadIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>
          </Box>
        );
      default:
        // Simple downloadable link for any other type of attachment
        return (
          <Box
            component="a"
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            download={attachment.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1.5,
              borderRadius: 1,
              backgroundColor: failed ? 'rgba(211, 47, 47, 0.08)' : (isTemporary ? 'rgba(25, 118, 210, 0.08)' : (isSelf ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)')),
              textDecoration: 'none',
              transition: 'background-color 0.2s',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: failed ? 'rgba(211, 47, 47, 0.12)' : (isTemporary ? 'rgba(25, 118, 210, 0.12)' : (isSelf ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.08)'))
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              handleDownload(attachment.url, attachment.name);
            }}
          >
            <FileDownloadIcon sx={{ 
              fontSize: 20, 
              opacity: 0.7, 
              color: failed ? '#d32f2f' : (isTemporary ? '#1976d2' : (isSelf ? 'white' : 'inherit')) 
            }} />
            <Typography 
              variant="mediumParagraph" 
              style={{ 
                color: failed ? '#d32f2f' : (isTemporary ? '#1976d2' : (isSelf ? 'white' : 'inherit')),
                fontSize: '0.95rem'
              }}
            >
              {attachment.name} {attachment.byteSize ? `(${Math.round(attachment.byteSize / 1024)}KB)` : ''}
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSelf ? 'row-reverse' : 'row',
        gap: 1.5,
        mb: 3,
        width: '100%',
        pr: isSelf ? 0 : 4,
        pl: isSelf ? 4 : 0,
      }}
    >
      <Avatar
        src={participantImage ? pictureToUrl(participantImage) : undefined}
        sx={{ 
          width: 32, 
          height: 32, 
          flexShrink: 0,
          alignSelf: 'flex-start',
          mt: isGroupChat ? 2 : 0.5,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        {participantName.charAt(0)}
      </Avatar>

      <Box sx={{ flex: 1, minWidth: 0, display: 'flex', flexDirection: 'column' }}>
        {!isSelf && isGroupChat && (
          <Typography
            variant="caption"
            style={{ 
              marginBottom: '4px',
              display: 'block',
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '0.85rem'
            }}
          >
            {participantName}
          </Typography>
        )}

        <Box sx={{ display: 'flex', flexDirection: isSelf ? 'row-reverse' : 'row' }}>
          <Box
            sx={{
              backgroundColor: failed ? '#ffebee' : (isTemporary ? '#e3f2fd' : (isSelf ? '#0a66c2' : '#f5f5f5')),
              color: failed ? '#d32f2f' : (isTemporary ? '#1976d2' : (isSelf ? 'white' : 'black')),
              borderRadius: 2,
              p: 1.5,
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
              maxWidth: '85%',
              position: 'relative',
              userSelect: 'text',
              opacity: isTemporary ? 0.8 : 1,
              '&::after': {
                content: '""',
                position: 'absolute',
                top: isGroupChat ? 24 : 15,
                [isSelf ? 'right' : 'left']: -6,
                width: 0,
                height: 0,
                border: '6px solid transparent',
                borderRightColor: failed ? '#ffebee' : (isTemporary ? '#e3f2fd' : (isSelf ? 'transparent' : '#f5f5f5')),
                borderLeftColor: failed ? 'transparent' : (isTemporary ? 'transparent' : (isSelf ? '#0a66c2' : 'transparent')),
                transform: isSelf ? 'translateX(6px)' : 'translateX(-6px)'
              },
              ...((!isSelf && !isTemporary) && {
                '&:hover': {
                  '& .reaction-trigger': {
                    opacity: 1,
                    transform: 'translateY(0)',
                    pointerEvents: 'auto',
                    backgroundColor: 'white'
                  }
                }
              })
            }}
            onMouseEnter={() => !isSelf && !isTemporary && canShowEmojiReactions && setShowReactions(true)}
            onMouseLeave={() => setShowReactions(false)}
          >
            {!isSelf && !isTemporary && canShowEmojiReactions && showReactions && (
              <Box
                className="reaction-trigger"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setShowReactions(true);
                }}
                sx={{
                  position: 'absolute',
                  top: -20,
                  left: '100%',
                  marginLeft: -4,
                  display: 'flex',
                  alignItems: 'center',
                  background: 'white',
                  padding: '2px 4px',
                  borderRadius: '12px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  border: '1px solid rgba(0,0,0,0.08)',
                  zIndex: 10,
                  whiteSpace: 'nowrap',
                  opacity: 1,
                  pointerEvents: 'auto'
                }}
              >
                <QuickReactions 
                  onReact={handleReaction} 
                  currentReactions={emojiReactions}
                />
              </Box>
            )}

            {!showReactions && canShowEmojiReactions && emojiReactions && emojiReactions.length > 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: -4,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  background: 'white',
                  padding: '2px 4px',
                  borderRadius: '12px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  border: '1px solid rgba(0,0,0,0.08)',
                  zIndex: 10,
                  minHeight: '20px',
                  transform: 'translateY(-50%)'
                }}
              >
                {emojiReactions.map((emoji, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{
                      fontSize: '13px',
                      lineHeight: 1,
                      padding: '2px 3px',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.04)',
                      }
                    }}
                    onClick={() => messageEntityUrn && removeReaction(messageEntityUrn, emoji)}
                  >
                    {emoji}
                  </Box>
                ))}
              </Box>
            )}

            {message && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <Box sx={{ flex: 1, minWidth: 0 }}>
                  <Typography
                    variant="mediumParagraph"
                    style={{ 
                      marginBottom: attachments.length > 0 ? '12px' : 0,
                      color: 'inherit',
                      fontSize: '0.95rem',
                      lineHeight: '1.4',
                      userSelect: 'text'
                    }}
                  >
                    {formatMessageContent(message)}
                  </Typography>
                </Box>
                {isTemporary && !failed && (
                  <CircularProgress size={16} thickness={4} sx={{ color: '#1976d2', ml: 1, mt: 0.5, flexShrink: 0 }} />
                )}
              </Box>
            )}

            {failed && (
              <Box sx={{ 
                display: 'flex', 
                gap: 1, 
                mt: 1.5,
                borderTop: '1px solid rgba(211, 47, 47, 0.2)',
                pt: 1.5
              }}>
                <Button
                  size="small"
                  onClick={() => retryTemporaryMessage ? retryTemporaryMessage() : undefined}
                  startIcon={<ReplayIcon />}
                  sx={{ 
                    color: '#d32f2f',
                    borderColor: '#d32f2f',
                    '&:hover': {
                      borderColor: '#d32f2f',
                      backgroundColor: 'rgba(211, 47, 47, 0.04)'
                    }
                  }}
                  variant="outlined"
                >
                  Retry
                </Button>
                <Button
                  size="small"
                  onClick={() => removeTemporaryMessageFromConvo ? removeTemporaryMessageFromConvo() : undefined}
                  startIcon={<CancelIcon />}
                  sx={{ 
                    color: '#d32f2f',
                    '&:hover': {
                      backgroundColor: 'rgba(211, 47, 47, 0.04)'
                    }
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}

            {attachments.length > 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: message ? 2 : 0 }}>
                {attachments.map((attachment) => (
                  <Box key={attachment.id}>
                    {renderAttachment(attachment)}
                  </Box>
                ))}
              </Box>
            )}

            {timestamp && (
              <Typography
                variant="smallCaption"
                style={{
                  position: 'absolute',
                  bottom: -20,
                  [isSelf ? 'left' : 'right']: 0,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontSize: '0.75rem',
                  whiteSpace: 'nowrap',
                  userSelect: 'none'
                }}
              >
                {timestamp}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Modal
        open={!!expandedMedia}
        onClose={() => setExpandedMedia(null)}
        onClick={() => setExpandedMedia(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
          '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: 'blur(4px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            maxWidth: '90vw',
            maxHeight: '90vh',
            zIndex: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {expandedMedia?.type === 'image' ? (
            <Box
              component="img"
              src={expandedMedia.url}
              alt="Expanded view"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: 1,
                boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
              }}
            />
          ) : expandedMedia?.type === 'video' ? (
            <Box
              component="video"
              controls
              autoPlay
              poster={expandedMedia.thumbnail}
              sx={{
                width: '100%',
                height: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
                borderRadius: 1,
                boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
              }}
            >
              <source src={expandedMedia.url} type="video/mp4" />
            </Box>
          ) : null}
          {expandedMedia && (
            <ImageControls 
              expanded
              onDownload={() => handleDownload(expandedMedia.url, expandedMedia.name)}
              onClose={() => setExpandedMedia(null)}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
}); 