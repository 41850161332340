import React from 'react';
import { 
  Box, 
  IconButton, 
  Typography, 
  Avatar,
} from '@mui/material';
import { 
  Person, 
  Refresh, 
  ExpandMore, 
  ExpandLess, 
  MoreHoriz,
} from '@mui/icons-material';
import { LinkedInPicture } from 'interfaces/linkedin';
import { pictureToUrl } from 'lib/redux/linkedin/helpers';

interface RecommendationSectionProps {
  title: string;
  items: Array<{ 
    name: string; 
    role: string; 
    picture?: LinkedInPicture | null; 
    id: string;
    [key: string]: any 
  }>;
  onSelect: (person: string) => void;
  onRefresh?: () => void;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  icon?: React.ReactNode;
  clampRoleText?: boolean;
  isExpanded?: boolean;
  onToggleExpand?: () => void;
}

export const RecommendationSection: React.FC<RecommendationSectionProps> = React.memo(({ 
  title, 
  items, 
  onSelect, 
  onRefresh, 
  onLoadMore, 
  isLoadingMore, 
  icon, 
  clampRoleText = false, 
  isExpanded, 
  onToggleExpand 
}) => {
  const formatRole = React.useCallback((role: string | null | undefined) => {
    if (typeof role === 'string') {
      return role.replace(/started a new position as/i, '');
    }
    return role || '';
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, height: '100%' }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        pl: 1,
        pr: 0.5,
        py: 0.5,
        backgroundColor: '#fff',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      }}>
        {icon}
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            fontSize: '0.7rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            letterSpacing: '0.02em',
            flex: 1,
          }}
        >
          {title}
        </Typography>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          {onRefresh && (
            <IconButton
              onClick={onRefresh}
              size="small"
              sx={{
                padding: 0.25,
                margin: -0.25,
                opacity: 0.5,
                transition: 'opacity 0.2s',
                '&:hover': {
                  opacity: 0.8,
                }
              }}
            >
              <Refresh sx={{ fontSize: 14 }} />
            </IconButton>
          )}
          {onToggleExpand && (
            <IconButton
              onClick={onToggleExpand}
              size="small"
              sx={{
                padding: 0.25,
                margin: -0.25,
                opacity: 0.5,
                transition: 'opacity 0.2s',
                '&:hover': {
                  opacity: 0.8,
                }
              }}
            >
              {isExpanded ? (
                <ExpandMore sx={{ fontSize: 14 }} />
              ) : (
                <ExpandLess sx={{ fontSize: 14 }} />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      <Box className="custom-scrollbar" sx={{ 
        overflowY: 'auto',
        flex: 1,
        pr: 1,
        '&:hover': {
          '&::-webkit-scrollbar-thumb': {
            background: '#a8a8a8',
          }
        }
      }}>
        {items.map((person) => (
          <Box
            key={person.id}
            onClick={() => onSelect(person.id)}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1,
              cursor: 'pointer',
              px: 1,
              py: 0.75,
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Avatar sx={{ width: 20, height: 20 }}>
              {person.picture ? (
                <img 
                  src={pictureToUrl(person.picture)} 
                  alt={person.name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  referrerPolicy="no-referrer"
                />
              ) : (
                <Person sx={{ fontSize: 14 }} />
              )}
            </Avatar>
            <Box sx={{ overflow: 'hidden', flex: 1 }}>
              <Typography 
                variant="body2" 
                noWrap
                sx={{ fontSize: '13px' }}
              >
                {person.name}
              </Typography>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  fontSize: '12px', 
                  display: '-webkit-box', 
                  mt: '1px',
                  lineHeight: 1.2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: title === "Recent Job Changes" ? 3 : 1,
                }}
              >
                {formatRole(person.role)}
              </Typography>
            </Box>
          </Box>
        ))}
        
        {/* Load More Button */}
        {onLoadMore && items.length > 0 && (
          <Box
            onClick={() => !isLoadingMore && onLoadMore()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              py: 1,
              mt: 1,
              cursor: isLoadingMore ? 'default' : 'pointer',
              borderTop: '1px solid rgba(0, 0, 0, 0.08)',
              color: 'text.secondary',
              opacity: isLoadingMore ? 0.7 : 1,
              '&:hover': !isLoadingMore ? {
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                color: 'text.primary',
              } : undefined,
            }}
          >
            {isLoadingMore ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <MoreHoriz sx={{ fontSize: 16, animation: 'pulse 1.5s infinite' }} />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 500,
                  }}
                >
                  Loading...
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="caption"
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 500,
                }}
              >
                Load More
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
});

interface RecommendationSectionWrapperProps {
  items: any[];
  title: string;
  icon: React.ReactNode;
  onSelect: (id: string) => void;
  onRefresh?: () => void;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  clampRoleText: boolean;
  isExpanded: boolean;
  onToggleExpand: () => void;
  flex?: string | number;
}

export const RecommendationSectionWrapper: React.FC<RecommendationSectionWrapperProps> = React.memo(({ 
  items, 
  title, 
  icon, 
  onSelect, 
  onRefresh, 
  onLoadMore, 
  isLoadingMore, 
  clampRoleText, 
  isExpanded, 
  onToggleExpand, 
  flex 
}) => {
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      flex: isExpanded ? 1 : (flex || '1 0 auto'),
      minHeight: 0,
      ...(isExpanded && {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#fff',
        zIndex: 2,
      }),
    }}>
      <RecommendationSection 
        title={title}
        items={items}
        onSelect={onSelect}
        onRefresh={onRefresh}
        onLoadMore={onLoadMore}
        isLoadingMore={isLoadingMore}
        icon={icon}
        clampRoleText={!isExpanded}
        isExpanded={isExpanded}
        onToggleExpand={onToggleExpand}
      />
    </Box>
  );
}); 