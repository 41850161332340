import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { LinkedInConversationParticipant } from 'interfaces/linkedin';
import { ConversationBody } from './ConversationBody';
import { LoadingConversationBody } from './LoadingConversationBody';
import { useLinkedInSearch } from 'lib/redux/linkedin/search/hook';
import { MessageBubble } from './MessageBubble';
import { useSendMessage } from 'lib/redux/linkedin/conversations/message-hook';
import { generateMessageGroups } from 'lib/redux/linkedin/helpers';
import { SOURCE_TYPE } from 'lib/redux/linkedin/types';

export interface NewMessageBodyProps {
  draftId: string;
}

export const NewMessageBody: React.FC<NewMessageBodyProps> = React.memo(({ draftId }) => {
  const { getMatchingLinkedInParticipants } = useLinkedInSearch();
  const { retryTemporaryMessage, removeTemporaryMessageFromConvo } = useSendMessage();
  const matchingDraft = useSelector((state: RootState) => state.linkedinNewConversation.drafts[draftId]);
  const temporaryMessages = useSelector((state: RootState) => state.linkedinTemporary.temporaryMessages[draftId]);
  
  const matchingConversationInfo = useMemo(() => {
    if (!matchingDraft) return null;
    const matchingParticipants = getMatchingLinkedInParticipants(matchingDraft.participants);
    if (!matchingParticipants) return null;
    return matchingParticipants
  }, [getMatchingLinkedInParticipants, matchingDraft]);
  
  const messageGroups = useMemo(() => {
    if (!temporaryMessages || !temporaryMessages.length) return null;
    return generateMessageGroups({
      id: draftId, sourceType: SOURCE_TYPE.CHAT, lastActivity: Date.now()
    }, null, temporaryMessages);
  }, [draftId, temporaryMessages]);
  
  if (matchingConversationInfo?.matchingConversation) {
    const matchingConversation = matchingConversationInfo.matchingConversation;
    return <ConversationBody conversationId={matchingConversation} />
  } else if ((!matchingConversationInfo || matchingConversationInfo?.isLoading) && matchingDraft && matchingDraft?.participants?.length > 0) {
    return <LoadingConversationBody />
  }

  return (
    <Box sx={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto'
    }}>
      {messageGroups && messageGroups.length > 0 ? (
        <Box sx={{ flexGrow: 1, p: 2 }}>
          {messageGroups?.map(({ date, messages }) => (
            <Box key={date} sx={{ mb: 4 }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
                position: 'relative',
              }}>
                <Box sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: '50%',
                  height: '1px',
                  backgroundColor: 'divider',
                  zIndex: 0,
                }}/>
                <Box sx={{
                  backgroundColor: 'background.paper',
                  px: 2,
                  color: 'text.secondary',
                  position: 'relative',
                  zIndex: 1,
                  fontSize: '0.75rem',
                }}>
                  {date}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {messages.map((messageProps) => (
                  <Box 
                    key={messageProps.entityUrn}
                    sx={{ mt: messageProps.isConsecutive ? 0.5 : 3 }}
                  >
                    <MessageBubble
                      message={messageProps.message}
                      participantName={messageProps.participantName}
                      participantImage={messageProps.participantImage || undefined}
                      isSelf={messageProps.isSelf}
                      timestamp={messageProps.timestamp}
                      renderContent={messageProps.renderContent}
                      isTemporary={messageProps.isTemporary}
                      failed={messageProps.isTemporary && messageProps.failed}
                      retryTemporaryMessage={messageProps.isTemporary ? () => {
                        retryTemporaryMessage(draftId, messageProps.entityUrn)
                      } : undefined}
                      removeTemporaryMessageFromConvo={messageProps.isTemporary ? () => {
                        removeTemporaryMessageFromConvo(draftId, messageProps.entityUrn)
                      } : undefined}
                      messageEntityUrn={messageProps.entityUrn}
                      emojiReactions={messageProps.emojiReactions || []}
                      sourceType={SOURCE_TYPE.CHAT}
                      isGroupChat={messageProps.isGroupChat}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Box sx={{
            textAlign: 'center',
            color: 'text.secondary',
            px: 3
          }}>
            <Box sx={{ 
              fontSize: '1.1rem', 
              mb: 1 
            }}>
              {matchingDraft?.participants?.length > 0 ? "Send a message to start a new conversation" : "Select a connection to start a new conversation"}
            </Box>
            <Box sx={{ 
              fontSize: '0.9rem',
              color: 'text.disabled'
            }}>
              {matchingDraft?.participants?.length > 0 ? "Use the message box below to send a message" : "Select a connection to start a new conversation"}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}); 