import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { Snippet } from '../../../../interfaces/db';
import { getServicesManager } from '../../../../services';

interface SnippetsState {
  snippets: Snippet[] | null;
  loading: boolean;
}

const initialState: SnippetsState = {
  snippets: null,
  loading: false
};

export const snippetsSlice = createSlice({
  name: 'snippets',
  initialState,
  reducers: {
    addSnippet: (state, action: PayloadAction<Snippet>) => {
      if (state.snippets == null) {
        state.snippets = [];
      }
      state.snippets.push(action.payload);
    },
    updateSnippet: (state, action: PayloadAction<Snippet>) => {
      if (state.snippets == null) {
        state.snippets = [];
      }
      const index = state.snippets.findIndex(snippet => snippet.snippet_id === action.payload.snippet_id);
      if (index !== -1) {
        state.snippets[index] = action.payload;
      }
    },
    deleteSnippet: (state, action: PayloadAction<string>) => {
      if (state.snippets == null) {
        state.snippets = [];
      }
      state.snippets = state.snippets.filter(snippet => snippet.snippet_id !== action.payload);
    },
    resetToDefault: (state) => {
      state.snippets = [];
    },
    setSnippets: (state, action: PayloadAction<Snippet[]>) => {
      state.snippets = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

export const { addSnippet, updateSnippet, deleteSnippet, resetToDefault, setSnippets, setLoading } = snippetsSlice.actions;

export function reloadSnippets(dispatch: Dispatch) {
  dispatch(setLoading(true));
  getServicesManager().getSnippets().then((snippets) => {
    if (snippets != null) {
      dispatch(setSnippets(snippets.sort((a, b)=> {
        // non global, user snippets first
        if (a.is_global !== b.is_global) {
          return a.is_global ? 1 : -1;
        }
        const a_is_team = a.team_id != null;
        const a_is_user = !a_is_team && a.user_id != null;
        const b_is_team = b.team_id != null;
        const b_is_user = !b_is_team && b.user_id != null;
        if (a_is_team !== b_is_team) {
          return a_is_team ? 1 : -1;
        }
        if (a_is_user !== b_is_user) {
          return a_is_user ? -1 : 1;
        }
        return 0
      })));
    }
  });
}

// Helper function to process snippet text with variables
export const processSnippetText = (text: string, firstName: string): string => {
  return text.replace(/\{\{\s*name\s*\}\}/g, firstName);
};
