import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export const NotSignedInView: React.FC = () => (
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    gap: 2,
    height: '100%'
  }}>
    <Typography variant="h6">Not signed in to LinkedIn</Typography>
    <Typography color="text.secondary">Please sign in to LinkedIn and refresh this page</Typography>
    <Button 
      variant="contained" 
      href="https://linkedin.com" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Open LinkedIn
    </Button>
  </Box>
); 