import { convertConversationFromReduxSafe } from "./chat-slice";

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConversationId, ERROR_MESSAGE, SOURCE_TYPE } from "../types";
import { RootState } from "store";
import { convertConversationToReduxSafe, updateConversation } from "./chat-slice";
import { convertSalesConversationFromReduxSafe, convertSalesConversationToReduxSafe, updateSalesConversation } from "./sales-nav-slice";
import { setAnalyzing } from "./analyze-slice";
import { setAnalyzed } from "./analyze-slice";

import { useLinkedInMetadata } from "../metadata/hook";
import { sleep } from "core";
import { SEC_TO_MS } from "cfg/const";
export const useLinkedInConversationData = () => {
  
  const dispatch = useDispatch();
  const linkedinChatConversations = useSelector((state: RootState) => state.linkedinChatConversations);
  const linkedinSalesNavigatorConversations = useSelector((state: RootState) => state.linkedinSalesNavigatorConversations);
  const { client, salesNavigatorClient } = useSelector((state: RootState) => state.linkedinClients);
  const { markReadState } = useLinkedInMetadata()

  const getConversationMetadataForId = useCallback((conversationId: ConversationId) => {
    if (!conversationId) return null;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) return linkedinChatConversations.conversations[conversationId.id]?.conversation;
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return linkedinSalesNavigatorConversations.conversations[conversationId.id]?.conversation;
    return null;
  }, [linkedinChatConversations, linkedinSalesNavigatorConversations]);

  const getConversationLoadedForId = useCallback((conversationId: ConversationId) => {
    if (!conversationId) return false;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) return linkedinChatConversations.conversationsLoaded.has(conversationId.id);
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return linkedinSalesNavigatorConversations.conversationsLoaded.has(conversationId.id);
    return false;
  }, [linkedinChatConversations, linkedinSalesNavigatorConversations]);

  const getConversationDataForId = useCallback((conversationId: ConversationId) => {
    if (!conversationId) return null;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) return linkedinChatConversations.conversationsLoaded.has(conversationId.id) ? linkedinChatConversations.conversations[conversationId.id].conversation : null;
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return linkedinSalesNavigatorConversations.conversationsLoaded.has(conversationId.id) ? linkedinSalesNavigatorConversations.conversations[conversationId.id].conversation : null;
    return null;
  }, [linkedinChatConversations, linkedinSalesNavigatorConversations]);

  const getConversationLoadingForId = useCallback((conversationId: ConversationId) => {  
    if (!conversationId) return false;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) return linkedinChatConversations.conversations[conversationId.id]?.isLoading;
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return linkedinSalesNavigatorConversations.conversations[conversationId.id]?.isLoading;
    return false;
  }, [linkedinChatConversations, linkedinSalesNavigatorConversations]);

  const doesConversationIdHaveAdditionalMessages = useCallback((conversationId: ConversationId) => {
    if (!conversationId) return false;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) return linkedinChatConversations.conversations[conversationId.id]?.loadOlderMessagesPrevCursor !== null && !linkedinChatConversations.conversations[conversationId.id]?.error;
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return linkedinSalesNavigatorConversations.conversations[conversationId.id]?.conversation?.nextPageStartsAt !== null && !linkedinSalesNavigatorConversations.conversations[conversationId.id]?.error;
    return false;
  }, [linkedinChatConversations, linkedinSalesNavigatorConversations]);

  const onError = useCallback((conversationId: ConversationId) => {
    // mark analyzed true and analyzing as false
    dispatch(setAnalyzed({ conversationId: conversationId.id, isAnalyzed: true }));
    dispatch(setAnalyzing({ conversationId: conversationId.id, isAnalyzing: false }));
  }, [dispatch]);

  const fetchLinkedInConversationWithoutMatching = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!client) return;
    if (!selectedConversationInfo) return;
    if (linkedinChatConversations.conversationsLoaded.has(selectedConversationInfo.id)) return
    const result = await client.getConversationById(selectedConversationInfo.id)
    if (!result) {
      // update that with an error... 
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          conversation: null,
          error: ERROR_MESSAGE.NO_CONVERSATION_FOUND
        }
      }))
      onError(selectedConversationInfo);
      return;
    }
    const conversastionWithMessages = await client.loadOlderMessages(result)
    dispatch(updateConversation({
      entityUrn: selectedConversationInfo.id,
      update: {
        conversation: convertConversationToReduxSafe(conversastionWithMessages),
        loadOlderMessagesPrevCursor: conversastionWithMessages._loadOlderMessagesPrevCursor,
        error: null,
      }
    }))
  }, [client, dispatch, linkedinChatConversations, linkedinSalesNavigatorConversations]);

  const fetchLinkedInConversationWithMatching = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!client) return;
    if (!selectedConversationInfo) return;
    const currentData = linkedinChatConversations.conversations[selectedConversationInfo.id]
    if (!currentData || !currentData.conversation) return;
    const result = await client.loadOlderMessages(convertConversationFromReduxSafe(currentData.conversation))
    if (!result) {
      // update that with an error... 
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          loadOlderMessagesPrevCursor: null,
          error: ERROR_MESSAGE.NO_CONVERSATION_FOUND
        }
      }))
      onError(selectedConversationInfo);
      return;
    }
    else {
      const update = {
        conversation: convertConversationToReduxSafe(result),
        loadOlderMessagesPrevCursor: result._loadOlderMessagesPrevCursor,
        error: null,
      };
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update
      }))
    }
  }, [client, dispatch, linkedinChatConversations]);

  const fetchLinkedInChatConversationOlderMessages = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!client) return;
    if (!selectedConversationInfo || selectedConversationInfo.sourceType !== SOURCE_TYPE.CHAT) return;
    const currentData = linkedinChatConversations.conversations[selectedConversationInfo.id]
    if (currentData && (currentData.isLoading || currentData.error)) return
    try {
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          isLoading: true,
          error: null
        }
      }))
      if (!currentData || !currentData.conversation) await fetchLinkedInConversationWithoutMatching(selectedConversationInfo);
      else await fetchLinkedInConversationWithMatching(selectedConversationInfo);
    } catch (error) {
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          error: ERROR_MESSAGE.NO_CONVERSATION_FOUND
        }
      }))
      onError(selectedConversationInfo);
    }
    finally {
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          isLoading: false,
        }
      }))
    }
  }, [client, dispatch, linkedinChatConversations]);

  const fetchLinkedInChatConversationNewerMessages = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!client) return;
    if (!selectedConversationInfo) return;
    const currentData = linkedinChatConversations.conversations[selectedConversationInfo.id]
    if (!currentData || !currentData.conversation) return;
    if (currentData && (currentData.isCheckingForNewMessages || currentData.error)) return
    try {
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          isCheckingForNewMessages: true,
          error: null
        }
      }))
      const result = await client.loadNewerMessages(convertConversationFromReduxSafe(currentData.conversation))
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          conversation: convertConversationToReduxSafe(result),
          loadNewerMessagesSyncToken: result._loadNewerMessagesSyncToken,
          error: null,
        }
      }))
      if (result && !result.read) { markReadState(selectedConversationInfo, true) }
    } catch (error) {
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          isCheckingForNewMessages: false,
        }
      }))
    }
    finally {
      dispatch(updateConversation({
        entityUrn: selectedConversationInfo.id,
        update: {
          isCheckingForNewMessages: false,
        }
      }))
    }
  }, [client, dispatch, linkedinChatConversations]);

  const fetchLinkedInSalesNavigatorConversationWithoutMatching = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!salesNavigatorClient || !salesNavigatorClient.hasAccess) return;
    if (!selectedConversationInfo) return;
    if (linkedinSalesNavigatorConversations.conversationsLoaded.has(selectedConversationInfo.id)) return
    const result = await salesNavigatorClient.getConversationById(selectedConversationInfo.id)
    if (!result) {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          conversation: null,
          error: ERROR_MESSAGE.NO_CONVERSATION_FOUND
        }
      }))
      onError(selectedConversationInfo);
      return;
    }
    else {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          conversation: convertSalesConversationToReduxSafe(result),
          isLoading: false,
          error: null,
        }
      }))
    }
  }, [salesNavigatorClient, dispatch, linkedinSalesNavigatorConversations]);

  const fetchLinkedInSalesNavigatorConversationWithMatching = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!salesNavigatorClient) return;
    const currentData = linkedinSalesNavigatorConversations.conversations[selectedConversationInfo.id]
    if (!currentData || !currentData.conversation) return;
    const result = await salesNavigatorClient.loadOlderMessages(convertSalesConversationFromReduxSafe(currentData.conversation))
    if (!result) {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          conversation: null,
          error: ERROR_MESSAGE.NO_CONVERSATION_FOUND
        }
      }))
    } else {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          conversation: convertSalesConversationToReduxSafe(result),
          isLoading: false,
          error: null,
        }
      }))
    }
  }, [salesNavigatorClient, dispatch, linkedinSalesNavigatorConversations]);

  const fetchLinkedInSalesNavigatorConversationOlderMessages = useCallback(async (selectedConversationInfo: ConversationId) => {
    if (!salesNavigatorClient) return;
    if (!selectedConversationInfo) return;
    const currentData = linkedinSalesNavigatorConversations.conversations[selectedConversationInfo.id]
    if (currentData && (currentData.isLoading || currentData.error)) return
    try {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          isLoading: true,
          error: null
        }
      }))
      if (!currentData || !currentData.conversation) await fetchLinkedInSalesNavigatorConversationWithoutMatching(selectedConversationInfo);
      else await fetchLinkedInSalesNavigatorConversationWithMatching(selectedConversationInfo);
    } catch (error) {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          error: ERROR_MESSAGE.NO_CONVERSATION_FOUND
        }
      }))
    }
    finally {
      dispatch(updateSalesConversation({
        id: selectedConversationInfo.id,
        update: {
          isLoading: false,
        }
      }))
    }
  }, [salesNavigatorClient, fetchLinkedInSalesNavigatorConversationWithMatching, fetchLinkedInSalesNavigatorConversationWithoutMatching]);

  const fetchLinkedInSalesNavigatorConversationNewerMessages = useCallback(async (selectedConversationInfo: ConversationId) => {
    // we use polling for sales navigator updates - TODO
  }, [salesNavigatorClient, dispatch, linkedinSalesNavigatorConversations]);

  const fetchSpecificLinkedInConversation = useCallback(async (conversationId: ConversationId) => {
    if (!conversationId) return null;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) await fetchLinkedInChatConversationOlderMessages(conversationId);
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) await fetchLinkedInSalesNavigatorConversationOlderMessages(conversationId);
  }, [fetchLinkedInChatConversationOlderMessages, fetchLinkedInSalesNavigatorConversationOlderMessages]);

  const fetchMultipleLinkedInConversations = useCallback(async (conversationIds: ConversationId[]) => {
    if (!client) return;
    // Process in larger batches (20) for better performance
    const BATCH_SIZE = 15;
    const NUMBER_OF_BATCHES = Math.ceil(conversationIds.length / BATCH_SIZE);
    for (let i = 0; i < NUMBER_OF_BATCHES; i++) {
      const batch = conversationIds.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);
      try {
        await Promise.all(batch.map(async (conversationId) => {
          if (conversationId.sourceType === SOURCE_TYPE.CHAT) await fetchLinkedInChatConversationOlderMessages(conversationId);
          if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) {
            await fetchLinkedInSalesNavigatorConversationOlderMessages(conversationId);
          }
        }));
        await sleep(5*SEC_TO_MS)
      } catch (error) {
        console.error('Error fetching batch:', error);
      }
    }
  }, [client, dispatch, fetchLinkedInChatConversationOlderMessages, fetchLinkedInSalesNavigatorConversationOlderMessages]);

  return {
    getConversationMetadataForId,
    getConversationLoadedForId,
    getConversationDataForId,
    getConversationLoadingForId,
    doesConversationIdHaveAdditionalMessages,
    fetchSpecificLinkedInConversation,
    fetchMultipleLinkedInConversations,
  }
}