import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { X, ExternalLink } from 'lucide-react';
import { Archive as ArchiveIcon, Unarchive as UnarchiveIcon } from '@mui/icons-material';
import { useLinkedInNavigate } from '../../../../lib/redux/linkedin/navigate/hook';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { TagButton } from '../../Modals/TagModal';
import { SnoozeButton } from '../../Modals/SnoozeModal';
import { SOURCE_TYPE } from 'lib/redux/linkedin/types';
import { updateArchived } from 'lib/redux/linkedin/metadata/slice';
import { getUrlSalesNavigator } from 'lib/redux/linkedin/helpers';

interface ConversationHeaderToolbarProps {
  isDraft?: boolean;
  profileUrl?: string;
}

export const ConversationHeaderToolbar: React.FC<ConversationHeaderToolbarProps> = React.memo(({ isDraft, profileUrl }) => {
  const { toConversation, closeDraft } = useLinkedInNavigate();
  const dispatch = useDispatch();
  const activeOption = useSelector((state: RootState) => state.linkedinSelected.activeOption);
  const selectedConversation = useSelector((state: RootState) => state.linkedinSelected.conversationId);
  const conversationState = useSelector((state: RootState) => 
    selectedConversation ? state.linkedinConversationStates.states[selectedConversation.id] : null
  );
  const isArchived = conversationState?.isArchived || false;

  const handleArchiveToggle = () => {
    if (!selectedConversation) return;
    dispatch(updateArchived({ 
      conversationId: selectedConversation,
      isArchived: !isArchived
    }));
  };

  const handleClose = () => {
    if (isDraft) { if (activeOption) closeDraft(activeOption) }
    else { toConversation(null) }
  };


 

  const handlePopout = () => {
    if (profileUrl) {
      window.open(profileUrl, '_blank');
      return;
    }
    if (!selectedConversation?.id) return;
    const url = getUrlSalesNavigator(selectedConversation);
    if (!url) return;
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {!isDraft && selectedConversation && (
        <>
          <Tooltip title="Open in LinkedIn" placement="bottom">
            <IconButton onClick={handlePopout} size="small">
              <ExternalLink size={20} />
            </IconButton>
          </Tooltip>
          <TagButton conversations={[selectedConversation]} size="small" />
          <SnoozeButton conversations={[selectedConversation]} size="small" />
          <Tooltip title={isArchived ? "Unarchive" : "Archive"} placement="bottom">
            <IconButton onClick={handleArchiveToggle} size="small">
              {isArchived ? <UnarchiveIcon sx={{ fontSize: 20 }} /> : <ArchiveIcon sx={{ fontSize: 20 }} />}
            </IconButton>
          </Tooltip>
        </>
      )}
      <IconButton onClick={handleClose} size="small">
        <X size={20} />
      </IconButton>
    </Box>
  );
}); 