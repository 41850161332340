import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { clearSearchResults, setSearchLoading, setSearchResults, setSearchError, clearAllSearchResults, setRecentConnections, convertPartcipantsToId, convertParticipantUrnsToId, updateMatchingConversationInfo, setRecentJobChanges, addRecentConnection, addRecentJobChange, setLoadingJobChanges, setLoadingConnections } from "./slice";
import { SearchResults } from "./slice";
import { LinkedInConversationParticipant, LinkedInFriendUpdateType } from "interfaces/linkedin";
import { SOURCE_TYPE } from "../types";

export const selectSearchResults = (state: RootState, query: string): SearchResults | undefined => state.linkedinSearchResults.results[query];

// Search hook
export function useLinkedInSearch() {
    const dispatch = useDispatch();
    const { client, salesNavigatorClient } = useSelector((state: RootState) => state.linkedinClients);
    const participants = useSelector((state: RootState) => state.linkedinSearchResults.participants);
    const currentJobChanges = useSelector((state: RootState) => state.linkedinSearchResults.recentJobChanges);
    const currentConnections = useSelector((state: RootState) => state.linkedinSearchResults.recentConnections);

    const getSalesNavigatorConversations = useCallback(async (query: string) => {
      if (!salesNavigatorClient || !salesNavigatorClient.hasAccess) return null;
      return await salesNavigatorClient.searchConversations(query);
    }, [salesNavigatorClient]);
  
    const performSearch = useCallback(async (query: string) => {
      if (!query.trim()) {
        dispatch(clearSearchResults(query));
        return;
      }

      // Only set loading if we don't already have results for this query
      dispatch(setSearchLoading({ query, isLoading: true }));
  
      try {
        const [conversationsResult, peopleResult, salesNavigatorResult] = await Promise.all([
          client?.searchConversations(query),
          client?.searchRecipients(query),
          getSalesNavigatorConversations(query)
        ]);
  
        // Batch our updates to prevent multiple rerenders
        dispatch(setSearchResults({
          query,
          results: {
            conversations: conversationsResult || { conversations: [], nextCursor: null, continues: false, mailboxName: null },
            salesNavigatorConversations: salesNavigatorResult || { conversations: [], nextPageStartsAt: new Date(), continues: false, filterName: 'INBOX' },
            people: peopleResult || [],
            isLoading: false,
            error: null
          }
        }));
      } catch (error) {
        console.error('Search failed:', error);
        dispatch(setSearchError({
          query,
          error: error instanceof Error ? error.message : 'Search failed'
        }));
      }
    }, [dispatch, client]);
  
    const clearSearch = useCallback((query: string) => {
      dispatch(clearSearchResults(query));
    }, [dispatch]);
  
    const clearAllSearches = useCallback(() => {
      dispatch(clearAllSearchResults());
    }, [dispatch]);

    const getRecentConnections = useCallback(async (clear: boolean = false) => {
      if (!client) return;
      dispatch(setLoadingConnections(true));
      try {
        const numberOfConnections = clear ? 0 : (currentConnections?.length ?? 0);
        const connections = await client.getRecentConnections(numberOfConnections ? numberOfConnections : undefined);
        if (clear) {
          dispatch(setRecentConnections(connections));
        } else {
          dispatch(addRecentConnection(connections));
        }
      } catch (error) {
        console.error('Get recent connections failed:', error);
      } finally {
        dispatch(setLoadingConnections(false));
      }
    }, [dispatch, client, currentConnections]);

    const getRecentJobChanges = useCallback(async (clear: boolean = false) => {
      if (!client) return;
      dispatch(setLoadingJobChanges(true));
      try {
        const numberOfJobChanges = clear ? 0 : (currentJobChanges?.length ?? 0);
        const connections = await client.getRecentFriendUpdates(numberOfJobChanges ? numberOfJobChanges : undefined, LinkedInFriendUpdateType.JOB_CHANGE);
        if (clear) {
          dispatch(setRecentJobChanges(connections));
        } else {
          dispatch(addRecentJobChange(connections));
        }
      } catch (error) {
        console.error('Get recent job changes failed:', error);
      } finally {
        dispatch(setLoadingJobChanges(false));
      }
    }, [dispatch, client, currentJobChanges]);

    const getMatchingParticipantsViaId = useCallback((participantsId: string) => {
      return participants[participantsId];
    }, [participants]);

    const getMatchingLinkedInParticipants = useCallback((participants: LinkedInConversationParticipant[]) => {
      const participantsId = convertPartcipantsToId(participants);
      return getMatchingParticipantsViaId(participantsId);
    }, [getMatchingParticipantsViaId]);

    const getMatchingParticipants = useCallback((participantUrns: string[]) => {
      const participantsId = convertParticipantUrnsToId(participantUrns);
      return getMatchingParticipantsViaId(participantsId);
    }, [getMatchingParticipantsViaId]);

    const fetchMatchingParticipants = useCallback(async (participantUrns: string[]) => {
      if (!client) return;
      if (participantUrns.length === 0) return;
      const currentInfo = getMatchingParticipants(participantUrns);
      if (currentInfo && (currentInfo.isLoading || currentInfo.noMatchingConversation || currentInfo.matchingConversation)) return;
      dispatch(updateMatchingConversationInfo({
        participants: participantUrns,
        update: { isLoading: true }
      }));
      try {
        const matchingConversation = await client.getConversationsByRecipients(participantUrns);
        dispatch(updateMatchingConversationInfo({
          participants: participantUrns,
        update: { 
          isLoading: false,
          noMatchingConversation: matchingConversation.length === 0,
          matchingConversation: matchingConversation.length > 0 ? {
            id: matchingConversation[0].entityUrn,
            sourceType: SOURCE_TYPE.CHAT,
            lastActivity: (matchingConversation[0].lastActivityAt || matchingConversation[0].createdAt).getTime()
          } : null
        }
      }));
    } catch (error) {
      console.error('Fetch matching participants failed:', error);
      dispatch(updateMatchingConversationInfo({
        participants: participantUrns,
        update: { isLoading: false, noMatchingConversation: true, matchingConversation: null }
      }));
    }
    }, [dispatch, client, getMatchingParticipants]);

    return {
      performSearch,
      clearSearch,
      clearAllSearches,
      getRecentConnections,
      getRecentJobChanges,
      getMatchingParticipants,
      getMatchingLinkedInParticipants,
      getMatchingParticipantsViaId,
      fetchMatchingParticipants,
      getSearchResults: useCallback(
        (state: RootState, query: string) => selectSearchResults(state, query),
        []
      )
    };
  }
  
  