import React from "react";
import { MULTIDIALER_HOSTNAME, SHAKEN_STIR } from "cfg/endpoints";
import { simplePost } from "network";
import { getServicesManager } from "services";
import { logInfo } from "core";
import { Loader } from "components/Loader";
import { X } from "lucide-react";

const _LOG_SCOPE = '[Trellus][SHAKEN/STIR]'

type FormTextRow = {
    name: string
    display_name: string
    value: string | undefined
    onUpdate: (newValue: string) => any
    isValid?: () => any
}

type FormSelectRow = {
    name: string
    display_name: string
    value: string | undefined
    onUpdate: (newValue: string) => any
    choices: [string, string][]
    isValid?: () => any
}

type FormRow = FormTextRow | FormSelectRow

interface Props {
    apiKey: string | null
    teamName: string | null
    teamId: string | null
    onSuccess?: () => void
    onFail?: () => void
    onCancel?: () => void
}

type State = {
    loading: boolean
    submissionInProgress: boolean
    submissionReceived: boolean
    showAlert?: boolean

    inputTeamId?: string
    inputBusinessName?: string
    inputStreetAddress?: string
    inputCity?: string
    inputStateOrRegion?: string
    inputPostalCode?: string
    inputIsoCountry?: string
    inputBusinessType?: string
    inputBusinessIndustry?: string
    inputBusinessRegistrationAuthority?: string
    inputBusinessRegistrationNumber?: string
    inputBusinessRegionsOfOperation?: string
    inputWebsiteUrl?: string
    inputRepresentativeFirstName?: string
    inputRepresentativeLastName?: string
    inputRepresentativeEmail?: string
    inputRepresentativePhoneNumber?: string
    inputRepresentativeBusinessTitle?: string
    inputRepresentativeJobPosition?: string
}

function isAsciiAlpha(s: string): boolean {
    return !!s.match(/^[A-Za-z]*$/)
}

export class ShakenStirRegistration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            submissionInProgress: false,
            submissionReceived: false,
            inputBusinessType: "Corporation",
            inputBusinessIndustry: "AUTOMOTIVE",
            inputBusinessRegistrationAuthority: "EIN",
            inputBusinessRegionsOfOperation: "USA_AND_CANADA",
            inputRepresentativeJobPosition: "Director",
        }
    }

    componentDidMount(): void {
        if (this.props.apiKey !== null) {
            if (this.props.teamId === null) {
                this.setState({loading: false})
            } else {
                this._reloadTeamData().finally(() => this.setState({loading: false}))
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // eek maybe we should check submissionInProgress before doing any setState
        if (this.props.apiKey !== null && (this.props.apiKey !== prevProps.apiKey || this.props.teamId !== prevProps.teamId)) {
            if (this.props.teamId === null) {
                this.setState({loading: false, submissionInProgress: false, submissionReceived: false})
            } else {
                this.setState({loading: true, submissionInProgress: false, submissionReceived: false})
                this._reloadTeamData().finally(() => this.setState({loading: false}))
            }
        }
        if (this.state.submissionReceived && this.state.submissionReceived !== prevState.submissionReceived) {
            this.props.onSuccess?.()
        }
    }

    async _reloadTeamData() {
        // require api key and team id to load
        if (this.props.apiKey === null || this.props.teamId === null) { return }
        // default business name to team name
        if (this.props.teamName) { this.setState({inputBusinessName: this.props.teamName}) }
        // fetch data from server
        const previousData = await getServicesManager().getCustomerProfileData(this.props.teamId)
        if (previousData === null) { return }
        // if successful, fill all form fields
        this.setState({
            inputBusinessName: previousData.business_info?.attributes.business_name,
            inputBusinessType: previousData.business_info?.attributes.business_type,
            inputBusinessIndustry: previousData.business_info?.attributes.business_industry,
            inputBusinessRegistrationAuthority: previousData.business_info?.attributes.business_registration_identifier,
            inputBusinessRegistrationNumber: previousData.business_info?.attributes.business_registration_number,
            inputBusinessRegionsOfOperation: previousData.business_info?.attributes.business_regions_of_operation,
            inputWebsiteUrl: previousData.business_info?.attributes.website_url,
            inputStreetAddress: previousData.address?.street,
            inputCity: previousData.address?.city,
            inputStateOrRegion: previousData.address?.region ?? "",
            inputPostalCode: previousData.address?.postal_code,
            inputIsoCountry: previousData.address?.iso_country,
            inputRepresentativeFirstName: previousData.authorized_representative?.attributes.first_name,
            inputRepresentativeLastName: previousData.authorized_representative?.attributes.last_name,
            inputRepresentativeEmail: previousData.authorized_representative?.attributes.email,
            inputRepresentativePhoneNumber: previousData.authorized_representative?.attributes.phone_number,
            inputRepresentativeBusinessTitle: previousData.authorized_representative?.attributes.business_title,
            inputRepresentativeJobPosition: previousData.authorized_representative?.attributes.job_position,
        })
    }

    _renderTextField(item: FormRow) {
        const isValid = (item.value ?? "") === "" ? true  // blank counts as valid so we don't start in a sea of red
                        : item.isValid ? item.isValid()
                        : true
        return (
            <div key={item.name} className="mb-4">
                <label 
                    htmlFor={`stir_shaken_${item.name}`} 
                    className="block text-sm font-medium text-gray-700 mb-2"
                >
                    {item.display_name} <span className="text-red-500">*</span>
                </label>
                <input 
                    id={`stir_shaken_${item.name}`} 
                    type="text" 
                    value={item.value} 
                    onChange={e => item.onUpdate(e.target.value)}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${isValid ? "" : "bg-red-200"}`}
                />
            </div>
        );
    }

    _renderSelectField(item: FormSelectRow) {
        return (
            <div key={item.name} className="mb-4">
                <label 
                    htmlFor={`stir_shaken_${item.name}`} 
                    className="block text-sm font-medium text-gray-700 mb-2"
                >
                    {item.display_name} <span className="text-red-500">*</span>
                </label>
                <select 
                    id={`stir_shaken_${item.name}`} 
                    value={item.value} 
                    onChange={e => item.onUpdate(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                >
                    {item.choices.map(([name, display_name]) => (
                        <option value={name} key={name}>{display_name}</option>
                    ))}
                </select>
            </div>
        );
    }

    _canSubmit(rows: FormRow[]): boolean {
        if (rows.some(x => (x.value ?? "") === "")) { return false }
        if (rows.some(x => x.isValid?.() === false)) { return false }
        return true
    }

    render() {
        if (this.state.loading) { 
            return (
                <div className="flex justify-center items-center h-full">
                    <Loader />
                </div>
            );
        }

        const bizFields: FormRow[] = [
            {
                name: "business_name",
                display_name: "Business Name",
                value: this.state.inputBusinessName,
                onUpdate: x => this.setState({inputBusinessName: x}),
            },
            {
                name: "business_type",
                display_name: "Type",
                value: this.state.inputBusinessType,
                onUpdate: x => this.setState({inputBusinessType: x}),
                choices: [
                    "Sole Proprietorship",
                    "Partnership",
                    "Limited Liability Corporation",
                    "Corporation",
                    "Co-operative",
                    "Non-profit",
                ].map(x => [x, x]),
            },
            {
                name: "business_industry",
                display_name: "Industry",
                value: this.state.inputBusinessIndustry,
                onUpdate: x => this.setState({inputBusinessIndustry: x}),
                choices: [
                    "Automotive",
                    "Agriculture",
                    "Banking",
                    "Consumer",
                    "Education",
                    "Engineering",
                    "Energy",
                    "Oil and Gas",
                    "Fast-moving Consumer Goods",
                    "Financial",
                    "Fintech",
                    "Food and Beverage",
                    "Government",
                    "Healthcare",
                    "Hospitality",
                    "Insurance",
                    "Legal",
                    "Manufacturing",
                    "Media",
                    "Online",
                    "Raw Materials",
                    "Real Estate",
                    "Religion",
                    "Retail",
                    "Jewelry",
                    "Technology",
                    "Telecommunications",
                    "Transportation",
                    "Travel",
                    "Electronics",
                    "Not-for-profit",
                ].map(x => [x.toUpperCase().replaceAll(/[^A-Z0-9]/g, "_"), x]),
            },
            {
                name: "business_registration_authority",
                display_name: "Business Registration Authority",
                value: this.state.inputBusinessRegistrationAuthority,
                onUpdate: x => this.setState({inputBusinessRegistrationAuthority: x}),
                choices: [
                    ["EIN", "USA Employer Identification Number (EIN)"],
                    ["DUNS", "Dun & Bradstreet (DUNS)"],
                    ["CCN", "Canadian Corporation Number (CCN)"]
                ],
            },
            {
                name: "business_registration_number",
                display_name: "Business Registration Number",
                value: this.state.inputBusinessRegistrationNumber,
                onUpdate: x => this.setState({inputBusinessRegistrationNumber: x}),
            },
            {
                name: "business_regions_of_operation",
                display_name: "Regions of Operation",
                value: this.state.inputBusinessRegionsOfOperation,
                onUpdate: x => this.setState({inputBusinessRegionsOfOperation: x}),
                choices: [
                    ['USA_AND_CANADA', 'USA and Canada'],
                    ['AFRICA', 'Africa'],
                    ['ASIA', 'Asia'],
                    ['EUROPE', 'Europe'],
                    ['LATIN_AMERICA', 'Latin America'],
                ],
            },
            {
                name: "website_url",
                display_name: "Website URL",
                value: this.state.inputWebsiteUrl,
                onUpdate: x => this.setState({inputWebsiteUrl: x}),
            },
        ]
        const addressFields: FormRow[] = [
            {name: "street_address", display_name: "Street Address",
             value: this.state.inputStreetAddress, onUpdate: x => this.setState({inputStreetAddress: x})},
            {name: "city", display_name: "City",
             value: this.state.inputCity, onUpdate: x => this.setState({inputCity: x})},
            {name: "state_or_region", display_name: "2-letter State or Region",
             value: this.state.inputStateOrRegion, onUpdate: x => this.setState({inputStateOrRegion: x.toLocaleUpperCase()}),
             isValid: () => this.state.inputStateOrRegion?.length === 2 && isAsciiAlpha(this.state.inputStateOrRegion)},
            {name: "postal_code", display_name: "Postal Code",
             value: this.state.inputPostalCode, onUpdate: x => this.setState({inputPostalCode: x})},
            {name: "iso_country", display_name: "2-letter Country Code",
             value: this.state.inputIsoCountry, onUpdate: x => this.setState({inputIsoCountry: x.toLocaleUpperCase()}),
             isValid: () => this.state.inputIsoCountry?.length === 2 && isAsciiAlpha(this.state.inputIsoCountry)},
        ]
        const repFields: FormRow[] = [
            {name: "representative_first_name", display_name: "First Name",
             value: this.state.inputRepresentativeFirstName, onUpdate: x => this.setState({inputRepresentativeFirstName: x})},
            {name: "representative_last_name", display_name: "Last Name",
             value: this.state.inputRepresentativeLastName, onUpdate: x => this.setState({inputRepresentativeLastName: x})},
            {name: "representative_email", display_name: "E-mail Address",
             value: this.state.inputRepresentativeEmail, onUpdate: x => this.setState({inputRepresentativeEmail: x})},
            {name: "representative_phone_number", display_name: "Phone Number",
             value: this.state.inputRepresentativePhoneNumber, onUpdate: x => this.setState({inputRepresentativePhoneNumber: x})},
            {name: "representative_business_title", display_name: "Job Title",
             value: this.state.inputRepresentativeBusinessTitle, onUpdate: x => this.setState({inputRepresentativeBusinessTitle: x})},
            {
                name: "representative_job_position",
                display_name: "Job Level",
                value: this.state.inputRepresentativeJobPosition,
                onUpdate: x => this.setState({inputRepresentativeJobPosition: x}),
                choices: [
                    ["Director", "Director"],
                    ["GM", "GM"],
                    ["VP", "VP"],
                    ["CEO", "CEO"],
                    ["CFO", "CFO"],
                    ["General Counsel", "General Counsel"],
                ],
            },
        ]
        const allFields: FormRow[] = [...bizFields, ...addressFields, ...repFields]
        const canSubmit = this._canSubmit(allFields)
        const submitDisabled = this.state.submissionInProgress || this.state.submissionReceived || !canSubmit
        
        return (
            <div className="w-full h-full flex flex-col">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-semibold">STIR/SHAKEN Registration for {this.props.teamName}</h2>
                    <button 
                        onClick={() => this.props.onCancel?.()} 
                        className="text-gray-500 hover:text-gray-700"
                        aria-label="Close"
                    >
                        <X size={20} />
                    </button>
                </div>
                
                <div className="flex-1 overflow-y-auto p-4">
                    <div className="mb-6">
                        <h3 className="text-base font-medium text-gray-700 mb-4">Company Information</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                            {bizFields.map(field => 
                                'choices' in field 
                                    ? this._renderSelectField(field as FormSelectRow)
                                    : this._renderTextField(field)
                            )}
                        </div>
                    </div>

                    <div className="mb-6">
                        <h3 className="text-base font-medium text-gray-700 mb-4">Postal Address</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                            {addressFields.map(field => 
                                'choices' in field 
                                    ? this._renderSelectField(field as FormSelectRow)
                                    : this._renderTextField(field)
                            )}
                        </div>
                    </div>

                    <div className="mb-6">
                        <h3 className="text-base font-medium text-gray-700 mb-4">Authorized Representative</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                            {repFields.map(field => 
                                'choices' in field 
                                    ? this._renderSelectField(field as FormSelectRow)
                                    : this._renderTextField(field)
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center p-4 border-t bg-gray-50">
                    <div className="text-sm text-gray-500">
                        All fields are required
                    </div>
                    <div className="flex space-x-3">
                        <button
                            onClick={() => this.props.onCancel?.()}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            disabled={submitDisabled}
                            className={`px-4 py-2 rounded-md text-white font-medium ${
                                submitDisabled 
                                    ? "bg-gray-300 cursor-not-allowed" 
                                    : "bg-blue-600 hover:bg-blue-700"
                            }`}
                            onClick={async () => {
                                if (!canSubmit) {
                                    this.setState({'showAlert': true})
                                    setTimeout(() => this.setState({'showAlert': false}), 5000)
                                    return
                                }

                                const payload = Object.fromEntries(allFields.map(x => [x.name, x.value ?? ""]))
                                payload['team_id'] = this.props.teamId ?? ""
                                
                                this.setState({submissionInProgress: true, submissionReceived: false})
                                try {
                                    // Use the services manager to submit the form
                                    if (this.props.apiKey) {
                                        const result = await getServicesManager().stirShakenManager.submitStirShakenRegistration(
                                            this.props.apiKey,
                                            payload
                                        )
                                        
                                        if (result) {
                                            // Set submission received
                                            this.setState({submissionReceived: true})
                                            
                                            // Poll for updated status after 2 seconds
                                            setTimeout(async () => {
                                                if (this.props.teamId) {
                                                    await this._reloadTeamData()
                                                }
                                            }, 2000)
                                        }
                                    }
                                } catch (e) {
                                    logInfo(`${_LOG_SCOPE} unexpected error submitting profile`, e)
                                } finally {
                                    this.setState({submissionInProgress: false})
                                }
                            }}
                        >
                            {this.state.submissionInProgress ? (
                                <div className="flex items-center justify-center">
                                    <div className="w-5 h-5 mr-2">
                                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                                    </div>
                                    <span>Submitting...</span>
                                </div>
                            ) : this.state.submissionReceived ? (
                                <div className="flex items-center justify-center">
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    </svg>
                                    <span>Submitted</span>
                                </div>
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
