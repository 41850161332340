import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button,
  Box,
  Typography,
  IconButton,
  Divider,
  Tooltip,
  TextField,
  styled
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AccessTime as TimeIcon, Clear as ClearIcon, Snooze as SnoozeIcon } from '@mui/icons-material';
import { RootState } from 'store';
import { updateSnooze } from 'lib/redux/linkedin/metadata/slice';
import { ConversationId } from 'lib/redux/linkedin/types';

interface SnoozeButtonProps {
  conversations: ConversationId[];
  size?: 'small' | 'medium';
  sx?: any;
}

export const SnoozeButton: React.FC<SnoozeButtonProps> = ({ conversations, size = 'medium', sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconSize = size === 'small' ? 20 : 24;
  
  return (
    <>
      <Tooltip title="Snooze" placement="bottom">
        <IconButton 
          onClick={() => setIsOpen(true)} 
          size={size}
          sx={{
            padding: size === 'small' ? '4px' : '8px',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              color: 'primary.main',
              transform: 'scale(1.05)',
            },
            '&:active': {
              transform: 'scale(0.95)',
            },
            ...sx
          }}
        >
          <SnoozeIcon sx={{ fontSize: iconSize }} />
        </IconButton>
      </Tooltip>
      <SnoozeModal 
        open={isOpen}
        onClose={() => setIsOpen(false)}
        conversations={conversations}
      />
    </>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.05)',
    backgroundImage: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
    maxWidth: '400px !important',
    margin: '16px',
    backdropFilter: 'blur(8px)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(4px)',
  }
}));

const QuickOption = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  transition: 'all 0.2s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.main,
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  textTransform: 'none',
  padding: '8px 24px',
  '&.primary': {
    backgroundImage: 'linear-gradient(to bottom right, #1976d2, #1565c0)',
    '&:hover': {
      filter: 'brightness(0.9)'
    }
  }
}));

const quickOptions = [
  { value: '1h', label: '1 hour' },
  { value: '3h', label: '3 hours' },
  { value: '1d', label: '1 day' },
  { value: '1w', label: '1 week' }
];

interface SnoozeModalProps {
  open: boolean;
  onClose: () => void;
  conversations: ConversationId[];
}

export const SnoozeModal: React.FC<SnoozeModalProps> = ({
  open,
  onClose,
  conversations
}) => {
  const dispatch = useDispatch();
  const conversationStates = useSelector((state: RootState) => state.linkedinConversationStates.states);
  
  // Check if any conversation has a snooze value
  const hasAnySnooze = conversations.some(conv => conversationStates[conv.id]?.snooze);
  const currentSnooze = conversations.length === 1 ? conversationStates[conversations[0].id]?.snooze : null;
  
  const [selectedOption, setSelectedOption] = useState<string>(currentSnooze ? 'custom' : '1h');
  const [customDate, setCustomDate] = useState<string>(
    currentSnooze ? new Date(currentSnooze).toISOString().slice(0, 16) : new Date().toISOString().slice(0, 16)
  );

  const getSnoozeDate = (option: string): Date => {
    if (option === 'custom') {
      return new Date(customDate);
    }
    
    const now = new Date();
    switch (option) {
      case '1h': return new Date(now.setHours(now.getHours() + 1));
      case '3h': return new Date(now.setHours(now.getHours() + 3));
      case '1d': return new Date(now.setDate(now.getDate() + 1));
      case '1w': return new Date(now.setDate(now.getDate() + 7));
      default: return now;
    }
  };

  const handleSnooze = () => {
    const snoozeDate = getSnoozeDate(selectedOption);
    conversations.forEach(conversation => {
      dispatch(updateSnooze({
        conversationId: conversation,
        snooze: snoozeDate.getTime()
      }));
    });
    onClose();
  };

  const handleClearSnooze = () => {
    conversations.forEach(conversation => {
      dispatch(updateSnooze({
        conversationId: conversation,
        snooze: null
      }));
    });
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        px: 2,
        py: 1.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TimeIcon color="primary" />
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Snooze {conversations.length > 1 ? 'Conversations' : 'Conversation'}
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <ClearIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ px: 2, py: 1 }}>
        {hasAnySnooze && (
          <Box sx={{ 
            mb: 2,
            p: 2,
            bgcolor: 'primary.light',
            borderRadius: 1,
            color: 'white'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="subtitle2">
                  {conversations.length === 1 ? 'Currently snoozed until:' : 'Some conversations are snoozed'}
                </Typography>
                {conversations.length === 1 && currentSnooze && (
                  <Typography variant="body1">
                    {new Date(currentSnooze).toLocaleString()}
                  </Typography>
                )}
              </Box>
              <Tooltip title="Clear snooze">
                <IconButton onClick={handleClearSnooze} size="small" sx={{ color: 'white' }}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}

        <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
          Quick Options
        </Typography>
        
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 1,
          mb: 2
        }}>
          {quickOptions.map(option => (
            <QuickOption
              key={option.value}
              onClick={() => setSelectedOption(option.value)}
              className={selectedOption === option.value ? 'selected' : ''}
            >
              {option.label}
            </QuickOption>
          ))}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
          Custom Date & Time
        </Typography>

        <Box 
          onClick={() => setSelectedOption('custom')}
          sx={{ 
            p: 2,
            border: '1px solid',
            borderColor: selectedOption === 'custom' ? 'primary.main' : 'divider',
            borderRadius: 1,
            cursor: 'pointer',
            transition: 'all 0.2s',
            '&:hover': {
              borderColor: 'primary.main',
            }
          }}
        >
          <TextField
            type="datetime-local"
            value={customDate}
            onChange={(e) => setCustomDate(e.target.value)}
            fullWidth
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                border: 'none',
                '& fieldset': {
                  border: 'none'
                }
              }
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        px: 2,
        py: 1.5,
        borderTop: '1px solid',
        borderColor: 'divider',
        gap: 1
      }}>
        <StyledButton onClick={onClose} color="inherit">
          Cancel
        </StyledButton>
        <StyledButton 
          onClick={handleSnooze}
          variant="contained"
          className="primary"
        >
          Set Snooze
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
}; 