import React, { useState, useEffect } from 'react';
import { SERVICE_CALLS, clearCache } from '../../extension-helpers';
import './Refresh.css';

const UPGRADE_STEPS = [
  "Initializing Trellus upgrade sequence...",
  "Clearing system cache...",
  "Optimizing local storage...",
  "Updating service workers...",
  "Finalizing system upgrade..."
];

const Refresh: React.FC = () => {
  const [isResetting, setIsResetting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const runUpgradeSequence = async () => {
    setIsResetting(true);
    setProgress(0);
    setCurrentStep(0);

    // Clear all cache types
    for (const [index, serviceCall] of Object.values(SERVICE_CALLS).entries()) {
      setCurrentStep(index);
      await clearCache(serviceCall);
      setProgress((prev) => Math.min(prev + (100 / Object.keys(SERVICE_CALLS).length), 95));
      await new Promise(resolve => setTimeout(resolve, 800)); // Add delay between steps
    }

    // Final step
    setCurrentStep(UPGRADE_STEPS.length - 1);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setProgress(100);

    // Reset after completion
    setTimeout(() => {
      setIsResetting(false);
      setProgress(0);
      setCurrentStep(0);
    }, 1500);
  };

  // Auto-start on mount
  useEffect(() => {
    runUpgradeSequence();
  }, []);

  return (
    <div className="refresh-container">
      <div className="upgrade-sequence">
        <div className="upgrade-text">
          {isResetting ? "Upgrading Trellus" : "Upgrade Complete"}
        </div>
        <button 
          className={`reset-button ${isResetting ? 'resetting' : ''}`}
          onClick={runUpgradeSequence}
          disabled={isResetting}
        >
          {isResetting ? (
            <div className="reset-content">
              <div className="spinner"></div>
              <span>{Math.round(progress)}%</span>
              <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
          ) : (
            'Fetch Latest Settings'
          )}
        </button>
        {isResetting && (
          <div className="step-text">
            {UPGRADE_STEPS[currentStep]}
          </div>
        )}
      </div>
    </div>
  );
};

export default Refresh;
