import { Box, Typography } from "@mui/material"
import { ReduxSafeSalesConversation } from "./conversations/sales-nav-slice"
import { LinkedInPicture, LinkedInAttachment, LinkedInConversationParticipant, LinkedInUpload } from "interfaces/linkedin"
import { LinkedInSalesProfile } from "interfaces/linkedin-salesnavigator"
import { ReduxSafeConversation } from "./conversations/chat-slice"
import { ConversationId, SOURCE_TYPE, TemporaryMessage } from "./types"
import { format } from "date-fns"

export interface MessageGroup {
  date: string;
  messages: Array<{
    message: string;
    participantName: string;
    participantImage: LinkedInPicture | null | undefined;
    isSelf: boolean;
    timestamp: string;
    renderContent?: LinkedInAttachment[];
    isTemporary?: boolean;
    failed?: boolean;
    isConsecutive: boolean;
    isGroupChat: boolean;
    entityUrn: string;
    emojiReactions?: string[];
  }>;
}

function convertSalesAttachmentToLinkedInAttachment(attachment: any): LinkedInAttachment {
  if (!attachment) return { file: null, vectorImage: null, video: null, audio: null };
  
  // Handle image attachments
  if (attachment.mediaType?.startsWith('image/')) {
    return {
      file: {
        assetUrn: attachment.id,
        byteSize: attachment.fileSize || 0,
        mediaType: attachment.mediaType,
        name: attachment.name || 'image',
        url: attachment.url
      } as LinkedInUpload,
      vectorImage: null,
      video: null,
      audio: null
    };
  }

  // Handle audio attachments
  if (attachment.mediaType?.startsWith('audio/')) {
    return {
      file: null,
      vectorImage: null,
      video: null,
      audio: {
        '_type': 'com.linkedin.messenger.AudioMetadata',
        url: attachment.url,
        duration: attachment.duration || 0
      }
    };
  }
  
  // Handle other file types
  return {
    file: {
      assetUrn: attachment.id,
      byteSize: attachment.fileSize || 0,
      mediaType: attachment.mediaType || 'application/octet-stream',
      name: attachment.name || 'file',
      url: attachment.url
    } as LinkedInUpload,
    vectorImage: null,
    video: null,
    audio: null
  };
}

export const generateMessageGroups = (
  selectedConversation: ConversationId | null,
  selectedConversationData: ReduxSafeConversation | ReduxSafeSalesConversation | null,
  temporaryMessages: TemporaryMessage[] | null
): MessageGroup[] | null => {
  if (!selectedConversation) return null;
  const messageGroupsByDate = selectedConversation.sourceType === SOURCE_TYPE.CHAT
    ? generateChatMessageGroups(selectedConversationData as ReduxSafeConversation, temporaryMessages)
    : generateSalesMessageGroups(selectedConversationData as ReduxSafeSalesConversation, temporaryMessages);

  return Object.entries(messageGroupsByDate).map(([date, messages]) => ({
    date,
    messages: messages.map((message: any, idx: number) => {
      const isTemporary = message.isTemporary;
      const isChat = selectedConversation.sourceType === SOURCE_TYPE.CHAT;
      const participant = selectedConversationData ? isChat 
        ?  (selectedConversationData as ReduxSafeConversation).conversationParticipants?.find(p => p.entityUrn === message.sender.entityUrn)
        : (selectedConversationData as ReduxSafeSalesConversation).participants.find(p => p.entityUrn === message.author)
        : null;
      const isSelf = isTemporary ? true : isChat && participant
        ? (participant as LinkedInConversationParticipant).participantType?.member?.distance === 'SELF'
        : !isChat && participant ? (participant as LinkedInSalesProfile).degree === 0 : false;

      const participantName = isTemporary && !selectedConversationData ? 'You' : isChat && participant
        ? `${(participant as LinkedInConversationParticipant).participantType?.member?.firstName?.text || ''} ${(participant as LinkedInConversationParticipant).participantType?.member?.lastName?.text || ''}`.trim()
        : !isChat && participant ? (participant as LinkedInSalesProfile).fullName : '';

      const timestamp = message.deliveredAt ? format(new Date(message.deliveredAt), 'h:mm a') : '';
      const previousMessage = idx > 0 ? messages[idx - 1] : null;
      const isConsecutive = previousMessage && (isChat 
        ? previousMessage.sender.entityUrn === message.sender.entityUrn
        : previousMessage.author === message.author);

      const isGroupChat = selectedConversationData ? isChat 
        ? ((selectedConversationData as ReduxSafeConversation).conversationParticipants?.length || 0) > 2
        : ((selectedConversationData as ReduxSafeSalesConversation).participants?.length || 0) > 2
        : false;

      // Handle attachments based on conversation type
      const renderContent = 
      isTemporary
        ? message.attachments || []
        : isChat 
          ? message.renderContent || []
          : (message.attachments || []).map(convertSalesAttachmentToLinkedInAttachment);

      return {
        message: isTemporary ? message.text : String(isChat ? message.body?.text || '' : message.body || ''),
        participantName,
        participantImage: isChat && participant
          ? (participant as LinkedInConversationParticipant).participantType?.member?.profilePicture || null
          : !isChat && participant ? (participant as LinkedInSalesProfile).profilePictureDisplayImage || null : null,
        isSelf,
        timestamp,
        renderContent,
        isTemporary: Boolean(message.isTemporary),
        failed: Boolean(message.failed),
        isConsecutive: Boolean(isConsecutive),
        isGroupChat,
        entityUrn: String(message.entityUrn || message.id || `temp-${idx}`),
        emojiReactions: 'reactionSummaries' in message ? (message.reactionSummaries || []).map((v: any) => v.emoji).filter((v: any) => v) : [],
      };
    })
  }));
};

function generateMessageFromTemporaryMessage(temporaryMessage: TemporaryMessage, selfUrn?: string) {
  return {
    sender: { entityUrn: selfUrn },
    text: temporaryMessage.text,
    body: temporaryMessage.text,
    deliveredAt: new Date(),
    isTemporary: true,
    failed: temporaryMessage.failed,
    entityUrn: temporaryMessage.uuid,
    attachments: temporaryMessage.attachments,
    emojiReactions: []
  };
}

function generateChatMessageGroups(conversation: ReduxSafeConversation | null, temporaryMessages: TemporaryMessage[] | null) {
  const selfParticipant = conversation?.conversationParticipants?.find(
    (p) => p.participantType?.member?.distance === 'SELF'
  );

  const messageGroupsByDate = conversation?.messages.reduce((groups: Record<string, any[]>, message) => {
    const date = format(new Date(message.deliveredAt), 'MMMM d, yyyy');
    if (!groups[date]) groups[date] = [];
    groups[date].push(message);
    return groups;
  }, {}) || {};

  if (temporaryMessages && temporaryMessages.length > 0) {
    const today = format(new Date(), 'MMMM d, yyyy');
    if (!messageGroupsByDate[today]) messageGroupsByDate[today] = [];
    temporaryMessages.forEach(tempMsg => {
      messageGroupsByDate[today].push(generateMessageFromTemporaryMessage(tempMsg, selfParticipant?.entityUrn));
    });
    messageGroupsByDate[today].sort((a, b) => Number(a.isTemporary) - Number(b.isTemporary));
  }

  return messageGroupsByDate;
}

function generateSalesMessageGroups(conversation: ReduxSafeSalesConversation | null, temporaryMessages: TemporaryMessage[] | null) {
  const selfParticipant = conversation?.participants.find(p => p.degree === 0);

  const messageGroupsByDate = conversation?.messages.reduce((groups: Record<string, any[]>, message) => {
    const date = format(new Date(message.deliveredAt), 'MMMM d, yyyy');
    if (!groups[date]) groups[date] = [];
    groups[date].push(message);
    return groups;
  }, {}) || {};

  if (temporaryMessages && temporaryMessages.length > 0) {
    const today = format(new Date(), 'MMMM d, yyyy');
    if (!messageGroupsByDate[today]) messageGroupsByDate[today] = [];
    temporaryMessages.forEach(tempMsg => {
      messageGroupsByDate[today].push(generateMessageFromTemporaryMessage(tempMsg, selfParticipant?.entityUrn));
    });
    messageGroupsByDate[today].sort((a, b) => Number(a.isTemporary) - Number(b.isTemporary));
  }

  return messageGroupsByDate;
}

export function pictureToUrl(picture: LinkedInPicture): string {
    return picture.artifacts.length === 0 ? picture.rootUrl
      : (picture.rootUrl ?? '') + picture.artifacts.reduce((a, b) => a.width < b.width ? a : b).fileIdentifyingUrlPathSegment
  }
  
  export type Participant = {
    name: string
    picture?: LinkedInPicture | null
    hostIdentityUrn: string
  }
  
  const renderTitle = (title: string) => {
    return <Typography variant="subtitle2" noWrap>
      {title}
    </Typography>
  }
  
  const renderConversationNameRegular = (conversation: ReduxSafeConversation, size: 'small' | 'large' = 'large') => {
    if (conversation.groupChat && conversation.title) return renderTitle(conversation.title);
    const otherParticipants: Participant[] = conversation.conversationParticipants
      ?.filter(p => p.participantType?.member?.distance !== 'SELF')
      .map(p => ({
        name: `${p.participantType?.member?.firstName?.text || ''} ${p.participantType?.member?.lastName?.text || ''}`.trim(),
        picture: p.participantType?.member?.profilePicture,
        hostIdentityUrn: p.hostIdentityUrn
      }))
      .filter(p => p.name) || [];
    return renderParticipants(otherParticipants, size);
  }
  
  const renderConversationNameSalesNav = (conversation: ReduxSafeSalesConversation, size: 'small' | 'large' = 'large') => {
    const otherParticipants = conversation.participants.filter(p => p.degree !== 0)
      .map(p => ({
        name: p.fullName,
        picture: p.profilePictureDisplayImage,
        hostIdentityUrn: p.entityUrn
      }))
      .filter(p => p.name) || [];
      return renderParticipants(otherParticipants, size);
  }
  
  const renderParticipants = (participants: Participant[], size: 'small' | 'large' = 'small') => {
    const dimensions = size === 'small' ? 16 : 40;
    return participants.map((p) => (
      <Box key={p.hostIdentityUrn} sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
        {p.picture ? (
          <img 
            src={pictureToUrl(p.picture)}
            alt={p.name}
            style={{
              width: dimensions,
              height: dimensions,
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            referrerPolicy="no-referrer"
          />
        ) : (
          <Box
            sx={{
              width: dimensions,
              height: dimensions,
              borderRadius: '50%',
              bgcolor: 'grey.300',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant={size === 'small' ? "caption" : "body1"} sx={{ fontSize: size === 'small' ? '0.6rem' : undefined }}>
              {p.name.split(' ').map(n => n[0]).join('').substring(0, 2)}
            </Typography>
          </Box>
        )}
        <Typography variant="subtitle2" noWrap sx={{ fontSize: size === 'small' ? '0.875rem' : undefined }}>
          {p.name}
        </Typography>
      </Box>
    ))
  }
  
  export const renderConversationName = (
    conversationId: ConversationId,
    conversation: ReduxSafeConversation | ReduxSafeSalesConversation,
    size: 'small' | 'large' = 'large'
  ) => {
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
      return renderConversationNameRegular(conversation as ReduxSafeConversation, size);
    } else {
      return renderConversationNameSalesNav(conversation as ReduxSafeSalesConversation, size);
    }
  }

  export const getLastMessageRegular = (
    conversation: ReduxSafeConversation
  ) => {
    return conversation.messages[conversation.messages.length - 1].body?.text ?? ''
  }

  export const getLastMessageSalesNav = (
    conversation: ReduxSafeSalesConversation
  ) => {
    return conversation.messages[conversation.messages.length - 1].body
  }

  export const getLastMessage = (
    conversationId: ConversationId,
    conversation: ReduxSafeConversation | ReduxSafeSalesConversation
  ) => {
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
      return getLastMessageRegular(conversation as ReduxSafeConversation);
    } else {
      return getLastMessageSalesNav(conversation as ReduxSafeSalesConversation);
    }
  }

  export const isUnreadRegular = (conversation: ReduxSafeConversation) => {
    return !conversation.read
  }

  export const isUnreadSalesNav = (conversation: ReduxSafeSalesConversation) => {
    return conversation.unreadMessageCount > 0;
  }

  export const isConversationUnread = (
    conversationId: ConversationId,
    conversation: ReduxSafeConversation | ReduxSafeSalesConversation
  ) => {
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
      return isUnreadRegular(conversation as ReduxSafeConversation);
    } else {
      return isUnreadSalesNav(conversation as ReduxSafeSalesConversation);
    }
  }

  export const getMessages = (
    conversationId: ConversationId,
    conversation: ReduxSafeConversation | ReduxSafeSalesConversation
  ) => {
    return conversation.messages;
  }

  export const processAttachment = (attachment: LinkedInAttachment) => {
    if (attachment.vectorImage) {
      return {
        id: attachment.vectorImage.digitalmediaAsset,
        name: 'Image',
        url: pictureToUrl(attachment.vectorImage),
        type: 'image'
      }
    }
    if (attachment.video) {
      return {
        id: attachment.video.entityUrn,
        name: 'Video',
        url: attachment.video.progressiveStreams[0]?.streamingLocations[0]?.url || '',
        type: 'video',
        thumbnail: attachment.video.thumbnail ? pictureToUrl(attachment.video.thumbnail) : undefined
      }
    }
    if (attachment.audio) {
      return {
        id: attachment.audio.url,
        name: 'Audio',
        url: attachment.audio.url,
        type: 'audio'
      }
    }
    if (attachment.file) {
      const mediaType = attachment.file.mediaType.split('/')[0]
      return {
        id: attachment.file.assetUrn,
        name: attachment.file.name,
        url: attachment.file.url,
        type: mediaType,
        byteSize: attachment.file.byteSize
      }
    }
    return null
  }

  export const getMessageAttachments = (renderContent?: LinkedInAttachment[]) => {
    if (!renderContent?.length) return []
    return renderContent
      .map(processAttachment)
      .filter((a): a is NonNullable<typeof a> => a !== null)
  }

  export const renderSimplifiedConversationName = (
    conversationId: ConversationId,
    conversation: ReduxSafeConversation | ReduxSafeSalesConversation
  ): { name: string; isGroup: boolean } => {
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
      const chatConversation = conversation as ReduxSafeConversation;
      
      // Check for group chat with title first
      if (chatConversation.groupChat && chatConversation.title) {
        return { name: chatConversation.title, isGroup: true };
      }

      const participants = chatConversation.conversationParticipants?.filter(p => 
        p.participantType?.member?.distance !== 'SELF'
      ) || [];

      if (participants.length === 0) return { name: 'No participants', isGroup: false };
      if (participants.length === 1) {
        return { 
          name: `${participants[0].participantType?.member?.firstName?.text || ''} ${participants[0].participantType?.member?.lastName?.text || ''}`.trim(),
          isGroup: false 
        };
      }
      return { 
        name: `${participants[0].participantType?.member?.firstName?.text || ''} ${participants[0].participantType?.member?.lastName?.text || ''} + ${participants.length - 1} others`.trim(),
        isGroup: true 
      };
    } else {
      const salesConversation = conversation as ReduxSafeSalesConversation;
      const participants = salesConversation.participants?.filter(p => p.degree !== 0) || [];

      if (participants.length === 0) return { name: 'No participants', isGroup: false };
      if (participants.length === 1) {
        return { 
          name: `${participants[0].firstName} ${participants[0].lastName}`.trim(),
          isGroup: false 
        };
      }
      return { 
        name: `${participants[0].firstName} ${participants[0].lastName} + ${participants.length - 1} others`,
        isGroup: true 
      };
    }
  };

  export const prependUrl = (conversationId: ConversationId) => {
    if (!conversationId?.sourceType) return;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) return 'https://www.linkedin.com/messaging/thread/';
    if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return 'https://www.linkedin.com/sales/inbox/';
  }

  export const getThreadId = (conversationId: ConversationId) => {
    if (!conversationId?.id) return;
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
        const threadId = conversationId.id.split(',')[1]?.split(')/')[0];
        if (!threadId) return;
        return threadId;
    }
    else return conversationId.id;
  }

  export const getUrlSalesNavigator = (conversationId: ConversationId) => {
    if (!conversationId?.id) return;
    const threadId = getThreadId(conversationId);
    if (!threadId) return;
    return `${prependUrl(conversationId)}${threadId}`;
  }

  
