import React, { useState, useEffect } from 'react';
import { DndContext, DragEndEvent, DragOverlay, useDraggable, useDroppable, DragStartEvent } from "@dnd-kit/core";
import { 
  Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, 
  MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, TextField, Typography, Menu, IconButton
} from '@mui/material';
import { MoreHorizontal, Phone, Search, Loader, CheckCircle, AlertTriangle, GripVertical } from 'lucide-react';
import { NumberRegistrationComponent } from './NumberRegistrationComponent';
import { MultiDialerQuotaInfo, UserDataResult } from 'interfaces/services';
import { convertFromReduxSafeUserResult, convertFromReduxSafeUserState } from 'lib/redux/store';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { MultiDialerSubscription, OptionalTwilioNumber, RegisteredTwilioNumber } from 'interfaces/db';
import { getServicesManager } from 'services';
import { convertOptionalNumberToTwilioNumber, format_number } from './cfg';
import { NumberSearch } from './NumberSearch';

interface DraggableNumberProps {
  number: string;
  spamScore: number | null;
  onRelease: () => void;
}

function DraggableNumber({ number, spamScore, onRelease }: DraggableNumberProps) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: number
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRelease = () => {
    onRelease();
    handleClose();
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      ref={setNodeRef}
      className={`flex items-center justify-between p-3 mb-2 rounded-lg border border-gray-200 bg-white ${
        isDragging ? "opacity-50 cursor-grabbing" : "cursor-pointer"
      }`}
    >
      <div className="flex items-center">
        <GripVertical
          {...listeners}
          {...attributes}
          className="mr-2 text-gray-500 cursor-grab"
          size={16}
          aria-label="Drag Handle"
        />
        <span className="font-mono mr-2">{number}</span>
        {spamScore && spamScore > 0.5 ? (
          <AlertTriangle className="text-yellow-500" size={16} aria-label="High Spam Score" />
        ) : (
          <CheckCircle className="text-green-500" size={16} aria-label="Low Spam Score" />
        )}
      </div>
      <IconButton
        size="small"
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        aria-label="More options"
      >
        <MoreHorizontal className="h-4 w-4" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <MenuItem onClick={handleRelease}>Release Number</MenuItem>
      </Menu>
    </div>
  );
}

interface DroppableZoneProps {
  rep: UserDataResult;
  numbers: RegisteredTwilioNumber[];
  subscription?: MultiDialerSubscription;
  onReleaseNumber: (number: string) => void;
  openModal: () => void;
}

function DroppableZone({ rep, numbers, onReleaseNumber, subscription, openModal }: DroppableZoneProps) {
  const { setNodeRef, isOver } = useDroppable({
    id: rep.user_id.toString(),
  });

  const isAtLimit = subscription 
    ? numbers.length >= subscription.max_phone_numbers_per_user 
    : false;

  const isButtonDisabled = !rep.parallel_enabled || !rep.can_dial || isAtLimit;

  return (
    <div 
      ref={setNodeRef} 
      className={`p-4 rounded-lg border border-gray-200 ${
        isOver ? 'bg-blue-100 border-blue-300' : 'bg-white'
      }`}
    >
      <div className="flex justify-between items-center mb-4">
        <Typography variant="subtitle1" className="font-semibold">
          {rep.user_name}
        </Typography>
        <Button
          onClick={openModal}
          variant="outlined"
          disabled={isButtonDisabled}
          startIcon={<Phone />}
          style={{
            borderColor: isButtonDisabled ? '#ccc' : '#000',
            color: isButtonDisabled ? '#777' : '#000',
            textTransform: 'none',
            borderRadius: '6px',
            padding: '4px 10px',
            fontSize: '0.875rem',
          }}
          className="transition-colors duration-200 hover:bg-gray-100"
        >
          {`${numbers.length}/${subscription?.max_phone_numbers_per_user || '—'} Assigned`}
        </Button>
      </div>
      <div className="space-y-2">
        {numbers.map((number) => (
          <DraggableNumber 
            key={number.rep_phone_value} 
            number={number.rep_phone_value}
            spamScore={number.nomorobo_spamscore}
            onRelease={() => onReleaseNumber(number.rep_phone_value)}
          />
        ))}
      </div>
    </div>
  );
}

type NumberManagementAdminProps = {
  parallel_users: UserDataResult[]
  all_users: UserDataResult[]
}

function NumberManagementAdminImpl({ parallel_users, all_users }: NumberManagementAdminProps) {
  const [selectedNumbers, setSelectedNumbers] = useState<RegisteredTwilioNumber[]>([]);
  const [quotaInfo, setQuotaInfo] = useState<MultiDialerQuotaInfo | null>(null);
  const [draggedId, setDraggedId] = useState<string | null>(null);
  
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRep, setSelectedRep] = useState<UserDataResult | null>(null);
  const [purchasingNumbers, setPurchasingNumbers] = useState<string[]>([]);

  function handleDragStart(event: DragStartEvent) {
    setDraggedId(event.active.id as string);
  }

  useEffect(() => {
    getServicesManager().getAllTwilioNumbersAdmin().then((numbers) => {
      if (!numbers) return;
      setSelectedNumbers(numbers);
    });

    getServicesManager().getMultidialerQuota().then((quota) => {
      if (!quota) return;
      setQuotaInfo(quota);
    });
  }, []);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    setDraggedId(null);
    if (!over) return;
    if (!active) return;
    const matchingNumber = selectedNumbers.find((n) => n.rep_phone_value.toString() === active.id);
    if (!matchingNumber) return;
    if (matchingNumber.user_id === over.id) return
    // check number of numbers for that user....
    if (!quotaInfo) return
    if (selectedNumbers.filter((n) => n.user_id === over.id).length >= quotaInfo?.subscription.max_phone_numbers_per_user) return
    setSelectedNumbers((prev) => {
      const updated = [...prev];
      const activeIndex = updated.findIndex((n) => n.rep_phone_value.toString() === active.id);
      updated[activeIndex] = { ...updated[activeIndex], user_id: over.id.toString() };
      return updated
    })
    reassignNumber(matchingNumber, over.id.toString());
  }

  const reassignNumber = async (number: RegisteredTwilioNumber, newUserId: string) => {
    await getServicesManager().reassignTwilioNumberAdmin(number.rep_phone_value, newUserId);
  };

  const handlePurchaseNumber = async (number: OptionalTwilioNumber, country_code?: string) => {
    if (!selectedRep || !selectedRep.parallel_enabled || !selectedRep.can_dial || !selectedRep.team_is_active)  return;
    if (!quotaInfo) return;
    const numbers_set = selectedNumbers.filter((n) => n.user_id === selectedRep.user_id)
    if (numbers_set.length >= quotaInfo.subscription.max_phone_numbers_per_user) {
      alert(`You can only select up to ${quotaInfo.subscription.max_phone_numbers_per_user} numbers.`);
      return;
    }
    setPurchasingNumbers([...purchasingNumbers, number.phone_number]);
    const success = await getServicesManager().acquireTwilioNumberAdmin(number.phone_number, selectedRep.user_id, country_code);
    if (!success) {
      setPurchasingNumbers(purchasingNumbers.filter((n) => n !== number.phone_number));
      alert('Failed to purchase number.');
      return;
    }
    setSelectedNumbers([...selectedNumbers, convertOptionalNumberToTwilioNumber(number, selectedRep)])
  };

  const handleReleaseNumber = async (phone_number: string) => {
    setSelectedNumbers((prev) => prev.filter((n) => n.rep_phone_value !== phone_number));
    await getServicesManager().releaseTwilioNumberAdmin(phone_number);
  };

  const openModal = (rep: UserDataResult) => {
    setSelectedRep(rep);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRep(null);
    setIsModalOpen(false);
  };

  const draggedNumber = draggedId 
    ? selectedNumbers.find(n => n.rep_phone_value.toString() === draggedId)
    : null;

  const allReps: UserDataResult[] = Array.from(new Set([...selectedNumbers.map((v) => v.user_id), ...parallel_users.map((v) => v.user_id)])).map((v) => all_users.find((u) => u.user_id === v)).filter((v) => v !== undefined) as UserDataResult[];

  return (
    <div className="container mx-auto space-y-6">
      <NumberRegistrationComponent />

      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {allReps.map((rep) => (
            <DroppableZone
              key={rep.user_id}
              rep={rep}
              numbers={selectedNumbers.filter((v) => v.user_id === rep.user_id)}
              onReleaseNumber={(phone_number) => handleReleaseNumber(phone_number)}
              subscription={quotaInfo?.subscription}
              openModal={() => openModal(rep)}
            />
          ))}
        </div>

        <DragOverlay>
          {draggedId && draggedNumber && (
            <div className="p-2 rounded-lg border border-gray-200 bg-white flex items-center">
              <span className="font-mono">{format_number(draggedNumber.rep_phone_value)}</span>
              {draggedNumber.nomorobo_spamscore && draggedNumber.nomorobo_spamscore > 0.5 ? (
                <AlertTriangle className="text-yellow-500 ml-2" size={16} aria-label="High Spam Score" />
              ) : (
                <CheckCircle className="text-green-500 ml-2" size={16} aria-label="Low Spam Score" />
              )}
            </div>
          )}
        </DragOverlay>
      </DndContext>

      {selectedRep && (
        <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="md">
          <DialogTitle>
            Acquire New Number for <strong>{selectedRep.user_name}</strong>
          </DialogTitle>
          <DialogContent dividers>
            <div className="mb-6">
              <Typography variant="h6" gutterBottom>
                Current Assigned Numbers
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Spam Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedNumbers.filter(num => num.user_id === selectedRep.user_id).map((number) => (
                    <TableRow key={number.rep_phone_value}>
                      <TableCell>{number.rep_phone_value}</TableCell>
                      <TableCell>
                        {number.nomorobo_spamscore && number.nomorobo_spamscore > 0.5 ? (
                          <AlertTriangle className="text-yellow-500" size={16} aria-label="High Spam Score" />
                        ) : (
                          <CheckCircle className="text-green-500" size={16} aria-label="Low Spam Score" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Acquire New Number
              </Typography>
              <NumberSearch 
                disabled={!selectedRep.parallel_enabled || !selectedRep.can_dial}
                handlePurchase={(number, code) => handlePurchaseNumber(number, code)}
                purchasingNumbers={[]} // Update based on your implementation
                quota={quotaInfo}
                registeredNumbers={selectedNumbers}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary" variant="text">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const ReduxWrapped = connect((state: RootState) => {
  const parallel_users = state.visibleAccounts.value?.users.filter((u) => u.parallel_enabled && u.can_dial && u.team_is_active).map((u) => convertFromReduxSafeUserResult(u)) || []
  return {
    all_users: state.visibleAccounts.value?.users.map((u) => convertFromReduxSafeUserResult(u)) || [],
    parallel_users,
  }
})(NumberManagementAdminImpl)

export { ReduxWrapped as NumberManagementAdmin}
