import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDraft } from "./slice";
import uuid from "react-uuid";
import { useLinkedInNavigate } from "../navigate/hook";
import { LinkedInConversationParticipant } from "interfaces/linkedin";
import { RootState } from "store";

export const useNewConversation = () => {
  const dispatch = useDispatch();
  const drafts = useSelector((state: RootState) => state.linkedinNewConversation.drafts);
  const { toNewDraft, closeSelectedParticipant } = useLinkedInNavigate();
  // get the current draft information
  const addDraft = useCallback(() => {
    const newMessageId = uuid();
    dispatch(createDraft({ id: newMessageId }));
    toNewDraft(newMessageId);
  }, [dispatch, toNewDraft]);

  const getMatchingDrafts = useCallback((participants: LinkedInConversationParticipant[]) => {
    const entityUrns = participants.map(p => p.hostIdentityUrn).sort().join(',');
    const allDrafts = Object.values(drafts);
    return allDrafts.find((v) => v.participants.map(p => p.hostIdentityUrn).sort().join(',') === entityUrns);
  }, [drafts]);

  const addDraftWithParticipants = useCallback((participants: LinkedInConversationParticipant[]) => {
    const matchingDraft = getMatchingDrafts(participants);
    if (matchingDraft) {
      toNewDraft(matchingDraft.id);
      closeSelectedParticipant();
      return;
    }
    const newMessageId = uuid();
    dispatch(createDraft({ id: newMessageId, toUrns: participants.map(p => p.hostIdentityUrn), participants: participants }));
    toNewDraft(newMessageId);
    closeSelectedParticipant();
  }, [dispatch, toNewDraft, drafts]);

  return {
    addDraft,
    addDraftWithParticipants
  }
}