import React, { useState, useMemo } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  IconButton,
  Box,
  TextField,
  Chip,
  Tooltip,
  Typography,
  alpha
} from '@mui/material';
import { 
  LocalOffer as TagIcon,
  Close as CloseIcon,
  Add as AddIcon 
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { updateTags } from 'lib/redux/linkedin/metadata/slice';
import { ConversationId } from 'lib/redux/linkedin/types';
import { styled } from '@mui/material/styles';
import { useLinkedInMetadata } from 'lib/redux/linkedin/metadata/hook';
import { Theme } from '@mui/material/styles';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
    maxWidth: '400px',
    margin: '16px',
    overflow: 'hidden'
  },
  '& .MuiBackdrop-root': {
    backgroundColor: ((theme: Theme) => alpha(theme.palette.background.default, 0.4)) as any,
    backdropFilter: 'blur(2px)'
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: 24,
  fontSize: '0.8125rem',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    margin: '0 4px 0 -4px',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      opacity: 0.8
    }
  }
}));

interface TagButtonProps {
  conversations: ConversationId[];
  size?: 'small' | 'medium';
  sx?: any;
}

export const TagButton: React.FC<TagButtonProps> = ({ conversations , size = 'medium', sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconSize = size === 'small' ? 20 : 24;
  
  return (
    <>
      <Tooltip title="Add tag" placement="bottom">
        <IconButton 
          onClick={() => setIsOpen(true)} 
          size={size}
          sx={{
            padding: size === 'small' ? '4px' : '8px',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              color: 'primary.main',
              transform: 'scale(1.05)',
            },
            '&:active': {
              transform: 'scale(0.95)',
            },
            ...sx
          }}
        >
          <TagIcon sx={{ fontSize: iconSize }} />
        </IconButton>
      </Tooltip>
      <TagModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        conversations={conversations}
      />
    </>
  );
};

interface TagModalProps {
  open: boolean;
  onClose: () => void;
  conversations: ConversationId[];
}

export const TagModal: React.FC<TagModalProps> = ({
  open,
  onClose,
  conversations
}) => {
  const dispatch = useDispatch();
  const [newTagName, setNewTagName] = useState('');
  const { allTags } = useLinkedInMetadata();
  
  // Get tags for all selected conversations
  const conversationTags = useSelector((state: RootState) => {
    return conversations.map(conversation => 
      state.linkedinConversationStates.states[conversation.id]?.tags || []
    );
  });

  // Calculate intersection of tags
  const tags = useMemo(() => {
    if (conversationTags.length === 0) return [];
    if (conversationTags.length === 1) return conversationTags[0];
    
    // Get intersection of all conversation tags
    return conversationTags.reduce((intersection, currentTags) => 
      intersection.filter(tag => currentTags.includes(tag))
    );
  }, [conversationTags]);

  // Calculate available tags (all tags minus intersection)
  const availableTags = useMemo(() => 
    Array.from(allTags).filter(tag => !tags.includes(tag))
  , [allTags, tags]);

  const handleCreateTag = () => {
    if (newTagName.trim()) {
      const newTags = [...tags, newTagName.trim()];
      conversations.forEach(conversation => {
        dispatch(updateTags({
          conversationId: conversation,
          tags: newTags
        }));
      });
      setNewTagName('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newTagName.trim()) {
      handleCreateTag();
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const newTags = tags.filter(tag => tag !== tagToRemove);
    conversations.forEach(conversation => {
      dispatch(updateTags({
        conversationId: conversation,
        tags: newTags
      }));
    });
  };

  const handleAddExistingTag = (tag: string) => {
    const newTags = [...tags, tag];
    conversations.forEach(conversation => {
      dispatch(updateTags({
        conversationId: conversation,
        tags: newTags
      }));
    });
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ 
        p: '12px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderColor: 'divider',
        mb: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TagIcon color="primary" sx={{ fontSize: 20 }} />
          <Typography sx={{ 
            fontSize: '1rem',
            fontWeight: 500,
            color: 'text.primary'
          }}>
            Manage Tags {conversations.length > 1 ? `(${conversations.length} conversations)` : ''}
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose} 
          size="small" 
          sx={{ 
            color: 'text.secondary',
            p: '4px'
          }}
        >
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: '0 16px 16px' }}>
        {/* Current Tags Section */}
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ 
            fontSize: '0.875rem',
            fontWeight: 500,
            color: 'text.primary',
            mb: 1
          }}>
            {conversations.length > 1 ? 'Common Tags' : 'Current Tags'}
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1,
            minHeight: 40,
            backgroundColor: theme => alpha('#F8F9FA', 0.6),
            borderRadius: 1,
            p: 1
          }}>
            {tags.length === 0 ? (
              <Typography sx={{ 
                fontSize: '0.875rem',
                color: 'text.secondary',
                fontStyle: 'italic'
              }}>
                {conversations.length > 1 ? 'No common tags' : 'No tags added yet'}
              </Typography>
            ) : (
              tags.map((tag) => (
                <StyledChip
                  key={tag}
                  label={tag}
                  onDelete={() => handleRemoveTag(tag)}
                  size="small"
                />
              ))
            )}
          </Box>
        </Box>

        {/* Available Tags Section */}
        {availableTags.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
              color: 'text.primary',
              mb: 1
            }}>
              Available Tags
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 1,
              minHeight: 40,
              backgroundColor: theme => alpha('#F8F9FA', 0.6),
              borderRadius: 1,
              p: 1
            }}>
              {availableTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  onClick={() => handleAddExistingTag(tag)}
                  sx={{
                    height: 24,
                    fontSize: '0.8125rem',
                    backgroundColor: 'background.paper',
                    '&:hover': {
                      backgroundColor: theme => alpha(theme.palette.primary.main, 0.08)
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        {/* Add New Tag Section */}
        <Box>
          <Typography sx={{ 
            fontSize: '0.875rem',
            fontWeight: 500,
            color: 'text.primary',
            mb: 1
          }}>
            Add New Tag
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            backgroundColor: theme => alpha('#F8F9FA', 0.6),
            borderRadius: 1
          }}>
            <TextField
              size="small"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter tag name"
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper'
                }
              }}
            />
            <IconButton 
              onClick={handleCreateTag}
              disabled={!newTagName.trim()}
              size="small"
              sx={{
                color: 'primary.main',
                '&.Mui-disabled': {
                  color: 'text.disabled'
                }
              }}
            >
              <AddIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
}; 