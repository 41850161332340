export enum AgentType {
  USER = 'USER',
  VOICE_BOT = 'VOICE_BOT',
  IVR = 'IVR'
}

export enum ConversationType {
  CALL = 'CALL',
  CHAT = 'CHAT'
}

export enum CallType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND'
}

export enum ResourceType {
  TEAM = 'TEAM',
  USER = 'USER',
  EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT'
}

export enum MessageType {
  HEARTBEAT = 'Heartbeat',
  SESSION_STATUS = 'SessionStatus',
  SESSION_INVITE = 'SessionInvite',
  USER_STATUS = 'UserStatus'
}

export type CustomerMetadata = {
    customer_name: string | null
    customer_account_name: string | null
    customer_role: string | null
}

export type CallMetadata = {
    call_type: CallType | null
    customer_phone: string | null
    agent_phone: string | null
    is_inbound: boolean | null
    is_trellus_telephony: boolean | null
    twilio_customer_sid: string | null
    twilio_agent_sid: string | null
}

export interface ConversationMetadata {
  conversation_type?: ConversationType;
  customer_metadata?: CustomerMetadata;
  call_metadata?: CallMetadata;
}

export interface SessionV2 {
  session_id: string;
  team_id: string;
  permission_by_team: boolean;
  resource_id: string;
  resource_type: ResourceType;
  base_path: string;
  started_at: string; // ISO datetime string
  conversation_type: ConversationType;
  call_type?: CallType;
}

export interface UserStatus {
  type: MessageType.USER_STATUS;
  user_id: string;
  reachable: boolean;
  active: boolean;
  connected: boolean;
  since_last_update: number | null;
  trellus_twilio_device_identity?: string;
}

export interface Heartbeat {
  type: MessageType.HEARTBEAT;
  time: number;
}

export interface SessionStatus {
  type: MessageType.SESSION_STATUS;
  session: SessionV2;
  agent_type: AgentType;
  agent_id: string;
  metadata: ConversationMetadata;
  is_ended: boolean;
  worker_mono: number;
}

export interface SessionInvite {
  type: MessageType.SESSION_INVITE;
  status: SessionStatus;
  is_dispatched?: boolean;
  user_id?: string;
  team_id?: string;
}