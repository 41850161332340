import React, { useRef, useEffect, useState, useLayoutEffect, useMemo } from 'react';
import { Box, Skeleton, Typography, CircularProgress } from '@mui/material';
import { useLinkedInConversationData } from '../../../../lib/redux/linkedin/conversations/data-hook';
import { MessageBubble } from './MessageBubble';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { generateMessageGroups } from '../../../../lib/redux/linkedin/helpers';
import { ConversationId, SOURCE_TYPE } from 'lib/redux/linkedin/types';
import { ReduxSafeSalesConversation } from 'lib/redux/linkedin/conversations/sales-nav-slice';
import { ReduxSafeConversation } from 'lib/redux/linkedin/conversations/chat-slice';
import { LoadingConversationBody } from './LoadingConversationBody';
import { useSendMessage } from 'lib/redux/linkedin/conversations/message-hook';
import { SEC_TO_MS } from 'cfg/const';
import { LoadingIndicator } from 'components/Common/LoadingIndicator';
import { useLinkedInMetadata } from 'lib/redux/linkedin/metadata/hook';

interface ConversationBodyContentProps {
  selectedConversation: ConversationId | null;
  selectedConversationMetadata: ReduxSafeSalesConversation | ReduxSafeConversation | null;
  selectedConversationData: ReduxSafeSalesConversation | ReduxSafeConversation | null;
  isLoadingMore: boolean;
  hasAdditionalMessages: boolean;
  fetchCurrentLinkedInConversation: () => void;
}

export const ConversationBodyContent: React.FC<ConversationBodyContentProps> = React.memo(({
  selectedConversation,
  selectedConversationMetadata,
  selectedConversationData,
  isLoadingMore,
  hasAdditionalMessages,
  fetchCurrentLinkedInConversation,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollPositionsRef = useRef<{[key: string]: number}>({});
  const previousHeightRef = useRef<number>(0);
  const previousMessagesLengthRef = useRef<number>(0);
  const [shouldPreserveScroll, setShouldPreserveScroll] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { retryTemporaryMessage, removeTemporaryMessageFromConvo } = useSendMessage();
  const { markReadState } = useLinkedInMetadata();

  // check if we the conversation is marked as unread, then mark as read
  useEffect(() => {
    if (!selectedConversation?.id) return;
    if (!selectedConversationData) return;
    let isMarkedAsUnread;
    if (selectedConversation.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) {
      isMarkedAsUnread = (selectedConversationData as ReduxSafeSalesConversation).unreadMessageCount > 0;
    } else {
      isMarkedAsUnread = !(selectedConversationData as ReduxSafeConversation).read;
    }
    if (isMarkedAsUnread) markReadState(selectedConversation, true);
  }, [selectedConversation?.id, selectedConversationData]);

  useEffect(() => {
    if (!selectedConversation?.id) return;
    if (selectedConversationData) return;
    fetchCurrentLinkedInConversation();
  }, [selectedConversation?.id]);

  // Scroll to bottom when new temporary messages are added
  const temporaryMessages = useSelector((state: RootState) => 
    selectedConversation?.id ? state.linkedinTemporary.temporaryMessages[selectedConversation.id] || [] : []
  );

  useEffect(() => {
    if (temporaryMessages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [temporaryMessages.length]);

  // Save scroll position when switching conversations or unmounting
  useEffect(() => {
    if (!selectedConversation?.id || !containerRef.current) return;

    const currentId = selectedConversation.id;
    const container = containerRef.current;

    // Restore saved scroll position or scroll to bottom if none exists
    if (scrollPositionsRef.current[currentId]) {
      container.scrollTop = scrollPositionsRef.current[currentId];
    } else if (selectedConversationData && isInitialLoad) {
      messagesEndRef.current?.scrollIntoView();
      setIsInitialLoad(false);
    }

    // Save scroll position when switching away or unmounting
    return () => {
      scrollPositionsRef.current[currentId] = container.scrollTop;
    };
  }, [selectedConversation?.id, selectedConversationData, isInitialLoad]);

  // Reset initial load flag when conversation changes
  useEffect(() => {
    if (selectedConversation?.id) {
      setIsInitialLoad(true);
    }
  }, [selectedConversation?.id]);

  // Preserve scroll position when loading more messages
  useLayoutEffect(() => {
    if (!containerRef.current || !shouldPreserveScroll) return;

    const currentHeight = containerRef.current.scrollHeight;
    const heightDifference = currentHeight - previousHeightRef.current;
    
    if (heightDifference > 0) {
      containerRef.current.scrollTop = heightDifference;
    }
    
    setShouldPreserveScroll(false);
  }, [selectedConversationData]);

  // Track messages length changes
  useEffect(() => {
    const currentLength = selectedConversationData?.messages?.length || 0;
    const previousLength = previousMessagesLengthRef.current;

    // If we have more messages and we're not loading older messages (from the top)
    if (currentLength > previousLength && !isLoadingMore) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    previousMessagesLengthRef.current = currentLength;
  }, [selectedConversationData?.messages?.length, isLoadingMore]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const container = event.currentTarget;
    const { scrollTop, scrollHeight } = container;
    
    if (scrollTop === 0 && !isLoadingMore && selectedConversationData && hasAdditionalMessages) {
      previousHeightRef.current = scrollHeight;
      setShouldPreserveScroll(true);
      fetchCurrentLinkedInConversation();
    }

    // Save scroll position while scrolling
    if (selectedConversation?.id) {
      scrollPositionsRef.current[selectedConversation.id] = scrollTop;
    }
  };

  const messageGroups = useMemo(() => 
    generateMessageGroups(selectedConversation, selectedConversationData || selectedConversationMetadata, temporaryMessages),
    [selectedConversation?.sourceType, selectedConversationMetadata, selectedConversationData, temporaryMessages]
  );

  const renderMessages = () => {
    if (!messageGroups) return null;

    return (
      <>
        {messageGroups.map(({ date, messages }) => (
          <Box key={date} sx={{ mb: 4, px: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: '50%',
                  height: '1px',
                  backgroundColor: 'divider',
                  zIndex: 0,
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: 'background.paper',
                  px: 2,
                  color: 'text.secondary',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                {date}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {messages.map((messageProps) => (
                <Box 
                  key={messageProps.entityUrn}
                  sx={{ 
                    mt: messageProps.isConsecutive ? 0.5 : 3
                  }}
                >
                  <MessageBubble
                    message={messageProps.message}
                    participantName={messageProps.participantName}
                    participantImage={messageProps.participantImage || undefined}
                    isSelf={messageProps.isSelf}
                    timestamp={messageProps.timestamp}
                    renderContent={messageProps.renderContent}
                    isTemporary={messageProps.isTemporary}
                    isGroupChat={messageProps.isGroupChat}
                    failed={messageProps.isTemporary && messageProps.failed}
                    retryTemporaryMessage={messageProps.isTemporary ? () => {
                      if (!selectedConversation?.id) return;
                      retryTemporaryMessage(selectedConversation.id, messageProps.entityUrn)
                    } : undefined}
                    removeTemporaryMessageFromConvo={messageProps.isTemporary ? () => {
                      if (!selectedConversation?.id) return;
                      removeTemporaryMessageFromConvo(selectedConversation.id, messageProps.entityUrn)
                    } : undefined}
                    messageEntityUrn={messageProps.entityUrn}
                    emojiReactions={messageProps.emojiReactions || []}
                    sourceType={selectedConversation?.sourceType}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </>
    );
  };

  if (!selectedConversation) {
    return null;
  }

  const noDataLoading = !selectedConversationData && !selectedConversationMetadata;
  const isInitialLoading = !selectedConversationData && selectedConversationMetadata;

  return (
    <Box 
      ref={containerRef}
      sx={{ 
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        minHeight: 0,
        position: 'relative'
      }}
      onScroll={handleScroll}
    >
      {(isLoadingMore || isInitialLoading) && (
        <LoadingIndicator 
          size="medium"
          position="floating"
          floatingPosition="top"
        />
      )}
      {noDataLoading ? (
        <LoadingConversationBody />
      ) : (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          flexGrow: 1,
          ...(isInitialLoading && {
            justifyContent: 'flex-end'
          })
        }}>
          {renderMessages()}
          <div ref={messagesEndRef} />
        </Box>
      )}
    </Box>
  );
});

// Container component that handles data fetching
export const ConversationBody: React.FC<{ conversationId: ConversationId }> = React.memo(({ conversationId }) => {
  const { 
    getConversationDataForId, getConversationMetadataForId,
    getConversationLoadingForId, doesConversationIdHaveAdditionalMessages,
    fetchSpecificLinkedInConversation, 
  } = useLinkedInConversationData();

  const selectedConversationData = getConversationDataForId(conversationId);
  const selectedConversationMetadata = getConversationMetadataForId(conversationId);
  const isLoadingMore = getConversationLoadingForId(conversationId);
  const hasAdditionalMessages = doesConversationIdHaveAdditionalMessages(conversationId);

  return (
    <ConversationBodyContent
      selectedConversation={conversationId}
      selectedConversationMetadata={selectedConversationMetadata}
      selectedConversationData={selectedConversationData}
      isLoadingMore={isLoadingMore || false}
      hasAdditionalMessages={hasAdditionalMessages || false}
      fetchCurrentLinkedInConversation={() => fetchSpecificLinkedInConversation(conversationId)}
    />
  );
}); 