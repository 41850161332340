import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { Box, Typography, CircularProgress, IconButton, Tooltip, Checkbox } from '@mui/material';
import { FixedSizeList, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ConversationItem, ConversationLoadingItem } from './ConversationTile';
import { useLinkedInConversations } from 'lib/redux/linkedin/conversations/list-hook';
import { useLinkedInNavigate } from 'lib/redux/linkedin/navigate/hook';
import { ConversationListHeader } from './ConversationListHeader';
import { useAnalyzeLinkedInConversations } from 'lib/redux/linkedin/conversations/analyzer';
import { ConversationId, DerivedInboxToIsDerivedFullyFromState, DerivedInboxToRequiresAnalysis, DerivedInboxType } from 'lib/redux/linkedin/types';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'store';
import { SEC_TO_MS } from 'cfg/const';
import { CONVERSATION_ITEM_HEIGHT_NO_TAGS } from './constants';
import { useLinkedInConversationData } from 'lib/redux/linkedin/conversations/data-hook';
import { LoadingIndicator } from '../../Common/LoadingIndicator';
import { 
  MarkEmailRead as MarkReadIcon,
  MarkunreadOutlined as MarkUnreadIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useLinkedInMetadata } from 'lib/redux/linkedin/metadata/hook';
import { useDispatch } from 'react-redux';
import { updateArchived } from 'lib/redux/linkedin/metadata/slice';
import { TagButton } from '../Modals/TagModal';
import { SnoozeButton } from '../Modals/SnoozeModal';

const OVERSCAN_COUNT = 10;
const SCROLL_THRESHOLD = CONVERSATION_ITEM_HEIGHT_NO_TAGS * 5;
const RESIZE_DEBOUNCE = 150; // ms


// Move outside component to avoid recreation
const getItemKey = (index: number, data: any) => data.conversationIds[index].id;

// Memoized selectors
const selectListState = (state: RootState) => ({
  userInbox: state.linkedinSelected.derivedInboxType,
  selectedTags: state.linkedinSelected.selectedTags,
  selectedConversation: state.linkedinSelected.conversationId,
  analyzing: state.linkedinAnalyze.analyzing,
  conversationStates: state.linkedinConversationStates.states
});

interface QuickActionsMenuProps {
  selectedCount: number;
  onClose: () => void;
  onMarkRead: () => void;
  onMarkUnread: () => void;
  onArchive: () => void;
  onUnarchive: () => void;
  onUnselectAll: () => void;
  isArchiveView: boolean;
  selectedConversations: ConversationId[];
}

const QuickActionsMenu: React.FC<QuickActionsMenuProps> = ({
  selectedCount,
  onClose,
  onMarkRead,
  onMarkUnread,
  onArchive,
  onUnarchive,
  onUnselectAll,
  isArchiveView,
  selectedConversations
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      p: 1,
      borderBottom: '1px solid',
      borderColor: 'divider',
      bgcolor: 'background.paper',
      zIndex: 1,
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, minWidth: 120 }}>
        <Checkbox
          checked={true}
          onChange={onUnselectAll}
          size="small"
          sx={{ p: 0.5 }}
        />
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {selectedCount} selected
        </Typography>
      </Box>
      <TagButton conversations={selectedConversations} size="small" />
      <SnoozeButton conversations={selectedConversations} size="small" />
      <Tooltip title="Mark as read">
        <IconButton size="small" onClick={onMarkRead}>
          <MarkReadIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Mark as unread">
        <IconButton size="small" onClick={onMarkUnread}>
          <MarkUnreadIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {isArchiveView ? (
        <Tooltip title="Unarchive">
          <IconButton size="small" onClick={onUnarchive}>
            <UnarchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Archive">
          <IconButton size="small" onClick={onArchive}>
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
    <IconButton size="small" onClick={onClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  </Box>
);

export const ConversationList: React.FC = React.memo(() => {
  const {
    conversationIds,
    allConversationIds,
    isLoading,
    continues,
    loadConversations,
    hasData,
    initialFetch
  } = useLinkedInConversations()
  const { initialAnalyticsLoaded } = useSelector((state: RootState) => state.linkedinAnalyze, shallowEqual);
  const { fetchMultipleLinkedInConversations } = useLinkedInConversationData();
  const { 
    userInbox,
    selectedTags,
    selectedConversation,
    analyzing,
    conversationStates
  } = useSelector(selectListState, shallowEqual);

  const analyzed = useSelector((state: RootState) => state.linkedinAnalyze.analyzed, shallowEqual);
  const { analyzeConversations, backFillAnalytics, requiresBackFill } = useAnalyzeLinkedInConversations();
  const { toConversation } = useLinkedInNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<FixedSizeList>(null);
  // Store the container height from AutoSizer
  const [containerHeight, setContainerHeight] = useState(0);

  // Add selected conversations state
  const [selectedConversations, setSelectedConversations] = useState<Set<string>>(new Set());

  // Fix isArchiveView check to use proper enum
  const isArchiveView = userInbox === DerivedInboxType.ARCHIVED;

  const { markReadState } = useLinkedInMetadata();
  const dispatch = useDispatch();

  // Memoize shouldFetchMore logic
  const shouldFetchMore = useCallback(() => {
    if (!continues || isLoading || !initialFetch) return false;
    if (DerivedInboxToIsDerivedFullyFromState[userInbox]) return false;
    if (selectedTags.length > 0) return false;
    if (DerivedInboxToRequiresAnalysis[userInbox] && allConversationIds.map((v) => !analyzed[v.id]).some(x => x)) return false;
    
    // Safely check analyzing state
    const isAnyConversationAnalyzing = conversationIds.find(id => analyzing[id.id])
    if (isAnyConversationAnalyzing) return false;

    const totalContentHeight = conversationIds.length * CONVERSATION_ITEM_HEIGHT_NO_TAGS;
    return containerHeight > 0 && (totalContentHeight - SCROLL_THRESHOLD) < containerHeight && continues;
  }, [continues, isLoading, initialFetch, userInbox, selectedTags, analyzing, conversationIds, containerHeight]);

  // Batch related effects
  useEffect(() => {
    if (!initialFetch) {
      loadConversations.initialFetch();
      return;
    }

    const hasDataConversations: ConversationId[] = [];
    const noDataConversations: ConversationId[] = [];
    
    allConversationIds.forEach((x) => {
      if (hasData(x)) {
        hasDataConversations.push(x);
      } else {
        noDataConversations.push(x);
      }
    });

    if (noDataConversations.length > 0) { fetchMultipleLinkedInConversations(noDataConversations); }
    if (hasDataConversations.length > 0) { analyzeConversations(hasDataConversations); }
  }, [initialFetch, allConversationIds]);

  // Add handlers for bulk actions
  const handleToggleSelect = useCallback((conversationId: string) => {
    setSelectedConversations(prev => {
      const newSet = new Set(prev);
      if (newSet.has(conversationId)) {
        newSet.delete(conversationId);
      } else {
        newSet.add(conversationId);
      }
      return newSet;
    });
  }, []);

  const clearSelection = useCallback(() => {
    setSelectedConversations(new Set());
  }, []);

  const handleBulkMarkRead = useCallback(async () => {
    // Process all selected conversations
    const promises = Array.from(selectedConversations).map(id => {
      const conversationId = conversationIds.find(conv => conv.id === id);
      if (conversationId) {
        return markReadState(conversationId, true);
      }
      return Promise.resolve();
    });

    // Wait for all operations to complete
    await Promise.all(promises);
    clearSelection();
  }, [selectedConversations, conversationIds, markReadState, clearSelection]);

  const handleBulkMarkUnread = useCallback(async () => {
    // Process all selected conversations
    const promises = Array.from(selectedConversations).map(id => {
      const conversationId = conversationIds.find(conv => conv.id === id);
      if (conversationId) {
        return markReadState(conversationId, false);
      }
      return Promise.resolve();
    });

    // Wait for all operations to complete
    await Promise.all(promises);
    clearSelection();
  }, [selectedConversations, conversationIds, markReadState, clearSelection]);

  const handleBulkArchive = useCallback(async () => {
    // Process all selected conversations
    const promises = Array.from(selectedConversations).map(id => {
      const conversationId = conversationIds.find(conv => conv.id === id);
      if (conversationId) {
        return dispatch(updateArchived({ 
          conversationId,
          isArchived: true
        }));
      }
      return Promise.resolve();
    });

    // Wait for all operations to complete
    await Promise.all(promises);
    clearSelection();
  }, [selectedConversations, conversationIds, dispatch, clearSelection]);

  const handleBulkUnarchive = useCallback(async () => {
    // Process all selected conversations
    const promises = Array.from(selectedConversations).map(id => {
      const conversationId = conversationIds.find(conv => conv.id === id);
      if (conversationId) {
        return dispatch(updateArchived({ 
          conversationId,
          isArchived: false
        }));
      }
      return Promise.resolve();
    });

    // Wait for all operations to complete
    await Promise.all(promises);
    clearSelection();
  }, [selectedConversations, conversationIds, dispatch, clearSelection]);

  // Update itemData to include selection state and handlers
  const itemData = useMemo(() => ({
    conversationIds,
    onSelect: toConversation,
    selectedId: selectedConversation,
    selectedConversations,
    onToggleSelect: handleToggleSelect
  }), [conversationIds, toConversation, selectedConversation, selectedConversations, handleToggleSelect]);

  useEffect(() => {
    if (shouldFetchMore()) {
      loadConversations.fetchMore();
    }
  }, [conversationIds, userInbox, continues, isLoading, containerHeight, loadConversations, analyzing, analyzed, selectedTags, initialFetch]);

  useEffect(() => {
    if (isLoading || !initialFetch) return;
    if (!conversationIds.every(id => analyzed[id.id])) return;
    if (requiresBackFill) {
      backFillAnalytics();
    }
  }, [isLoading, initialFetch, initialAnalyticsLoaded, analyzed, conversationIds, requiresBackFill]);

  // Memoize the scroll handler with minimal dependencies
  const handleScroll = useCallback(({ scrollOffset }: { scrollOffset: number }) => {
    if (isLoading || DerivedInboxToIsDerivedFullyFromState[userInbox] || conversationIds.length === 0 || !initialFetch || selectedTags.length > 0) return;

    const totalHeight = conversationIds.length * CONVERSATION_ITEM_HEIGHT_NO_TAGS;
    const remainingHeight = totalHeight - (scrollOffset + containerHeight);

    if (remainingHeight < SCROLL_THRESHOLD && continues) {
      requestAnimationFrame(() => {
        loadConversations.fetchMore();
      });
    }
  }, [isLoading, userInbox, continues, containerHeight, conversationIds.length, loadConversations, initialFetch, selectedTags]);

  // Simplified keyboard navigation
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) return;
    
    const currentIndex = selectedConversation 
      ? conversationIds.findIndex(conv => conv.id === selectedConversation.id)
      : -1;

    let nextIndex = -1;
    if (e.key === 'ArrowUp' && currentIndex > 0) {
      e.preventDefault();
      nextIndex = currentIndex - 1;
    } else if (e.key === 'ArrowDown' && currentIndex < conversationIds.length - 1) {
      e.preventDefault();
      nextIndex = currentIndex + 1;
    }

    if (nextIndex !== -1) {
      toConversation(conversationIds[nextIndex]);
      
      // Only scroll if the item is out of view
      if (listRef.current) {
        const list = listRef.current;
        const itemSize = CONVERSATION_ITEM_HEIGHT_NO_TAGS;
        const itemOffset = nextIndex * itemSize;
        const listHeight = list.props.height as number;
        const scrollOffset = (list as any)._outerRef.scrollTop;
        
        // Check if item is outside the visible area
        const itemTop = itemOffset;
        const itemBottom = itemOffset + itemSize;
        const viewportTop = scrollOffset;
        const viewportBottom = scrollOffset + listHeight;

        if (itemTop < viewportTop || itemBottom > viewportBottom) {
          list.scrollToItem(nextIndex, 'center');
        }
      }
    }
  }, [selectedConversation, conversationIds, toConversation]);

  // Optimize the list renderer with stable ref and debouncing
  const renderList = useCallback(({ height, width }: { height: number; width: number }) => {
    if (height !== containerHeight) {
      setContainerHeight(height);
    }

    return (
      <FixedSizeList
        ref={listRef}
        height={height}
        width={width}
        itemCount={conversationIds.length}
        itemSize={CONVERSATION_ITEM_HEIGHT_NO_TAGS}
        onScroll={handleScroll}
        overscanCount={OVERSCAN_COUNT}
        itemData={itemData}
        itemKey={getItemKey}
        style={{ 
          willChange: 'transform',
          WebkitOverflowScrolling: 'touch',
        }}
        useIsScrolling
      >
        {ConversationItem}
      </FixedSizeList>
    );
  }, [containerHeight, conversationIds, handleScroll, itemData]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Add cleanup effect for stale selections
  useEffect(() => {
    setSelectedConversations(prev => {
      const newSet = new Set(prev);
      let hasChanges = false;
      
      // Remove any selected conversations that are no longer in the list
      prev.forEach(id => {
        if (!conversationIds.some(conv => conv.id === id)) {
          newSet.delete(id);
          hasChanges = true;
        }
      });

      return hasChanges ? newSet : prev;
    });
  }, [conversationIds]);

  // Convert selected IDs to ConversationId objects for the buttons
  const selectedConversationObjects = useMemo(() => {
    return Array.from(selectedConversations).map(id => 
      conversationIds.find(conv => conv.id === id)
    ).filter((conv): conv is ConversationId => conv !== undefined);
  }, [selectedConversations, conversationIds]);

  return (
    <Box 
      ref={containerRef}
      sx={{ 
        width: '100%', 
        height: '100%', 
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
        transform: 'translateZ(0)',
        willChange: 'transform',
        contain: 'strict'
      }}
    >
      {selectedConversations.size > 0 && (
        <QuickActionsMenu
          selectedCount={selectedConversations.size}
          onClose={clearSelection}
          onMarkRead={handleBulkMarkRead}
          onMarkUnread={handleBulkMarkUnread}
          onArchive={handleBulkArchive}
          onUnarchive={handleBulkUnarchive}
          onUnselectAll={clearSelection}
          isArchiveView={isArchiveView}
          selectedConversations={selectedConversationObjects}
        />
      )}
      <ConversationListHeader />
      
      <Box sx={{ flex: 1, position: 'relative' }}>
        {conversationIds.length === 0 && isLoading ? (
          <Box sx={{ 
            width: '100%', 
            height: '100%', 
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            p: 2
          }}>
            {[...Array(15)].map((_, i) => (
              <ConversationLoadingItem 
                key={i} 
                style={{ 
                  height: CONVERSATION_ITEM_HEIGHT_NO_TAGS,
                  width: '100%',
                  display: 'block'
                }} 
              />
            ))}
          </Box>
        ) : (
          <>
            <AutoSizer>
              {renderList}
            </AutoSizer>

            {isLoading && conversationIds.length > 0 && (
              <LoadingIndicator 
                size="small"
                position="floating"
                floatingPosition="bottom"
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}); 