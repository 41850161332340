import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { ExtensionMissingView } from './ExtensionMissingView';
import { LinkedInPermissionMissingView } from './LinkedInPermissionMissingView';
import { CheckingConfigurationView } from './CheckingConfigurationView';
import { NotSignedInView } from './NotSignedInView';

interface LinkedInConfigurationProps {
  isCheckingConfig: boolean;
  hasExtension: boolean;
  hasLinkedInAccess: boolean;
  initError: string | null;
  installUrl: string;
  upgradeUrl: string;
  isNotSignedIn: boolean;
}

export const LinkedInConfiguration: React.FC<LinkedInConfigurationProps> = ({
  isCheckingConfig,
  hasExtension,
  hasLinkedInAccess,
  initError,
  installUrl,
  upgradeUrl,
  isNotSignedIn,
}) => {
  
  if (isCheckingConfig) {
    return <CheckingConfigurationView />;
  }

  if (!hasExtension) {
    return <ExtensionMissingView installUrl={installUrl} />;
  }

  if (!hasLinkedInAccess) {
    return <LinkedInPermissionMissingView upgradeUrl={upgradeUrl} />;
  }

  if (isNotSignedIn) {
    return <NotSignedInView />;
  }

  if (initError) {
    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%',
        color: 'error.main'
      }}>
        {initError}
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      height: '100%'
    }}>
      <CircularProgress />
    </Box>
  );
}; 