import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface LoadingIndicatorProps {
  size?: 'small' | 'medium' | 'large';
  position?: 'static' | 'floating';
  floatingPosition?: 'top' | 'bottom';
}

const sizeMap = {
  small: {
    container: 28,
    spinner: 18,
    thickness: 4
  },
  medium: {
    container: 32,
    spinner: 20,
    thickness: 4
  },
  large: {
    container: 40,
    spinner: 24,
    thickness: 3.5
  }
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ 
  size = 'medium',
  position = 'floating',
  floatingPosition = 'top'
}) => {
  const dimensions = sizeMap[size];
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: dimensions.container,
        height: dimensions.container,
        backgroundColor: 'background.paper',
        borderRadius: '50%',
        boxShadow: position === 'floating' ? '0px 2px 8px rgba(0, 0, 0, 0.08)' : 'none',
        zIndex: 1,
        backdropFilter: 'blur(8px)',
        ...(position === 'floating' && {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          ...(floatingPosition === 'bottom' ? {
            bottom: 16,
          } : {
            top: 16,
          })
        })
      }}
    >
      <CircularProgress 
        size={dimensions.spinner}
        thickness={dimensions.thickness}
        sx={{
          color: 'primary.main',
          '.MuiCircularProgress-circle': {
            strokeLinecap: 'round',
          }
        }}
      />
    </Box>
  );
}; 