import { ConversationId, DerivedInboxType, InboxType, SOURCE_TYPE } from "../types";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setSelectedConversation, setDerivedInboxType, setInboxType, setSourceType, clearSourceType, setExpandMode, addNewMessage, closeNewMessage, QUERY_PARAMS, setSelectedParticipant, clearSelectedParticipant, updateState } from "./slice";
import { useNavigate } from "react-router-dom";
import { useLinkedInMetadata } from "../metadata/hook";

export const useLinkedInNavigate = () => {
    const dispatch = useDispatch();
    const { markReadState } = useLinkedInMetadata()
    // update or remove query params hook
    const navigate = useNavigate();
    const toConversation = useMemo(() => {
      return (conversationId: ConversationId | null, closeSelectedParticipant: boolean = false) => {
        if (conversationId) markReadState(conversationId, true)
        if (closeSelectedParticipant) {
          dispatch(updateState({
            selectedParticipant: null,
            activeOption: conversationId ? conversationId.id : null,
            conversationId: conversationId ? conversationId : null,
          }))
        } else { 
          dispatch(setSelectedConversation(conversationId));
        }
        if (conversationId) {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set(QUERY_PARAMS.CONVERSATION_ID, conversationId?.id);
          urlParams.set(QUERY_PARAMS.CONVERSATION_SOURCE, conversationId?.sourceType);
          navigate(`?${urlParams.toString()}`);
        } else {
          // remove query params for just conversationId and conversationSource 
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete(QUERY_PARAMS.CONVERSATION_ID);
          urlParams.delete(QUERY_PARAMS.CONVERSATION_SOURCE);
          navigate(`?${urlParams.toString()}`);
        }
      }
    }, [dispatch, navigate]);

    const toUserInbox = useMemo(() => {
      return (inboxId: DerivedInboxType) => {
        dispatch(setDerivedInboxType(inboxId));
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set(QUERY_PARAMS.INBOX_TYPE, inboxId);
        navigate(`?${urlParams.toString()}`);
      }
    }, [dispatch, navigate]);

    const toBackendInbox = useMemo(() => {
      return (inboxId: InboxType) => {
        dispatch(setInboxType(inboxId));
      }
    }, [dispatch]);

    const toSource = useMemo(() => {
      return (source: SOURCE_TYPE[]) => {
        dispatch(setSourceType(source));
      }
    }, [dispatch]);

    const toExpandMode = useMemo(() => {
      return (toExpand: boolean) => {
        dispatch(setExpandMode(toExpand));
      }
    }, [dispatch]);

    const toNewDraft = useMemo(() => {
      return (draftId: string) => {
        dispatch(addNewMessage(draftId));
      }
    }, [dispatch]);

    const closeDraft = useMemo(() => {
      return (draftId: string) => {
        dispatch(closeNewMessage(draftId));
      }
    }, [dispatch]);

    const toSelectedParticipant = useMemo(() => {
      return (participant: string) => {
        dispatch(setSelectedParticipant(participant));
      }
    }, [dispatch]);

    const closeSelectedParticipant = useMemo(() => {
      return () => {
        dispatch(clearSelectedParticipant());
      }
    }, [dispatch]);

    return {
      toConversation,
      toUserInbox,
      toSource,
      toExpandMode,
      toBackendInbox,
      toNewDraft,
      closeDraft,
      toSelectedParticipant,
      closeSelectedParticipant
    };
};
