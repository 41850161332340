import { LinkedIn } from "components/LinkedIn/LinkedIn"
import { useState, useEffect, useCallback, useMemo } from "react"
import './embedded.css'
import { EXT_MANAGER_KEY, MESSAGE_TYPES, TRANSIT_MESSAGE_KEYS } from "cfg/endpoints";
import { SHADOW_UI_MOUNTS } from "cfg/managers";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Modal, Box, Typography, TextField, InputAdornment, List, ListItem, ListItemAvatar, Avatar, ListItemText, Chip, Button, CircularProgress } from "@mui/material";
import { Person, CheckCircle, Search, ContentCopy } from "@mui/icons-material";
import { LinkedInNormalizedParticipant } from "interfaces/linkedin";
import { pictureToUrl } from "lib/redux/linkedin/helpers";
import { useLinkedInSearch } from 'lib/redux/linkedin/search/hook';
import debounce from 'lodash/debounce';
import { useSendMessage } from "lib/redux/linkedin/conversations/message-hook";
import { getServicesManager } from "services";
import { TRACKER_TYPE } from "cfg/sources";

const INVITE_MESSAGE = 'Try out superhuman on LinkedIn (via Trellus) - https://app.trellus.ai/install'

export const LinkedInEmbedded = () => {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [opened, setOpened] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState<LinkedInNormalizedParticipant[]>([]);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const {client, hasInitializedLinkedInClient} = useSelector((state: RootState) => state.linkedinClients);
    const { performSearch, clearSearch, getSearchResults } = useLinkedInSearch();
    const { sendInvites } = useSendMessage()

    // Get search results from state
    const searchResults = useSelector((state: RootState) => 
        searchQuery.trim() ? getSearchResults(state, searchQuery) : undefined
    );

    // Memoize the search function
    const handleSearch = useCallback(
        (query: string) => {
            if (query.trim()) {
                performSearch(query);
            } else {
                clearSearch(query);
            }
        },
        [performSearch, clearSearch]
    );

    // Create debounced search only once
    const debouncedSearch = useMemo(
        () => debounce(handleSearch, 300),
        [handleSearch]
    );

    // Handle search query changes
    const handleSearchQueryChange = useCallback((newQuery: string) => {
        setSearchQuery(newQuery);
        if (searchModalOpen) {
            debouncedSearch(newQuery);
        }
    }, [searchModalOpen, debouncedSearch]);

    // Clear search on modal close
    useEffect(() => {
        if (!searchModalOpen) {
            setSearchQuery('');
            clearSearch('');
            debouncedSearch.cancel();
        } else {
            // Do initial search when modal opens
            performSearch('');
        }
    }, [searchModalOpen, clearSearch, debouncedSearch, performSearch]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const sendMessageToTop = (message_data: {[key: string]: any}) => {
        const message = {
            type: MESSAGE_TYPES.TRELLUS_ROUTER_PASSING, 
            [TRANSIT_MESSAGE_KEYS.SOURCE_INFO]: { [EXT_MANAGER_KEY.MANAGER_TYPE]: 'EXTERNAL'}, 
            [TRANSIT_MESSAGE_KEYS.TARGET_INFO]: { [EXT_MANAGER_KEY.MANAGER_TYPE]: SHADOW_UI_MOUNTS.SUPERHUMAN_LINKEDIN}, 
            [TRANSIT_MESSAGE_KEYS.MESSAGE_INFO]: message_data
        }
        if (window.top && document.location.ancestorOrigins 
            && document.location.ancestorOrigins.length > 0) {
            const ancestorOrigin = document.location.ancestorOrigins[0];
            try {
                window.top.postMessage(message, ancestorOrigin);
            } catch (e) {
                console.error('Failed to send message to parent:', e);
            }
        }
    };

    const handleWindowMessage = (event: MessageEvent) => {
        const data = event.data;
        if (!data || !data.type) return;
        switch (data.type) {
            case MESSAGE_TYPES.APP_TO_EXTERNAL_CHECK_IS_LOADED:
                sendMessageToTop({ type: MESSAGE_TYPES.LINKEDIN_TO_PARENT_CAN_SHOW });
                break;
            case MESSAGE_TYPES.APP_TO_EXTERNAL_OPEN_LINKEDIN:
                setOpened(true);
                getServicesManager().submitTrackers(window.location.href, TRACKER_TYPE.NAVIGATE, [ { payload_key: 'opened_linked_in', payload_value: '1' } ]);
                break;
            case MESSAGE_TYPES.APP_TO_EXTERNAL_CLOSE_LINKEDIN:
                setOpened(false);
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleWindowMessage);
        // Notify parent that we're loaded
        // Remove listener when component unmounts
        return () => {
            window.removeEventListener('message', handleWindowMessage);
        };
    }, []);

    const handleEscape = () => {
        sendMessageToTop({ type: MESSAGE_TYPES.EXTERNAL_TO_APP_CLOSE_LINKEDIN });
        setOpened(false);
    };

    const handleSelectPerson = (person: LinkedInNormalizedParticipant) => {
        const isAlreadySelected = selectedPeople.find(p => p.entityUrn === person.entityUrn);
        if (isAlreadySelected) {
            setSelectedPeople(selectedPeople.filter(p => p.entityUrn !== person.entityUrn));
        } else if (selectedPeople.length < 10) {
            setSelectedPeople([...selectedPeople, person]);
        }
    };

    const handleRemovePerson = (personUrn: string) => {
        setSelectedPeople(selectedPeople.filter(p => p.entityUrn !== personUrn));
    };

    const handleInviteCopy = async () => {
        try {
            await navigator.clipboard.writeText(INVITE_MESSAGE);
            setToastMessage('Invite link copied! ✨');
        } catch (error) {
            setToastMessage('Please copy https://app.trellus.ai/install to invite friends');
        }
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    }

    const handleInviteRegular = async () => {
        setSearchModalOpen(true);
    }

    const handleInvite = async () => {
        if (!client || !hasInitializedLinkedInClient) handleInviteCopy();
        else handleInviteRegular();
        getServicesManager().submitTrackers(window.location.href, TRACKER_TYPE.NAVIGATE, [ { payload_key: 'invite_friends', payload_value: '1' } ]);
    };

    return (
        <div className="w-full h-full flex-grow relative flex flex-col">
            <div className="absolute top-0 left-0 right-0 z-50 px-3 py-2 pt-2.5 flex justify-between items-center bg-white shadow-sm">
                <div className="flex items-center gap-3">
                    <span className="text-sm font-medium text-gray-700">LinkedIn Superhuman</span>
                    <button
                        onClick={handleInvite}
                        className="inline-flex items-center gap-1.5 px-3.5 py-1.5 text-sm font-medium text-white bg-gradient-to-r from-blue-600 via-blue-500 to-blue-600 hover:from-blue-700 hover:via-blue-600 hover:to-blue-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-500 transition-all duration-200 shadow-sm hover:shadow-md"
                    >
                        <svg className="w-3.5 h-3.5 text-blue-100" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zm3 11.25a1 1 0 01-1 1H4a1 1 0 01-1-1V14a3 3 0 013-3h4a3 3 0 013 3v3.25zm4-6a.75.75 0 000-1.5h-3a.75.75 0 000 1.5h3z"/>
                        </svg>
                        <span className="relative">Invite Friends</span>
                    </button>
                </div>
                <button
                    onClick={handleEscape}
                    className="px-3 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-400 transition-colors"
                >
                    Exit Superhuman Mode
                </button>
            </div>
            {opened ? <div className="w-full h-full flex-grow overflow-hidden pt-12">
                <LinkedIn />
            </div> : null}
            
            {/* Search/Invite Modal */}
            <Modal
                open={searchModalOpen}
                onClose={() => {
                    setSearchModalOpen(false);
                    setSelectedPeople([]);
                    setSearchQuery('');
                    clearSearch('');
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 600,
                        maxHeight: '85vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 'rgb(0 0 0 / 25%) 0px 4px 16px',
                        outline: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden'
                    }}
                >
                    {/* Header */}
                    <Box sx={{ 
                        p: 2.5, 
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                    }}>
                        <Typography variant="h6" sx={{ 
                            fontSize: '1.125rem',
                            fontWeight: 600,
                            color: 'text.primary'
                        }}>
                            Invite People to Try Superhuman
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                            Select up to 10 connections to invite
                        </Typography>
                    </Box>

                    {/* Content */}
                    <Box sx={{ p: 2.5, flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                        {/* Selected People Pills */}
                        {selectedPeople.length > 0 && (
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontWeight: 500 }}>
                                    Selected ({selectedPeople.length}/10)
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {selectedPeople.map((person) => (
                                        <Chip
                                            key={person.entityUrn}
                                            avatar={<Avatar src={person.image ? pictureToUrl(person.image) : undefined} />}
                                            label={person.name}
                                            onDelete={() => handleRemovePerson(person.entityUrn)}
                                            sx={{ 
                                                bgcolor: 'action.selected',
                                                '& .MuiChip-deleteIcon': {
                                                    color: 'text.secondary',
                                                    '&:hover': {
                                                        color: 'text.primary'
                                                    }
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}

                        {/* Search Input */}
                        <TextField
                            fullWidth
                            placeholder="Search your connections..."
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={(e) => handleSearchQueryChange(e.target.value)}
                            sx={{ 
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    bgcolor: 'action.hover',
                                    '&:hover': {
                                        bgcolor: 'action.hover',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'divider'
                                        }
                                    }
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: 'text.secondary' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {/* Results List */}
                        <List sx={{ 
                            overflow: 'auto', 
                            flex: 1,
                            mx: -2.5,
                            px: 2.5,
                            '& .MuiListItem-root': {
                                borderRadius: 1,
                                mb: 0.5
                            }
                        }}>
                            {searchResults?.isLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                                    <CircularProgress size={24} />
                                </Box>
                            ) : searchResults?.people?.map((person) => {
                                const isSelected = selectedPeople.some(p => p.entityUrn === person.entityUrn);
                                const isDisabled = selectedPeople.length >= 10 && !isSelected;
                                return (
                                    <ListItem
                                        key={person.entityUrn}
                                        disablePadding
                                        sx={{
                                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                                            opacity: isDisabled ? 0.5 : 1,
                                            '&:hover': {
                                                backgroundColor: 'action.hover',
                                                '& .select-button': {
                                                    opacity: 1
                                                }
                                            },
                                            bgcolor: isSelected ? 'action.selected' : 'transparent',
                                            transition: 'background-color 0.2s'
                                        }}
                                    >
                                        <Box 
                                            onClick={() => {
                                                if (!isDisabled) {
                                                    handleSelectPerson(person);
                                                }
                                            }}
                                            sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                width: '100%',
                                                p: 1.5,
                                                pl: 2
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar 
                                                    src={person.image ? pictureToUrl(person.image) : undefined}
                                                    sx={{ width: 36, height: 36 }}
                                                >
                                                    <Person />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={person.name}
                                                secondary={person.tagline}
                                                primaryTypographyProps={{
                                                    variant: 'body2',
                                                    sx: { fontWeight: 500 }
                                                }}
                                                secondaryTypographyProps={{
                                                    variant: 'caption',
                                                    sx: {
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        color: 'text.secondary'
                                                    }
                                                }}
                                            />
                                            <Box 
                                                className="select-button"
                                                sx={{ 
                                                    opacity: isSelected ? 1 : 0,
                                                    transition: 'all 0.2s',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: isSelected ? 'primary.main' : 'text.secondary',
                                                    gap: 0.5,
                                                    ml: 1,
                                                    minWidth: 70,
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                {isSelected ? (
                                                    <>
                                                        <CheckCircle fontSize="small" />
                                                        <Typography variant="caption" sx={{ fontWeight: 500 }}>Selected</Typography>
                                                    </>
                                                ) : (
                                                    <Typography variant="caption" sx={{ fontWeight: 500 }}>Select</Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </ListItem>
                                );
                            })}
                            {!searchResults?.isLoading && searchQuery && (!searchResults?.people || searchResults.people.length === 0) && (
                                <Box sx={{ py: 8, textAlign: 'center', color: 'text.secondary' }}>
                                    <Typography variant="body2">No results found</Typography>
                                </Box>
                            )}
                        </List>
                    </Box>

                    {/* Action Buttons */}
                    <Box sx={{ 
                        p: 2.5,
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: 1,
                        borderColor: 'divider',
                        bgcolor: 'background.paper'
                    }}>
                        {/* Left side */}
                        <Button
                            onClick={handleInviteCopy}
                            startIcon={<ContentCopy fontSize="small" />}
                            sx={{ 
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'text.primary',
                                    bgcolor: 'action.hover'
                                }
                            }}
                        >
                            Copy Link
                        </Button>

                        {/* Right side */}
                        <Box sx={{ display: 'flex', gap: 1.5 }}>
                            <Button 
                                onClick={() => {
                                    setSearchModalOpen(false);
                                    setSelectedPeople([]);
                                    setSearchQuery('');
                                    clearSearch('');
                                }} 
                                sx={{ 
                                    color: 'text.secondary',
                                    fontWeight: 500,
                                    '&:hover': {
                                        bgcolor: 'action.hover'
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disabled={selectedPeople.length === 0}
                                onClick={() => {
                                    if (selectedPeople.length > 0) {
                                        const peopleToInvite = [...selectedPeople];
                                        // Close modal immediately
                                        setSearchModalOpen(false);
                                        setSelectedPeople([]);
                                        setSearchQuery('');
                                        clearSearch('');
                                        // Send invites in background
                                        void sendInvites(peopleToInvite, INVITE_MESSAGE).then(() => {
                                            setToastMessage(`Invites sent to ${peopleToInvite.length} connection${peopleToInvite.length > 1 ? 's' : ''}! ✨`);
                                            setShowToast(true);
                                            setTimeout(() => setShowToast(false), 3000);
                                        });
                                    }
                                }}
                                sx={{
                                    bgcolor: 'primary.main',
                                    fontWeight: 600,
                                    px: 3,
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    },
                                    '&.Mui-disabled': {
                                        bgcolor: 'action.disabledBackground',
                                        color: 'text.disabled'
                                    }
                                }}
                            >
                                Send Invites
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            {/* Toast Notification */}
            {showToast && (
                <div className="fixed bottom-4 right-4 px-4 py-2 bg-gray-800 text-white text-sm rounded-md shadow-lg transition-opacity duration-200">
                    {toastMessage}
                </div>
            )}
        </div>
    )
}