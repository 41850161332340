import { AGENT_TYPE, EmailValueProp } from "interfaces/db"
import { Typography } from "interfaces/typography"
import React from "react"
import { AgentInputBody } from "./AgentInputBody"
import { TranscriptEmailInputBody } from "./TranscriptEmailInputBody"
import { EMAIL_INPUT_DATA } from "./interfaces"
import { EMAIL_INPUT } from "interfaces/services"
import { connect } from "react-redux"
import { RootState } from "store"

export type EmailInputProps = { 
    data: EMAIL_INPUT_DATA,
    selected_section: EMAIL_INPUT,
    onSectionUpdate: (section: EMAIL_INPUT) => void,
    onTranscriptSelect: (transcript_id: string) => void
    onEmailRequest: () => void,
    onBotRequest: () => void
    email_value_props: EmailValueProp[] | null,
}

class ProspectOverviewPageImpl extends React.Component<EmailInputProps> {
    renderProfilePic() {
        const prospect_name = this.props.data.prospect_name
        return <div className="w-7 h-7 p-1 flex items-center text-center justify-center" 
        style={{
            'borderRadius': '50%', 
            'backgroundColor': '#ACE7BA',
            'boxShadow': 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'    
        }}>
            <Typography variant="largeParagraph" fontWeight={650}>
                {prospect_name ? prospect_name[0].toUpperCase() : 'P'}
            </Typography>
        </div>
    }

    renderProfileName() {
        return <Typography fontSize={14} fontWeight={600} variant="largeParagraph">
            {this.props.data.prospect_name ?? 'Unknown Prospect'}
        </Typography>
    }

    renderCompanyJobTitle() {
        const company = this.props.data.prospect_company
        const title = this.props.data.prospect_title
        if (!company && !title) return null
        const text = [title, company].filter((v) => v).join(' at ')
        return <Typography variant="largeParagraph" className="text-gray-500 text-sm">
            {text}
        </Typography>
    }

    renderProfileInformation() {
        return <div className="w-full flex flex-grow flex-col gap-0">
            {this.renderProfileName()}
            {this.renderCompanyJobTitle()}
        </div>
    }

    renderProfileDataSection() {
        return <div className="w-full flex flex-row items-center gap-2">
            {this.renderProfilePic()}
            {this.renderProfileInformation()}
        </div>
    }

    _canApplyPersonlization() {
        if (!this.props.email_value_props || this.props.email_value_props.length === 0) return false
        if (!this.props.data.agent_data[AGENT_TYPE.LINKEDIN].info?.summary && !this.props.data.agent_data[AGENT_TYPE.COMPANY_WEBSITE].info?.summary) return false
        if (!this.props.data.prospect_name  && !this.props.data.prospect_company&& !this.props.data.prospect_title) return false
        return true
    }

    _canCreateBot() {
        if (!this.props.email_value_props || this.props.email_value_props.length === 0) return false
        if (!this.props.data.agent_data[AGENT_TYPE.COMPANY_WEBSITE].info?.summary && !this.props.data.agent_data[AGENT_TYPE.COMPANY_WEBSITE].info?.header_and_content) return false
        if (!this.props.data.prospect_title || !this.props.data.prospect_name) return false
        return true
    }

    renderPersonalizeButton() {
        const canPersonalize = this._canApplyPersonlization()
        return <button 
            onClick={() => {
                if (!canPersonalize) return
            this.props.onEmailRequest.bind(this)()
        }}
            className={`h-8 px-4 rounded-lg transition-all duration-200 flex items-center gap-2 ${
                canPersonalize 
                    ? 'bg-gradient-to-r from-blue-500 via-blue-600 to-indigo-500 hover:from-blue-600 hover:via-blue-700 hover:to-indigo-600 cursor-pointer shadow-sm hover:shadow-md hover:scale-[1.02]' 
                    : 'bg-gray-100 cursor-not-allowed'
            }`}
            style={{ transition: 'all 0.2s ease-in-out' }}
            title={!canPersonalize ? "Requires prospect and company information" : "Generate a personalized email"}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke={canPersonalize ? "white" : "gray"}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <Typography variant="mediumParagraph" color={canPersonalize ? "white" : "gray"} fontWeight={500}>
                Create Email
            </Typography>
        </button>
    }

    renderPracticeButton() {
        const canCreateBot = this._canCreateBot()
        if (!canCreateBot) return null
        return <button 
            onClick={this.props.onBotRequest}
            className={`h-8 px-4 rounded-lg transition-all duration-200 flex items-center gap-2 ${
                canCreateBot 
                    ? 'bg-gradient-to-r from-purple-500 via-purple-600 to-fuchsia-500 hover:from-purple-600 hover:via-purple-700 hover:to-fuchsia-600 cursor-pointer shadow-sm hover:shadow-md hover:scale-[1.02]' 
                    : 'bg-gray-100 cursor-not-allowed'
            }`}
            style={{ transition: 'all 0.2s ease-in-out' }}
            title={!canCreateBot ? "Requires prospect title and company research" : "Create a practice bot for this prospect"}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke={canCreateBot ? "white" : "gray"}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <Typography variant="mediumParagraph" color={canCreateBot ? "white" : "gray"} fontWeight={500}>
                {"Practice with AI"}
            </Typography>
        </button>
    }

    renderHeader() {
        if (!this.props.data.prospect_name && !this.props.data.prospect_company && !this.props.data.prospect_title) return null
        return <div className="w-full flex flex-col px-4 py-2 border-b border-gray-200 bg-white" style={{
            boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
        }}>
            <div className="w-full flex flex-row items-center">
                {this.renderProfileDataSection()}
            </div>
            {this.props.email_value_props && this.props.email_value_props.length > 0 ? (
                <div className="flex items-center gap-3 mt-2">
                    {this.renderPracticeButton()}
                    {this.renderPersonalizeButton()}
                </div>
            ) : null}
        </div>
    }

    renderEmptyState(type: "research" | "transcripts") {
        const content = type === "research" 
            ? {
                title: "No Company Research Available",
                description: "Company information will appear here once available.",
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                )
            }
            : {
                title: "No Call Transcripts Yet",
                description: "Transcripts from your calls will appear here.",
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                    </svg>
                )
            }

        return (
            <div className="w-full h-full flex flex-col items-center justify-center p-8 text-center">
                {content.icon}
                <Typography variant="h3" fontWeight={600} className="mt-4 mb-2">
                    {content.title}
                </Typography>
                <Typography variant="mediumParagraph" className="text-gray-500">
                    {content.description}
                </Typography>
        </div>
        )
    }

    renderSectionHeader(title: string) {
        return (
            <div className="sticky top-0 px-3 py-2 border-b border-gray-200 bg-gradient-to-r from-gray-50 to-white z-10 flex items-center">
                <div className="flex items-center gap-2">
                    {title === "Company Research" ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                    )}
                    <Typography variant="mediumParagraph" fontWeight={600} className="text-gray-700">{title}</Typography>
                </div>
            </div>
        )
    }

    renderResearchSection() {
        const hasResearch = this.props.data.agent_data[AGENT_TYPE.COMPANY_WEBSITE].info?.summary
        
        return (
            <div className="w-1/2 border-r border-gray-200 overflow-hidden flex flex-col bg-white">
                {this.renderSectionHeader("Company Research")}
                <div className="flex-1 overflow-y-auto">
                    {hasResearch ? (
                        <div className="p-3">
                            <AgentInputBody agent_type={AGENT_TYPE.COMPANY_WEBSITE} current_data={this.props.data} />
                        </div>
                    ) : this.renderEmptyState("research")}
                </div>
            </div>
        )
    }

    renderTranscriptsSection() {
        const hasTranscripts = this.props.data.number_info.summaries && this.props.data.number_info.summaries.length > 0
        
        return (
            <div className="w-1/2 overflow-hidden flex flex-col bg-white">
                {this.renderSectionHeader("Call Transcripts")}
                <div className="flex-1 overflow-y-auto">
                    {hasTranscripts ? (
                        <div className="p-3">
                            <TranscriptEmailInputBody onTranscriptSelect={this.props.onTranscriptSelect.bind(this)} current_data={this.props.data} />
                        </div>
                    ) : this.renderEmptyState("transcripts")}
                </div>
        </div>
        )
    }
    
    render() {
        return (
            <div className="w-full h-screen flex flex-col bg-gray-50">
                {this.renderHeader()}
                <div className="flex-1 flex flex-row overflow-hidden">
                    {this.renderResearchSection()}
                    {this.renderTranscriptsSection()}
                </div>
        </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
        return {
            email_value_props: state.emailValueProps.value,
        };
};
    
const ReduxWrapped = connect(mapStateToProps)(ProspectOverviewPageImpl)
  
export { ReduxWrapped as ProspectOverviewPage}