export enum ANALYTIC_REPORTS_TYPE {
    REP_PERFORMANCE='rep_performance',
    TOP_LINE_METRICS='top_line_metrics',
    CALL_FUNNEL='call_funnel',
    WHEN_TO_CALL='when_to_call',
    GEOGRAPHY='geography',
    CADENCE_PERFORMANCE='cadence_performance',
    INDUSTRY_PERFORMANCE='industry_performance',
    LIST_PERFORMANCE='list_performance',
    MARKET_PERFORMANCE='market_performance',
    LEAD_PERFORMANCE='lead_performance',
    NUMBER_HEALTH='number_health',
}

export type AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE;
    label: string;
    uuid: string
}

export const DEFAULT_ANALYTICS_TAB: AnalyticsTab[] = [
    { type: ANALYTIC_REPORTS_TYPE.TOP_LINE_METRICS, label: 'Top Line Metrics', uuid: ANALYTIC_REPORTS_TYPE.TOP_LINE_METRICS },
    { type: ANALYTIC_REPORTS_TYPE.REP_PERFORMANCE, label: 'Rep Performance', uuid: ANALYTIC_REPORTS_TYPE.REP_PERFORMANCE },
    { type: ANALYTIC_REPORTS_TYPE.CALL_FUNNEL, label: 'Call Funnel', uuid: ANALYTIC_REPORTS_TYPE.CALL_FUNNEL },
    { type: ANALYTIC_REPORTS_TYPE.WHEN_TO_CALL, label: 'When to Call', uuid: ANALYTIC_REPORTS_TYPE.WHEN_TO_CALL },
    { type: ANALYTIC_REPORTS_TYPE.GEOGRAPHY, label: 'Connect Rate by State', uuid: ANALYTIC_REPORTS_TYPE.GEOGRAPHY },
]

export const NUMBER_HEALTH_TAB: AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE.NUMBER_HEALTH,
    label: 'Number Health',
    uuid: ANALYTIC_REPORTS_TYPE.NUMBER_HEALTH
}

export const CADENCE_PERFORMANCE_TAB: AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE.CADENCE_PERFORMANCE,
    label: 'Cadence Performance',
    uuid: ANALYTIC_REPORTS_TYPE.CADENCE_PERFORMANCE
}

export const INDUSTRY_PERFORMANCE_TAB: AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE.INDUSTRY_PERFORMANCE,
    label: 'Industry Performance',
    uuid: ANALYTIC_REPORTS_TYPE.INDUSTRY_PERFORMANCE
}

export const LIST_PERFORMANCE_TAB: AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE.LIST_PERFORMANCE,
    label: 'List Performance',
    uuid: ANALYTIC_REPORTS_TYPE.LIST_PERFORMANCE
}

export const MARKET_PERFORMANCE_TAB: AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE.MARKET_PERFORMANCE,
    label: 'Market Performance',
    uuid: ANALYTIC_REPORTS_TYPE.MARKET_PERFORMANCE
}

export const LEAD_PERFORMANCE_TAB: AnalyticsTab = {
    type: ANALYTIC_REPORTS_TYPE.LEAD_PERFORMANCE,
    label: 'Lead Performance',
    uuid: ANALYTIC_REPORTS_TYPE.LEAD_PERFORMANCE
}

export const renderSectionLabel = (label: string, customJSX?: JSX.Element) => {
 return <div className='w-full flex flex-row items-center justify-between'>
        <h1 className="text-2xl font-semibold text-gray-900">{label}</h1>
        {customJSX}
    </div>
}