import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { setLinkedInClient, setSalesNavigatorClient } from '../../lib/redux/linkedin/client/slice';
import { LinkedInChat, LinkedInSalesNavigatorChat } from '../../lib/linkedin';
import { Box } from '@mui/material';
import { LinkedInSidebar } from './LinkedInSidebar/LinkedInSidebar';
import { LinkedInConfiguration } from './Configuration/LinkedInConfiguration';
import { EXTENSION_ID, MESSAGE_TYPES, SUGGEST_FEATURE_PAGE } from 'cfg/endpoints';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setHasInitializedLinkedInClient, setHasInitializedSalesNavigatorClient } from 'lib/redux/linkedin/client/slice';
import { ConversationList } from './ConversationList/ConversationList';
import { MessageView } from './ConversationData/MessageView';
import { LinkedInSalesMessage } from 'interfaces/linkedin-salesnavigator';
import { ProfileDetailsPanel } from './ConversationData/Headers/ProfileDetailsPanel';
import { useLinkedInSearch } from 'lib/redux/linkedin/search/hook';
import { convertPartcipantsToId } from 'lib/redux/linkedin/search/slice';
import { reloadEmailValueProps } from 'lib/redux/store';
import { LinkedInMessage } from 'interfaces/linkedin';
import { addToConversation, ReduxSafeConversation } from 'lib/redux/linkedin/conversations/chat-slice';
import { useLinkedInConversations } from 'lib/redux/linkedin/conversations/list-hook';
import { addToSalesConversation, convertToReduxSafeSalesMessage, ReduxSafeSalesConversation, ReduxSafeSalesMessage } from 'lib/redux/linkedin/conversations/sales-nav-slice';
import { ConversationState, updatePartialConversationState } from 'lib/redux/linkedin/metadata/slice';
import { ConversationId, SOURCE_TYPE } from 'lib/redux/linkedin/types';
import { reloadSnippets } from 'lib/redux/linkedin/snippets/slice';

const INSTALL_URL = 'https://app.trellus.ai/install'
const UPGRADE_URL = 'https://app.trellus.ai/upgrade'

// Global handler to completely suppress ResizeObserver errors
if (typeof window !== 'undefined') {
  const originalHandler = window.onerror;
  window.onerror = (msg, url, lineNo, columnNo, error) => {
    if (msg.toString().includes('ResizeObserver')) {
      return false;
    }
    return originalHandler ? originalHandler(msg, url, lineNo, columnNo, error) : false;
  };
}

export const LinkedIn: React.FC = React.memo(() => {
  // Block ResizeObserver errors and track their source
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    // Block the error at window level
    const errorHandler = (event: ErrorEvent) => {
      if (event.message.includes('ResizeObserver')) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    };

    // Also intercept console errors
    const originalError = console.error;
    console.error = (...args) => {
      if (args[0]?.includes?.('ResizeObserver') || args[0]?.message?.includes?.('ResizeObserver')) {
        return;
      }
      originalError.apply(console, args);
    };
    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
      console.error = originalError;
    };
  }, []);

  const { hasInitializedLinkedInClient, hasInitializedSalesNavigatorClient, client, salesNavigatorClient } = useSelector((state: RootState) => state.linkedinClients);
  const dispatch = useDispatch();
  const [localClient, setLocalClient] = useState<LinkedInChat | null>(null);
  const [localSalesNavigatorClient, setLocalSalesNavigatorClient] = useState<LinkedInSalesNavigatorChat | null>(null);
  const [hasSalesNavigatorClient, setHasSalesNavigatorClient] = useState(true);
  const [isNotSignedIn, setIsNotSignedIn] = useState(false);
  const [wasExplicitlyClosed, setWasExplicitlyClosed] = useState(false);
  const [wasExplicitlyExpanded, setWasExplicitlyExpanded] = useState(false);
  
  const [isCheckingConfig, setIsCheckingConfig] = useState(!hasInitializedLinkedInClient || !hasInitializedSalesNavigatorClient);
  const [hasExtension, setHasExtension] = useState(hasInitializedLinkedInClient && hasInitializedSalesNavigatorClient);
  const [hasLinkedInAccess, setHasLinkedInAccess] = useState(hasInitializedLinkedInClient && hasInitializedSalesNavigatorClient);
  const [initError, setInitError] = useState<string | null>(null);

  const isExpandMode = useSelector((state: RootState) => state.linkedinSelected.expandMode);
  const selectedConversationId = useSelector((state: RootState) => state.linkedinSelected.conversationId);
  const newMessageIds = useSelector((state: RootState) => state.linkedinSelected.newMessages);
  const activeOption = useSelector((state: RootState) => state.linkedinSelected.activeOption);
  const profilePanelOpen = useSelector((state: RootState) => state.linkedinSelected.profilePanelOpen);
  const selectedParticipant = useSelector((state: RootState) => state.linkedinSelected.selectedParticipant);
  const emailValueProps = useSelector((state: RootState) => state.emailValueProps.value);

  const drafts = useSelector((state: RootState) => state.linkedinNewConversation.drafts, shallowEqual);
  const { fetchMatchingParticipants, getMatchingParticipantsViaId } = useLinkedInSearch();
  const { getRecentConnections, getRecentJobChanges } = useLinkedInSearch();

  const chatConversations = useSelector((state: RootState) => state.linkedinChatConversations.conversations);
  const salesNavigatorConversations = useSelector((state: RootState) => state.linkedinSalesNavigatorConversations.conversations);
  const { loadConversations } = useLinkedInConversations();

  const conversationStates = useSelector((state: RootState) => state.linkedinConversationStates.states);
  const snippets = useSelector((state: RootState) => state.linkedinSnippets.snippets);

  // check all chat conversations if we don't have that

  useEffect(() => {
    let participants = [...Object.values(drafts).map((draft) => convertPartcipantsToId(draft.participants)).flat()]
    if (selectedParticipant) participants.push(selectedParticipant);
    participants = participants.filter((v) => {
      const matchingConversation = getMatchingParticipantsViaId(v);
      return !matchingConversation && (v.length > 0 && v !== "")
    });
    if (participants.length > 0) participants.forEach((participant) => {
      if (participant && participant.length > 0 && participant !== "") {
        fetchMatchingParticipants([participant]);
      }
    });
  }, [Object.values(drafts).map((draft) => draft.participants), selectedParticipant]);

  // Check if the extension is installed
  useEffect(() => {
    if (hasExtension) return;
    if (!chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
      setHasExtension(false);
      setIsCheckingConfig(false);
      return;
    } else {
      setHasExtension(true);
    }
  }, []);

  const checkAccess = () => {
    chrome.runtime.sendMessage(EXTENSION_ID, { type: MESSAGE_TYPES.HAS_LINKEDIN_ACCESS }, (response) => {
      setHasLinkedInAccess(response);
      setIsCheckingConfig(false);
    });
  };

  useEffect(() => {
    if (snippets == null) reloadSnippets(dispatch);
  }, [snippets]);

  // Check LinkedIn access
  useEffect(() => {
    let pollInterval: NodeJS.Timeout | null = null;

    if (hasExtension && !hasLinkedInAccess) {
      // Initial check
      checkAccess();

      // Start polling
      pollInterval = setInterval(checkAccess, 2000);
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
        pollInterval = null;
      }
    };
  }, [hasExtension, hasLinkedInAccess]);

  // Initialize clients when LinkedIn access is available
  useEffect(() => {
    if (!hasLinkedInAccess) return
    if (hasInitializedLinkedInClient || hasInitializedSalesNavigatorClient) return;
    const client = new LinkedInChat();
    client.start().then(() => {
      setLocalClient(client);
    }).catch((error) => {
      setIsNotSignedIn(true);
      setIsCheckingConfig(false);
    });
    const salesNavigatorClient = new LinkedInSalesNavigatorChat();
    salesNavigatorClient.start().then(() => {
      setLocalSalesNavigatorClient(salesNavigatorClient);
    }).catch((error) => {
      setHasSalesNavigatorClient(false);
    });
  }, [hasLinkedInAccess]);

  // Initialize client through Redux when available
  useEffect(() => {
    if (!localClient) return;
    dispatch(setLinkedInClient(localClient));
  }, [localClient, dispatch]);

  useEffect(() => {
    if (!localSalesNavigatorClient) return;
    dispatch(setSalesNavigatorClient(localSalesNavigatorClient));
  }, [localSalesNavigatorClient, dispatch]);

  // Set initialized to true when client is initialized
  useEffect(() => {
    if (!client) return;
    dispatch(setHasInitializedLinkedInClient(true));
  }, [client, dispatch]);

  useEffect(() => {
    if (!salesNavigatorClient) return;
    dispatch(setHasInitializedSalesNavigatorClient(true));
  }, [salesNavigatorClient, dispatch]);

  useEffect(() => {
    if (hasSalesNavigatorClient) return;
    dispatch(setHasInitializedSalesNavigatorClient(true));
  }, [hasSalesNavigatorClient, dispatch]);

  useEffect(() => {
    if (hasInitializedLinkedInClient && hasInitializedSalesNavigatorClient) {
      setIsCheckingConfig(false);
    }
  }, [hasInitializedLinkedInClient, hasInitializedSalesNavigatorClient]);

  useEffect(() => {
    if (emailValueProps !== null) return
    if (!hasInitializedLinkedInClient) return
    reloadEmailValueProps(dispatch)
  }, [emailValueProps, dispatch, hasInitializedLinkedInClient]);


  const updateConversationStateOnNewMessage = useCallback((conversationId: ConversationId, lastRepMessageFromRepAt?: number, lastMessageFromProspectAt?: number) => {
    const conversationState = conversationStates[conversationId.id];
    if (!conversationState) return;
    const update: Partial<ConversationState> = {};
    if (lastMessageFromProspectAt) update['lastMessageFromProspectAt'] = lastMessageFromProspectAt;
    if (lastRepMessageFromRepAt) update['lastMessageFromRepAt'] = lastRepMessageFromRepAt;
    dispatch(updatePartialConversationState({
      conversationId,
      conversationState: update
    }));
  }, [dispatch, conversationStates]);

  const isRepSalesMessage = (conversation: ReduxSafeSalesConversation, message: LinkedInSalesMessage) => {
    const repId = conversation.participants.find((x) => x.degree === 0)?.entityUrn
    return message.author === repId
  }

  const onNewSalesMessage = useCallback(async (message: LinkedInSalesMessage) => {
    const extract_id = (id: string) => id.split(':')[3]
    const conversationMatch = salesNavigatorConversations[extract_id(message.threadId)];
    if (conversationMatch && conversationMatch.conversation) {
      dispatch(addToSalesConversation({
        id: extract_id(message.threadId),
        message: convertToReduxSafeSalesMessage(message)
      }))
      const timestamp = message.deliveredAt.getTime()
      const conversationId: ConversationId = {
        id: extract_id(message.threadId),
        sourceType: SOURCE_TYPE.SALES_NAVIGATOR,
        lastActivity: timestamp
      }
      if (isRepSalesMessage(conversationMatch.conversation, message)) {
        updateConversationStateOnNewMessage(conversationId, timestamp)
      } else {
        updateConversationStateOnNewMessage(conversationId, undefined, timestamp)
      }
    } else {
      // will auto-enact an analysis
      loadConversations.reload();
    }
  }, [salesNavigatorConversations, dispatch, loadConversations]);

  const isRepMessage = (conversation: ReduxSafeConversation, message: LinkedInMessage) => {
    const repId = conversation.conversationParticipants.find((x) => x.participantType.member?.distance === 'SELF')?.entityUrn
    return message.sender.entityUrn === repId
  }

  const onNewChatMessage = useCallback(async (message: LinkedInMessage) => {
    const conversationMatch = chatConversations[message.conversation.entityUrn];
    if (conversationMatch && conversationMatch.conversation) {
      dispatch(addToConversation({
        id: message.conversation.entityUrn,
        message: message
      }))
      const timestamp = message.deliveredAt.getTime()
      const conversationId: ConversationId = {
        id: message.conversation.entityUrn,
        sourceType: SOURCE_TYPE.CHAT,
        lastActivity: timestamp
      }
      if (isRepMessage(conversationMatch.conversation, message)) {
        updateConversationStateOnNewMessage(conversationId, timestamp)
      } else {
        updateConversationStateOnNewMessage(conversationId, undefined, timestamp)
      }
    } else {
      loadConversations.reload(); // will auto-enact an analysis
    }
  }, [chatConversations, dispatch, loadConversations]);

  useEffect(() => {
    if (!hasInitializedSalesNavigatorClient) return;
    if (!salesNavigatorClient || !salesNavigatorClient.hasAccess) return;
    salesNavigatorClient.onNewMessage = onNewSalesMessage
  }, [hasInitializedSalesNavigatorClient, salesNavigatorClient, onNewSalesMessage]);

  useEffect(() => {
    if (!hasInitializedLinkedInClient) return;
    if (!client) return;
    client.onNewMessage = onNewChatMessage
  }, [hasInitializedLinkedInClient, client, onNewChatMessage]);

  useEffect(() => {
    if (!hasInitializedLinkedInClient) return;
    if (!client) return;
    getRecentConnections();
    getRecentJobChanges();
  }, [hasInitializedLinkedInClient, client]);

  const shouldShowConversationList = !isExpandMode || (!selectedConversationId && !newMessageIds.length);

  const messageComponentShown = (selectedParticipant !== null || activeOption !== null || selectedConversationId !== null || newMessageIds.length > 0);

  // Pass these handlers to LinkedInSidebar
  const handleExpandClick = useCallback(() => {
    setWasExplicitlyClosed(false);
    setWasExplicitlyExpanded(true);
  }, []);

  const handleCollapseClick = useCallback(() => {
    setWasExplicitlyClosed(true);
    setWasExplicitlyExpanded(false);
  }, []);

  // Determine if sidebar should be expanded
  const shouldExpandSidebar = useMemo(() => {
    if (wasExplicitlyClosed) return false;
    if (wasExplicitlyExpanded) return true;
    if (!messageComponentShown) return true;
    return false;
  }, [wasExplicitlyClosed, wasExplicitlyExpanded, messageComponentShown]);

  if (
    isNotSignedIn ||
    !hasInitializedLinkedInClient ||
    !hasInitializedSalesNavigatorClient ||
    isCheckingConfig || 
    !hasExtension || 
    !hasLinkedInAccess || 
    initError) {
    return <LinkedInConfiguration 
      isCheckingConfig={isCheckingConfig}
      isNotSignedIn={isNotSignedIn}
      hasExtension={hasExtension}
      hasLinkedInAccess={hasLinkedInAccess}
      initError={initError}
      installUrl={INSTALL_URL}
      upgradeUrl={UPGRADE_URL}
    />;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      bgcolor: 'background.default',
      flexDirection: 'column'
    }}>
      <Box sx={{
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        position: 'relative'
      }}>
        <LinkedInSidebar
          isExpanded={shouldExpandSidebar}
          onExpand={handleExpandClick}
          onCollapse={handleCollapseClick}
        />
        <Box sx={{ 
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flex: !shouldShowConversationList ? 'none' : 1,
          width: !shouldShowConversationList ? 0 : messageComponentShown ? 320 : 'auto',
          minWidth: !shouldShowConversationList ? 0 : messageComponentShown ? 320 : 0,
          maxWidth: !shouldShowConversationList ? 0 : messageComponentShown ? 320 : '100%',
          height: '100%',
          overflow: 'hidden',
          borderRight: activeOption ? '1px solid' : 'none',
          borderRightColor: 'divider',
          transition: theme => theme.transitions.create(
            ['width', 'min-width', 'max-width'],
            { duration: theme.transitions.duration.standard }
          ),
          willChange: 'width, min-width, max-width'
        }}>
          <ConversationList />
        </Box>
        {messageComponentShown && <Box sx={{
          flex: 1,
          minWidth: 300,
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          ...(profilePanelOpen && {
            maxWidth: 'calc(100% - 320px)',
          }),
          transition: theme => theme.transitions.create(['max-width'], {
            duration: theme.transitions.duration.standard,
          })
        }}>
          <MessageView />
        </Box>}
        <Box sx={{
          position: 'fixed',
          right: profilePanelOpen ? 0 : '-320px',
          top: 0,
          bottom: 0,
          width: 320,
          height: '100%',
          borderLeft: '1px solid',
          borderLeftColor: 'divider',
          overflow: 'hidden',
          bgcolor: 'background.paper',
          boxShadow: theme => profilePanelOpen ? theme.shadows[8] : 'none',
          transition: theme => theme.transitions.create(['right', 'box-shadow'], {
            duration: theme.transitions.duration.standard,
          }),
          zIndex: theme => theme.zIndex.drawer + 1
        }}>
          {activeOption && (() => {
            return (
              <ProfileDetailsPanel
                open={true}
                name={""}
                pictureUrl={undefined}
                profileUrl={undefined}
                isLoading={false}
              />
            );
          })()}
        </Box>
      </Box>
    </Box>
  );
});