import React, { useEffect, useMemo } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { MessageComposer } from './Composer/MessageComposer';
import { useLinkedInConversationData } from '../../../lib/redux/linkedin/conversations/data-hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { renderSimplifiedConversationName } from '../../../lib/redux/linkedin/helpers';
import { updateActiveOption } from 'lib/redux/linkedin/navigate/slice';
import { ConversationBody } from './Body/ConversationBody';
import { NewMessageBody } from './Body/NewMessageBody';
import { ConversationHeader } from './Headers/ConversationHeader';
import { NewMessageHeader } from './Headers/NewMessageHeader';
import { LoadingConversation } from './LoadingConversation';
import { useLinkedInSearch } from 'lib/redux/linkedin/search/hook';
import { convertLinkedInConnectionToConversationParticipant, convertLinkedInFriendUpdateToConversationParticipant, convertLinkedInNormalizedParticipantToConversationParticipant } from 'interfaces/linkedin';
import { useNewConversation } from 'lib/redux/linkedin/new-conversation/hook';
import { useLinkedInNavigate } from 'lib/redux/linkedin/navigate/hook';

const emptyConversation = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      p: 3
    }}>
      <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
        No conversation selected
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Please select a conversation from the list to view messages
      </Typography>
    </Box>
  );
}

export const MessageView: React.FC = React.memo(() => {
  const { getConversationMetadataForId } = useLinkedInConversationData();
  const { getMatchingParticipantsViaId } = useLinkedInSearch();
  const { toConversation } = useLinkedInNavigate();
  const { addDraftWithParticipants } = useNewConversation();
  const newMessages = useSelector((state: RootState) => state.linkedinSelected.newMessages);
  const selectedConversation = useSelector((state: RootState) => state.linkedinSelected.conversationId);
  const drafts = useSelector((state: RootState) => state.linkedinNewConversation.drafts);
  const activeOption = useSelector((state: RootState) => state.linkedinSelected.activeOption);
  const selectedParticipant = useSelector((state: RootState) => state.linkedinSelected.selectedParticipant);
  const allPeople = useSelector((state: RootState) => state.linkedinSearchResults.allResults);
  const allConnections = useSelector((state: RootState) => state.linkedinSearchResults.allConnections);
  const allJobChanges = useSelector((state: RootState) => state.linkedinSearchResults.allJobChanges);
  const dispatch = useDispatch();

  const selectedConversationMetadata = selectedConversation ? getConversationMetadataForId(selectedConversation) : null;

  const matchingDrafts = Object.values(drafts).filter(draft => newMessages.includes(draft.id));

  const numberItems = matchingDrafts.length + (selectedConversation ? 1 : 0) + (selectedParticipant ? 1 : 0);
  const shouldShowStack = numberItems > 1
  const isDraft = activeOption && matchingDrafts.some(draft => draft.id === activeOption);
  const isParticipant = selectedParticipant && activeOption === selectedParticipant;

  const matchingParticipant = useMemo(() => {
    if (!selectedParticipant) return null;
    return allPeople[selectedParticipant]
  }, [selectedParticipant, allPeople]);
  const matchingConnection = useMemo(() => {
    if (!selectedParticipant) return null;
    return allConnections[selectedParticipant];
  }, [selectedParticipant, allConnections]);
  const matchingJobChange = useMemo(() => {
    if (!selectedParticipant) return null;
    return allJobChanges[selectedParticipant];
  }, [selectedParticipant, allJobChanges]);

  const participantData = selectedParticipant ? getMatchingParticipantsViaId(selectedParticipant) : null;

  useEffect(() => {
    if (!selectedParticipant || !participantData) return;
    if (participantData.isLoading) return
    if (participantData.matchingConversation) {
      toConversation(participantData.matchingConversation, true);
      return;
    }
    if (!matchingParticipant && !matchingConnection && !matchingJobChange) return
    if (!participantData.noMatchingConversation) return 
    if (matchingParticipant) {
      addDraftWithParticipants([convertLinkedInNormalizedParticipantToConversationParticipant(matchingParticipant)]);
    } else if (matchingConnection) {
      addDraftWithParticipants([convertLinkedInConnectionToConversationParticipant(matchingConnection)]);
    } else if (matchingJobChange) {
      addDraftWithParticipants([convertLinkedInFriendUpdateToConversationParticipant(matchingJobChange)]);
    }
  }, [selectedParticipant, participantData, matchingParticipant, matchingConnection, matchingJobChange, addDraftWithParticipants]);

  const onSelect = (option: string) => { dispatch(updateActiveOption(option)) }
  
  if (!selectedConversation && matchingDrafts.length === 0 && !selectedParticipant) { return emptyConversation(); }

  return (
    <Box sx={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden'
    }}>
      {shouldShowStack && (
        <Box sx={{ p: 1, borderBottom: '1px solid', borderColor: 'divider' }}>
          <Stack direction="row" spacing={1} sx={{ overflowX: 'auto', pb: 1 }}>
            {selectedParticipant && (
              <Chip
                label={matchingParticipant ? matchingParticipant.name : matchingConnection ? `${matchingConnection.to.firstName} ${matchingConnection.to.lastName}` : ''}
                variant={activeOption === selectedParticipant ? "filled" : "outlined"}
                color="primary"
                onClick={() => onSelect(selectedParticipant)}
              />
            )}
            {selectedConversation && (
              selectedConversationMetadata ?
              <Chip
                label={String(renderSimplifiedConversationName(selectedConversation, selectedConversationMetadata).name)}
                variant={activeOption === selectedConversation.id ? "filled" : "outlined"}
                color="primary"
                onClick={() => onSelect(selectedConversation.id)}
              />
              :
              <Chip
                label="Loading..."
                variant={activeOption === selectedConversation.id ? "filled" : "outlined"}
                onClick={() => onSelect(selectedConversation.id)}
              />
            )}
            {matchingDrafts.map((draft, index) => (
              <Chip
                key={draft.id}
                label={`Draft #${index + 1}`}
                variant={activeOption === draft.id ? "filled" : "outlined"}
                color="primary"
                onClick={() => onSelect(draft.id)}
              />
            ))}
          </Stack>
        </Box>
      )}
      {isParticipant ? (
        <>
          <LoadingConversation />
        </>
      ) : isDraft ? (
        <>
          <NewMessageHeader draftId={activeOption} />
          <NewMessageBody draftId={activeOption} />
        </>
      ) : selectedConversation ? (
        <>
          <ConversationHeader conversationId={selectedConversation} />
          <ConversationBody conversationId={selectedConversation} />
        </>
      ) : null}
      <MessageComposer />
    </Box>
  );
}); 