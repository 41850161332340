import { EXTENSION_ID, MESSAGE_TYPES } from "cfg/endpoints";

export enum SERVICE_CALLS {
    USER_INFO = 'USER_INFO',
    USER_SETTING = 'USER_SETTINGS',
    TEAM_INFO = 'TEAM_INFO',
    AUTODIALER_INFO = 'AUTODIALER_INFO',
    AUTODIALER_SETTINGS = 'AUTODIALER_SETTINGS',
    AUTODIALER_MAPPINGS = 'AUTODIALER_MAPPINGS',
    AUTODIALER_MAPPING_OPTIONS = 'AUTODIALER_MAPPING_OPTIONS',
    PARALLEL_SETTINGS = 'PARALLEL_SETTINGS',
    PARALLEL_VM_OPTIONS = 'PARALLEL_VM_OPTIONS',
    EMAIL_VALUE_PROPS = 'EMAIL_VALUE_PROPS',
    BOT_SUBSCRIPTION_INFO = 'BOT_SUBSCRIPTION_INFO',
}
  

export function clearCache(service_call: SERVICE_CALLS) {
console.log('clear cache', service_call)
try {
    if (!chrome || !chrome.runtime || !chrome.runtime.sendMessage) return;
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      {
        type: MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_CLEAR_CACHE,
        service_call: service_call
      }
    )
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
}   