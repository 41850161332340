import React, { useEffect } from 'react';
import { Box, IconButton, Tooltip, Badge, Typography, Paper, Avatar, Divider, ButtonGroup, Button, Dialog, DialogTitle, DialogContent, TextField, alpha } from '@mui/material';
import { Search, ChevronLeft, ChevronRight, Person, WorkOutline, Edit, Refresh, InfoOutlined, Close as CloseIcon, Flag as GoalIcon, ExpandMore, ExpandLess, MoreHoriz, Settings } from '@mui/icons-material';
import { SearchModal } from './SearchHeader';
import { useLinkedInNavigate } from 'lib/redux/linkedin/navigate/hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useNewConversation } from 'lib/redux/linkedin/new-conversation/hook';
import { DerivedInboxType, INBOX_ICONS, INBOX_LABELS } from 'lib/redux/linkedin/types';
import { useLinkedInSearch } from 'lib/redux/linkedin/search/hook';
import { LinkedInConnection, LinkedInFriendUpdate, LinkedInPicture } from 'interfaces/linkedin';
import { Analytics } from './Analytics';
import { RecommendationSectionWrapper } from './RecommendationSection';
import { useNavigate } from 'react-router-dom';
import { SettingsPages } from 'components/Settings/Settings';

const COLLAPSED_WIDTH = 48;
const EXPANDED_WIDTH = 220;

const INBOXES = [DerivedInboxType.ALL, DerivedInboxType.SNOOZED, DerivedInboxType.COLD_OUTBOUND, DerivedInboxType.ONGOING_CONVERSATIONS, DerivedInboxType.ARCHIVED];

export const LinkedInSidebar: React.FC<{
  isExpanded: boolean;
  onExpand: () => void;
  onCollapse: () => void;
}> = React.memo(({ isExpanded, onExpand, onCollapse }) => {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [isRetrying, setIsRetrying] = React.useState(false);
  const [expandedSections, setExpandedSections] = React.useState<{
    recentConnections: boolean;
    recentJobChanges: boolean;
  }>({
    recentConnections: false,
    recentJobChanges: false,
  });

  const { toUserInbox, toSelectedParticipant } = useLinkedInNavigate();
  const userInbox = useSelector((state: RootState) => state.linkedinSelected.derivedInboxType);
  const { addDraft } = useNewConversation();
  const recentConnections: LinkedInConnection[] | null = useSelector((state: RootState) => state.linkedinSearchResults.recentConnections);
  const recentJobChanges: LinkedInFriendUpdate[] | null = useSelector((state: RootState) => state.linkedinSearchResults.recentJobChanges);
  const loadingConnections: boolean = useSelector((state: RootState) => state.linkedinSearchResults.loadingConnections);
  const loadingJobChanges: boolean = useSelector((state: RootState) => state.linkedinSearchResults.loadingJobChanges);
  const { getRecentConnections, getRecentJobChanges } = useLinkedInSearch();
  const navigate = useNavigate();
  // Add error effect
  React.useEffect(() => {
    const checkForErrors = () => {
      const hasConnectionError = !recentConnections && !isRetrying;
      const hasJobChangesError = !recentJobChanges && !isRetrying;
      setHasError(hasConnectionError || hasJobChangesError);
    };

    checkForErrors();
  }, [recentConnections, recentJobChanges, isRetrying]);

  // Reset expanded sections when minimizing
  React.useEffect(() => {
    if (!isExpanded) {
      setExpandedSections({
        recentConnections: false,
        recentJobChanges: false,
      });
    }
  }, [isExpanded]);

  const handleNewMessage = () => {
    addDraft();
  };

  const renderInboxIcon = (inbox: DerivedInboxType) => {
    return (
      <Badge
        color="primary"
        max={99}
        sx={{
          '& .MuiBadge-badge': {
            right: -8,
            top: 2,
            fontSize: '0.65rem',
            height: 16,
            minWidth: 16,
            padding: '0 4px',
            fontWeight: 600,
          }
        }}
      >
        {INBOX_ICONS[inbox]}
      </Badge>
    );
  };

  return (
    <Box
      sx={{
        width: isExpanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH,
        height: '100%',
        flex: '0 0 auto',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        py: 0.5,
        px: 0.5,
        transition: 'width 0.2s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '& .custom-scrollbar': {
          '&::-webkit-scrollbar': {
            width: '6px',
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c1',
            borderRadius: '4px',
            '&:hover': {
              background: '#a8a8a8',
            }
          },
          scrollbarWidth: 'thin',
          scrollbarColor: '#c1c1c1 #f1f1f1',
        }
      }}
    >
      {/* Analytics Section */}
      {(!expandedSections.recentConnections && !expandedSections.recentJobChanges || !isExpanded) && (
        <Analytics
          isExpanded={isExpanded}
        />
      )}

      {/* Search and New Message Section */}
      {(!expandedSections.recentConnections && !expandedSections.recentJobChanges || !isExpanded) && (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: 1,
          mb: 1,
          mx: 0.5,
          flexShrink: 0,
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isExpanded ? 'row' : 'column',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}>
            <Box 
              onClick={() => !hasError && setIsSearchOpen(true)}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: isExpanded ? 'flex-start' : 'center',
                flex: isExpanded ? 1 : 'none',
                borderRadius: 1,
                cursor: hasError ? 'not-allowed' : 'pointer',
                bgcolor: 'rgba(0, 0, 0, 0.03)',
                opacity: hasError ? 0.6 : 1,
                '&:hover': { 
                  bgcolor: hasError ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.06)',
                },
                ...(isExpanded ? {
                  px: 1.5,
                  py: 1,
                } : {
                  width: 32,
                  height: 32,
                })
              }}
            >
              <Search sx={{ 
                color: 'text.secondary', 
                fontSize: isExpanded ? 20 : 22
              }} />
              {isExpanded && (
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  noWrap
                  sx={{ 
                    flex: 1,
                    fontSize: '14px',
                    ml: 1,
                  }}
                >
                  Search for chat
                </Typography>
              )}
            </Box>

            <Box
              onClick={() => !hasError && handleNewMessage()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 32,
                height: 32,
                borderRadius: 1,
                cursor: hasError ? 'not-allowed' : 'pointer',
                color: 'text.secondary',
                bgcolor: 'rgba(0, 0, 0, 0.03)',
                opacity: hasError ? 0.6 : 1,
                '&:hover': { 
                  bgcolor: hasError ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.06)',
                },
              }}
            >
              <Edit sx={{ fontSize: 20 }} />
            </Box>
          </Box>
        </Box>
      )}

      {/* Inbox List */}
      {(!expandedSections.recentConnections && !expandedSections.recentJobChanges) && (
        <Box 
          className="custom-scrollbar"
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: isExpanded ? 'flex-start' : 'center', 
            gap: 0.5,
            py: 1,
            borderTop: '1px solid rgba(0, 0, 0, 0.08)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            maxHeight: 'min(30vh, 250px)',
            overflowY: 'auto',
            px: 0.5,
            flexShrink: 0,
          }}
        >
          {INBOXES.map((inbox) => (
            <Tooltip 
              key={inbox} 
              title={!isExpanded ? INBOX_LABELS[inbox] : ''}
              placement="right"
            >
              <Box
                onClick={() => toUserInbox(inbox)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  width: '100%',
                  px: 0.5,
                  py: 0.5,
                  borderRadius: 1,
                  cursor: 'pointer',
                  color: userInbox === inbox ? 'primary.main' : 'text.secondary',
                  backgroundColor: userInbox === inbox ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                  '&:hover': { 
                    backgroundColor: userInbox === inbox 
                      ? 'rgba(25, 118, 210, 0.12)' 
                      : 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  width: 32,
                  height: 32,
                  color: 'inherit',
                }}>
                  {renderInboxIcon(inbox)}
                </Box>
                {isExpanded && (
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      flex: 1,
                      fontSize: '13px',
                    }}
                  >
                    {INBOX_LABELS[inbox]}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          ))}
        </Box>
      )}

      {/* Recommendations Container */}
      {isExpanded && (
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          position: 'relative',
          overflow: 'hidden',
        }}>
          {/* Who to Reach Out To Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              pt: expandedSections.recentConnections || expandedSections.recentJobChanges ? 0 : 1,
              pb: 5,
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              flex: 1,
              overflow: 'auto',
              position: 'relative',
            }}>
              {/* Recent Connections Section */}
              {recentConnections && recentConnections.length > 0 && (
                <RecommendationSectionWrapper
                  title="Recent Connections"
                  items={recentConnections.map(conn => ({
                    name: `${conn.to.firstName} ${conn.to.lastName}`,
                    role: conn.to.headline,
                    id: conn.to.entityUrn,
                    picture: conn.to.picture?.vectorImage
                  }))}
                  onSelect={toSelectedParticipant}
                  onRefresh={() => getRecentConnections(true)}
                  onLoadMore={getRecentConnections}
                  isLoadingMore={loadingConnections}
                  icon={<Person sx={{ fontSize: 14, color: 'text.secondary', opacity: 0.8 }} />}
                  clampRoleText={!expandedSections.recentConnections}
                  isExpanded={expandedSections.recentConnections}
                  onToggleExpand={() => {
                    setExpandedSections(prev => ({
                      recentConnections: !prev.recentConnections,
                      recentJobChanges: false,
                    }));
                  }}
                  flex={recentJobChanges && recentJobChanges.length > 0 ? 1 : undefined}
                />
              )}

              {/* Recent Job Changes Section */}
              {(!expandedSections.recentConnections && recentJobChanges && recentJobChanges.length > 0) && (
                <RecommendationSectionWrapper
                  title="Recent Job Changes"
                  items={recentJobChanges.map(conn => ({
                    name: `${conn.userName}`,
                    role: conn.description,
                    id: conn.userId,
                    picture: conn.userPicture
                  }))}
                  onSelect={toSelectedParticipant}
                  onRefresh={() => getRecentJobChanges(true)}
                  onLoadMore={getRecentJobChanges}
                  isLoadingMore={loadingJobChanges}
                  icon={<WorkOutline sx={{ fontSize: 14, color: 'text.secondary', opacity: 0.8 }} />}
                  clampRoleText={!expandedSections.recentJobChanges}
                  isExpanded={expandedSections.recentJobChanges}
                  onToggleExpand={() => {
                    setExpandedSections(prev => ({
                      recentConnections: false,
                      recentJobChanges: !prev.recentJobChanges,
                    }));
                  }}
                  flex={recentConnections && recentConnections.length > 0 ? 1 : undefined}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* Bottom Controls */}
      <Box sx={{ 
        position: 'absolute',
        bottom: 12,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: isExpanded ? 'space-between' : 'center',
        alignItems: 'center',
        px: isExpanded ? 1.5 : 0,
        flexDirection: isExpanded ? 'row' : 'column',
        gap: isExpanded ? 0 : 1,
        zIndex: 1,
      }}>
        <IconButton
          onClick={() => navigate(`/settings?tab=${SettingsPages.SNIPPETS}`)}
          sx={{
            width: 24,
            height: 24,
            color: 'text.secondary',
            opacity: 0.6,
            transition: 'opacity 0.2s ease-in-out',
            '&:hover': { 
              opacity: 1,
              backgroundColor: 'transparent'
            },
          }}
        >
          <Settings sx={{ fontSize: 20 }} />
        </IconButton>

        <IconButton
          onClick={isExpanded ? onCollapse : onExpand}
          sx={{
            width: 24,
            height: 24,
            color: 'text.secondary',
            opacity: 0.6,
            transition: 'opacity 0.2s ease-in-out',
            '&:hover': { 
              opacity: 1,
              backgroundColor: 'transparent'
            },
          }}
        >
          {isExpanded ? <ChevronLeft sx={{ fontSize: 20 }} /> : <ChevronRight sx={{ fontSize: 20 }} />}
        </IconButton>
      </Box>

      <SearchModal
        open={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </Box>
  );
}); 