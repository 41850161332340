import React, { useState } from 'react';
import { Box, Modal, Typography, CircularProgress, List, ListItem, ListItemAvatar, Avatar, ListItemText, Fade, Paper, Tabs, Tab } from '@mui/material';
import { Search, Person, Group as GroupIcon, LinkedIn as LinkedInIcon, Explore as ExploreIcon } from '@mui/icons-material';
import { SearchResults } from 'lib/redux/linkedin/search/slice';
import { useLinkedInNavigate } from 'lib/redux/linkedin/navigate/hook';
import { pictureToUrl } from 'lib/redux/linkedin/helpers';
import { ConversationId, SOURCE_TYPE } from 'lib/redux/linkedin/types';
import { LinkedInConversation, LinkedInNormalizedParticipant } from 'interfaces/linkedin';
import { LinkedInSalesConversation } from 'interfaces/linkedin-salesnavigator';
import { formatDistanceToNow } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setSelectedParticipant, clearSelectedConversation } from 'lib/redux/linkedin/navigate/slice';

interface SearchResultsModalProps {
  open: boolean;
  onClose: () => void;
  searchQuery: string;
  isLoading?: boolean;
  searchResults?: SearchResults;
  children?: React.ReactNode;
}

type TabValue = 'people' | 'conversations';

export const SearchResultsModal: React.FC<SearchResultsModalProps> = ({
  open,
  onClose,
  searchQuery,
  isLoading = false,
  searchResults,
  children
}) => {
  const [activeTab, setActiveTab] = useState<TabValue>('conversations');
  const { toConversation } = useLinkedInNavigate();
  const dispatch = useDispatch();

  const hasResults = searchResults && (
    searchResults.conversations.conversations.length > 0 ||  
    searchResults.salesNavigatorConversations.conversations.length > 0 ||
    searchResults.people.length > 0
  );

  const firstDegreeConnections = searchResults?.people || [];

  const handleConversationClick = (conversation: ConversationId) => {
    toConversation(conversation);
    onClose();
  };

  const renderPeopleResults = () => {
    if (!searchResults?.people.length) {
      return (
        <Box sx={{ py: 4, textAlign: 'center', color: 'text.secondary' }}>
          <Typography variant="body2">No people found</Typography>
        </Box>
      );
    }

    if (!firstDegreeConnections.length) {
      return (
        <Box sx={{ py: 4, textAlign: 'center', color: 'text.secondary' }}>
          <Typography variant="body2">No first-degree connections found</Typography>
        </Box>
      );
    }

    const handlePersonClick = (person: LinkedInNormalizedParticipant) => {
      dispatch(clearSelectedConversation());
      dispatch(setSelectedParticipant(person.hostIdentityUrn));
      onClose();
    };

    return (
      <List dense>
        {firstDegreeConnections.map((person) => (
          <ListItem
            key={person.entityUrn}
            component="div"
            onClick={() => handlePersonClick(person)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <ListItemAvatar>
              <Avatar src={person.image ? pictureToUrl(person.image) : undefined}>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
              primary={person.name}
              secondary={person.tagline}
              primaryTypographyProps={{
                variant: 'body2',
                sx: { fontWeight: 500 }
              }}
              secondaryTypographyProps={{
                variant: 'caption',
                sx: { 
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const renderConversationResults = () => {
    if (!searchResults?.conversations.conversations.length && !searchResults?.salesNavigatorConversations.conversations.length) {
      return (
        <Box sx={{ py: 4, textAlign: 'center', color: 'text.secondary' }}>
          <Typography variant="body2">No conversations found</Typography>
        </Box>
      );
    }

    // Combine and sort conversations by date
    const allConversations = [
      ...(searchResults?.conversations.conversations || []).map(conv => ({
        ...conv,
        sourceType: SOURCE_TYPE.CHAT,
        lastActivity: (conv.lastActivityAt || conv.createdAt).getTime()
      })),
      ...(searchResults?.salesNavigatorConversations.conversations || []).map(conv => ({
        ...conv,
        sourceType: SOURCE_TYPE.SALES_NAVIGATOR,
        lastActivity: (conv.messages?.[conv.messages.length - 1]?.deliveredAt).getTime()
      }))
    ].sort((a, b) => b.lastActivity - a.lastActivity);

    return (
      <List dense>
        {allConversations.map((conversation) => {
          const isLinkedInChat = conversation.sourceType === SOURCE_TYPE.CHAT;
          const isGroup = isLinkedInChat && (conversation as LinkedInConversation).groupChat;
          
          let otherParticipants = [];
          if (isLinkedInChat) {
            otherParticipants = (conversation as LinkedInConversation).conversationParticipants
              ?.filter((p) => p.participantType?.member?.distance !== 'SELF')
              .map(p => ({
                name: `${p.participantType?.member?.firstName?.text || ''} ${p.participantType?.member?.lastName?.text || ''}`.trim(),
                picture: p.participantType?.member?.profilePicture,
                hostIdentityUrn: p.hostIdentityUrn
              }))
              .filter(p => p.name) || [];
          } else {
            const salesConv = conversation as LinkedInSalesConversation;
            otherParticipants = salesConv.participants
              ?.filter(p => p.degree !== 0)
              .map(p => ({
                name: p.fullName,
                picture: p.profilePictureDisplayImage,
                hostIdentityUrn: p.entityUrn
              }))
              .filter(p => p.name) || [];
          }

          const conversationName = isGroup 
            ? (conversation as LinkedInConversation).title || otherParticipants.map((p: { name: string }) => p.name).join(', ')
            : otherParticipants.map((p: { name: string }) => p.name).join(', ');

          const messageText = isLinkedInChat 
            ? (conversation as LinkedInConversation).messages?.[conversation.messages.length - 1]?.body?.text
            : (conversation as LinkedInSalesConversation).messages?.[conversation.messages.length - 1]?.body;

          return (
            <ListItem
              key={isLinkedInChat ? (conversation as LinkedInConversation).entityUrn : (conversation as LinkedInSalesConversation).id}
              component="div"
              onClick={() => handleConversationClick({
                id: isLinkedInChat ? (conversation as LinkedInConversation).entityUrn : (conversation as LinkedInSalesConversation).id,
                lastActivity: conversation.lastActivity,
                sourceType: conversation.sourceType,
              })}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                  {/* Source Icon */}
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    opacity: 0.7,
                    mr: 1
                  }}>
                    {isLinkedInChat ? (
                      <LinkedInIcon sx={{ fontSize: 14 }} />
                    ) : (
                      <ExploreIcon sx={{ fontSize: 14 }} />
                    )}
                  </Box>

                  {/* Group Icon or Avatar */}
                  {isGroup ? (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        minWidth: 24,
                        minHeight: 24,
                        borderRadius: '50%',
                        bgcolor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 1,
                        flexShrink: 0
                      }}
                    >
                      <GroupIcon sx={{ color: 'white', fontSize: 16 }} />
                    </Box>
                  ) : otherParticipants[0]?.picture ? (
                    <Avatar 
                      src={pictureToUrl(otherParticipants[0].picture)}
                      alt={otherParticipants[0].name}
                      sx={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        bgcolor: 'grey.300',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 1,
                      }}
                    >
                      <Typography variant="caption" style={{ fontSize: '1rem' }}>
                        {otherParticipants[0]?.name?.[0] || '?'}
                      </Typography>
                    </Box>
                  )}

                  {/* Names and Time */}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="body2" sx={{ fontWeight: 500, flex: 1 }}>
                      {conversationName}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatDistanceToNow(new Date(conversation.lastActivity), { addSuffix: true })}
                    </Typography>
                  </Box>
                </Box>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ 
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    pl: 5
                  }}
                >
                  {messageText || ''}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderContent = () => {
    if (!searchQuery) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          py: 8,
          color: 'text.secondary'
        }}>
          <Search sx={{ fontSize: 48, mb: 2, opacity: 0.5 }} />
          <Typography variant="body1">
            Start typing to search conversations
          </Typography>
        </Box>
      );
    }

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (searchResults && !hasResults) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          py: 8,
          color: 'text.secondary'
        }}>
          <Search sx={{ fontSize: 48, mb: 2, opacity: 0.5 }} />
          <Typography variant="body1">
            No results found for "{searchQuery}"
          </Typography>
        </Box>
      );
    }

    return (
      <Fade in={true}>
        <Box>
          <Tabs 
            value={activeTab} 
            onChange={(_, newValue: TabValue) => setActiveTab(newValue)}
            sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}
          >
            <Tab 
              value="conversations" 
              label={`Conversations (${searchResults?.conversations.conversations.length || 0})`}
              sx={{ textTransform: 'none' }}
            />
            <Tab 
              value="people" 
              label={`People (${firstDegreeConnections.length})`}
              sx={{ textTransform: 'none' }}
            />
          </Tabs>
          <Box sx={{ mt: 1, overflow: 'auto', maxHeight: 'calc(80vh - 180px)' }}>
            {activeTab === 'people' ? renderPeopleResults() : renderConversationResults()}
          </Box>
        </Box>
      </Fade>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          }
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
        {renderContent()}
      </Box>
    </Modal>
  );
}; 