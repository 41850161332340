import React, { useState, useEffect } from 'react';
import { Typography } from '../../interfaces/typography';
import { useLocation } from 'react-router-dom';

// Update the interface to match what BotPortal expects
export const CallPlacer: React.FC<{ onPlaceCall: (phoneNumber: string, name: string, customInstructions?: string, address?: string) => void }> = ({ onPlaceCall }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [customInstructions, setCustomInstructions] = useState('');
  const [lastTest, setLastTest] = useState<{
    phoneNumber: string;
    name: string;
    address?: string;
    customInstructions: string;
    date: string;
  } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Check if we're in mobile-only mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const onlyCall = searchParams.get('only_call') === 'true';

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (phoneNumber.trim() && name.trim()) {
      // Set submitting state
      setIsSubmitting(true);
      
      // Call the onPlaceCall prop function passed from BotPortal
      onPlaceCall(
        phoneNumber.trim(), 
        name.trim(), 
        customInstructions.trim(), 
        address.trim() || undefined
      );
      
      // Save the test data to state only (not localStorage)
      const newTest = {
        phoneNumber: phoneNumber.trim(),
        name: name.trim(),
        address: address.trim() || undefined,
        customInstructions: customInstructions.trim(),
        date: new Date().toISOString()
      };
      
      setLastTest(newTest);
      
      // Reset submitting state after a short delay
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
      
      // Don't clear the form - keep values for easier repeat testing
    }
  };

  const clearForm = () => {
    setPhoneNumber('');
    setName('');
    setAddress('');
    setCustomInstructions('');
  };

  const loadSavedTest = (test: any) => {
    setPhoneNumber(test.phoneNumber);
    setName(test.name);
    setAddress(test.address || '');
    setCustomInstructions(test.customInstructions || '');
  };

  // Set styles based on mobile mode
  const inputPadding = onlyCall ? '0.85rem 1rem' : '0.6rem 0.75rem';
  const inputFontSize = onlyCall ? '1.1rem' : '0.9rem';
  const buttonSize = onlyCall ? '1.1rem' : '0.9rem';
  const buttonPadding = onlyCall ? '1rem 1.5rem' : '0.75rem 1rem';
  const labelSize = onlyCall ? '1rem' : '0.85rem';
  const containerPadding = onlyCall ? '1.5rem' : '1.5rem';
  const formGap = onlyCall ? '1.5rem' : '1.25rem';

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      overflow: 'auto'
    }}>
      {/* Test Bot Form */}
      <div style={{
        padding: containerPadding,
        background: 'white',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginBottom: '1.5rem' 
        }}>
          <Typography variant="h2" style={{ fontSize: onlyCall ? '1.3rem' : '1.1rem', fontWeight: 600, margin: 0 }}>
            Test Your Bot
          </Typography>
          
          <button
            type="button"
            onClick={clearForm}
            style={{
              padding: '0.4rem 0.8rem',
              background: 'transparent',
              color: '#6B7280',
              border: '1px solid #E5E7EB',
              borderRadius: '4px',
              fontSize: '0.8rem',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
            }}
          >
            Clear Form
          </button>
        </div>

        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: formGap }}>
          {/* Name and Phone Number - stack vertically on mobile */}
          <div style={{ display: 'flex', flexDirection: onlyCall ? 'column' : 'row', gap: '1rem' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
              <label
                htmlFor="name"
                style={{
                  fontSize: labelSize,
                  fontWeight: 500,
                  color: '#4B5563',
                  marginBottom: onlyCall ? '0.5rem' : '0.25rem'
                }}
              >
                Your Name
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                style={{
                  padding: inputPadding,
                  borderRadius: '6px',
                  border: '1px solid #E2E8F0',
                  fontSize: inputFontSize,
                  transition: 'border-color 0.2s ease',
                  outline: 'none',
                  marginBottom: onlyCall ? '1rem' : '0'
                }}
              />
            </div>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
              <label
                htmlFor="phoneNumber"
                style={{
                  fontSize: labelSize,
                  fontWeight: 500,
                  color: '#4B5563',
                  marginBottom: onlyCall ? '0.5rem' : '0.25rem'
                }}
              >
                Your Phone Number
              </label>
              <input
                id="phoneNumber"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
                style={{
                  padding: inputPadding,
                  borderRadius: '6px',
                  border: '1px solid #E2E8F0',
                  fontSize: inputFontSize,
                  transition: 'border-color 0.2s ease',
                  outline: 'none',
                }}
              />
            </div>
          </div>

          {/* Address field (optional) */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <label
              htmlFor="address"
              style={{
                fontSize: labelSize,
                fontWeight: 500,
                color: '#4B5563',
                marginBottom: onlyCall ? '0.5rem' : '0.25rem',
              }}
            >
              Address (Optional)
            </label>
            <input
              id="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter customer address"
              style={{
                padding: inputPadding,
                borderRadius: '6px',
                border: '1px solid #E2E8F0',
                fontSize: inputFontSize,
                transition: 'border-color 0.2s ease',
                outline: 'none',
              }}
            />
          </div>

          {/* Custom Instructions */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <label
              htmlFor="customInstructions"
              style={{
                fontSize: labelSize,
                fontWeight: 500,
                color: '#4B5563',
                marginBottom: onlyCall ? '0.5rem' : '0.25rem'
              }}
            >
              Custom Instructions (Optional)
            </label>
            <textarea
              id="customInstructions"
              value={customInstructions}
              onChange={(e) => setCustomInstructions(e.target.value)}
              placeholder="Enter any custom instructions for the bot"
              rows={4}
              style={{
                padding: inputPadding,
                borderRadius: '6px',
                border: '1px solid #E2E8F0',
                fontSize: inputFontSize,
                transition: 'border-color 0.2s ease',
                outline: 'none',
                resize: 'vertical',
                minHeight: onlyCall ? '120px' : '100px'
              }}
            />
          </div>

          <button
            type="submit"
            disabled={!phoneNumber.trim() || !name.trim() || isSubmitting}
            style={{
              marginTop: onlyCall ? '1rem' : '0.5rem',
              padding: buttonPadding,
              borderRadius: '6px',
              background: !phoneNumber.trim() || !name.trim() || isSubmitting ? '#E2E8F0' : '#3B82F6',
              color: 'white',
              border: 'none',
              cursor: !phoneNumber.trim() || !name.trim() || isSubmitting ? 'not-allowed' : 'pointer',
              fontSize: buttonSize,
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
              transition: 'all 0.2s ease',
              height: onlyCall ? '3.5rem' : 'auto',
              opacity: isSubmitting ? 0.7 : 1,
            }}
          >
            <svg
              width={onlyCall ? "20" : "16"}
              height={onlyCall ? "20" : "16"}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
            {isSubmitting ? 'Placing Call...' : 'Place Test Call'}
          </button>
        </form>
      </div>

      {/* Last Test - only show this if we're not in mobile-only mode or if we have a lastTest */}
      {lastTest && (
        <div style={{
          padding: '1.5rem',
          background: 'white',
          borderRadius: '8px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
        }}>
          <Typography variant="h2" style={{ marginBottom: '1rem', fontSize: onlyCall ? '1.2rem' : '1.1rem', fontWeight: 600 }}>
            Last Test
          </Typography>

          <div 
            style={{
              padding: onlyCall ? '1.25rem' : '1rem',
              borderRadius: '6px',
              border: '1px solid #E5E7EB',
              background: '#F9FAFB',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
            }}
            onClick={() => loadSavedTest(lastTest)}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem', flexDirection: onlyCall ? 'column' : 'row' }}>
              <Typography variant="mediumParagraph" style={{ fontWeight: 600, color: '#1F2937', fontSize: onlyCall ? '1rem' : '0.9rem' }}>
                {lastTest.name} - {lastTest.phoneNumber}
              </Typography>
              <Typography variant="smallParagraph" style={{ color: '#6B7280', fontSize: onlyCall ? '0.9rem' : '0.8rem', marginTop: onlyCall ? '0.25rem' : '0' }}>
                {new Date(lastTest.date).toLocaleString()}
              </Typography>
            </div>
            
            {lastTest.address && (
              <div style={{ marginTop: '0.5rem' }}>
                <Typography variant="smallParagraph" style={{ color: '#6B7280', fontSize: onlyCall ? '0.9rem' : '0.8rem' }}>
                  Address: {lastTest.address}
                </Typography>
              </div>
            )}
            
            {lastTest.customInstructions && (
              <div style={{ marginTop: '0.5rem' }}>
                <Typography variant="smallParagraph" style={{ color: '#6B7280', fontSize: onlyCall ? '0.9rem' : '0.8rem' }}>
                  Instructions: {lastTest.customInstructions.length > 100 ? `${lastTest.customInstructions.substring(0, 100)}...` : lastTest.customInstructions}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}; 