export const LINKEDIN_PROFILE_TYPE = 'com.linkedin.voyager.dash.identity.profile.Profile'
export const LINKEDIN_OWNPROFILE_TYPE = 'com.linkedin.voyager.common.Me'
export const LINKEDIN_CONNECTION_TYPE = 'com.linkedin.voyager.dash.relationships.Connection'
export const LINKEDIN_MINI_PROFILE_TYPE = 'com.linkedin.voyager.identity.shared.MiniProfile'
export const LINKEDIN_VECTOR_IMAGE_TYPE = 'com.linkedin.common.VectorImage'
export const LINKEDIN_VECTOR_ARTIFACT_TYPE = 'com.linkedin.common.VectorArtifact'
export const LINKEDIN_VIDEO_METADATA_TYPE = 'com.linkedin.videocontent.VideoPlayMetadata'
export const LINKEDIN_VIDEO_DOWNLOAD_METADATA_TYPE = 'com.linkedin.videocontent.ProgressiveDownloadMetadata'
export const LINKEDIN_VIDEO_STREAMING_LOCATION_TYPE = 'com.linkedin.videocontent.StreamingLocation'
export const LINKEDIN_AUDIO_TYPE = 'com.linkedin.messenger.AudioMetadata'
export const LINKEDIN_CONVERSATION_TYPE = 'com.linkedin.messenger.Conversation'
export const LINKEDIN_CONVERSATION_PARTICIPANT_TYPE = 'com.linkedin.messenger.MessagingParticipant'
export const LINKEDIN_CONVERSATION_PARTICIPANT_MEMBER_TYPE = 'com.linkedin.messenger.MemberParticipantInfo'
export const LINKEDIN_CONVERSATION_PARTICIPANT_ORG_TYPE = 'com.linkedin.messenger.OrganizationParticipantInfo'
export const LINKEDIN_CONVERSATION_PARTICIPANT_CUSTOM_TYPE = 'com.linkedin.messenger.CustomParticipantInfo'
export const LINKEDIN_CONVERSATION_DISABLED_FEATURE_TYPE = 'com.linkedin.messenger.ConversationDisabledFeature'
export const LINKEDIN_ATTRIBUTED_TEXT_TYPE = 'com.linkedin.pemberly.text.AttributedText'
export const LINKEDIN_MESSAGE_TYPE = 'com.linkedin.messenger.Message'
export const LINKEDIN_REACTION_SUMMARY_TYPE = 'com.linkedin.messenger.ReactionSummary'

// type definitions in this file are interfaces and omit a bunch of fields that we receive from LinkedIn
// but don't understand or have a use for.

export interface LinkedInOwnProfile {
  '$type': typeof LINKEDIN_OWNPROFILE_TYPE
  // name
  firstName: string
  lastName: string
  // IDs
  dashEntityUrn: string  // API user id of the form `urn:li:fsd_profile:${opaque_string}`
  publicIdentifier: string | null  // `first-last-XXXXX` for forming clickable links, doesn't show up in typeahead search
  // detail fields
  standardizedPronoun: string | null
  customPronoun: string | null
  occupation: string | null
  memorialized: boolean  // Bruce Willis could've used this
  premiumSubscriber: boolean  // probably not useful to us
  // images
  picture: LinkedInPicture | null
}

export interface LinkedInPicture {
  '$type': typeof LINKEDIN_VECTOR_IMAGE_TYPE
  rootUrl: string  // might be empty string
  digitalmediaAsset?: string | null  // some sort of id
  artifacts: LinkedInPictureArtifact[]  // if empty, then rootUrl is the full url
}

export interface LinkedInPictureArtifact {
  '$type': typeof LINKEDIN_VECTOR_ARTIFACT_TYPE
  width: number   // px
  height: number  // px
  fileIdentifyingUrlPathSegment: string  // concatenate onto end of rootUrl for a whole url
  expiresAt?: Date  // seems to only occur on your own profile picture
}

export interface LinkedInVideo {
  '_type': typeof LINKEDIN_VIDEO_METADATA_TYPE
  entityUrn: string
  duration: number  // probably milliseconds
  thumbnail: LinkedInPicture
  liveStreamCreatedAt: Date | null
  liveStreamEndedAt: Date | null
  progressiveStreams: LinkedInVideoStreamVersion[]
}

export interface LinkedInVideoStreamVersion {
  '_type': typeof LINKEDIN_VIDEO_DOWNLOAD_METADATA_TYPE
  size: number | null
  bitRate: number
  width: number
  height: number
  mediaType: string  // probably 'video/mp4'
  streamingLocations: LinkedInVideoStreamLocation[]
}

export interface LinkedInVideoStreamLocation {
  '_type': typeof LINKEDIN_VIDEO_STREAMING_LOCATION_TYPE
  url: string
  expiresAt: Date | null
}

export interface LinkedInAudio {
  '_type': typeof LINKEDIN_AUDIO_TYPE
  url: string
  duration: number  // milliseconds
}

export interface LinkedInAttributedText {
  _type: typeof LINKEDIN_ATTRIBUTED_TEXT_TYPE
  text: string
  //attributes: any[]  // TODO
}

export interface LinkedInConversationList {
  conversations: LinkedInConversation[]
  mailboxName: string | null  // null = multiple mailboxes
  nextCursor: string | null  // null iff continues is false
  continues: boolean  // whether, at retrieval time, there existed more results than were returned
}

export interface LinkedInConversation {
  _type: typeof LINKEDIN_CONVERSATION_TYPE
  // ids
  backendUrn: string       // of the form `urn:li:messagingThread:${thread_id}`
  entityUrn: string        // of the form `urn:li:msg_conversation:(${MiniProfile.dashEntityUrn},${thread_id})`
  conversationUrl: string  // of the form `https://www.linkedin.com/messaging/thread/${thread_id}/`, probably for making clickable links
  // state
  notificationStatus: string,  // observed: 'ACTIVE'
  read: boolean,
  unreadCount: number,
  createdAt: Date,
  lastActivityAt: Date | null,
  lastReadAt: Date | null,
  state: string | null,  // observed: null, 'PENDING'
  // pagination state
  _loadOlderMessagesPrevCursor?: string | null,  // undefined = not attempted yet, string = pass this to loadOlderMessages(), null = reached beginning of history
  _loadNewerMessagesSyncToken?: string | null,
  // defining properties
  title: string | null,
  headlineText: string | null,
  shortHeadlineText: string | null,
  conversationParticipants: LinkedInConversationParticipant[],
  creator: LinkedInConversationParticipant,
  conversationVerificationLabel: string | null,  // observed: null
  conversationVerificationExplanation: string | null,  // observed: null
  groupChat: boolean,
  //hostConversationActions: [],  // TODO
  categories: string[],  // observed: ARCHIVE, INBOX, INMAIL, PRIMARY_INBOX, SECONDARY_INBOX
  disabledFeatures: LinkedInConversationDisabledFeature[],  // probably names of actions we shouldn't attempt
  contentMetadata: {conversationAdContent?: any} | null,  // only seen it on ads
  incompleteRetriableData: boolean,
  messages: LinkedInMessage[],  // LinkedIn sends this as .messages.elements
  conversationTypeText: LinkedInAttributedText | null  // observed .text: "InMail", "LinkedIn Offer", "Sponsored"
}

export interface LinkedInConversationParticipant {
  _type: typeof LINKEDIN_CONVERSATION_PARTICIPANT_TYPE
  hostIdentityUrn: string  // `urn:li:fsd_company:${company_id}` or `urn:li:fsd_profile:${user_id}`
  entityUrn: string // `urn:li:msg_messagingParticipant:${hostIdentityUrn}`
  participantType: {
    member: LinkedInConversationParticipantMemberInfo | null
    organization: LinkedInConversationParticipantOrganizationInfo | null
    custom: LinkedInConversationParticipantCustomInfo | null  // linkedin-internal?
  }
}

export type LinkedInNormalizedParticipant = {
  name: string
  tagline: string | null
  image: LinkedInPicture | null
  hostIdentityUrn: string
  entityUrn: string
  url: string | null
}

export function convertLinkedInNormalizedParticipantToConversationParticipant(p: LinkedInNormalizedParticipant): LinkedInConversationParticipant {
  return {
    _type: LINKEDIN_CONVERSATION_PARTICIPANT_TYPE,
    hostIdentityUrn: p.hostIdentityUrn,
    entityUrn: p.entityUrn,
    participantType: {
      member: {
        _type: LINKEDIN_CONVERSATION_PARTICIPANT_MEMBER_TYPE,
        profileUrl: p.url,
        firstName: {
          _type: LINKEDIN_ATTRIBUTED_TEXT_TYPE,
          text: p.name,
        },
        lastName: null,
        headline: null,
        profilePicture: p.image,
        distance: "DISTANCE_1",
        pronoun: null
      },
      organization: null,
      custom: null
    }
  }
}

export function convertLinkedInConnectionToConversationParticipant(c: LinkedInConnection): LinkedInConversationParticipant {
  return {
    _type: LINKEDIN_CONVERSATION_PARTICIPANT_TYPE,
    hostIdentityUrn: c.to.entityUrn,
    entityUrn: c.to.entityUrn,
    participantType: {
      member: {
        _type: LINKEDIN_CONVERSATION_PARTICIPANT_MEMBER_TYPE,
        profileUrl: null,
        firstName: {
          _type: LINKEDIN_ATTRIBUTED_TEXT_TYPE,
          text: c.to.firstName,
        },
        lastName: {
          _type: LINKEDIN_ATTRIBUTED_TEXT_TYPE,
          text: c.to.lastName,
        },
        headline: null,
        profilePicture: c.to.picture?.vectorImage ?? null,
        distance: "DISTANCE_1",
        pronoun: null
      },
      organization: null,
      custom: null
    }
  }
}

export function convertLinkedInFriendUpdateToConversationParticipant(f: LinkedInFriendUpdate): LinkedInConversationParticipant {
  return {
    _type: LINKEDIN_CONVERSATION_PARTICIPANT_TYPE,
    hostIdentityUrn: f.userId,
    entityUrn: f.userId,
    participantType: {
      member: {
        _type: LINKEDIN_CONVERSATION_PARTICIPANT_MEMBER_TYPE,
        profileUrl: null,
        firstName: {
          _type: LINKEDIN_ATTRIBUTED_TEXT_TYPE,
          text: f.userName,
        },
        lastName: null,
        headline: null,
        profilePicture: f.userPicture ?? null,
        distance: "DISTANCE_1",
        pronoun: null
      },
      organization: null,
      custom: null
    }
  }
}

export interface LinkedInConversationParticipantMemberInfo {
  _type: typeof LINKEDIN_CONVERSATION_PARTICIPANT_MEMBER_TYPE
  profileUrl: string | null  // on LinkedIn
  firstName: LinkedInAttributedText | null
  lastName: LinkedInAttributedText | null
  headline: LinkedInAttributedText | null  // like `${job_title} at ${company}` or whatever
  profilePicture: LinkedInPicture | null
  distance: string  // "SELF", `DISTANCE_${positive_integer}`, or "OUT_OF_NETWORK"
  pronoun: string | null
}

export interface LinkedInConversationParticipantOrganizationInfo {
  _type: typeof LINKEDIN_CONVERSATION_PARTICIPANT_ORG_TYPE
  name: LinkedInAttributedText | null
  tagline: LinkedInAttributedText | null  // cheesy slogan
  logo: LinkedInPicture | null
  pageUrl: string | null  // org's website
}

export interface LinkedInConversationParticipantCustomInfo {
  _type: typeof LINKEDIN_CONVERSATION_PARTICIPANT_CUSTOM_TYPE
  name: LinkedInAttributedText | null
  image: LinkedInPicture | null
}

export interface LinkedInConversationParticipantReference extends Omit<LinkedInConversationParticipant, 'participantType'> {}

export interface LinkedInConversationDisabledFeature {
  _type: typeof LINKEDIN_CONVERSATION_DISABLED_FEATURE_TYPE
  disabledFeature: LinkedInConversationDisableableFeature | string
  reasonText: LinkedInAttributedText | null
}

export type LinkedInConversationDisableableFeature
  = 'ADD_PARTICIPANT'
  | 'CREATE_GROUP_CHAT_LINK'
  | 'CREATE_LINKEDIN_VIRTUAL_MEETING'
  | 'CREATE_NEW_GROUP_CHAT'
  | 'DELETE_MESSAGE'
  | 'DISPLAY_URL_AS_LINK_IN_MESSAGE'
  | 'EDIT_MESSAGE'
  | 'REACTIONS'
  | 'REMOVE_PARTICIPANT'
  | 'RENAMED_CONVERSATION'
  | 'REPLY'
  | 'REPLY_TO_MESSAGE'
  | 'SEND_EXTERNAL_MEDIA_MESSAGE'
  | 'SEND_VIDEO_MESSAGE'
  | 'SEND_VOICE_MESSAGE'
  | 'UPDATE_MESSAGE_REQUEST_STATE'

export interface LinkedInMessage {
  _type: typeof LINKEDIN_MESSAGE_TYPE
  // ids
  backendUrn: string  // `urn:li:messagingMessage:${message_id}`
  backendConversationUrn: string  // `urn:li:messagingThread:${thread_id}`
  entityUrn: string  // `urn:li:msg_message:(urn:li:fsd_profile:${probably_our_user_id},${message_id})`
  conversation: { entityUrn: string }
  // defining properties
  subject: string | null
  body: LinkedInAttributedText | null
  footer: string | null
  sender: LinkedInConversationParticipantReference
  messageBodyRenderFormat: string  // observed: "DEFAULT", "RECALLED"
  // attachments and poll options
  renderContent: LinkedInAttachment[]
  reactionSummaries: LinkedInReactionSummary[]
  // peripheral state
  actor: LinkedInConversationParticipant | null
  deliveredAt: Date
}

export interface LinkedInReactionSummary {
  _type: typeof LINKEDIN_REACTION_SUMMARY_TYPE
  emoji: string  // single character, probably validated against some server-side list
  count: number  // number of people who reacted with this emoji
  viewerReacted: boolean  // whether you're one of them
  firstReactedAt: Date
}

export interface LinkedInAttachment {
  file?: LinkedInUpload | null
  vectorImage?: LinkedInPicture | null
  video?: LinkedInVideo | null
  audio?: LinkedInAudio | null
}

export interface LinkedInUpload {
  assetUrn: string  // `urn:li:digitalmediaAsset:${14 bytes in base64}`
  byteSize: number
  mediaType: string
  name: string
  url: string
}

export interface LinkedInProfile {
  '$type': typeof LINKEDIN_PROFILE_TYPE
  // name
  firstName: string
  lastName: string
  headline: string  // whatever they want to say about themselves
  // IDs
  entityUrn: string
  publicIdentifier: string | null
  // details
  memorialized: boolean
  offers: string | null  // We extract this from .profilePicture on the raw profile. Observed: "HIRING", "OPEN_TO_WORK"
  picture?: {
    vectorImage: LinkedInPicture | null
  }
}

export interface LinkedInConnection {
  '$type': typeof LINKEDIN_CONNECTION_TYPE
  createdAt: Date  // something like when the friend request was accepted
  to: LinkedInProfile
}

export enum LinkedInFriendUpdateType {
  ALL = 'ALL',
  JOB_CHANGE = 'JOB_CHANGE',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  EDUCATION_PROP = 'EDUCATION_PROP',
}

export interface LinkedInFriendUpdate {  // totally artificial because we're parsing it from some mysterious serialization format
  activityUrn: string  // unique identifier of the form `urn:li:activity:${maybe 19 digits}`. can point a browser at `${LINKEDIN_DOT_COM}/feed/update/${activityUrn}`,
                       // except education updates start 'urn:li:prop(EDUCATION_PROP,' and are not linkable
  kind: LinkedInFriendUpdateType
  description: string  // verb phrase, i.e. everything but the person's name
  userId: string
  userName: string
  userPicture?: LinkedInPicture
}
