import React, { useState, useEffect, useRef } from 'react';
import { Loader } from "components/Loader";
import { getServicesManager } from "services";
import { RecordedGreeting } from "interfaces/db";
import { clearCache, SERVICE_CALLS } from 'extension-helpers';

type WorkflowStep = 'initial' | 'recording' | 'recorded';

export const RecordVM: React.FC = () => {
  const [greetings, setGreetings] = useState<RecordedGreeting[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [workflowStep, setWorkflowStep] = useState<WorkflowStep>('initial');
  const [newRecordingName, setNewRecordingName] = useState('');
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  useEffect(() => {
    clearCache(SERVICE_CALLS.PARALLEL_SETTINGS)
    clearCache(SERVICE_CALLS.PARALLEL_VM_OPTIONS)
  }, []);
  // State for editing
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedName, setEditedName] = useState<string>('');

  useEffect(() => {
    getServicesManager().getGreetings().then((fetchedGreetings: RecordedGreeting[] | null) => {
      if (fetchedGreetings) {
        setGreetings(fetchedGreetings);
      }
      setIsLoading(false);
    });
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const claimedMimeType = mediaRecorderRef.current?.mimeType
        const mimeType = claimedMimeType && claimedMimeType.length > 0 ? claimedMimeType : "audio/ogg"
        const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
        setAudioBlob(audioBlob);
        setWorkflowStep('recorded');
      };

      mediaRecorderRef.current.start();
      setWorkflowStep('recording');
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
  };

  const handleRecordNewVoicemail = () => {
    if (workflowStep === 'initial') {
      startRecording();
    } else if (workflowStep === 'recording') {
      stopRecording();
    }
  };

  const handleSaveRecording = async () => {
    if (newRecordingName && audioBlob) {
      try {
        const servicesManager = getServicesManager();
        
        // Convert the audioBlob to a File object and upload
        const file = new File([audioBlob], `${newRecordingName}.wav`, { type: audioBlob.type });
        const result = await servicesManager.uploadGreeting(newRecordingName, file);
        
        if (result) {
          // If upload is successful, update the local state
          setGreetings([...greetings, result]);
          setNewRecordingName('');
          setWorkflowStep('initial');
          setAudioBlob(null);
          // Optionally, show a success message to the user
          alert('Voicemail saved successfully!');
        } else {
          // Handle upload failure
          alert('Failed to save the voicemail. Please try again.');
        }
      } catch (error) {
        console.error('Error saving voicemail:', error);
        alert('An error occurred while saving the voicemail.');
      }
    }
  };

   // New: Function to handle playing a voicemail
   const handlePlayRecording = async (greeting: string) => {
    try {
      const url = await getServicesManager().getSpecificGreetingURL(greeting);
      if (url) {
        setAudioURL(url);
      }
    } catch (error) {
      console.error('Error playing voicemail:', error);
      alert('An error occurred while trying to play the voicemail.');
    }
  };

  // Handle editing a voicemail
  const handleEdit = async (index: number) => {
    setEditingIndex(index);
    setEditedName(greetings[index].name);

    try {
      const url = await getServicesManager().getSpecificGreetingURL(greetings[index].recorded_greeting_id);
      if (url) {
        setAudioURL(url);
      } else {
        console.error('Fetched data is not a url:', JSON.stringify(url));
        alert('Failed to load voicemail audio.');
      }
    } catch (error) {
      console.error('Error fetching voicemail audio:', error);
      alert('Failed to load voicemail audio.');
    }
  };

  const handleSaveEdit = async (index: number) => {
    const oldGreeting = greetings[index];
    const newName = editedName.trim();

    if (!newName) {
      alert('Voicemail name cannot be empty.');
      return;
    }

    try {
      const servicesManager = getServicesManager();
      const updatedGreeting = await servicesManager.renameGreeting(oldGreeting.recorded_greeting_id, newName);

      if (updatedGreeting) {
        const updatedGreetings = [...greetings];
        updatedGreetings[index] = updatedGreeting;
        setGreetings(updatedGreetings);
        setEditingIndex(null);
        setEditedName('');
      } else {
        alert('Failed to rename voicemail. Please try again.');
      }
    } catch (error) {
      console.error('Error renaming voicemail:', error);
      alert('An error occurred while renaming the voicemail.');
    }
  };

  const handleDelete = async (index: number) => {
    try {
      const success = await getServicesManager().deleteGreeting(greetings[index].recorded_greeting_id);
      if (success) {
        setGreetings([...greetings.slice(0, index), ...greetings.slice(index + 1)])
      } else {
        alert('Failed to delete voicemail. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting voicemail:', error);
      alert('An error occurred while deleting the voicemail.');
    }
  }

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditedName('');
    setAudioBlob(null);
  };

  if (isLoading) return <Loader />;

  return (
    <div className='w-full h-full'>
    <div className="max-w-md mx-auto p-6 bg-gray-100 rounded-xl">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Record VM</h1>
      
      <div className="bg-white rounded-lg p-6 mb-6">
        {workflowStep === 'initial' && (
          <button 
            onClick={handleRecordNewVoicemail}
            className="w-full py-4 px-6 rounded-full font-bold text-white bg-blue-500 hover:bg-blue-600 transition-all duration-300 flex items-center justify-center"
          >
            <span className="mr-2">🎤</span>
            Record New Voicemail
          </button>
        )}
        
        {workflowStep === 'recording' && (
          <button 
            onClick={handleRecordNewVoicemail}
            className="w-full py-4 px-6 rounded-full font-bold text-white bg-red-500 hover:bg-red-600 transition-all duration-300 flex items-center justify-center"
          >
            <span className="mr-2">⏹️</span>
            Stop Recording
          </button>
        )}
        
        {workflowStep === 'recorded' && (
          <div className="space-y-4">
            {audioBlob && (
              <audio src={URL.createObjectURL(audioBlob)} controls className="w-full mb-4" />
            )}
            <input
              type="text"
              placeholder="Enter voicemail name"
              value={newRecordingName}
              onChange={(e) => setNewRecordingName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            <div className="flex space-x-2">
              <button
                onClick={handleSaveRecording}
                disabled={!newRecordingName || !audioBlob}
                className="flex-1 py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors disabled:bg-green-300 disabled:cursor-not-allowed"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setWorkflowStep('initial');
                  setAudioBlob(null);
                  setNewRecordingName('');
                }}
                className="flex-1 py-2 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
              >
                Discard
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Saved Voicemails</h2>
        {greetings.length === 0 ? (
          <p className="text-gray-500 italic text-center py-4">No saved voicemails yet.</p>
        ) : (
          <ul className="space-y-3">
            {greetings.map((greeting, index) => (
              <li
                key={index}
                className={`flex justify-between items-center p-1 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors ${
                  editingIndex === index ? 'border-2 border-blue-500' : ''
                }`}
              >
                {editingIndex === index ? (
                  // Expanded view for editing
                  <div className="w-full">
                    <div className="bg-white rounded-lg p-6">
                      <h2 className="text-xl font-semibold mb-4 text-gray-700">Edit Voicemail</h2>
                      {audioURL && <audio
                        src={audioURL}
                        controls
                        className="w-full mb-4"
                      />}
                      <input
                        type="text"
                        placeholder="Enter new voicemail name"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md mb-4"
                      />
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleSaveEdit(index)}
                          className="flex-1 py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className="flex-1 py-2 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Default view with Edit button
                  <>
                    <span className="text-gray-700 font-medium">{greeting.name}</span>
                    <div className="space-x-2">
                      <button
                        onClick={() => handleEdit(index)}
                        disabled={editingIndex !== null && editingIndex !== index}
                        className={`text-yellow-500 hover:text-yellow-600 p-2 rounded-full hover:bg-yellow-100 transition-colors ${
                          editingIndex !== null && editingIndex !== index ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        ✏️
                      </button>
                      <button
                        onClick={() => handleDelete(index)}
                        disabled={editingIndex !== null && editingIndex !== index}
                        className={`text-red-500 hover:text-red-600 p-2 rounded-full hover:bg-red-100 transition-colors ${
                          editingIndex !== null && editingIndex !== index ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        ❌
                      </button>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
    </div>
  );
};
