import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, IconButton, Paper, List, ListItem, ListItemText, Popper, ClickAwayListener, Modal, Typography, CircularProgress, Chip, Button } from '@mui/material';
import { Image as ImageIcon, AttachFile as AttachFileIcon, InsertEmoticon as InsertEmoticonIcon, Send as SendIcon, Close as CloseIcon, ZoomOutMap as ZoomOutMapIcon, AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useSendMessage } from 'lib/redux/linkedin/conversations/message-hook';
import { processSnippetText } from 'lib/redux/linkedin/snippets/slice';
import { getUrlSalesNavigator, renderSimplifiedConversationName } from 'lib/redux/linkedin/helpers';
import { SOURCE_TYPE } from 'lib/redux/linkedin/types';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { Expand, Shrink } from 'lucide-react';
import { useLinkedInNavigate } from '../../../../lib/redux/linkedin/navigate/hook';
import { getServicesManager } from 'services';
import { LinkedInGenRequest } from 'interfaces/services';
import { ReduxSafeSalesConversation } from 'lib/redux/linkedin/conversations/sales-nav-slice';
import { ReduxSafeConversation } from 'lib/redux/linkedin/conversations/chat-slice';
import { useNavigate } from 'react-router-dom';
import { SettingsPages } from 'components/Settings';

export const MessageComposer: React.FC = React.memo(() => {
  const [message, setMessage] = useState('');
  const [showSnippets, setShowSnippets] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [snippetSearch, setSnippetSearch] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const { canSend, sendMessage } = useSendMessage();
  const selectedConversation = useSelector((state: RootState) => state.linkedinSelected.conversationId);
  const snippets = useSelector((state: RootState) => state.linkedinSnippets.snippets);
  const emailValueProps = useSelector((state: RootState) => state.emailValueProps.value);
  const conversation = useSelector((state: RootState) => 
    selectedConversation ?
    selectedConversation?.sourceType === SOURCE_TYPE.CHAT 
      ? state.linkedinChatConversations.conversations[selectedConversation?.id]?.conversation
      : state.linkedinSalesNavigatorConversations.conversations[selectedConversation?.id]?.conversation
    : null
  );
  const navigate = useNavigate();
  const [attachedImages, setAttachedImages] = useState<Array<{ file: File; previewUrl: string }>>([]);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [showImagePreview, setShowImagePreview] = useState<string | null>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const expandMode = useSelector((state: RootState) => state.linkedinSelected.expandMode);
  const { toExpandMode } = useLinkedInNavigate();

  const handleSend = async () => {
    const trimmedMessage = message.trim();
    const attachments = [
      ...attachedImages.map(img => img.file),
      ...attachedFiles
    ];
    
    if (!trimmedMessage && attachments.length === 0) return;
    try {
      await sendMessage(trimmedMessage, attachments);
      
      // Clear the form after successful send
      setMessage('');
      // Clean up all image preview URLs
      attachedImages.forEach(img => URL.revokeObjectURL(img.previewUrl));
      setAttachedImages([]);
      setAttachedFiles([]);
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (showSnippets) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();
        
        const newIndex = event.key === 'ArrowUp'
          ? Math.max(0, selectedIndex - 1)
          : Math.min(filteredSnippets.length - 1, selectedIndex + 1);
        
        setSelectedIndex(newIndex);

        // Scroll selected item into view
        const listItem = document.querySelector(`[data-snippet-index="${newIndex}"]`);
        if (listItem) {
          listItem.scrollIntoView({ block: 'nearest' });
        }
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (filteredSnippets.length > 0) {
          handleSnippetSelect(filteredSnippets[selectedIndex].snippet_text);
        }
      } else if (event.key === 'Escape') {
        setShowSnippets(false);
      }
      return; // Stop here if snippets are shown
    }

    // Only handle message sending if no snippets are shown
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setMessage(newValue);

    // Check for snippet trigger
    const lastChar = newValue[newValue.length - 1];
    if (lastChar === '/') {
      setShowSnippets(true);
      setSnippetSearch('');
    } else if (showSnippets && !newValue.includes('/')) {
      setShowSnippets(false);
    } else if (showSnippets) {
      const searchText = newValue.split('/').pop() || '';
      setSnippetSearch(searchText.toLowerCase());
    }
  };

  const handleSnippetSelect = (snippetText: string) => {
    if (!conversation) return;
    
    const { name } = renderSimplifiedConversationName(selectedConversation!, conversation);
    const firstName = name.split(' ')[0];
    
    const processedText = processSnippetText(snippetText, firstName);
    // Replace the entire text after the last slash with the processed snippet
    const lastSlashIndex = message.lastIndexOf('/');
    setMessage(lastSlashIndex >= 0 ? message.slice(0, lastSlashIndex) + processedText : processedText);
    setShowSnippets(false);
  };

  const filteredSnippets = snippets?.filter(snippet => 
    snippet.snippet_name.toLowerCase().includes(snippetSearch.toLowerCase())
  ) ?? [];

  // Reset selected index when filtered snippets change
  useEffect(() => {
    setSelectedIndex(0);
  }, [snippetSearch]);

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const cursor = textFieldRef.current?.querySelector('textarea')?.selectionStart || message.length;
    const newMessage = message.slice(0, cursor) + emojiData.emoji + message.slice(cursor);
    setMessage(newMessage);
    setShowEmojiPicker(false);
  };

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    const newImages = imageFiles.map(file => ({
      file,
      previewUrl: URL.createObjectURL(file)
    }));
    
    setAttachedImages(prev => [...prev, ...newImages]);
    
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const validFiles = files.filter(file => {
      if (file.size > 25 * 1024 * 1024) {
        alert(`File ${file.name} is larger than 25MB`);
        return false;
      }
      return true;
    });
    
    setAttachedFiles(prev => [...prev, ...validFiles]);
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemoveImage = (index: number) => {
    setAttachedImages(prev => {
      const newImages = [...prev];
      URL.revokeObjectURL(newImages[index].previewUrl);
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const handleRemoveFile = (index: number) => {
    setAttachedFiles(prev => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  useEffect(() => {
    return () => {
      // Cleanup preview URLs when component unmounts
      attachedImages.forEach(img => URL.revokeObjectURL(img.previewUrl));
    };
  }, []);

  const handleImageButtonClick = () => {
    imageInputRef.current?.click();
  };

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleGenerateAI = async () => {
    if (!conversation || !emailValueProps || emailValueProps.length === 0 || !selectedConversation) return;
    
    setIsGeneratingAI(true);
    try {
      const { name } = renderSimplifiedConversationName(selectedConversation, conversation);
      
      let messageHistory = null;
      let research = null;
      if (selectedConversation.sourceType === SOURCE_TYPE.CHAT) {
        const chatConversation = conversation as ReduxSafeConversation;
        messageHistory = chatConversation.messages ? JSON.stringify(chatConversation.messages.map((msg: any) => ({
          text: typeof msg.body === 'string' ? msg.body : msg.body?.text || '',
          timestamp: msg.deliveredAt,
          sender: msg.sender.entityUrn
        }))) : null;
        const otherParticipant = chatConversation.conversationParticipants.filter((v) => v.participantType?.member?.distance !== 'SELF')[0]
        if (otherParticipant) {
          research = `${name} - ${otherParticipant.participantType?.member?.headline}`
        } else {
          research = `${name} - No research`
        }
      } else {
        const salesConversation = conversation as ReduxSafeSalesConversation;
        messageHistory = salesConversation.messages ? JSON.stringify(salesConversation.messages.map((msg: any) => ({
          text: msg.body,
          timestamp: msg.deliveredAt,
          sender: msg.author
        }))) : null;
        research = `${name} - No research`
      }

      const request: LinkedInGenRequest = {
        prospect_name: name,
        platform_person_url: getUrlSalesNavigator(selectedConversation) ?? 'NO_URL_FOUND',
        value_prop: emailValueProps[0],
        research: research,
        message_history: messageHistory
      };

      const response = await getServicesManager().generateLinkedInMessage(request);
      if (response?.gen_message) {
        setMessage(response.gen_message);
      }
    } catch (error) {
      console.error('Failed to generate AI message:', error);
    } finally {
      setIsGeneratingAI(false);
    }
  };

  return (
    <Box sx={{ 
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1.5,
      borderTop: '1px solid',
      borderColor: 'divider',
      backgroundColor: 'background.paper',
      position: 'relative'
    }}>
      {/* Hidden file inputs */}
      <input
        type="file"
        ref={imageInputRef}
        onChange={handleImageSelect}
        accept="image/*"
        multiple
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        multiple
        style={{ display: 'none' }}
      />

      {/* Attached files preview */}
      {(attachedImages.length > 0 || attachedFiles.length > 0) && (
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mb: 1
        }}>
          {attachedImages.length > 0 && (
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
              gap: 1
            }}>
              {attachedImages.map((img, index) => (
                <Box
                  key={index}
                  sx={{ 
                    p: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Box 
                    component="img"
                    src={img.previewUrl}
                    alt={`Preview ${index + 1}`}
                    sx={{ 
                      width: 40,
                      height: 40,
                      objectFit: 'cover',
                      borderRadius: 0.5,
                      cursor: 'pointer'
                    }}
                    onClick={() => setShowImagePreview(img.previewUrl)}
                  />
                  <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Typography noWrap variant="body2">
                      {img.file.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {(img.file.size / 1024 / 1024).toFixed(2)} MB
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveImage(index)}
                      sx={{ color: 'text.secondary' }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => setShowImagePreview(img.previewUrl)}
                      sx={{ color: 'text.secondary' }}
                    >
                      <ZoomOutMapIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {attachedFiles.length > 0 && (
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
              gap: 1
            }}>
              {attachedFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{ 
                    p: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Box 
                    sx={{ 
                      width: 40,
                      height: 40,
                      backgroundColor: 'white',
                      borderRadius: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <AttachFileIcon sx={{ color: 'text.secondary' }} />
                  </Box>
                  <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Typography noWrap variant="body2">
                      {file.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFile(index)}
                    sx={{ color: 'text.secondary' }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}

      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5
      }}>
        <TextField
          fullWidth
          multiline
          maxRows={6}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder="Write a message... (Type / for snippets)"
          variant="outlined"
          size="small"
          ref={textFieldRef}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#f3f3f3',
              minHeight: '44px',
              borderRadius: '12px',
              '& fieldset': {
                border: 'none'
              },
              '&:hover fieldset': {
                border: 'none'
              },
              '&.Mui-focused fieldset': {
                border: 'none'
              },
              '& textarea': {
                p: 1.5
              }
            }
          }}
        />
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton 
              size="small" 
              onClick={handleImageButtonClick}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: 'action.hover'
                }
              }}
            >
              <ImageIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <IconButton 
              size="small"
              onClick={handleFileButtonClick}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: 'action.hover'
                }
              }}
            >
              <AttachFileIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={(e) => {
                e.stopPropagation();
                setShowEmojiPicker(!showEmojiPicker);
              }}
              sx={{
                backgroundColor: showEmojiPicker ? 'action.selected' : 'transparent',
                '&:hover': {
                  backgroundColor: showEmojiPicker ? 'action.selected' : 'action.hover'
                }
              }}
            >
              <InsertEmoticonIcon sx={{ fontSize: 20 }} />
            </IconButton>
            {emailValueProps && emailValueProps.length > 0 && (
              <IconButton
                size="small"
                onClick={handleGenerateAI}
                disabled={isGeneratingAI}
                sx={{
                  color: isGeneratingAI ? 'primary.main' : 'text.secondary',
                  position: 'relative',
                  '&:hover': {
                    color: 'primary.main',
                    backgroundColor: 'action.hover'
                  }
                }}
              >
                <AutoAwesomeIcon sx={{ fontSize: 20 }} />
                {isGeneratingAI && (
                  <CircularProgress
                    size={28}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-14px',
                      marginLeft: '-14px'
                    }}
                  />
                )}
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton 
              onClick={() => toExpandMode(!expandMode)} 
              size="small"
              sx={{ color: 'text.secondary' }}
            >
              {expandMode ? <Shrink style={{ width: 20, height: 20 }} /> : <Expand style={{ width: 20, height: 20 }} />}
            </IconButton>
            <IconButton 
              onClick={handleSend}
              disabled={(!message.trim() && attachedImages.length === 0 && attachedFiles.length === 0) || !canSend}
              size="small"
              sx={{ 
                backgroundColor: (!message.trim() && attachedImages.length === 0 && attachedFiles.length === 0) ? undefined : '#0a66c2',
                color: (!message.trim() && attachedImages.length === 0 && attachedFiles.length === 0) ? undefined : 'white',
                '&:hover': {
                  backgroundColor: (!message.trim() && attachedImages.length === 0 && attachedFiles.length === 0) ? undefined : '#004182'
                }
              }}
            >
              <SendIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Modal
        open={!!showImagePreview}
        onClose={() => setShowImagePreview(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}
      >
        <Box
          sx={{
            position: 'relative',
            maxWidth: '90vw',
            maxHeight: '90vh',
            outline: 'none',
          }}
        >
          <IconButton
            onClick={() => setShowImagePreview(null)}
            sx={{
              position: 'absolute',
              top: -40,
              right: 0,
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="img"
            src={showImagePreview || ''}
            alt="Preview"
            sx={{
              maxWidth: '100%',
              maxHeight: '90vh',
              objectFit: 'contain',
              borderRadius: 1,
              boxShadow: 24
            }}
          />
        </Box>
      </Modal>

      {showEmojiPicker && (
        <Popper
          open={true}
          anchorEl={textFieldRef.current}
          placement="top-start"
          style={{ zIndex: 1300 }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
            <Box sx={{ 
              backgroundColor: 'background.paper',
              borderRadius: 1,
              boxShadow: 3,
              '.EmojiPickerReact': {
                '--epr-bg-color': 'transparent',
                '--epr-category-label-bg-color': 'transparent',
                border: 'none',
                boxShadow: 'none'
              }
            }}>
              <EmojiPicker 
                onEmojiClick={(emojiData) => {
                  handleEmojiClick(emojiData);
                  setShowEmojiPicker(false);
                }}
                autoFocusSearch={false}
                width={320}
                height={400}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      )}

      {showSnippets && textFieldRef.current && (
        <Popper
          open={true}
          anchorEl={textFieldRef.current}
          placement="top-start"
          style={{ width: textFieldRef.current.clientWidth, zIndex: 1300 }}
        >
          <ClickAwayListener onClickAway={() => setShowSnippets(false)}>
            <Paper 
              elevation={0}
              sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '8px',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                overflow: 'hidden',
                mt: 1
              }}
            >
              <List sx={{ 
                maxHeight: 300, 
                overflow: 'auto',
                py: 1,
                px: 0.5
              }}>
                {filteredSnippets.length > 0 ? (
                  filteredSnippets.map((snippet, index) => (
                    <ListItem
                      key={snippet.snippet_id}
                      component="div"
                      onClick={() => handleSnippetSelect(snippet.snippet_text)}
                      data-snippet-index={index}
                      sx={{
                        py: 1,
                        px: 1.5,
                        cursor: 'pointer',
                        borderRadius: 1,
                        backgroundColor: index === selectedIndex ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <ListItemText 
                        primary={
                          <Typography sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            color: 'text.primary',
                            mb: 0.5
                          }}>
                            {snippet.snippet_name}
                          </Typography>
                        }
                        secondary={
                          <Typography 
                            component="div" 
                            sx={{ 
                              color: 'text.secondary',
                              fontSize: '0.8125rem',
                              lineHeight: 1.5
                            }}
                          >
                            {snippet.snippet_text.split(/(\{\{name\}\})/).map((part: string, i: number) => (
                              part === '{{name}}' ? (
                                <Box
                                  key={i}
                                  component="span"
                                  sx={{
                                    mx: 0.5,
                                    px: 0.75,
                                    py: 0.25,
                                    borderRadius: 0.75,
                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    fontSize: '0.75rem',
                                    fontWeight: 500,
                                    color: 'text.primary',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  name
                                </Box>
                              ) : (
                                <span key={i}>{part}</span>
                              )
                            ))}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem sx={{ py: 2, px: 2 }}>
                    <ListItemText 
                      primary={
                        <Typography sx={{ 
                          fontSize: '0.875rem',
                          color: 'text.secondary',
                          textAlign: 'center'
                        }}>
                          No snippets found
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>
              <Box
                sx={{
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: 'rgba(0, 0, 0, 0.02)'
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.75rem',
                    ml: 1
                  }}
                >
                  Type to search snippets
                </Typography>
                <Button
                  size="small"
                  onClick={() => {
                    navigate(`/settings?tab=${SettingsPages.SNIPPETS}`);
                  }}
                  sx={{
                    fontSize: '0.75rem',
                    textTransform: 'none',
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'text.primary',
                      bgcolor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  Configure snippets
                </Button>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </Box>
  );
}); 