import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Bot, BotCall } from '../../interfaces/db'
import { Typography } from '../../interfaces/typography'
import { Call } from "@vapi-ai/server-sdk/api"
import { VAPI_LIST_KEY } from 'cfg/vapi'
import { CallDetailsPanel } from './CallDetailsPanel'
import { CallPlacer } from './CallPlacer'
import { OutboundCallManager } from './OutboundCallManager'

import { getServicesManager } from '../../services'
import { useVapi } from './vapi'

// CSS for the pulsing animation
const pulseAnimation = `
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px rgba(16, 185, 129, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
    }
  }
`;

type TabType = 'calls' | 'place-call';

// Tab Button Component
const TabButton: React.FC<{ 
  tab: TabType; 
  label: string;
  activeTab: TabType;
  setActiveTab: (tab: TabType) => void;
}> = ({ tab, label, activeTab, setActiveTab }) => (
  <button
    onClick={() => setActiveTab(tab)}
    style={{
      padding: '0.6rem 1.2rem',
      background: activeTab === tab ? 'white' : 'transparent',
      border: 'none',
      borderRadius: '8px 8px 0 0',
      cursor: 'pointer',
      fontWeight: activeTab === tab ? 600 : 400,
      color: activeTab === tab ? '#1F2937' : '#6B7280',
      transition: 'all 0.2s ease',
    }}
  >
    {label}
  </button>
);

export const BotPortal: React.FC = () => {
  const { botId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const onlyCall = searchParams.get('only_call') === 'true'
  
  const [activeTab, setActiveTab] = useState<TabType>(onlyCall ? 'place-call' : 'calls')
  const [errors, setErrors] = useState<{[key: string]: boolean}>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [inputBotId, setInputBotId] = useState('')
  const [selectedCall, setSelectedCall] = useState<Call | null>(null)
  const [isActivePanelExpanded, setIsActivePanelExpanded] = useState(!onlyCall)
  const [loadingMoreCalls, setLoadingMoreCalls] = useState(false)
  const [timeNow, setTimeNow] = useState(new Date())
  
  // Timer for updating elapsed time
  const timerRef = useRef<number | null>(null);

  // Use the updated vapi hook with the botId
  const { 
    placeCall, 
    pollForActiveCalls, 
    allBotCalls, 
    fetchPastCalls, 
    hasMorePastCalls, 
    isReady 
  } = useVapi(botId || null);

  // Define activeCalls before using it in the effect
  const activeCalls = useMemo(() => {
    return allBotCalls.filter((x) => !x.endedAt && x.assistantId === botId && !x.endedReason);
  }, [allBotCalls, botId]);

  const allBotCallsForAssistant = useMemo(() => {
    return allBotCalls.filter((x) => x.assistantId === botId);
  }, [allBotCalls, botId]);

  // Memoize the selected call to prevent unnecessary re-renders
  const memoizedSelectedCall = useMemo(() => {
    if (!selectedCall) return null;
    
    // Find the call in allBotCalls
    const updatedCall = allBotCalls.find(call => call.id === selectedCall.id);
    
    // Only return the updated call if it's different from the current selectedCall
    if (updatedCall && JSON.stringify(updatedCall) !== JSON.stringify(selectedCall)) {
      return updatedCall;
    }
    
    return selectedCall;
  }, [selectedCall, allBotCalls]);

  useEffect(() => {
    if (!botId || !isReady || onlyCall) return;
    fetchPastCalls();
    pollForActiveCalls();
  }, [botId, isReady, fetchPastCalls, pollForActiveCalls, onlyCall]);

  // Set up timer to update elapsed time for active calls
  useEffect(() => {
    // Only start the timer if there are active calls
    if (activeCalls.length > 0) {
      // Clear any existing timer
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
      
      // Set up a new timer that updates every second
      timerRef.current = window.setInterval(() => {
        setTimeNow(new Date());
      }, 1000);
    } else if (timerRef.current) {
      // Clear the timer if there are no active calls
      window.clearInterval(timerRef.current);
      timerRef.current = null;
    }
    
    // Clean up on unmount
    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, [activeCalls.length]);

  const handleLoadMoreCalls = async () => {
    if (loadingMoreCalls || !hasMorePastCalls) return;
    setLoadingMoreCalls(true);
    try {
      await fetchPastCalls();
    } catch (error) {
      console.error('Error fetching more calls:', error);
    } finally {
      setLoadingMoreCalls(false);
    }
  };

  const handleSelectCall = (call: Call) => {
    // Only update if the selected call is different
    if (!selectedCall || selectedCall.id !== call.id) {
      setSelectedCall(call);
    }
  }

  const handleClosePanel = () => {
    setSelectedCall(null)
  }

  const handleSubmitBotId = (e: React.FormEvent) => {
    e.preventDefault()
    if (inputBotId.trim()) {
      navigate(`/bot-portal/${inputBotId.trim()}${onlyCall ? '?only_call=true' : ''}`)
    }
  }

  const handlePlaceCall = (phoneNumber: string, name: string, customInstructions?: string | null, address?: string) => {
    if (!botId) return; 
    placeCall({
      phoneNumber: phoneNumber.trim(),
      name: name.trim(),
      customInstructions: customInstructions ?? "N/A",
      address: address,
    });
  };

  const formatDuration = (startDate: string, endDate: string) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const durationMs = end.getTime() - start.getTime()
    const minutes = Math.floor(durationMs / 60000)
    const seconds = Math.floor((durationMs % 60000) / 1000)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const formatElapsedTime = (startDate: string) => {
    const start = new Date(startDate)
    const now = timeNow // Use the state variable that updates every second
    const durationMs = now.getTime() - start.getTime()
    const minutes = Math.floor(durationMs / 60000)
    const seconds = Math.floor((durationMs % 60000) / 1000)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const toggleActivePanel = () => {
    setIsActivePanelExpanded(!isActivePanelExpanded);
  };

  // Active Calls Panel Component
  const ActiveCallsPanel: React.FC = () => (
    <div style={{
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      width: isActivePanelExpanded ? '250px' : '50px',
      background: '#1E293B',
      color: 'white',
      transition: 'width 0.3s ease',
      zIndex: 10,
      display: onlyCall ? 'none' : 'flex',
      flexDirection: 'column',
      boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)'
    }}>
      <style>{pulseAnimation}</style>
      <div style={{
        display: 'flex',
        justifyContent: isActivePanelExpanded ? 'space-between' : 'center',
        alignItems: 'center',
        padding: '0.75rem',
        borderBottom: '1px solid #334155'
      }}>
        {isActivePanelExpanded && (
          <Typography variant="h2" style={{ color: 'white', margin: 0, fontSize: '0.9rem' }}>
            Active Calls {activeCalls.length > 0 && `(${activeCalls.length})`}
          </Typography>
        )}
        <button
          onClick={toggleActivePanel}
          style={{
            background: 'transparent',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.25rem'
          }}
        >
          {isActivePanelExpanded ? (
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          ) : (
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          )}
        </button>
      </div>

      <div style={{ 
        overflowY: 'auto',
        flex: 1,
        padding: isActivePanelExpanded ? '0.75rem' : '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
      }}>
        {activeCalls.length === 0 ? (
          <div style={{ 
            textAlign: isActivePanelExpanded ? 'center' : 'center',
            padding: '0.75rem',
            color: '#94A3B8'
          }}>
            {isActivePanelExpanded ? 'No active calls' : (
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
              </svg>
            )}
          </div>
        ) : (
          activeCalls.map(call => (
            <div 
              key={call.id}
              onClick={() => handleSelectCall(call)}
              style={{
                background: '#334155',
                borderRadius: '6px',
                padding: isActivePanelExpanded ? '0.75rem' : '0.5rem',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                border: selectedCall?.id === call.id ? '2px solid #60A5FA' : '1px solid #475569',
                display: 'flex',
                flexDirection: isActivePanelExpanded ? 'column' : 'row',
                alignItems: isActivePanelExpanded ? 'flex-start' : 'center',
                justifyContent: isActivePanelExpanded ? 'flex-start' : 'center',
              }}
            >
              {isActivePanelExpanded ? (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '0.25rem' }}>
                    <Typography variant="h3" style={{ color: 'white', fontSize: '0.85rem', fontWeight: 500, margin: 0 }}>
                      {call.customer?.name || 'Unknown'}
                    </Typography>
                    <div style={{ 
                      width: '8px', 
                      height: '8px', 
                      borderRadius: '50%', 
                      background: '#10B981',
                      animation: 'pulse 1.5s infinite'
                    }} />
                  </div>
                  <Typography variant="smallParagraph" style={{ color: '#94A3B8', marginBottom: '0.25rem', fontSize: '0.75rem' }}>
                    {call.customer?.number || 'Unknown Number'}
                  </Typography>
                  {call.startedAt && (
                    <Typography variant="smallParagraph" style={{ color: '#60A5FA', fontSize: '0.75rem' }}>
                      Duration: {formatElapsedTime(call.startedAt)}
                    </Typography>
                  )}
                </>
              ) : (
                <div style={{ 
                  width: '8px', 
                  height: '8px', 
                  borderRadius: '50%', 
                  background: '#10B981',
                  animation: 'pulse 1.5s infinite'
                }} />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );

  // Mobile-friendly call testing screen
  if (onlyCall && botId) {
    return (
      <div style={{ 
        height: '100vh',
        background: 'linear-gradient(180deg, #F8FAFC 0%, #E2E8F0 100%)',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        overflow: 'auto'
      }}>
        <div style={{
          padding: '1rem',
          background: 'white',
          borderRadius: '12px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          marginBottom: '1rem'
        }}>
          <Typography variant="h1" style={{ fontSize: '1.5rem', marginBottom: '0.5rem', textAlign: 'center' }}>
            Test Bot Call
          </Typography>
          <Typography variant="mediumParagraph" style={{ color: '#6B7280', textAlign: 'center', marginBottom: '0.5rem' }}>
            Bot ID: {botId}
          </Typography>
        </div>
        
        <div style={{ flex: 1 }}>
          <div style={{ maxWidth: '100%', margin: '0 auto' }}>
            <CallPlacer onPlaceCall={handlePlaceCall} />
          </div>
        </div>
      </div>
    );
  }

  if (!botId) {
    return (
      <div style={{ 
        minHeight: '100vh',
        background: 'linear-gradient(180deg, #F8FAFC 0%, #E2E8F0 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem'
      }}>
        <div style={{ maxWidth: '400px', width: '100%' }}>
          <div style={{
            background: 'white',
            borderRadius: '16px',
            padding: '2rem',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }}>
            <Typography variant="h1" style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
              Bot Portal
            </Typography>
            
            <form onSubmit={handleSubmitBotId}>
              <input
                type="text"
                value={inputBotId}
                onChange={(e) => setInputBotId(e.target.value)}
                placeholder="Enter Bot ID"
                style={{
                  width: '100%',
                  padding: '0.75rem 1rem',
                  borderRadius: '8px',
                  border: '1px solid #E2E8F0',
                  marginBottom: '1rem',
                  fontSize: '1rem'
                }}
              />
              
              <button
                type="submit"
                disabled={!inputBotId.trim()}
                style={{
                  width: '100%',
                  padding: '0.75rem 1rem',
                  borderRadius: '8px',
                  background: !inputBotId.trim() ? '#E2E8F0' : '#3B82F6',
                  color: 'white',
                  border: 'none',
                  cursor: !inputBotId.trim() ? 'not-allowed' : 'pointer',
                  fontSize: '1rem',
                  fontWeight: 500
                }}
              >
                View Bot
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  if (botId && allBotCalls) {
    return (
      <div style={{ 
        height: '100vh',
        background: 'linear-gradient(180deg, #F8FAFC 0%, #E2E8F0 100%)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginLeft: isActivePanelExpanded ? '250px' : '50px',
        transition: 'margin-left 0.3s ease'
      }}>
        <ActiveCallsPanel />

        <div style={{ display: 'flex', gap: '0.5rem', padding: '0.75rem 1rem 0', borderBottom: '1px solid #E5E7EB' }}>
          <TabButton tab="calls" label="Call History" activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabButton tab="place-call" label="Test Bot" activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>

        <div style={{ 
          flex: 1,
          display: 'flex',
          gap: '1rem',
          padding: '1rem',
          overflow: 'hidden'
        }}>
          {activeTab === 'calls' ? (
            <>
              {/* Call List Panel */}
              <div style={{ 
                flex: 1,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
              }}>
                <div style={{
                  flex: 1,
                  overflowY: 'auto',
                  padding: '0.25rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.75rem'
                }}>
                  {allBotCallsForAssistant.map((call) => (
                    <div 
                      key={call.id} 
                      onClick={() => handleSelectCall(call)}
                      style={{
                        background: !call.endedAt && call.startedAt ? '#F0FDF4' : 'white',
                        borderRadius: '8px',
                        padding: '1rem',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        border: selectedCall?.id === call.id 
                          ? '2px solid #3B82F6' 
                          : (!call.endedAt && call.startedAt) 
                            ? '1px solid #A7F3D0' 
                            : '1px solid #E5E7EB',
                        transform: selectedCall?.id === call.id ? 'scale(1.01)' : 'scale(1)',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem'
                      }}
                    >
                      <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'flex-start',
                        gap: '1rem'
                      }}>
                        <div>
                          <Typography variant="h2" style={{ 
                            color: '#1F2937', 
                            fontSize: '1rem', 
                            fontWeight: 600,
                            marginBottom: '0.25rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                          }}>
                            {call.customer?.number || 'Unknown Number'}
                            {!call.endedAt && call.startedAt && (
                              <span style={{ 
                                fontSize: '0.7rem',
                                fontWeight: 500,
                                color: 'white',
                                background: '#10B981',
                                padding: '0.2rem 0.4rem',
                                borderRadius: '9999px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '0.25rem',
                                animation: 'pulse 1.5s infinite'
                              }}>
                                <span style={{ 
                                  width: '5px', 
                                  height: '5px', 
                                  borderRadius: '50%', 
                                  background: 'white',
                                  display: 'inline-block'
                                }}></span>
                                Active
                              </span>
                            )}
                          </Typography>
                          {call.startedAt && call.endedAt && (
                            <Typography variant="mediumParagraph" style={{ 
                              color: '#4B5563', 
                              fontSize: '0.85rem',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0.25rem'
                            }}>
                              <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                              </svg>
                              {formatDuration(call.startedAt, call.endedAt)}
                            </Typography>
                          )}
                          {call.startedAt && !call.endedAt && (
                            <Typography variant="mediumParagraph" style={{ 
                              color: '#059669', 
                              fontSize: '0.85rem',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0.25rem'
                            }}>
                              <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                              </svg>
                              In Progress - {formatElapsedTime(call.startedAt)}
                            </Typography>
                          )}
                        </div>
                        <Typography variant="smallParagraph" style={{ 
                          color: '#6B7280', 
                          fontSize: '0.8rem',
                          backgroundColor: '#F3F4F6',
                          padding: '0.25rem 0.5rem',
                          borderRadius: '4px'
                        }}>
                          {new Date(call.createdAt).toLocaleString(undefined, {
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Typography>
                      </div>
                      
                      {!call.endedAt && call.startedAt ? (
                        <div style={{ 
                          fontSize: '0.85rem',
                          color: '#059669',
                          background: '#ECFDF5',
                          padding: '0.75rem',
                          borderRadius: '6px',
                          border: '1px solid #A7F3D0',
                          lineHeight: '1.4',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        }}>
                          <div style={{ 
                            width: '8px', 
                            height: '8px', 
                            borderRadius: '50%', 
                            background: '#10B981',
                            animation: 'pulse 1.5s infinite'
                          }} />
                          Live call in progress. Click to view details.
                        </div>
                      ) : call.analysis?.summary ? (
                        <div style={{ 
                          fontSize: '0.85rem',
                          color: '#4B5563',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          background: '#F9FAFB',
                          padding: '0.75rem',
                          borderRadius: '6px',
                          border: '1px solid #E5E7EB',
                          lineHeight: '1.4'
                        }}>
                          {call.analysis.summary}
                        </div>
                      ) : null}
                    </div>
                  ))}
                  
                  {hasMorePastCalls && (
                    <div 
                      onClick={handleLoadMoreCalls}
                      style={{
                        padding: '0.75rem',
                        textAlign: 'center',
                        background: '#F3F4F6',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        color: '#4B5563',
                        fontWeight: 500,
                        border: '1px solid #E5E7EB',
                        marginTop: '0.5rem'
                      }}
                    >
                      {loadingMoreCalls ? 'Loading...' : 'Load More Calls'}
                    </div>
                  )}
                </div>
              </div>

              {/* Details Panel */}
              {memoizedSelectedCall && (
                <div style={{ width: '450px', overflow: 'hidden' }}>
                  <CallDetailsPanel 
                    call={memoizedSelectedCall} 
                    onClose={handleClosePanel}
                  />
                </div>
              )}
            </>
          ) : (
            <div style={{ flex: 1 }}>
              <CallPlacer onPlaceCall={handlePlaceCall} />
            </div>
          )}
        </div>
      </div>
    )
  }
  else if (loading) {
    return (
      <div style={{ 
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(180deg, #F8FAFC 0%, #E2E8F0 100%)'
      }}>
        <ActiveCallsPanel />
        <Typography variant="largeParagraph">Loading...</Typography>
      </div>
    )
  }
  else if (errors[botId || '']) {
    return (
      <div style={{ 
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(180deg, #F8FAFC 0%, #E2E8F0 100%)'
      }}>
        <ActiveCallsPanel />
        <Typography variant="largeParagraph" style={{ color: '#EF4444' }}>
          Error loading calls
        </Typography>
      </div>
    )
  }

  return (
    <div style={{ 
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(180deg, #F8FAFC 0%, #E2E8F0 100%)'
    }}>
      <ActiveCallsPanel />
      <Typography variant="largeParagraph">No calls found</Typography>
    </div>
  )
}
