import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Skeleton, styled, Chip, Checkbox } from '@mui/material';
import { formatDistanceToNow, format } from 'date-fns';
import { 
  LocalOffer as TagIcon, 
  Snooze as SnoozeIcon,
  DoneAll as MarkReadIcon,
  MarkunreadOutlined as MarkUnreadIcon,
  AccessTime as TimeIcon,
  Message as MessageIcon,
  BusinessCenter as BusinessCenterIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  Group as GroupIcon,
  LinkedIn as LinkedInIcon,
  Explore as ExploreIcon
} from '@mui/icons-material';
import { SnoozeModal } from '../Modals/SnoozeModal';
import { TagModal } from '../Modals/TagModal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { ConversationIds, SOURCE_TYPE, DerivedInboxType } from 'lib/redux/linkedin/types';
import { ConversationId } from 'lib/redux/linkedin/types';
import { ReduxSafeConversation } from 'lib/redux/linkedin/conversations/chat-slice';
import { ReduxSafeSalesConversation, ReduxSafeSalesMessage } from 'lib/redux/linkedin/conversations/sales-nav-slice';
import { renderSimplifiedConversationName, pictureToUrl } from 'lib/redux/linkedin/helpers';
import { getLastMessage, isConversationUnread } from 'lib/redux/linkedin/helpers';
import { Typography } from 'interfaces/typography';
import { TagButton } from '../Modals/TagModal';
import { SnoozeButton } from '../Modals/SnoozeModal';
import { LinkedInMessage, LinkedInAttachment, LinkedInUpload } from 'interfaces/linkedin';
import { updateArchived } from 'lib/redux/linkedin/metadata/slice';
import { alpha } from '@mui/material/styles';
import { useLinkedInMetadata } from 'lib/redux/linkedin/metadata/hook';
import { ListChildComponentProps } from 'react-window';
import { ProfilePopover } from './ProfilePopover';
import { CONVERSATION_ITEM_HEIGHT_NO_TAGS } from './constants';

interface ConversationTileProps {
  conversationId: ConversationId;
  conversation: ReduxSafeConversation | ReduxSafeSalesConversation;
  isSelected: boolean;
  onSelect: (id: ConversationId) => void;
  style?: React.CSSProperties;
  isChecked?: boolean;
  onToggleSelect?: (id: string) => void;
}

const SnoozeChip = styled(Chip)(({ theme }) => ({
  height: '20px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& .MuiChip-icon': {
    color: 'inherit',
    marginLeft: '4px',
    fontSize: '14px'
  },
  '& .MuiChip-label': {
    padding: '0 6px',
    fontSize: '0.75rem'
  }
}));

const StyledTypography = styled(Typography)<{ unread?: boolean }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex: 1;
  font-weight: ${({ unread }) => unread ? 600 : 400};
`;

const TimeText = styled(Typography)`
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const MessageContainer = styled(Box)`
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 6px;
  flex: 1;
  max-width: 100%;
`;

interface MessageTextProps extends React.ComponentProps<typeof Typography> {
  italic?: boolean;
  hasTags?: boolean;
  isSelf?: boolean;
  unread?: boolean;
}

const MessageText = styled(Typography)<MessageTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: ${({ unread }) => unread ? 1 : 0.8};
  flex: 1;
  font-style: ${({ italic }) => italic ? 'italic' : 'normal'};
  font-weight: ${({ unread }) => unread ? 600 : 400};
  line-height: 1.4;
  max-width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;

  ${({ hasTags }) => hasTags ? `
    white-space: nowrap;
  ` : `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}

  ${({ isSelf }) => isSelf && `
    font-style: italic;
  `}
`;

const commonIconButtonStyles = {
  padding: '4px',
  color: 'text.secondary',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: 'primary.main',
    transform: 'scale(1.05)',
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
};

const SourceIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '6px',
  opacity: 0.7,
  flexShrink: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: theme.palette.text.secondary,
  }
}));

const MetadataContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 0 0 auto;
`;

const NameContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  gap: 8px;
`;

const NameContent = styled(Box)`
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
  overflow: hidden;
  gap: 1;
`;

const SelectableAvatar = styled(Box)<{ isHovered: boolean, isChecked: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  flex-shrink: 0;

  .avatar-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox {
    opacity: ${({ isHovered, isChecked }) => isHovered || isChecked ? 1 : 0};
  }

  .image {
    opacity: ${({ isHovered, isChecked }) => isHovered || isChecked ? 0 : 1};
  }

  &:hover {
    .checkbox {
      opacity: 1;
    }
    .image {
      opacity: 0;
    }
  }
`;

const getFirstParticipantInfo = (conversationId: ConversationId, conversation: ReduxSafeConversation | ReduxSafeSalesConversation) => {
  if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
    const chatConversation = conversation as ReduxSafeConversation;
    const participant = chatConversation.conversationParticipants?.find(p => 
      p.participantType?.member?.distance !== 'SELF'
    );
    return {
      picture: participant?.participantType?.member?.profilePicture,
    };
  } else {
    const salesConversation = conversation as ReduxSafeSalesConversation;
    const participant = salesConversation.participants?.find(p => p.degree !== 0);
    return {
      picture: participant?.profilePictureDisplayImage,
    };
  }
};

const getMessagePreview = (conversationId: ConversationId, conversation: ReduxSafeConversation | ReduxSafeSalesConversation) => {
  const lastMessageContent = conversation.messages?.[conversation.messages.length - 1];
  if (!lastMessageContent) {
    return { text: 'No messages', isItalic: true, isSelf: false };
  }

  // Determine if the message is from self
  const isSelf = conversationId.sourceType === SOURCE_TYPE.CHAT 
    ? ('conversationParticipants' in conversation && conversation.conversationParticipants?.find(p => 
        p.participantType?.member?.distance === 'SELF' && 
        p.entityUrn === (lastMessageContent as LinkedInMessage).sender.entityUrn
      ))
    : ('participants' in conversation && conversation.participants.find(p => 
        p.degree === 0 && 
        p.entityUrn === (lastMessageContent as ReduxSafeSalesMessage).author
      ));

  // Get sender name for non-self messages
  const lastMessage = getLastMessage(conversationId, conversation);
  const attachments = conversationId.sourceType === SOURCE_TYPE.CHAT 
    ? (lastMessageContent as LinkedInMessage).renderContent || []
    : (lastMessageContent as ReduxSafeSalesMessage).attachments || [];

  // If there's a message text, show it along with attachment info
  if (lastMessage) {
    if (attachments.length > 0) {
      const fileTypes = attachments.map((attachment: LinkedInAttachment | LinkedInUpload) => {
        const type = 'mediaType' in attachment ? attachment.mediaType : null;
        switch (type) {
          case 'image':
          case 'image/jpeg':
          case 'image/png':
            return 'an image';
          case 'video':
          case 'video/mp4':
            return 'a video';
          case 'audio':
          case 'audio/mpeg':
            return 'a voice message';
          default:
            return 'a file';
        }
      });
      return { 
        text: isSelf ? `You: ${lastMessage} · ${fileTypes.join(', ')}` : `${lastMessage} · ${fileTypes.join(', ')}`, 
        isItalic: false,
        isSelf: Boolean(isSelf)
      };
    }
    return { 
      text: isSelf ? `You: ${lastMessage}` : lastMessage, 
      isItalic: false,
      isSelf: Boolean(isSelf)
    };
  }

  // If no message text but has attachments, show attachment info
  if (attachments.length > 0) {
    const fileTypes = attachments.map((attachment: LinkedInAttachment | LinkedInUpload) => {
      const type = 'mediaType' in attachment ? attachment.mediaType : null;
      switch (type) {
        case 'image':
        case 'image/jpeg':
        case 'image/png':
          return 'an image';
        case 'video':
        case 'video/mp4':
          return 'a video';
        case 'audio':
        case 'audio/mpeg':
          return 'a voice message';
        default:
          return 'a file';
      }
    });
    return { 
      text: isSelf ? `You sent ${fileTypes.join(', ')}` : `Sent ${fileTypes.join(', ')}`, 
      isItalic: true,
      isSelf: Boolean(isSelf)
    };
  }

  // If no message text and no attachments, it was likely deleted
  return { text: 'Message was deleted', isItalic: true, isSelf: false };
};

interface StyledBoxProps {
  selected: boolean;
  unread: boolean;
  theme?: any;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 4px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  transition: all 0.2s ease-in-out;
  position: relative;
  
  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.04)};
    .action-buttons {
      opacity: 1;
    }
  }

  ${({ theme, selected, unread }) => `
    ${selected && `
      background-color: ${alpha(theme.palette.primary.main, 0.08)};
      &:hover {
        background-color: ${alpha(theme.palette.primary.main, 0.12)};
        .action-buttons {
          opacity: 1;
        }
      }
    `}
    
    ${unread && !selected && `
      background-color: ${alpha(theme.palette.primary.main, 0.04)};
      &:hover {
        background-color: ${alpha(theme.palette.primary.main, 0.08)};
      }
    `}
  `}
`;

export const ConversationTile: React.FC<ConversationTileProps> = React.memo(({
  conversationId,
  conversation,
  isSelected,
  onSelect,
  style,
  isChecked = false,
  onToggleSelect
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState<HTMLElement | null>(null);
  const { salesNavigatorClient } = useSelector((state: RootState) => state.linkedinClients);
  const dispatch = useDispatch();

  const conversationState = useSelector((state: RootState) => 
    state.linkedinConversationStates.states[conversationId.id]
  );
  
  const isUnread = isConversationUnread(conversationId, conversation);
  const hasSalesNavigatorAccess = salesNavigatorClient ? salesNavigatorClient.hasAccess : false;
  const isArchived = conversationState?.isArchived || false;
  const { markReadState } = useLinkedInMetadata()

  const handleProfileMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMouseLeave = () => {
    setProfileAnchorEl(null);
  };

  const handleArchiveToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(updateArchived({ 
      conversationId,
      isArchived: !isArchived
    }));
  };

  const handleReadToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    markReadState(conversationId, isUnread)
  };

  const handleClick = (e: React.MouseEvent) => {
    onSelect(conversationId);
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggleSelect?.(conversationId.id);
  };

  const messagePreview = getMessagePreview(conversationId, conversation);
  const { name, isGroup } = renderSimplifiedConversationName(conversationId, conversation);
  const { picture } = getFirstParticipantInfo(conversationId, conversation);

  // Check if it's a 1:1 chat
  const isOneOnOne = !isGroup && !(conversation as ReduxSafeConversation).groupChat;

  return (
    <div style={style}>
      <StyledBox
        selected={isSelected}
        unread={isUnread}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          ...(conversationState?.isArchived && {
            opacity: 0.7,
          }),
        }}
      >
        <Box sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          height: '80%',
          width: 3,
          borderRadius: '0 4px 4px 0',
          bgcolor: isUnread ? 'primary.main' : 'transparent',
          boxShadow: isUnread ? `0 0 8px ${alpha('#0a66c2', 0.3)}` : 'none',
          transition: 'all 0.2s ease-in-out'
        }} />
        {/* Main Content */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: conversationState?.tags?.length ? 0.5 : 1,
          mb: 'auto' 
        }}>
          {/* Header Row: Name + Timestamp */}
          <NameContainer>
            <NameContent>
              <SelectableAvatar 
                isHovered={isHovered} 
                isChecked={isChecked}
                onClick={handleCheckboxClick}
                sx={{ cursor: 'pointer' }}
              >
                <Box className="avatar-content image">
                  {isGroup && (conversation as ReduxSafeConversation).groupChat ? (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        minWidth: 24,
                        minHeight: 24,
                        borderRadius: '50%',
                        bgcolor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: 0
                      }}
                    >
                      <GroupIcon sx={{ color: 'white', fontSize: 16 }} />
                    </Box>
                  ) : picture ? (
                    <img 
                      src={pictureToUrl(picture)}
                      alt=""
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                      referrerPolicy="no-referrer"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        bgcolor: isGroup ? 'primary.main' : 'grey.300',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {isGroup ? (
                        <GroupIcon sx={{ color: 'white', fontSize: 16 }} />
                      ) : (
                        <Typography variant="caption" style={{ fontSize: '1rem', color: isGroup ? 'white' : 'inherit' }}>
                          {name.split(' ')[0][0]}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
                <Box className="avatar-content checkbox">
                  <Checkbox
                    checked={isChecked}
                    size="small"
                    sx={{
                      padding: 0,
                      '& .MuiSvgIcon-root': { fontSize: 20 }
                    }}
                  />
                </Box>
              </SelectableAvatar>
              <StyledTypography 
                variant="largeParagraph"
                unread={isUnread}
                sx={{ 
                  flex: 1,
                  minWidth: 0,
                }}
                style={{ fontSize: '15px' }}
              >
                {name}
              </StyledTypography>
            </NameContent>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              flexShrink: 0
            }}>
              {conversationState?.snooze && !isNaN(new Date(conversationState.snooze).getTime()) && (
                <SnoozeChip
                  icon={<TimeIcon />}
                  label={format(new Date(conversationState.snooze), 'M/d')}
                  size="small"
                />
              )}
              {conversationId.lastActivity && (
                <TimeText variant="smallCaption">
                  {formatDistanceToNow(new Date(conversationId.lastActivity), { addSuffix: true })}
                </TimeText>
              )}
            </Box>
          </NameContainer>

          {/* Only show ProfilePopover for 1:1 chats */}
          {isOneOnOne && profileAnchorEl && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '100%',
                transform: 'translateY(-50%)',
                bgcolor: 'background.paper',
                boxShadow: 4,
                borderRadius: 1,
                p: 2,
                zIndex: 1000,
                minWidth: 200,
              }}
            >
              <Typography style={{ fontWeight: 500, marginBottom: 1 }}>
                Under Construction
              </Typography>
              <Typography style={{ color: 'text.secondary' }}>
                Profile details coming soon...
              </Typography>
            </Box>
          )}
            
          {/* Message Row */}
          <MessageContainer>
            {hasSalesNavigatorAccess && (
              <Tooltip title={
                conversationId.sourceType === SOURCE_TYPE.CHAT 
                  ? "LinkedIn DMs" 
                  : "Sales Navigator"
              } placement="right">
                <SourceIcon>
                  {conversationId.sourceType === SOURCE_TYPE.CHAT ? (
                    <LinkedInIcon sx={{ fontSize: 14 }} />
                  ) : (
                    <ExploreIcon sx={{ fontSize: 14 }} />
                  )}
                </SourceIcon>
              </Tooltip>
            )}
            <MessageText 
              variant="mediumParagraph" 
              italic={messagePreview.isItalic}
              hasTags={Boolean(conversationState?.tags?.length)}
              isSelf={messagePreview.isSelf}
              unread={isUnread}
              style={{ fontSize: '14px' }}
            >
              {messagePreview.isSelf ? (
                <><span style={{ color: '#0a66c2', fontWeight: 500 }}>You:</span> {messagePreview.text.substring(4)}</>
              ) : (
                messagePreview.text
              )}
            </MessageText>
          </MessageContainer>

          {/* Tags Row */}
          {conversationState?.tags?.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 0.5,
              minHeight: 24,
              mt: 0.25
            }}>
              {conversationState.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  sx={{
                    height: 20,
                    fontSize: '0.75rem',
                    backgroundColor: theme => alpha(theme.palette.primary.main, 0.08),
                    color: 'primary.main',
                    '& .MuiChip-label': {
                      px: 1,
                      lineHeight: '20px'
                    }
                  }}
                />
              ))}
            </Box>
          )}
        </Box>

        {/* Action Buttons */}
        <Box 
          className="action-buttons"
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0,
            transition: 'opacity 0.15s ease-in-out',
            display: 'flex',
            gap: 0.5,
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            padding: '4px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            zIndex: 2,
          }}
        >
          <TagButton conversations={[conversationId]} size="small" />
          <SnoozeButton conversations={[conversationId]} size="small" />
          <Tooltip title={isUnread ? "Mark as read" : "Mark as unread"}>
            <IconButton
              size="small"
              onClick={handleReadToggle}
              sx={commonIconButtonStyles}
            >
              {isUnread ? <MarkReadIcon fontSize="small" /> : <MarkUnreadIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
          <Tooltip title={isArchived ? "Unarchive" : "Archive"}>
            <IconButton
              size="small"
              onClick={handleArchiveToggle}
              sx={commonIconButtonStyles}
            >
              {isArchived ? <UnarchiveIcon fontSize="small" /> : <ArchiveIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </StyledBox>

      <SnoozeModal 
        open={isSnoozeModalOpen}
        onClose={() => setIsSnoozeModalOpen(false)}
        conversations={[conversationId]}
      />

      <TagModal
        open={isTagModalOpen}
        onClose={() => setIsTagModalOpen(false)}
        conversations={[conversationId]}
      />
    </div>
  );
});

ConversationTile.displayName = 'ConversationTile'; 

interface ConversationItemData {
  conversationIds: ConversationIds;
  selectedId: ConversationId | null;
  onSelect: (id: ConversationId) => void;
  selectedConversations: Set<string>;
  onToggleSelect: (id: string) => void;
}

export const ConversationLoadingItem = React.memo(({ style }: { style: React.CSSProperties }) => (
  <Box sx={{ 
    ...style, 
    p: 1, 
    display: 'flex', 
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: 'divider',
    mb: 0.5,
    backgroundColor: 'background.paper'
  }}>
    <Skeleton variant="circular" width={28} height={28} sx={{ mr: 1.5 }} />
    <Box sx={{ flex: 1 }}>
      <Skeleton variant="text" width="60%" height={18} sx={{ mb: 0.5 }} />
      <Skeleton variant="text" width="40%" height={14} />
    </Box>
  </Box>
));

ConversationLoadingItem.displayName = 'ConversationLoadingItem';

export const ConversationItem = React.memo<ListChildComponentProps<ConversationItemData>>(({ data, index, style }) => {
  const conversationId = data.conversationIds[index];
  const { selectedId, onSelect, selectedConversations, onToggleSelect } = data;

  const chatConversation = useSelector((state: RootState) => 
    state.linkedinChatConversations.conversations[conversationId?.id ?? '']
  );
  const salesConversation = useSelector((state: RootState) => 
    state.linkedinSalesNavigatorConversations.conversations[conversationId?.id ?? '']
  );
  const conversationState = useSelector((state: RootState) => 
    state.linkedinConversationStates.states[conversationId?.id ?? '']
  );
  
  // Early return if conversation ID is invalid
  if (!conversationId?.id) {
    return null;
  }

  let conversation: ReduxSafeConversation | ReduxSafeSalesConversation | null = null;
  if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
    conversation = chatConversation ? chatConversation.conversation : null;
  } else {
    conversation = salesConversation ? salesConversation.conversation : null;
  }

  const isSelected = selectedId?.id === conversationId.id;
  if (!conversation) return <ConversationLoadingItem style={style} />;

  const modifiedStyle = {
    ...style,
    height: CONVERSATION_ITEM_HEIGHT_NO_TAGS,
    backgroundColor: 'background.paper',
  };

  return (
    <ConversationTile 
      conversationId={conversationId}
      conversation={conversation}
      isSelected={isSelected}
      onSelect={onSelect}
      style={modifiedStyle}
      isChecked={selectedConversations.has(conversationId.id)}
      onToggleSelect={onToggleSelect}
    />
  );
}, (prevProps, nextProps) => {
  const prevData = prevProps.data;
  const nextData = nextProps.data;
  const index = prevProps.index;

  // Add null checks for conversation IDs
  const prevConvId = prevData.conversationIds[index]?.id;
  const nextConvId = nextData.conversationIds[index]?.id;
  
  const prevIsSelected = prevData.selectedConversations.has(prevConvId);
  const nextIsSelected = nextData.selectedConversations.has(nextConvId);
  
  return (
    prevConvId === nextConvId &&
    prevData.selectedId?.id === nextData.selectedId?.id &&
    prevIsSelected === nextIsSelected
  );
});

ConversationItem.displayName = 'ConversationItem'; 