import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

interface LinkedInPermissionMissingViewProps {
  upgradeUrl: string;
}

export const LinkedInPermissionMissingView: React.FC<LinkedInPermissionMissingViewProps> = ({ upgradeUrl }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center',
      height: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      gap: 4,
      p: 4,
      textAlign: 'center'
    }}>
      <RocketLaunchIcon sx={{ 
        fontSize: 48, 
        color: 'primary.main',
        mb: 2
      }} />
      
      <Box sx={{ 
        bgcolor: 'primary.main', 
        color: 'primary.contrastText',
        p: 1.5,
        px: 3,
        borderRadius: 2,
        fontSize: '1rem',
        fontWeight: 'medium',
        boxShadow: 1
      }}>
        Ready to Supercharge Your LinkedIn?
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'medium' }}>
          Unlock Powerful LinkedIn Features
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: '1rem', maxWidth: '320px', mx: 'auto' }}>
          Activate the extension to streamline your LinkedIn workflow and access advanced messaging features
        </Typography>
      </Box>

      <Link 
        href={upgradeUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ 
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          py: 1.5,
          px: 6,
          borderRadius: 2,
          textDecoration: 'none',
          fontWeight: 600,
          fontSize: '1.1rem',
          transition: 'all 0.2s',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'primary.dark',
            textDecoration: 'none',
            transform: 'translateY(-2px)',
            boxShadow: 3
          }
        }}
      >
        Activate Now
      </Link>
    </Box>
  );
}; 