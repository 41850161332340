import React from 'react';
import { Box, Skeleton, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { clearSelectedParticipant } from 'lib/redux/linkedin/navigate/slice';

const MESSAGE_WIDTHS = [240, 180, 260, 200]; // Fixed widths for each message
const MESSAGE_HEIGHTS = [60, 40, 80, 50]; // Fixed heights for each message

export const LoadingConversation: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearSelectedParticipant());
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      bgcolor: 'background.paper',
      position: 'relative'
    }}>
      {/* Header with close button */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        minHeight: 72,
        bgcolor: 'background.paper',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width={160} height={24} />
            <Skeleton variant="text" width={120} height={20} />
          </Box>
        </Box>
        <IconButton 
          onClick={handleClose} 
          size="small"
          sx={{ ml: 1 }}
        >
          <Close />
        </IconButton>
      </Box>

      {/* Message skeletons */}
      <Box sx={{ 
        flex: 1, 
        p: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 3,
        overflow: 'auto',
        bgcolor: 'background.default'
      }}>
        {MESSAGE_WIDTHS.map((width, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
              width: '100%'
            }}
          >
            {index % 2 === 0 && <Skeleton variant="circular" width={32} height={32} />}
            <Box sx={{ 
              width: width,
              flexShrink: 0
            }}>
              <Skeleton 
                variant="rounded"
                width="100%"
                height={MESSAGE_HEIGHTS[index]}
                sx={{ borderRadius: 2 }}
              />
            </Box>
            {index % 2 !== 0 && <Skeleton variant="circular" width={32} height={32} />}
          </Box>
        ))}
      </Box>

      {/* Input skeleton */}
      <Box sx={{ 
        p: 2, 
        borderTop: '1px solid', 
        borderColor: 'divider',
        bgcolor: 'background.paper'
      }}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Box>
    </Box>
  );
}; 