import React, { useState, useEffect, useMemo } from 'react'
import { Typography } from '../../interfaces/typography'
import { Call } from "@vapi-ai/server-sdk/api"
import { X, ChevronDown, ChevronRight, Clock, Phone, User, Volume2 } from 'lucide-react'

interface CallDetailsPanelProps {
  call: Call | null
  onClose: () => void
}

// Use React.memo to prevent unnecessary re-renders
export const CallDetailsPanel: React.FC<CallDetailsPanelProps> = React.memo(({ call, onClose }) => {
  const [isAnalysisExpanded, setIsAnalysisExpanded] = useState(true)
  const [isRecordingExpanded, setIsRecordingExpanded] = useState(true)
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(true)
  const [isTranscriptExpanded, setIsTranscriptExpanded] = useState(true)
  const [isCallInfoExpanded, setIsCallInfoExpanded] = useState(true)
  const [customerName, setCustomerName] = useState<string>('Unknown Customer')
  
  // Memoize the active state to prevent unnecessary re-renders
  const isActive = useMemo(() => {
    return !!call?.startedAt && !call?.endedAt;
  }, [call?.startedAt, call?.endedAt]);

  // Memoize the recording URL to prevent re-renders
  const recordingUrl = useMemo(() => {
    return call?.artifact?.recordingUrl;
  }, [call?.artifact?.recordingUrl]);

  // Use a ref to stabilize the audio player and prevent re-renders
  const audioRef = React.useRef<HTMLAudioElement>(null);
  
  // Update audio source when recording URL changes without recreating the element
  useEffect(() => {
    if (audioRef.current && recordingUrl) {
      // Store the current time to restore it after changing the source
      const currentTime = audioRef.current.currentTime;
      const wasPlaying = !audioRef.current.paused;
      
      // Only update if the URL has actually changed
      if (audioRef.current.src !== recordingUrl) {
        audioRef.current.src = recordingUrl;
        audioRef.current.load();
        
        // Restore playback state after loading
        audioRef.current.currentTime = currentTime;
        if (wasPlaying) {
          const playPromise = audioRef.current.play();
          if (playPromise !== undefined) {
            playPromise.catch(e => console.error("Failed to resume playback:", e));
          }
        }
      }
    }
  }, [recordingUrl]);

  // Extract customer name from analysis data if available
  useEffect(() => {
    if (!call) return;
    
    if (call.customer?.name) {
      setCustomerName(call.customer.name);
    } else if (call.analysis?.structuredData) {
      try {
        const data = call.analysis.structuredData;
        if (typeof data === 'object' && data !== null && 'name' in data && typeof data.name === 'string') {
          setCustomerName(data.name);
        }
      } catch (error) {
        console.error('Error parsing analysis data for name:', error);
      }
    }
  }, [call]);

  // Memoize the audio player to prevent re-renders
  const AudioPlayer = useMemo(() => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Volume2 size={14} color="#4B5563" />
        <audio 
          ref={audioRef}
          controls 
          style={{ width: '100%', height: '32px', borderRadius: '4px' }}
        />
      </div>
    );
  }, []); // Empty dependency array to prevent recreation

  // Memoize the header to prevent unnecessary re-renders
  const Header = useMemo(() => {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        padding: '0.5rem 0.75rem',
        borderBottom: '1px solid #E5E7EB',
        background: isActive ? '#ECFDF5' : 'white'
      }}>
        <Typography variant="h2" style={{ color: isActive ? '#059669' : '#1F2937', fontSize: '1rem', fontWeight: 600, margin: 0 }}>
          Call Details
          {isActive && (
            <span style={{ 
              marginLeft: '0.5rem',
              fontSize: '0.75rem',
              fontWeight: 500,
              color: 'white',
              background: '#10B981',
              padding: '0.2rem 0.4rem',
              borderRadius: '9999px',
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.2rem',
              animation: 'pulse 1.5s infinite'
            }}>
              <span style={{ 
                width: '5px', 
                height: '5px', 
                borderRadius: '50%', 
                background: 'white',
                display: 'inline-block'
              }}></span>
              Active
            </span>
          )}
        </Typography>
        <button 
          onClick={onClose}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '0.2rem',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <X size={16} color={isActive ? '#059669' : '#6B7280'} />
        </button>
      </div>
    );
  }, [isActive, onClose]);

  if (!call) return null;

  // Reusable expandable section component
  const ExpandableSection = ({ 
    title, 
    isExpanded, 
    setIsExpanded, 
    children, 
    isActive = false,
    showWhenActive = false,
    isTranscript = false
  }: { 
    title: string, 
    isExpanded: boolean, 
    setIsExpanded: (value: boolean) => void, 
    children: React.ReactNode,
    isActive?: boolean,
    showWhenActive?: boolean,
    isTranscript?: boolean
  }) => {
    // Don't render if it's an active call and this section shouldn't show during active calls
    if (isActive && !showWhenActive) return null;
    
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        ...(isTranscript && isExpanded ? { flex: 1, minHeight: '200px' } : {})
      }}>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#F3F4F6',
            padding: '0.5rem 0.75rem',
            borderRadius: '6px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          <Typography variant="h3" style={{ color: '#1F2937', fontSize: '0.9rem', fontWeight: 600, margin: 0 }}>
            {title}
          </Typography>
          {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </button>
        {isExpanded && (
          <div style={{
            background: '#F9FAFB',
            padding: '0.5rem 0.75rem',
            marginTop: '0.25rem',
            borderRadius: '6px',
            ...(isTranscript ? { 
              flex: 1, 
              display: 'flex', 
              flexDirection: 'column',
              overflow: 'hidden',
              minHeight: '150px'
            } : {})
          }}>
            {children}
          </div>
        )}
      </div>
    );
  };

  if (!call) return null;

  return (
    <div 
      key={call.id}
      style={{
      width: '100%',
      height: '100%',
      background: 'white',
      borderLeft: '1px solid #E5E7EB',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <style>
        {`
          @keyframes pulse {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0.6;
            }
            100% {
              opacity: 1;
            }
          }
        `}
      </style>
      
      {/* Header */}
      {Header}

      {/* Scrollable Content */}
      <div style={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '0.75rem',
        gap: '0.75rem',
        overflowY: 'auto',
        maxHeight: '100%'
      }}>
        {/* Call Info Section */}
        <ExpandableSection 
          title="Call Information" 
          isExpanded={isCallInfoExpanded} 
          setIsExpanded={setIsCallInfoExpanded}
          showWhenActive={true}
          isActive={isActive}
        >
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.5rem',
            flexWrap: 'wrap'
          }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <Phone size={16} color={isActive ? '#059669' : '#4B5563'} />
              <Typography variant="mediumParagraph" style={{ color: isActive ? '#059669' : '#1F2937', fontWeight: 600, fontSize: '0.95rem', margin: 0 }}>
                {call.customer?.number || 'Unknown'}
              </Typography>
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <Clock size={16} color={isActive ? '#059669' : '#4B5563'} />
              <Typography variant="smallParagraph" style={{ color: isActive ? '#059669' : '#6B7280', fontSize: '0.85rem', margin: 0 }}>
                {new Date(call.createdAt).toLocaleString()}
              </Typography>
            </div>
            
            {isActive ? (
              <div style={{ 
                color: 'white', 
                background: '#10B981', 
                padding: '0.25rem 0.5rem', 
                borderRadius: '4px', 
                fontSize: '0.8rem',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem',
                animation: 'pulse 1.5s infinite'
              }}>
                <Clock size={12} />
                In Progress
              </div>
            ) : (
              call.startedAt && call.endedAt && (
                <div style={{ 
                  color: '#4B5563', 
                  background: '#E5E7EB', 
                  padding: '0.25rem 0.5rem', 
                  borderRadius: '4px', 
                  fontSize: '0.8rem',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.25rem'
                }}>
                  <Clock size={12} />
                  {formatDuration(call.startedAt, call.endedAt)}
                </div>
              )
            )}
          </div>
        </ExpandableSection>

        {/* Active Call Message */}
        {isActive && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            background: '#F0FDF4',
            borderRadius: '6px',
            gap: '0.5rem'
          }}>
            <div style={{ 
              width: '12px', 
              height: '12px', 
              borderRadius: '50%', 
              background: '#10B981',
              animation: 'pulse 1.5s infinite'
            }} />
            <Typography variant="mediumParagraph" style={{ color: '#059669', fontSize: '0.95rem', fontWeight: 500, textAlign: 'center' }}>
              Live Call in Progress
            </Typography>
            <Typography variant="smallParagraph" style={{ color: '#059669', fontSize: '0.85rem', textAlign: 'center' }}>
              Recording, transcript, and analysis available when call ends
            </Typography>
          </div>
        )}

        {/* Content that only shows for completed calls */}
        {/* Recording Section */}
        {recordingUrl && !isActive && (
          <ExpandableSection 
            title="Recording" 
            isExpanded={isRecordingExpanded} 
            setIsExpanded={setIsRecordingExpanded}
            isActive={isActive}
          >
            {AudioPlayer}
          </ExpandableSection>
        )}

        {/* Summary Section */}
        {call.analysis?.summary && !isActive && (
          <ExpandableSection 
            title="Summary" 
            isExpanded={isSummaryExpanded} 
            setIsExpanded={setIsSummaryExpanded}
            isActive={isActive}
          >
            <div style={{
              fontSize: '0.9rem',
              color: '#4B5563',
              lineHeight: '1.5',
              maxHeight: '200px',
              minHeight: '80px',
              overflowY: 'auto',
              padding: '0.25rem'
            }}>
              {call.analysis.summary}
            </div>
          </ExpandableSection>
        )}

        {/* Analysis Data Section */}
        {call.analysis?.structuredData && !isActive && (
          <ExpandableSection 
            title="Analysis Data" 
            isExpanded={isAnalysisExpanded} 
            setIsExpanded={setIsAnalysisExpanded}
            isActive={isActive}
          >
            <div style={{
              maxHeight: '200px',
              minHeight: '100px',
              overflowY: 'auto',
              padding: '0.25rem'
            }}>
              <pre style={{ 
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                color: '#4B5563',
                margin: 0,
                fontFamily: 'monospace',
                fontSize: '0.8rem'
              }}>
                {JSON.stringify(call.analysis.structuredData, null, 2)}
              </pre>
            </div>
          </ExpandableSection>
        )}

        {/* Transcript Section */}
        {call.artifact?.messagesOpenAIFormatted && !isActive ? (
          <ExpandableSection 
            title="Transcript" 
            isExpanded={isTranscriptExpanded} 
            setIsExpanded={setIsTranscriptExpanded}
            isActive={isActive}
            isTranscript={true}
          >
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '0.75rem',
              flex: 1,
              overflowY: 'auto',
              minHeight: '200px',
              padding: '0.25rem'
            }}>
              {call.artifact.messagesOpenAIFormatted.filter((v) => v.role !== 'system').map((message, index) => (
                <div 
                  key={index}
                  style={{ 
                    padding: '0.75rem',
                    background: message.role === 'assistant' ? '#E5E7EB' : '#DBEAFE',
                    borderRadius: '6px',
                    border: '1px solid ' + (message.role === 'assistant' ? '#D1D5DB' : '#BFDBFE')
                  }}
                >
                  <Typography 
                    variant="smallParagraph" 
                    style={{ 
                      color: message.role === 'assistant' ? '#4B5563' : '#2563EB',
                      fontWeight: 600,
                      marginBottom: '0.25rem',
                      fontSize: '0.8rem'
                    }}
                  >
                    {message.role === 'assistant' ? 'Bot' : 'User'}
                  </Typography>
                  <Typography variant="mediumParagraph" style={{ color: '#1F2937', fontSize: '0.9rem', margin: 0, lineHeight: '1.5' }}>
                    {message.content}
                  </Typography>
                </div>
              ))}
            </div>
          </ExpandableSection>
        ) : !isActive && (
          <ExpandableSection 
            title="Transcript" 
            isExpanded={isTranscriptExpanded} 
            setIsExpanded={setIsTranscriptExpanded}
            isActive={isActive}
            isTranscript={true}
          >
            <div style={{
              textAlign: 'center',
              padding: '1rem',
              minHeight: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography variant="mediumParagraph" style={{ color: '#6B7280', fontSize: '0.9rem' }}>
                No transcript available
              </Typography>
            </div>
          </ExpandableSection>
        )}
      </div>
    </div>
  )
}, (prevProps, nextProps) => {
  // Custom comparison function for React.memo
  // Only re-render if the call ID changes or if the call data has meaningful changes
  if (prevProps.call?.id !== nextProps.call?.id) {
    return false; // Different call, should re-render
  }
  
  if (!prevProps.call || !nextProps.call) {
    return prevProps.call === nextProps.call; // If one is null, only re-render if they're different
  }
  
  // Check for meaningful changes in call data
  const prevEndedAt = prevProps.call.endedAt;
  const nextEndedAt = nextProps.call.endedAt;
  
  if (prevEndedAt !== nextEndedAt) {
    return false; // Call ended status changed, should re-render
  }
  
  // Check if recording URL changed
  const prevRecordingUrl = prevProps.call.artifact?.recordingUrl;
  const nextRecordingUrl = nextProps.call.artifact?.recordingUrl;
  
  if (prevRecordingUrl !== nextRecordingUrl) {
    return false; // Recording URL changed, should re-render
  }
  
  // Check if transcript changed
  const prevTranscript = prevProps.call.artifact?.messagesOpenAIFormatted;
  const nextTranscript = nextProps.call.artifact?.messagesOpenAIFormatted;
  
  if (JSON.stringify(prevTranscript) !== JSON.stringify(nextTranscript)) {
    return false; // Transcript changed, should re-render
  }
  
  // Check if analysis data changed
  const prevAnalysis = prevProps.call.analysis;
  const nextAnalysis = nextProps.call.analysis;
  
  if (JSON.stringify(prevAnalysis) !== JSON.stringify(nextAnalysis)) {
    return false; // Analysis changed, should re-render
  }
  
  return true; // No meaningful changes, don't re-render
});

const formatDuration = (startDate: string, endDate: string) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const durationMs = end.getTime() - start.getTime()
  const minutes = Math.floor(durationMs / 60000)
  const seconds = Math.floor((durationMs % 60000) / 1000)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
} 