import { useMemo, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "store";
import { ConversationId, DerivedInboxToIsDerivedFullyFromState, DerivedInboxType, ERROR_MESSAGE, InboxType, SOURCE_TYPE } from "../types";
import { addConversations, setError, setLoading, setPolling, updateInboxState } from "./chat-slice";
import { addSalesConversations, setSalesError, setSalesLoading, updateSalesInboxState } from "./sales-nav-slice";
import { LinkedInSalesConversationList } from "interfaces/linkedin-salesnavigator";
import { LinkedInConversationList } from "interfaces/linkedin";
import { getSelectedState, getInboxStates, getSortedConversationIds, getAllConversationIds } from './selectors';

// Memoized base selectors
const selectBaseState = (state: RootState) => ({
  chatConversations: state.linkedinChatConversations.conversations,
  salesConversations: state.linkedinSalesNavigatorConversations.conversations,
  clients: {
    client: state.linkedinClients.client,
    salesNavigatorClient: state.linkedinClients.salesNavigatorClient
  }
});

// Helper functions to determine if we should fetch more conversations
const shouldFetchLinkedInConversations = (
  userInbox: DerivedInboxType,
  continues: boolean,
  sourceOptions: SOURCE_TYPE[],
  tags: string[],
  dates: { firstDateChat: number | null; lastDateChat: number | null }
) => {
  if (!continues) return false;
  if (sourceOptions?.length && !sourceOptions.includes(SOURCE_TYPE.CHAT)) return false;
  if (DerivedInboxToIsDerivedFullyFromState[userInbox]) return false;
  if (tags?.length > 0) return false;
  return true;
};

const shouldFetchSalesNavigatorConversations = (
  userInbox: DerivedInboxType,
  continues: boolean,
  salesNavigatorClient: any,
  sourceOptions: SOURCE_TYPE[],
  tags: string[],
  dates: { firstDateSalesNavigator: number | null; lastDateSalesNavigator: number | null }
) => {
  if (!continues) return false;
  if (sourceOptions?.length && !sourceOptions.includes(SOURCE_TYPE.SALES_NAVIGATOR)) return false;
  if (!salesNavigatorClient?.hasAccess) return false;
  if (DerivedInboxToIsDerivedFullyFromState[userInbox]) return false;
  if (tags?.length > 0) return false;
  return true;
};

// Hook implementation
export function useLinkedInConversations() {
  const dispatch = useDispatch();

  // Use memoized selectors with shallowEqual
  const { userInbox, inboxType, sourceOptions, tags } = useSelector(getSelectedState, shallowEqual);
  const { chatInboxState, salesInboxState } = useSelector(getInboxStates, shallowEqual);
  const conversationIds = useSelector(getSortedConversationIds, shallowEqual);
  const allConversationIds = useSelector(getAllConversationIds, shallowEqual);
  const { chatConversations, salesConversations, clients } = useSelector(selectBaseState, shallowEqual);

  // Memoize status to prevent unnecessary updates
  const status = useMemo(() => ({
    isLoading: chatInboxState.isLoading || (salesInboxState.isLoading && clients.salesNavigatorClient?.hasAccess),
    continues: chatInboxState.continues || (salesInboxState.continues && clients.salesNavigatorClient?.hasAccess),
    error: chatInboxState.error || salesInboxState.error,
    initialFetch: chatInboxState.initialFetch || (salesInboxState.initialFetch && clients.salesNavigatorClient?.hasAccess)
  }), [
    chatInboxState.isLoading, chatInboxState.continues, chatInboxState.error, chatInboxState.initialFetch,
    salesInboxState.isLoading, salesInboxState.continues, salesInboxState.error, salesInboxState.initialFetch,
    clients.salesNavigatorClient?.hasAccess
  ]);

  // Memoize dates calculation
  const dates = useMemo(() => ({
    firstDateChat: chatInboxState.conversationIds?.[0]?.lastActivity ?? null,
    lastDateChat: chatInboxState.conversationIds?.[chatInboxState.conversationIds.length - 1]?.lastActivity ?? null,
    firstDateSalesNavigator: salesInboxState.conversationIds?.[0]?.lastActivity ?? null,
    lastDateSalesNavigator: salesInboxState.conversationIds?.[salesInboxState.conversationIds.length - 1]?.lastActivity ?? null
  }), [chatInboxState.conversationIds, salesInboxState.conversationIds]);

  const reloadMoreLinkedInConversations = useCallback(async () => {
    if (!clients.client) return;
    try {
      dispatch(setLoading({ inboxType, isLoading: true }));
      let result: LinkedInConversationList | null = null;
      switch (inboxType) {
        case InboxType.ALL:
          result = await clients.client.listConversations('INBOX', undefined);
          break;
        case InboxType.UNREAD:
          result = await clients.client.listUnreadConversations(undefined);
          break;
      }
      if (result) {
        dispatch(addConversations({ inboxType, conversations: result.conversations ?? [] }));
      }
    } catch (error) {
    } finally {
      dispatch(setLoading({ inboxType, isLoading: false }));
    }
  }, [clients.client, chatInboxState.isLoading, chatInboxState.error, chatInboxState.continues, chatInboxState.nextCursor, inboxType, dispatch]);

  const reloadMoreSalesNavigatorConversations = useCallback(async () => {
    if (!clients.salesNavigatorClient || !clients.salesNavigatorClient.hasAccess) return;
    try {
      dispatch(setSalesLoading({ inboxType, isLoading: true }));
      let result: LinkedInSalesConversationList | null = null;
      switch (inboxType) {
        case InboxType.ALL:
          result = await clients.salesNavigatorClient.listConversations('INBOX', undefined);
          break;
        case InboxType.UNREAD:
          result = await clients.salesNavigatorClient.listUnreadConversations(undefined);
          break;
      }
      if (result) {
        dispatch(addSalesConversations({ inboxType, conversations: result.conversations ?? [] }));
      }
    } catch (error) {
    } finally {
      dispatch(setSalesLoading({ inboxType, isLoading: false }));
    }
  }, [clients.salesNavigatorClient, salesInboxState.isLoading, salesInboxState.error, salesInboxState.continues, inboxType, dispatch]);

  // Memoize fetch functions to prevent recreation
  const fetchMoreLinkedInConversations = useCallback(async () => {
    if (!clients.client || chatInboxState.isLoading || chatInboxState.error || !chatInboxState.continues) return;
    try {
      dispatch(setLoading({ inboxType, isLoading: true }));
      let result: LinkedInConversationList | null = null;
      switch (inboxType) {
        case InboxType.ALL:
          result = await clients.client.listConversations('INBOX', chatInboxState.nextCursor ?? undefined);
          break;
        case InboxType.UNREAD:
          result = await clients.client.listUnreadConversations(chatInboxState.nextCursor ?? undefined);
          break;
      }

      if (result) {
        dispatch(addConversations({ inboxType, conversations: result.conversations ?? [] }));
        dispatch(updateInboxState({ 
          inboxType, 
          update: { 
            nextCursor: result.nextCursor ?? null,
            continues: result.continues ?? false
          } 
        }));
      }
    } catch (error) {
      dispatch(setError({ inboxType, error: ERROR_MESSAGE.NO_CONVERSATION_FOUND }));
    } finally {
      dispatch(setLoading({ inboxType, isLoading: false }));
    }
  }, [clients.client, chatInboxState.isLoading, chatInboxState.error, chatInboxState.continues, chatInboxState.nextCursor, inboxType, dispatch]);

  const fetchMoreSalesNavigatorConversations = useCallback(async () => {
    if (!clients.salesNavigatorClient || !clients.salesNavigatorClient.hasAccess || salesInboxState.isLoading || salesInboxState.error || !salesInboxState.continues) return;
    try {
      dispatch(setSalesLoading({ inboxType, isLoading: true }));
      let result: LinkedInSalesConversationList | null = null;
      // get all conversations and get the min last activity date
      switch (inboxType) {
        case InboxType.ALL:
          result = await clients.salesNavigatorClient.listConversations('INBOX', salesInboxState.pageStartsAt ? new Date(salesInboxState.pageStartsAt) : undefined);
          break;
        case InboxType.UNREAD:
          result = await clients.salesNavigatorClient.listUnreadConversations(salesInboxState.pageStartsAt ? new Date(salesInboxState.pageStartsAt) : undefined);
          break;
      }
      console.log('fetching more sales navigator conversations', result)
      
      if (result) {
        dispatch(addSalesConversations({ inboxType, conversations: result.conversations ?? [] }));
        dispatch(updateSalesInboxState({ 
          inboxType, 
          update: { 
            pageStartsAt: result.nextPageStartsAt ? new Date(result.nextPageStartsAt).getTime() : null,
            continues: result.continues ?? false
          } 
        }));
      }
    } catch (error) {
      dispatch(setSalesError({ inboxType, error: ERROR_MESSAGE.NO_CONVERSATION_FOUND }));
    } finally {
      dispatch(setSalesLoading({ inboxType, isLoading: false }));
    }
  }, [clients.salesNavigatorClient, salesInboxState.isLoading, salesInboxState.error, salesInboxState.continues, salesInboxState.pageStartsAt, inboxType, dispatch]);

  const fetchUpdatesLinkedInConversations = useCallback(async () => {
    if (!clients.client || chatInboxState.isPolling || chatInboxState.error || !chatInboxState.continues) return;
    try {
      dispatch(setPolling({ inboxType, isPolling: true }));
      const result = await clients.client.getConversationUpdates(chatInboxState.pollingCursor ?? undefined);
      dispatch(addConversations({ inboxType, conversations: result.conversations ?? [] }));
      dispatch(updateInboxState({ 
        inboxType, 
        update: { 
          pollingCursor: result.nextCursor ?? null,
        } 
      }));
    } catch (error) {
      dispatch(setPolling({ inboxType, isPolling: false }));
    } finally {
      dispatch(setPolling({ inboxType, isPolling: false }));
    }
  }, [clients.client, chatInboxState.isPolling, chatInboxState.error, chatInboxState.continues, chatInboxState.pollingCursor, dispatch]);

  const fetchUpdatesSalesNavigatorConversations = useCallback(async () => {}, [clients.salesNavigatorClient]);

  // Memoize shouldFetchMore calculations
  const shouldFetchMore = useMemo(() => ({
    linkedin: shouldFetchLinkedInConversations(userInbox, chatInboxState.continues, sourceOptions, tags, dates),
    salesNavigator: shouldFetchSalesNavigatorConversations(userInbox, salesInboxState.continues, clients.salesNavigatorClient, sourceOptions, tags, dates)
  }), [userInbox, chatInboxState.continues, salesInboxState.continues, sourceOptions, tags, dates, clients.salesNavigatorClient]);

  // Memoize load conversations functions
  const loadConversations = useMemo(() => ({
    initialFetch: async () => {
      if (status.initialFetch) return;
      
      const requestChat = !chatInboxState.initialFetch;
      const requestSalesNavigator = !salesInboxState.initialFetch;
      
      if (requestChat) {
        dispatch(updateInboxState({ inboxType, update: { initialFetch: true } }));
        fetchMoreLinkedInConversations();
      }
      if (requestSalesNavigator) {
        dispatch(updateSalesInboxState({ inboxType, update: { initialFetch: true } }));
        fetchMoreSalesNavigatorConversations();
      }
    },
    fetchUpdates: () => {
      fetchUpdatesLinkedInConversations();
      fetchUpdatesSalesNavigatorConversations();
    },
    fetchMore: () => {
      if (shouldFetchMore.salesNavigator) fetchMoreSalesNavigatorConversations();
      if (shouldFetchMore.linkedin) fetchMoreLinkedInConversations();
    },
    fetchMoreChat: () => {
      fetchMoreLinkedInConversations();
    },
    fetchMoreSalesNavigator: () => {
      fetchMoreSalesNavigatorConversations();
    },
    reload: () => {
      reloadMoreLinkedInConversations();
      reloadMoreSalesNavigatorConversations();
    }
  }), [
    status.initialFetch,
    chatInboxState.initialFetch,
    salesInboxState.initialFetch,
    shouldFetchMore,
    inboxType,
    fetchMoreLinkedInConversations,
    fetchMoreSalesNavigatorConversations,
    reloadMoreLinkedInConversations,
    reloadMoreSalesNavigatorConversations,
    dispatch
  ]);

  // Memoize hasData function
  const hasData = useCallback((conversationId: ConversationId) => {
    if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
      return chatConversations[conversationId.id] !== undefined;
    } else if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) {
      return salesConversations[conversationId.id] !== undefined;
    }
    return false;
  }, [chatConversations, salesConversations]);

  return {
    sourceOptions,
    conversationIds,
    allConversationIds,
    ...status,
    hasData,
    loadConversations,
  };
}

