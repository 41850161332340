import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, IconButton, InputBase } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { SearchResultsModal } from './SearchResultsModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import debounce from 'lodash/debounce';
import { useLinkedInSearch } from 'lib/redux/linkedin/search/hook';

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
}

export const SearchModal: React.FC<SearchModalProps> = ({ open, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { performSearch, clearSearch, getSearchResults } = useLinkedInSearch();
  
  // Only get search results if we have a query
  const searchResults = useSelector((state: RootState) => 
    searchQuery.trim() ? getSearchResults(state, searchQuery) : undefined
  );

  // Memoize the search function
  const handleSearch = useCallback(
    (query: string) => {
      if (query.trim()) {
        performSearch(query);
      } else {
        clearSearch(query);
      }
    },
    [performSearch, clearSearch]
  );

  // Create debounced search only once
  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 300),
    [handleSearch]
  );

  // Handle search query changes
  const handleSearchQueryChange = useCallback((newQuery: string) => {
    setSearchQuery(newQuery);
    if (open) {
      debouncedSearch(newQuery);
    }
  }, [open, debouncedSearch]);

  // Clear search on modal close
  useEffect(() => {
    if (!open) {
      setSearchQuery('');
      clearSearch('');
      debouncedSearch.cancel();
    }
  }, [open, clearSearch, debouncedSearch]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleClear = useCallback(() => {
    setSearchQuery('');
    clearSearch('');
    debouncedSearch.cancel();
  }, [clearSearch, debouncedSearch]);

  return (
    <SearchResultsModal
      open={open}
      onClose={onClose}
      searchQuery={searchQuery}
      isLoading={searchResults?.isLoading}
      searchResults={searchResults}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          p: '2px 8px',
          gap: 1,
          mb: 2,
          '&:hover': {
            backgroundColor: '#eeeeee',
          },
        }}
      >
        <Search sx={{ color: 'text.secondary', fontSize: 20 }} />
        <InputBase
          autoFocus
          placeholder="Search messages..."
          value={searchQuery}
          onChange={(e) => handleSearchQueryChange(e.target.value)}
          sx={{
            flex: 1,
            fontSize: '0.875rem',
            '& input': {
              padding: '8px 0',
            },
          }}
        />
        {searchQuery && (
          <IconButton
            size="small"
            onClick={handleClear}
            sx={{ p: '4px' }}
          >
            <Clear sx={{ fontSize: 16, color: 'text.secondary' }} />
          </IconButton>
        )}
      </Box>
    </SearchResultsModal>
  );
}; 