import { createSelector } from 'reselect';
import { RootState } from "store";
import { ConversationId, DerivedInboxToIsDerivedFullyFromState, DerivedInboxType, InboxType, SOURCE_TYPE } from "../types";
import { ConversationExchangeState, ConversationState } from "../metadata/slice";

// Base selectors
const getLinkedInSelected = (state: RootState) => state.linkedinSelected;
const getConversationStates = (state: RootState) => state.linkedinConversationStates.states;
const getChatConversations = (state: RootState) => state.linkedinChatConversations;
const getSalesConversations = (state: RootState) => state.linkedinSalesNavigatorConversations;
const getClients = (state: RootState) => state.linkedinClients;
const getAnalyzed = (state: RootState) => state.linkedinAnalyze.analyzed;

// Combined selectors
export const getSelectedState = createSelector(
  [getLinkedInSelected],
  (selected) => ({
    userInbox: selected.derivedInboxType,
    inboxType: selected.inboxType,
    sourceOptions: selected.sourceType,
    tags: selected.selectedTags
  })
);

export const getInboxStates = createSelector(
  [getChatConversations, getSalesConversations, getLinkedInSelected],
  (chat, sales, selected) => ({
    chatInboxState: chat.inboxState[selected.inboxType],
    salesInboxState: sales.inboxState[selected.inboxType]
  })
);

export const getAllConversationIds = createSelector(
  [getChatConversations, getSalesConversations, getLinkedInSelected],
  (chat, sales, selected) => [...chat.inboxState[selected.inboxType].conversationIds, ...sales.inboxState[selected.inboxType].conversationIds]
);

export const getSortedConversationIds = createSelector(
  [
    getSelectedState,
    getConversationStates,
    getChatConversations,
    getSalesConversations,
    getAnalyzed,
  ],
  (selected, states, chat, sales, analyzed) => {

    const getMatchingConversation = (conversationId: ConversationId) => { 
      let conversation: ConversationId | null = null;
      if (conversationId.sourceType === SOURCE_TYPE.CHAT) {
        const chatConversation = chat.conversations[conversationId.id];
        if (chatConversation && chatConversation.conversation) {
          conversation = {
            ...conversationId,
            lastActivity: chatConversation.conversation.lastActivityAt || chatConversation.conversation.createdAt
          };
        }
      } else if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) {
        const salesConversation = sales.conversations[conversationId.id];
        if (salesConversation && salesConversation.conversation) {
          conversation = {
            ...conversationId,
            lastActivity: salesConversation.conversation.messages[salesConversation.conversation.messages.length - 1].deliveredAt
          };
        }
      }
      return conversation || conversationId;
    }

    const lastMessageFromRep = (conversation: ConversationId) => {
      if (conversation.sourceType === SOURCE_TYPE.CHAT) {
        const chatConversation = chat.conversations[conversation.id];
        if (!chatConversation || !chatConversation.conversation) return false;
        const repParticipant = chatConversation.conversation.conversationParticipants.find(x => x.participantType?.member?.distance === 'SELF')
        if (!repParticipant) return false;
        const lastMessage = chatConversation.conversation.messages[chatConversation.conversation.messages.length - 1];
        if (!lastMessage) return false;
        return lastMessage.sender.entityUrn === repParticipant.hostIdentityUrn;
      } else if (conversation.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) {
        const salesConversation = sales.conversations[conversation.id];
        if (!salesConversation || !salesConversation.conversation) return false;
        const repParticipant = salesConversation.conversation.participants.find(x => x.degree === 0)
        if (!repParticipant) return false;
        const lastMessage = salesConversation.conversation.messages[salesConversation.conversation.messages.length - 1];
        if (!lastMessage) return false;
        return lastMessage.author === repParticipant.entityUrn;
      }
      return false;
    }

    const checkAgainstAnalyzedInboxTypes = (conversation: ConversationId) => {
      if (selected.userInbox === DerivedInboxType.COLD_OUTBOUND && (states[conversation.id]?.exchangeState !== ConversationExchangeState.NO_EXCHANGE || !states[conversation.id]?.isLastMessageFromRep || !analyzed[conversation.id])) return false;
      if (selected.userInbox === DerivedInboxType.ONGOING_CONVERSATIONS && states[conversation.id]?.exchangeState !== ConversationExchangeState.HAS_HAD_EXCHANGE) return false;
      if (selected.userInbox === DerivedInboxType.SNOOZED && !states[conversation.id]?.snooze) return false;
      if (selected.userInbox === DerivedInboxType.ARCHIVED && !states[conversation.id]?.isArchived) return false;
      return selected.userInbox === DerivedInboxType.ARCHIVED || !states[conversation.id]?.isArchived;
    }

    
    let combinedIds: ConversationId[] = [];
    if (selected.tags.length > 0 || DerivedInboxToIsDerivedFullyFromState[selected.userInbox]) {
      let inboxIds: ConversationId[] = [];
      let tagIds: ConversationId[] = [];
      if (DerivedInboxToIsDerivedFullyFromState[selected.userInbox]) {
        inboxIds = Object.values(states).filter((x) => {
          if (!x?.conversationId) return false;
          if (selected.sourceOptions.length && !selected.sourceOptions.includes(SOURCE_TYPE.CHAT) && x.conversationId.sourceType === SOURCE_TYPE.CHAT) return false;
          if (selected.sourceOptions.length && !selected.sourceOptions.includes(SOURCE_TYPE.SALES_NAVIGATOR) && x.conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) return false;
          if (selected.userInbox === DerivedInboxType.SNOOZED) return x.snooze;
          if (selected.userInbox === DerivedInboxType.ARCHIVED) return x.isArchived;
          return false;
        }).map((x) => x.conversationId)
      }

      if (selected.tags.length > 0) {
        tagIds = Object.values(states).filter((x) => {
          if (!x?.conversationId) return false;
          return selected.tags.some((tag) => x.tags?.includes(tag));
        }).map((x) => x.conversationId)
      }

      // if both are there, combine the intersection
      const tagIdsValues = tagIds.map((x) => x.id);
      if (selected.tags.length > 0 && DerivedInboxToIsDerivedFullyFromState[selected.userInbox]) {
        combinedIds = [...inboxIds.filter((x) => tagIdsValues.includes(x.id))];
      } else {
        // make sure to remove duplicates
        combinedIds = [...tagIds, ...inboxIds.filter((x) => !tagIdsValues.includes(x.id))];
      }
      return combinedIds.map((x) => getMatchingConversation(x)).filter((x) => {
        if (!x?.id || !states[x.id]) return false;
        return checkAgainstAnalyzedInboxTypes(x);
      }).sort((a: ConversationId, b: ConversationId) => {
        if (selected.userInbox === DerivedInboxType.SNOOZED) return (states[b.id]?.snooze ?? 0) - (states[a.id]?.snooze ?? 0);
        return (b.lastActivity || 0) - (a.lastActivity || 0);
      });
    }
    
    if (!selected.sourceOptions.length || selected.sourceOptions.includes(SOURCE_TYPE.CHAT)) {
      const chatIds = chat.inboxState[selected.inboxType].conversationIds || [];
      combinedIds = combinedIds.concat(chatIds.filter(x => x?.id));
    }
    
    if (!selected.sourceOptions.length || selected.sourceOptions.includes(SOURCE_TYPE.SALES_NAVIGATOR)) {
      const salesIds = sales.inboxState[selected.inboxType].conversationIds || [];
      combinedIds = combinedIds.concat(salesIds.filter(x => x?.id));
    }

    // Sort conversations and filter out archived ones unless we're in archived view
    return combinedIds
      .map((x) => getMatchingConversation(x))
      .filter(x => {
        if (!x?.id) return false;
        return checkAgainstAnalyzedInboxTypes(x);
      })
      .sort((a: ConversationId, b: ConversationId) => (b.lastActivity || 0) - (a.lastActivity || 0));
  }
); 