import { LinkedInPicture, LinkedInUpload } from "./linkedin"

export const LINKEDIN_SALES_CONVERSATION_TYPE = 'com.linkedin.sales.messaging.message.Thread'
export const LINKEDIN_SALES_MESSAGE_TYPE = 'com.linkedin.sales.messaging.message.Message'
export const LINKEDIN_SALES_PROFILE_TYPE = 'com.linkedin.sales.profile.Profile'
export const LINKEDIN_SALES_MEMBER_PROFILE_TYPE = 'com.linkedin.sales.deco.desktop.common.DecoratedGlobalTypeaheadProfile'
export const LINKEDIN_SALES_COMPANY_PROFILE_TYPE = 'com.linkedin.sales.deco.desktop.common.DecoratedGlobalTypeaheadCompany'
export const LINKEDIN_SALES_COMPANY_REF_TYPE = 'com.linkedin.sales.deco.common.company.DecoratedCompanyEntity'
export const LINKEDIN_SALES_POSITION_TYPE = 'com.linkedin.sales.deco.common.profile.DecoratedPosition'
export const LINKEDIN_SALES_CREATE_MESSAGE_RESPONSE_TYPE = 'com.linkedin.sales.messaging.message.CreateMessageResponse'

export interface LinkedInSalesProfile {
  $type: typeof LINKEDIN_SALES_PROFILE_TYPE
  entityUrn: string  // of the form urn:li:fs_salesProfile:(xxxxxxxxxxxxx_xxxxxxxxxxxxxxxxxxxxxxxxx,NAME_SEARCH,xxxx),
  firstName: string
  lastName: string
  fullName: string
  degree: number  // number of social network hops. 0 = yourself, 1 = friend, 2 = friend of friend, ...
  profilePictureDisplayImage: LinkedInPicture
}

export interface LinkedInSalesCompanyProfile {
  $recipeType: typeof LINKEDIN_SALES_COMPANY_PROFILE_TYPE | typeof LINKEDIN_SALES_COMPANY_REF_TYPE
  entityUrn: string  // of the form urn:li:fs_salesCompany:XXXXX
  name: string
  industry: string
  location: string
  companyPictureDisplayImage: LinkedInPicture
}

export interface LinkedInSalesMemberProfile {
  $recipeType: typeof LINKEDIN_SALES_MEMBER_PROFILE_TYPE
  entityUrn: string  // same form as LinkedInSalesProfile
  firstName: string
  lastName: string
  fullName: string
  degree: number
  location?: string
  headline?: string
  profilePictureDisplayImage: LinkedInPicture
}

export interface LinkedInSalesMemberPosition {
  $recipeType: typeof LINKEDIN_SALES_POSITION_TYPE
  companyUrn: string
  companyName: string
  description: string
  title: string
  location: string
  startedOn?: {month?: number, year?: number}
  companyUrnResolutionResult?: LinkedInSalesCompanyProfile
  // no idea what these are but they showed up in the output
  posId: number
  new: boolean
  current: boolean
}

export interface LinkedInSalesContactSearchResults {
  people: LinkedInSalesMemberProfile[],
  companies: LinkedInSalesMemberPosition[],
}

export interface LinkedInSalesConversationList {
  conversations: LinkedInSalesConversation[]
  filterName: string  // default = 'INBOX' = everything. Could also be 'UNREAD', 'ARCHIVED', 'SENT', 'INMAIL_PENDING', 'INMAIL_ACCEPTED', or 'INMAIL_DECLINED'.
  nextPageStartsAt: Date
  continues: boolean  // whether, at retrieval time, there existed more results than were returned
}

export interface LinkedInSalesConversation {
  $type: typeof LINKEDIN_SALES_CONVERSATION_TYPE
  nextPageStartsAt: Date | null
  totalMessageCount: number
  unreadMessageCount: number
  //restrictions: any[]  // TODO
  archived: boolean
  messages: LinkedInSalesMessage[]
  participantsResolutionResults: Record<string, string>
  id: string
  participants: LinkedInSalesProfile[]
}

export interface LinkedInSalesMessage {
  $type: typeof LINKEDIN_SALES_MESSAGE_TYPE
  type: string  // observed: "MESSAGE", "INVITATION"
  attachments: LinkedInUpload[]
  author: string
  body: string
  deliveredAt: Date
  id: string
  threadId: string  // added by us. Can also be recovered by deleting the first 32 characters after the hyphen in .id.
}

export interface LinkedInSalesCreateMessageResponse {
  $type: typeof LINKEDIN_SALES_CREATE_MESSAGE_RESPONSE_TYPE
  threadId: string
  messageId: string
}