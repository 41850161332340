import React from 'react';
import { Box, Typography, Link } from '@mui/material';

interface ExtensionMissingViewProps {
  installUrl: string;
}

export const ExtensionMissingView: React.FC<ExtensionMissingViewProps> = ({ installUrl }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center',
      height: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      gap: 3,
      p: 4,
      textAlign: 'center'
    }}>
      <Box sx={{ 
        bgcolor: 'error.main', 
        color: 'error.contrastText',
        p: 1,
        px: 2,
        borderRadius: 1,
        fontSize: '0.875rem'
      }}>
        Extension Required
      </Box>
      
      <Typography color="text.secondary">
        You must install the Trellus extension to activate this feature
      </Typography>

      <Link 
        href={installUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ 
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          py: 1.5,
          px: 4,
          borderRadius: 1,
          textDecoration: 'none',
          fontWeight: 500,
          transition: 'background-color 0.2s',
          '&:hover': {
            bgcolor: 'primary.dark',
            textDecoration: 'none'
          }
        }}
      >
        Install Extension
      </Link>

      <Typography variant="caption" color="text.secondary">
        Refresh the page after installation
      </Typography>
    </Box>
  );
}; 