import { AutodialerSettings } from '../../components/AutoDialerSettings';
import { Keywords } from '../../components/Keywords';
import { Loader } from '../../components/Loader';
import { NumberRegistration } from '../../components/NumberRegistration';
import { RecordVM } from '../../components/RecordVM';
import { GeneralSettings } from '../../components/RegularSettings/GeneralSettings';
import { UserGroupSettings } from '../../components/UserGroups';
import { TeamManagement } from '../../components/UserManagement/UserManagement';
import { Typography } from '../../interfaces/typography';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { CustomScores } from '../../components/CustomScores';
import { SnippetManagement } from 'components/SnippetManagement';
export type SettingsProps = {
    defaultSettings?: SettingsPages
    isAdmin: boolean
    hasParallel: boolean
    userLoaded: boolean
    adminLoaded: boolean
}

export enum SettingsPages {
    GENERAL = 'General',
    AUTODIALER_SETTINGS = 'Autodialer',
    CUSTOM_SCORES = 'Custom Scores',
    USER_MANAGEMENT = 'Users',
    SUBTEAMS = 'Subteams',
    NUMBERS = 'Numbers',
    VOICEMAILS = 'Voicemails',
    SNIPPETS = 'Snippets',
}

function SettingsImpl({ defaultSettings, isAdmin, hasParallel, userLoaded, adminLoaded }: SettingsProps) {
  const getSettingOptions = () => {
    const options = [SettingsPages.GENERAL, SettingsPages.AUTODIALER_SETTINGS]
    if (isAdmin) options.push(SettingsPages.CUSTOM_SCORES)
    if (isAdmin) options.push(SettingsPages.USER_MANAGEMENT)
    if (isAdmin) options.push(SettingsPages.SUBTEAMS)
    options.push(SettingsPages.SNIPPETS)
    if (hasParallel) options.push(SettingsPages.NUMBERS)
    if (hasParallel) options.push(SettingsPages.VOICEMAILS)
    return options
  }

  const renderContent = () => {
    switch (activeSettingPage) {
      case SettingsPages.GENERAL:
        return <GeneralSettings />
      case SettingsPages.AUTODIALER_SETTINGS:
        return <AutodialerSettings />
      case SettingsPages.CUSTOM_SCORES:
        return <CustomScores />
      case SettingsPages.USER_MANAGEMENT:
        return <TeamManagement />
      case SettingsPages.SUBTEAMS:
        return <UserGroupSettings />
      case SettingsPages.NUMBERS:
        return <NumberRegistration />
      case SettingsPages.VOICEMAILS:
        return <RecordVM />
      case SettingsPages.SNIPPETS:
        return <SnippetManagement />
      default:
        return <div>
            <Typography variant="h3">Select a setting option to configure</Typography>
        </div>
    }
  }

  const [activeSettingPage, setActiveTab] = useState<SettingsPages>(defaultSettings ?? SettingsPages.GENERAL)
  const navigate = useNavigate()
  const location = useLocation()

  const updateSelectedSettings = (id: SettingsPages) => {
    if (!id) return
    if (id === activeSettingPage) return
    
    // Only check for unsaved changes when navigating between tabs
    const customScoresElement = document.querySelector('[role="tabpanel"]')
    if (activeSettingPage === SettingsPages.CUSTOM_SCORES && customScoresElement) {
      const hasChanges = customScoresElement.getAttribute('data-has-changes') === 'true'
      if (hasChanges) {
        const shouldLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?')
        if (!shouldLeave) {
          return
        }
      }
    }
    
    navigate(`/settings?tab=${id}`)
  }

  const getCurrentId = () => { return new URLSearchParams(location.search).get("tab") }
  
  React.useEffect(() => {
    const pageSessionId = getCurrentId()
    if (!pageSessionId) return
    if (!Object.values(SettingsPages).includes(pageSessionId as SettingsPages)) return
    // Don't show confirmation when URL changes externally
    setActiveTab(pageSessionId as SettingsPages)
  }, [location])

  const renderOption = (settingPage: SettingsPages) => {
    return <button
    key={settingPage}
    onClick={() => updateSelectedSettings(settingPage)}
    className={`
      px-4 py-2 text-sm font-medium whitespace-nowrap transition-colors relative
      ${
        activeSettingPage === settingPage
          ? "text-emerald-600"
          : "text-gray-500 hover:text-gray-700"
      }
    `}
  >
    {settingPage}
    {activeSettingPage === settingPage && (
      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />
    )}
  </button>
  }

  return (
    <div className="h-full w-full mx-auto flex flex-row gap-2">
      <div className="flex flex-col gap-2 overflow-hidden pt-1.5 pl-1 w-full flex-grow">
        <div className='flex flex-row items-center gap-3'>
          <h1 className="text-2xl font-bold text-gray-900 pl-2.5">Settings</h1>
        </div>
        {userLoaded && adminLoaded ? <nav className="flex items-center flex-wrap border-b border-gray-200">
          <div className="flex-1 flex flex-wrap flex-row items-center">
            {getSettingOptions().map((settingPage) => renderOption(settingPage))}
          </div>
      </nav> : <Loader />}
      {!userLoaded || !adminLoaded ? null : <div className="flex-1 w-full h-full overflow-auto ">
            {renderContent()}
        </div>}
      </div>
    </div>
  );
}

export const Settings = connect((state: RootState) => {
    const hasParallel = state.user.value ? state.user.value.parallel_enabled : false
    const isAdmin = state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : false
    const userLoaded = state.user.value !== null
    const adminLoaded = state.adminWrite.value !== null
    return {
    isAdmin,
    hasParallel: hasParallel ?? false,
    userLoaded,
    adminLoaded,
    }
  })(SettingsImpl)