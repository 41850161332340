export const DOM_UI_MOUNTS = Object.freeze({
    PRE_CALL_UI: 'PRE_CALL_UI',
    EMAIL_REQUEST_UI: 'EMAIL_REQUEST_UI',
    WRONG_NUMBER_ACTIVE_DIAL: 'WRONG_NUMBER_ACTIVE_DIAL',
    LINKEDIN_AUTOMATION_UI: 'LINKEDIN_AUTOMATION_UI',
    UI_TRELLUS_DIALER_CONTROLLER: 'UI_TRELLUS_DIALER_CONTROLLER', // standardized across all platforms
    UI_NATIVE_DIALER_CONTROLLER: 'UI_NATIVE_DIALER_CONTROLLER',
    UI_SWITCH_TO_TRELLUS_CONTROLLED: 'UI_SWITCH_TO_TRELLUS_CONTROLLED',
    UI_ACTIVATE_SUPERHUMAN_INTERFACE: 'UI_ACTIVATE_SUPERHUMAN_INTERFACE',
  })
  
  export const TRELLUS_WEB_IFRAME_MOUNTS = Object.freeze({
    DATA_IFRAME: 'DATA_IFRAME',
    LITE_MODE: 'LITE_MODE_IFRAME',
    START_MODE: 'START_MODE_IFRAME',
  })
  
  export const SHADOW_UI_MOUNTS = Object.freeze({
    EMAIL_MOBILE_VIEW: 'EMAIL_MOBILE_VIEW',
    EMBEDDED_COACHING: 'EMBEDDED_COACHING',
    EMBEDDED_LOGGER: 'EMBEDDED_LOGGER',
    INCOMING_CALLBACK: 'INCOMING_CALLBACK',
    SPEAK_ON_CONNECT: 'SPEAK_ON_CONNECT',
    FLOATING_KEYPAD: 'FLOATING_KEYPAD',
    FLOATING_NOTES: 'FLOATING_NOTES',
    EXTENSION_STALE_NOTIFIER: 'EXTENSION_STALE_NOTIFIER',
    FLOATING_REQUIRED_DISPOSITIONS: 'FLOATING_REQUIRED_DISPOSITIONS',
    MINIMAL_COACHING_VIEW: 'MINIMAL_COACHING_VIEW',
    TEXT_MANAGER_MODAL: 'TEXT_MANAGER_MODAL',
    TOAST_NOTIFIER: 'TOAST_NOTIFIER',
    COMMAND_K_MANAGER: 'COMMAND_K_MANAGER',
    SUPERHUMAN_LINKEDIN: 'SUPERHUMAN_LINKEDIN',
    ...TRELLUS_WEB_IFRAME_MOUNTS,
  })
  
  export const LITE_MODE_UI_COMPONENTS = Object.freeze({
    DRAGGABLE_NODE: 'DRAGGABLE_NODE',
    COACHING_IFRAME: 'COACHING_IFRAME',
    CLOSE_BUTTON: 'CLOSE_BUTTON',
    TRELLUS_ICON: 'TRELLUS_ICON',
    POPPED_OUT_SUGGESTION_CARD: 'POPPED_OUT_SUGGESTION_CARD',
    METRIC_NODE: 'METRIC_NODE',
    SUGGESTIONS_NODE: 'SUGGESTIONS_NODE',
    POST_CALL_INFO: 'POST_CALL_INFO',
  })
  
  export const PRE_CALL_UI_COMPONENTS = Object.freeze({
    // *** PRE CALL UI *** //
    RESEARCH_AND_TRANSCRIPT_BUTTON: 'RESEARCH_AND_TRANSCRIPT_BUTTON',
    LINKEDIN_PRE_CALL_BUTTON: 'LINKEDIN_PRE_CALL_BUTTON',
    LINKEDIN_FINDER_BUTTON: 'LINKEDIN_FINDER_BUTTON',
    LINKEDIN_OPTIONS_FOUND: 'LINKEDIN_OPTIONS_FOUND',
  })
  
  export const LINKEDIN_AUTOMATION_COMPONENTS = Object.freeze({
    AUTOMATION_PARENT_BOX: 'AUTOMATION_PARENT_BOX',
    ACTIVATION_CONTENT: 'ACTIVATION_CONTENT',
    NO_DATA_CONTENT: 'NO_DATA_CONTENT',
    CONNECTION_AUTOMATION_CONTENT: 'CONNECTION_AUTOMATION_CONTENT',
    PENDING_DATA_CONTENT: 'PENDING_DATA_CONTENT',
  })
  
  export const AUTODIALER_UI_DISPLAYS = Object.freeze({
    EXPIRED_DISPLAY: 'EXPIRED_DISPLAY',
    PROFILE_AUTODIALER_DISPLAY: 'PROFILE_AUTODIALER_DISPLAY',
    FULL_AUTODIALER_DISPLAY: 'FULL_AUTODIALER_DISPLAY',
    PASSIVE_MODE: 'PASSIVE_MODE',
  });
  
  export const AUTODIALER_UI_COMPONENTS = Object.freeze({
    PLAY_ICON: 'PLAY_ICON',
    NUMBER_LINES_SELECTION: 'NUMBER_LINES_SELECTION',
    AUTODIALER_PROGRESS_BAR: 'AUTODIALER_PROGRESS_BAR',
    INACTIVE_PROGRESS_BAR: 'INACTIVE_PROGRESS_BAR',
    ACTIVE_PROGRESS_BAR: 'ACTIVE_PROGRESS_BAR',
    AUTODIALER_SETTINGS_CONTROL: 'AUTODIALER_SETTINGS_CONTROL',
    AUTODIALER_MUSIC_CONTROL: 'AUTODIALER_MUSIC_CONTROL',
    PASSIVE_AUTO_OPEN: 'PASSIVE_AUTO_OPEN',
    PASSIVE_HANGUP: 'PASSIVE_HANGUP',
    AUTODIALER_SWITCH_ACTIVE_MODE: 'AUTODIALER_SWITCH_ACTIVE_MODE',
    AUTODIALER_SWITCH_DIALING_MODE: 'AUTODIALER_SWITCH_DIALING_MODE',
    EXPIRED_UI: 'EXPIRED_UI',
    STATISTICS_UI: 'STATISTICS_UI',
    VM_SELECTOR_PARENT: 'VM_SELECTOR',
    VM_DROP_NODE: 'VM_DROP_NODE',
    VM_DROP_OPTIONS: 'VM_DROP_OPTIONS',
    TRIAL_COUNTER: 'TRIAL_COUNTER',
    UPGRADE_BUTTON: 'UPGRADE_BUTTON',
  })
  
  export const TRELLUS_UI_COMPONENTS = Object.freeze({
    UI_EXPANDED_VIEW: 'UI_EXPANDED_VIEW',
    UI_NUMBER_LINES_SELECTION: 'UI_NUMBER_LINES_SELECTION',
    UI_NUMBER_SELECTOR: 'UI_NUMBER_SELECTOR',
    UI_TASK_LIST: 'UI_TASK_LIST',
    UI_TASK_LIST_METADATA: 'UI_TASK_LIST_METADATA',
    UI_FOOTER: 'UI_FOOTER',
    UI_NOTES_ICON: 'UI_NOTES_ICON',
    UI_KEYPAD_ICON: 'UI_KEYPAD_ICON',
    UI_DARK_MODE_TOGGLE: 'UI_DARK_MODE_TOGGLE',
    UI_AUDIO_DEVICES: 'UI_AUDIO_DEVICES',
    UI_HEADER: 'UI_HEADER',
    UI_SWITCH_TO_NATIVE: 'UI_SWITCH_TO_NATIVE',
    UI_MINIMIZE_BUTTON: 'UI_MINIMIZE_BUTTON',
    UI_CONTROLLER_HEADER: 'UI_CONTROLLER_HEADER',
    UI_TRELLUS_PLAY: 'UI_TRELLUS_PLAY',
    UI_TRELLUS_MUSIC: 'UI_TRELLUS_MUSIC',
    UI_TRELLUS_SETTINGS: 'UI_TRELLUS_SETTINGS',
    UI_TRELLUS_STATS: 'UI_TRELLUS_STATS',
    UI_LOG_NOTICE: 'UI_LOG_NOTICE',
    UI_LOG_DETAILS: 'UI_LOG_DETAILS',
    UI_LOG_EXPANDED_LIST: 'UI_LOG_EXPANDED_LIST',
    UI_CANCELLED_CALL_INFO: 'UI_CANCELLED_CALL_INFO',
    UI_CANCELLED_LIST: 'UI_CANCELLED_LIST',
    UI_AUTO_COMPLETE_NOTICE: 'UI_AUTO_COMPLETE_NOTICE',
    UI_TRIAL_COUNTER: 'UI_TRIAL_COUNTER',
    LISTEN_TO_RING_SETTING: 'LISTEN_TO_RING_SETTING',
    UI_UPGRADE_BUTTON: 'UI_UPGRADE_BUTTON',
    UI_SORTER_CONTROLLER: 'UI_SORTER_CONTROLLER',
    UI_VOICEMAIL_DROP: 'UI_VOICEMAIL_DROP',
    UI_COMPLETED_NOTICE: 'UI_COMPLETED_NOTICE',
    UI_COMPLETED_DETAILS: 'UI_COMPLETED_DETAILS',
    UI_INCOMING_NOTICE: 'UI_INCOMING_NOTICE',
    UI_INCOMING_EXPANDED_LIST: 'UI_INCOMING_EXPANDED_LIST',
    UI_UPGRADE_PARALLEL: 'UI_UPGRADE_PARALLEL',
    UI_TEXT_SELECTOR: 'UI_TEXT_SELECTOR',
    UI_LIST_SELECTOR: 'UI_LIST_SELECTOR',
  })
  export const MINIMAL_TRELLUS_UI_COMPONENTS = Object.freeze({
    UI_MINIMAL_VIEW: 'UI_MINIMAL_VIEW',
    MINIMAL_PLAY_BUTTON: 'MINIMAL_PLAY_BUTTON',
    MINIMAL_NUMBER_LINES: 'MINIMAL_NUMBER_LINES',
    MINIMAL_EXPAND_BUTTON: 'MINIMAL_EXPAND_BUTTON',
    MINIMAL_UI_TASK_LIST_METADATA: 'MINIMAL_UI_TASK_LIST_METADATA',
    MINIMAL_TASK_LIST: 'MINIMAL_TASK_LIST',
    MINIMAL_CANCELLED_LIST: 'MINIMAL_CANCELLED_LIST',
    MINIMAL_GRAB_NODE: 'MINIMAL_GRAB_NODE',
  })
  
  export const UI_COMPONENTS = Object.freeze({
    // *** PRE CALL UI *** //
    ...PRE_CALL_UI_COMPONENTS,
  
    // *** LINKEDIN AUTOMATION *** //
    ...LINKEDIN_AUTOMATION_COMPONENTS,
  
    /// ** LITE MODE ** ///
    ...LITE_MODE_UI_COMPONENTS,
  
    // *** AUTODIALER UI *** //
    ...AUTODIALER_UI_COMPONENTS,
  
    // *** TRELLUS UI *** //
    ...TRELLUS_UI_COMPONENTS,
    ...MINIMAL_TRELLUS_UI_COMPONENTS,
  })
  
  export const TRELLUS_WEB_INTERACTORS = Object.freeze({
    RESEARCH_LOOKUP: 'RESEARCH_LOOKUP',
    TRANSCRIPT_LOOKUP: 'TRANSCRIPT_LOOKUP',
    METADATA_TRANSMITTER: 'METADATA_TRANSMITTER',
    BACKGROUND_NOTIFIER: 'BACKGROUND_NOTIFIER',
  })
  
  export const MANAGER_SET_KEYS = Object.freeze({
    INSTANCE: 'INSTANCE',
    CLASS: 'CLASS',
  })
  
  export const CFG_MANAGERS = Object.freeze({
    SETTINGS: 'SETTINGS',
    FIELD_SETTINGS: 'FIELD_SETTINGS',
    USER_INFO: 'USER_INFO',
    TRIAL_MANAGER: 'TRIAL_MANAGER',
    PARALLEL_SETTINGS: 'PARALLEL_SETTINGS',
    CSV_LIST_MANAGER: 'CSV_LIST_MANAGER',
    EXTENSION_STALE: 'EXTENSION_STALE',
  })
  
  export const CONTROLLER_MANAGERS = Object.freeze({
    SYSTEM_CONTROLLER: 'SYSTEM_CONTROLLER',
    CALL_INFO_SYNC: 'CALL_INFO_CONTROLLER',
    DIAL_PLAN_CONTROLLER: 'DIAL_PLAN_CONTROLLER',
    LIVE_SESSION_CONNECTOR: 'LIVE_SESSION_CONNECTOR',
  })
  
  export const NATIVE_REALTIME_MANAGERS = Object.freeze({
    NATIVE_REALTIME_DETECTOR: 'NATIVE_REALTIME_DETECTOR', // in charge of detecting real-time calls + synchronizing across scopes
    NATIVE_AUDIO_INTERCEPTOR: 'INJECTED_NATIVE_AUDIO_INTERCEPTOR', // in charge of intercepting audio + transmitting audio data
  })
  
  export const NATIVE_SESSION_MANAGERS = Object.freeze({ // in charge of communicating and processing information via api.trellus.ai
    NATIVE_SESSION_CONNECTOR: 'NATIVE_SESSION_CONNECTOR', // in charge of connecting to native dialer sessions
    NATIVE_COACHING_MANAGER: 'NATIVE_COACHING_MANAGER', // in charge of coaching
    NATIVE_IDENTITY_MANAGER: 'NATIVE_IDENTITY_MANAGER', // in charge of managing identity
    NATIVE_OUTCOME_MANAGER: 'NATIVE_OUTCOME_MANAGER', // in charge of managing outcomes
  })
  
  export const NATIVE_PROSPECT_INFO_MANAGERS = Object.freeze({
    NATIVE_FIELD_AUTOMATION_SEARCHER: 'NATIVE_FIELD_AUTOMATION_SEARCHER', // in charge of finding field automations
    NATIVE_AUGMENTOR: 'NATIVE_AUGMENTOR', // in charge of collecting passive prospect info + getting current snapshot
    NATIVE_ACTIVE_PROSPECT_INFO: 'NATIVE_ACTIVE_PROSPECT_INFO', // in charge of monitoring the current active prospect links/metadata
    NATIVE_LINKEDIN_FINDER: 'NATIVE_LINKEDIN_FINDER', // in charge of finding linkedin info for prospects
    NATIVE_CLIENT_LOG_MANAGER: 'NATIVE_CLIENT_LOG_MANAGER',
    NATIVE_VM_INFO: 'NATIVE_VM_INFO',
  })
  
  export const API_CONTROLLERS = Object.freeze({
    TRELLUS_TASK_LOAD_MANAGER: 'TRELLUS_TASK_LOAD_MANAGER',
    TRELLUS_TASK_STATUS_MANAGER: 'TRELLUS_TASK_STATUS_MANAGER',
    TRELLUS_TASK_LOG_MANAGER: 'TRELLUS_TASK_LOG_MANAGER',
    TRELLUS_EXECUTION_MANAGER: 'TRELLUS_EXECUTION_MANAGER',
    TRELLUS_SESSION_TRACKER: 'TRELLUS_SESSION_TRACKER',
    TRELLUS_CALLBACK_MANAGER: 'TRELLUS_CALLBACK_MANAGER',
    TRELLUS_PLAN_MANAGER: 'TRELLUS_PLAN_MANAGER',
    TRELLUS_LIST_SELECTOR: 'TRELLUS_LIST_SELECTOR',
    TRELLUS_AUDIO_MANAGER: 'TRELLUS_AUDIO_MANAGER',
    TRELLUS_CHECK_CADENCE_COMPLETENESS: 'TRELLUS_CHECK_CADENCE_COMPLETENESS',
    TRELLUS_TASK_AUTO_COMPLETOR: 'TRELLUS_TASK_AUTO_COMPLETOR',
    TRELLUS_VM_SYNC: 'TRELLUS_VM_SYNC',
    TRELLUS_TEXT_MANAGER: 'TRELLUS_TEXT_MANAGER',
  })
  
  export const API_ENGINES = Object.freeze({
    ENGINE_ORCHESTRATOR: 'ENGINE_ORCHESTRATOR',
    TRELLUS_ENGINE: 'TRELLUS_ENGINE',
    NATIVE_ENGINE: 'NATIVE_ENGINE',
  })
  
  export const NATIVE_CONTROLLED_AUTOMATIONS = Object.freeze({
    NATIVE_DIAL_ENSURE_MANAGER: 'NATIVE_DIAL_ENSURE_MANAGER',
    NATIVE_WRONG_NUMBER_AUTOMATION: 'NATIVE_WRONG_NUMBER_AUTOMATION',
    NATIVE_SUMMARY_AUTOMATION: 'NATIVE_SUMMARY_AUTOMATION',
    NATIVE_SINGLE_FIELD_AUTOMATION: 'NATIVE_SINGLE_FIELD_AUTOMATION',
    NATIVE_MULTI_FIELD_AUTOMATION: 'NATIVE_MULTI_FIELD_AUTOMATION',
    NATIVE_HANG_UP_AUTOMATION: 'NATIVE_HANG_UP_AUTOMATION',
    NATIVE_AUTO_OPEN_LINKS_AUTOMATION: 'NATIVE_AUTO_OPEN_LINKS_AUTOMATION',
    NATIVE_DOM_AUTOMATION_MANAGER: 'NATIVE_DOM_AUTOMATION_MANAGER',
  })
  
  export const NATIVE_INFO_MANAGERS = Object.freeze({
    NATIVE_CLICK_LISTENERS: 'NATIVE_CLICK_LISTENERS',
    NATIVE_CONTENT_LISTENERS: 'NATIVE_CONTENT_LISTENERS',
    NATIVE_KEYPAD_DATA_MANAGER: 'NATIVE_KEYPAD_DATA_MANAGER',
  })
  
  export const NATIVE_TRELLUS_CONTROLLED_MANAGERS = Object.freeze({
    TRELLUS_CONTROLLED_TASK_EXECUTION_MANAGER: 'TRELLUS_CONTROLLED_TASK_EXECUTION_MANAGER',
    TRELLUS_CONTROLLED_LOG_MANAGER: 'TRELLUS_CONTROLLED_LOG_MANAGER',
  })
  
  export const NATIVE_AUTODIALER_MANAGERS = Object.freeze({
    NATIVE_DIAL_PLAN_MANAGER: 'NATIVE_DIAL_PLAN_MANAGER',
    NATIVE_AUTODIALER_TASK_INFO_MANAGER: 'NATIVE_AUTODIALER_TASK_INFO_MANAGER',
    NATIVE_AUTODIALER_TASK_EVALUATION_MANAGER: 'NATIVE_AUTODIALER_TASK_EVALUATION_MANAGER',
    NATIVE_AUTODIALER_TASK_MOVE_MANAGER: 'NATIVE_AUTODIALER_TASK_MOVE_MANAGER',
    NATIVE_AUTODIALER_TASK_EXECUTION_MANAGER: 'NATIVE_AUTODIALER_TASK_EXECUTION_MANAGER',
    NATIVE_AUTODIALER_TASK_LOG_MANAGER: 'NATIVE_AUTODIALER_TASK_LOG_MANAGER',
    NATIVE_AUTODIALER_CLICK_LISTENERS: 'NATIVE_AUTODIALER_CLICK_LISTENERS',
    NATIVE_AUTODIALER_COMPLETION_MANAGER: 'NATIVE_AUTODIALER_COMPLETION_MANAGER',
  })
  
  export const MISC_MANAGERS = Object.freeze({
    MUSIC_MANAGER: 'MUSIC_MANAGER',
    TIME_MANAGER: 'TIME_MANAGER',
  })
  
  export const MANAGERS = Object.freeze({
    // *** CFGS *** //
    ...CFG_MANAGERS,
  
    // *** CONTROLLERS *** //
    ...CONTROLLER_MANAGERS,
  
    // *** MISC *** //
    ...MISC_MANAGERS,
  
    // **** NATIVE **** //
    ...NATIVE_REALTIME_MANAGERS,
    ...NATIVE_SESSION_MANAGERS,
    ...NATIVE_PROSPECT_INFO_MANAGERS,
    ...NATIVE_AUTODIALER_MANAGERS,
  
    ...TRELLUS_WEB_INTERACTORS,
  
    // *** TRELLUS CONTROLLERS *** //
    ...API_CONTROLLERS,
  
    // ** TRELLUS ENGINES ** //
    ...API_ENGINES,
  
    // *** NATIVE CONTROLLED AUTOMATIONS *** //
    ...NATIVE_CONTROLLED_AUTOMATIONS,
  
    // *** NATIVE INFO MANAGERS *** //
    ...NATIVE_INFO_MANAGERS,
  
    // *** UI *** //
    ...DOM_UI_MOUNTS,
    ...SHADOW_UI_MOUNTS,
    ...UI_COMPONENTS,
  
    // *** EXTERNAL TRELLUS WEB *** //
    EXTERNAL: 'EXTERNAL',
  })
  
  export const MUSIC_PARAM = {
    MUSIC_STATE: 'MUSIC_STATE', // current music state
    CAN_PLAY_MUSIC: 'CAN_PLAY_MUSIC', // whether or not music can be played
  }
  
  export const AUTOMATION_STATUS = {
    READY: 'READY',
    PENDING: 'PENDING',
    DEPLOYING: 'DEPLOYED',
    FAILED: 'FAILED',
    COMPLETE: 'COMPLETE',
    WAITING_FOR_VERIFICATION: 'WAITING_FOR_VERIFICATION',
    REQUIRES_USER_INPUT: 'REQUIRES_USER_INPUT',
  };
  