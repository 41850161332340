import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Add salesfloor slice
export interface SalesFloorState {
  manualMuteOverride: boolean | null;
}

const salesFloorInitialState: SalesFloorState = {
  manualMuteOverride: null,
};

export const salesFloorSlice = createSlice({
  name: 'salesFloor',
  initialState: salesFloorInitialState,
  reducers: {
    setManualMuteOverride: (state, action: PayloadAction<boolean | null>) => {
      state.manualMuteOverride = action.payload;
    },
  },
});

export const { setManualMuteOverride } = salesFloorSlice.actions;

// Add user state conversion function
export const convertFromReduxSafeUserState = (state: any) => state;
