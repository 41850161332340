import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './components/SessionStatus/SessionStoreSetter';
import { adminWriteSlice, allSyntheticConversationalDataSlice, dispositionOptionSlice, emailsValuePropsSlice, externalAccountsSlice, 
  filterSlice, globalBotsSlice, metricDataSlice, pendingAuthorizationInfo, 
  pendingReviewsSlice, prospectInfoOptionSlice, scopesSlice, sessionListParamsSlice, 
  settingsSlice, syntheticConversationalDataSlice, syntheticSessionQueryDataSlice, 
  teamBotsSlice, teamSlice, transcriptFilterSlice, userGroupsSlice, userSlice, 
  visibleAccountsSlice, activeSynthCallSlice, callAnalysisSlice, botsToEmotionSlice, customDefinitionsSlice, leaderboardMembershipsSlice, analyticsResetTimerSlice,
  teamBotSkillsSlice, globalBotSkillsSlice } from './lib/redux/store';
import { linkedInClientsSlice } from 'lib/redux/linkedin/client/slice';
import { searchResultsSlice } from 'lib/redux/linkedin/search/slice';
import { chatConversationsSlice } from 'lib/redux/linkedin/conversations/chat-slice';
import { salesNavigatorConversationsSlice } from 'lib/redux/linkedin/conversations/sales-nav-slice';
import { conversationStatesSlice, LINKEDIN_STATES_STORAGE_KEY } from 'lib/redux/linkedin/metadata/slice';
import { analyzeSlice } from 'lib/redux/linkedin/conversations/analyze-slice';
import { selectedConversationSlice as selectedLinkedInSlice } from 'lib/redux/linkedin/navigate/slice';
import { temporarySlice } from 'lib/redux/linkedin/conversations/temporary-slice';
import { newConversationSlice } from 'lib/redux/linkedin/new-conversation/slice';
import { snippetsSlice } from 'lib/redux/linkedin/snippets/slice';
import { SAVED_FILTERS_STORAGE_KEY, savedFiltersSlice } from './lib/redux/saved-filters/slice';
import { salesFloorSlice } from 'lib/redux/salesfloor/slice';
  
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    sessionList: sessionListParamsSlice.reducer,
    userGroupInfo: userGroupsSlice.reducer,
    externalAccounts: externalAccountsSlice.reducer,
    scope: scopesSlice.reducer,
    adminWrite: adminWriteSlice.reducer,
    user: userSlice.reducer,
    team: teamSlice.reducer,
    visibleAccounts: visibleAccountsSlice.reducer,
    customDefinitions: customDefinitionsSlice.reducer,
    pendingReviews: pendingReviewsSlice.reducer,
    authenticationInfo: pendingAuthorizationInfo.reducer,
    emailValueProps: emailsValuePropsSlice.reducer,
    dispositionOptions: dispositionOptionSlice.reducer,
    prospectInfoOptions: prospectInfoOptionSlice.reducer,
    filter: filterSlice.reducer,
    transcriptFilter: transcriptFilterSlice.reducer,
    settings: settingsSlice.reducer,
    metricData: metricDataSlice.reducer,
    teamBots: teamBotsSlice.reducer,
    globalBots: globalBotsSlice.reducer,
    teamBotSkills: teamBotSkillsSlice.reducer,
    globalBotSkills: globalBotSkillsSlice.reducer,
    synthConversations: syntheticConversationalDataSlice.reducer,
    synthConversationQueryParam: syntheticSessionQueryDataSlice.reducer,
    allSynthConversations: allSyntheticConversationalDataSlice.reducer,
    activeSynthCall: activeSynthCallSlice.reducer,
    callAnalysis: callAnalysisSlice.reducer,
    botsToEmotion: botsToEmotionSlice.reducer,
    linkedinClients: linkedInClientsSlice.reducer,
    linkedinSearchResults: searchResultsSlice.reducer,
    linkedinChatConversations: chatConversationsSlice.reducer,
    linkedinSalesNavigatorConversations: salesNavigatorConversationsSlice.reducer,
    linkedinConversationStates: conversationStatesSlice.reducer,
    linkedinAnalyze: analyzeSlice.reducer,
    linkedinSelected: selectedLinkedInSlice.reducer,
    linkedinTemporary: temporarySlice.reducer,
    linkedinNewConversation: newConversationSlice.reducer,
    linkedinSnippets: snippetsSlice.reducer,
    savedFilters: savedFiltersSlice.reducer,
    leaderboardMemberships: leaderboardMembershipsSlice.reducer,
    analyticsResetTimer: analyticsResetTimerSlice.reducer,
    salesFloor: salesFloorSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
// This ensures RootState includes all our reducers with proper typing
export type RootState = ReturnType<typeof store.getState>;


// Add these at the top level of the file
const STORAGE_WRITE_INTERVAL = 5000; // 5 seconds

// Add this function at the top level
function setupStorageWriter() {
  let currentStates = JSON.parse(localStorage.getItem(LINKEDIN_STATES_STORAGE_KEY) || '{}');
  setInterval(() => {
    if (!store || !store.getState || !store.getState().linkedinConversationStates) {
      return;
    }
    const newStates = store.getState().linkedinConversationStates.states;
    if (JSON.stringify(currentStates) !== JSON.stringify(newStates)) {
      currentStates = newStates;
      localStorage.setItem(LINKEDIN_STATES_STORAGE_KEY, JSON.stringify(newStates));
    }
  }, STORAGE_WRITE_INTERVAL);
}

// Call this when your app initializes (e.g., in index.tsx or App.tsx)
setupStorageWriter();

function setupSavedFiltersStorageWriter() {
  let currentFilters = JSON.parse(localStorage.getItem(SAVED_FILTERS_STORAGE_KEY) || '{}');
  setInterval(() => {
    const filters = store.getState().savedFilters.filters;
    if (JSON.stringify(currentFilters) !== JSON.stringify(filters)) {
      currentFilters = filters;
      localStorage.setItem(SAVED_FILTERS_STORAGE_KEY, JSON.stringify(filters));
    }
  }, STORAGE_WRITE_INTERVAL);
}

setupSavedFiltersStorageWriter();