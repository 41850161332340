import { useCallback, useMemo } from "react";

import { ConversationId, InboxType, SOURCE_TYPE } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { addTemporaryMessage, convertToLinkedInAttachment, removeTemporaryMessage, updateTemporaryMessage } from "./temporary-slice";
import uuid from "react-uuid";
import { addToSalesConversation } from "./sales-nav-slice";
import { addConversations, addEmojiReaction, addToConversation, removeEmojiReaction } from "./chat-slice";
import { LINKEDIN_SALES_MESSAGE_TYPE } from "interfaces/linkedin-salesnavigator";
import { useLinkedInSearch } from "../search/hook";
import { useLinkedInNavigate } from "../navigate/hook";
import { sleep } from "core";
import { SEC_TO_MS } from "cfg/const";
import { LinkedInNormalizedParticipant } from "interfaces/linkedin";

export const useSendMessage = () => {
    const { toConversation, closeDraft } = useLinkedInNavigate();
    const client = useSelector((state: RootState) => state.linkedinClients.client);
    const salesNavigatorClient = useSelector((state: RootState) => state.linkedinClients.salesNavigatorClient);
    const selectedConversation = useSelector((state: RootState) => state.linkedinSelected.conversationId);
    const salesNavigatorConversations = useSelector((state: RootState) => state.linkedinSalesNavigatorConversations.conversations);
    const activeOption = useSelector((state: RootState) => state.linkedinSelected.activeOption);
    const temporaryMessages = useSelector((state: RootState) => state.linkedinTemporary.temporaryMessages);
    const { getMatchingParticipants } = useLinkedInSearch();
    const dispatch = useDispatch();

    const drafts = useSelector((state: RootState) => state.linkedinNewConversation.drafts);

    const activeConversation = useMemo(() => {
        if (!selectedConversation) return null;
        if (selectedConversation.id === activeOption) return selectedConversation;
        return null;
    }, [selectedConversation, activeOption]);

    const draftMatchingConversationInfo = useMemo(() => {
        if (!drafts) return false;
        if (!activeOption) return false;
        if (!drafts[activeOption]) return false;
        if (!drafts[activeOption].participants || drafts[activeOption].participants.length === 0) return false;
        return getMatchingParticipants(drafts[activeOption].participants.map(p => p.hostIdentityUrn))
    }, [drafts, activeOption, getMatchingParticipants]);

    const draft = useMemo(() => {
        if (!activeOption) return null;
        if (!drafts) return null;
        if (!drafts[activeOption]) return null;
        return drafts[activeOption];
    }, [drafts, activeOption]);

    const canSend = useMemo(() => {
        if (!activeOption) return false;
        if (selectedConversation?.id === activeOption) return true;
        return draft && draftMatchingConversationInfo && !draftMatchingConversationInfo.isLoading && draft?.participants?.length > 0
    }, [selectedConversation, activeOption, draftMatchingConversationInfo, draft]);

    const sendDraftMessage = useCallback(async (text: string, attachments?: File[]) => {
        if (!client) return;
        if (!draftMatchingConversationInfo || draftMatchingConversationInfo.isLoading) return;
        if (!draft) return;
        // temporary draft message - and then we want to get the conversation list and move to that... 
        const newMessageUUID = uuid();
        dispatch(addTemporaryMessage({
            conversationId: draft.id,
            message: {
                uuid: newMessageUUID,
                text: text,
                attachments: convertAttachmentsToTemporaryMessageAttachments(newMessageUUID, attachments),
                files: attachments || [],
                failed: false
            }
        }));
        let success = false;
        try {
            const result = await client.sendToNewThread(text, draft.participants.map(p => p.hostIdentityUrn), attachments);
            // get the conversation with the participants...
            if (!result) throw new Error('Failed to send message');
            success = true;
            let matchingConversation;
            for (let i = 0; i < 2; i++) {
                const conversation = await client.getConversationsByRecipients(draft.participants.map(p => p.hostIdentityUrn));
                if (conversation.length > 0) {
                    matchingConversation = conversation[0];
                    break;
                }
                await sleep(1.5*SEC_TO_MS)
            }
            if (matchingConversation) {
                toConversation({
                    id: matchingConversation.entityUrn,
                    sourceType: SOURCE_TYPE.CHAT,
                    lastActivity: Date.now()
                });
                closeDraft(draft.id);
            } else {
                console.error('Failed to find matching conversation');
            }
        } catch (error) {}
        if (!success) {
            dispatch(updateTemporaryMessage({
                conversationId: draft.id,
                messageId: newMessageUUID,
                update: {
                  failed: true
                }
              }));
        } else {
            dispatch(removeTemporaryMessage({
                conversationId: draft.id,
                messageId: newMessageUUID
              }));
        }
    }, [draftMatchingConversationInfo]);

    const sendExistingMessageLinkedInChat = useCallback(async (conversationId: ConversationId, text: string, attachments?: File[]) => {
        if (!client) return;
        try {
            const result = await client.sendToConversation(text, conversationId.id, attachments);
            if (!result) return;
            dispatch(addToConversation({
                id: conversationId.id,
                message: result
            }));
            return result;
        } catch (error) {
            console.error('Failed to send message:', error);
            throw error; // Re-throw to handle in the component
        }
    }, [selectedConversation, client]);

    const sendExistingMessageLinkedInSalesNavigator = useCallback(async (conversationId: ConversationId, text: string, attachments?: File[]) => {
        if (!salesNavigatorClient) return;
        try {
            const currentMessage = salesNavigatorConversations[conversationId.id]
            if (!currentMessage) return;
            const repAuthor = currentMessage.conversation?.participants.find((p) => p.degree === 0)?.entityUrn;
            if (!repAuthor) return;
            const result = await salesNavigatorClient.sendToConversation(text, conversationId.id, attachments);
            if (!result) return;
            dispatch(addToSalesConversation({
                id: conversationId.id,
                message: {
                    $type: LINKEDIN_SALES_MESSAGE_TYPE,
                    type: "MESSAGE",
                    attachments: attachments?.map(file => ({
                        id: result.messageId + '_' + file.name,
                        assetUrn: `urn:li:fs_salesAsset:(${result.messageId},${file.name})`,
                        name: file.name,
                        byteSize: file.size,
                        mediaType: file.type,
                        url: file.type.startsWith('image/') 
                          ? URL.createObjectURL(file) 
                          : `data:${file.type};attachment,${file.name}`
                    })) || [],
                    author: repAuthor,
                    body: text,
                    deliveredAt: new Date().valueOf(),
                    id: result.messageId,
                    threadId: result.threadId,
                }
            }));
            return result;
        } catch (error) {
            console.error('Failed to send message:', error);
            throw error; // Re-throw to handle in the component
        }
    }, [selectedConversation, salesNavigatorClient, salesNavigatorConversations]);

    const convertAttachmentsToTemporaryMessageAttachments = useCallback((newMessageUUID: string, attachments?: File[]) => {
        if (!attachments) return [];
        return attachments.map(file => convertToLinkedInAttachment(file, newMessageUUID)) || [];
    }, []);

    const sendExistingMessage = useCallback(async (conversationId: ConversationId, text: string, attachments?: File[]) => {
        if (!canSend) return;
        const newMessageUUID = uuid();
        dispatch(addTemporaryMessage({
            conversationId: conversationId.id,
            message: {
              uuid: newMessageUUID,
              text: text,
              attachments: convertAttachmentsToTemporaryMessageAttachments(newMessageUUID, attachments),
              files: attachments || [],
              failed: false
            }
          }));
          let success = false;
          try {
            const result = conversationId.sourceType === SOURCE_TYPE.CHAT ? await sendExistingMessageLinkedInChat(conversationId, text, attachments) : 
            await sendExistingMessageLinkedInSalesNavigator(conversationId, text, attachments);
            success = result !== undefined;
          } catch (error) {
            console.error('Failed to send message:', error);
          }
          if (!success) {
            dispatch(updateTemporaryMessage({
                conversationId: conversationId.id,
                messageId: newMessageUUID,
                update: {
                  failed: true
                }
              }));
          } else {
            dispatch(removeTemporaryMessage({
                conversationId: conversationId.id,
                messageId: newMessageUUID
              }));
          }
        }, [canSend, sendExistingMessageLinkedInChat, sendExistingMessageLinkedInSalesNavigator]);

    const sendMessage = useCallback((text: string, attachments?: File[]) => {
        if (!canSend) return;
        if (activeConversation) sendExistingMessage(activeConversation, text, attachments);
        else if (draftMatchingConversationInfo && draftMatchingConversationInfo.matchingConversation) {
            sendExistingMessage(draftMatchingConversationInfo.matchingConversation, text, attachments);
        } else sendDraftMessage(text, attachments);
    }, [canSend, activeConversation, sendExistingMessage, draftMatchingConversationInfo, sendDraftMessage]);

    const retryTemporaryMessage = useCallback((id: string, temporary_uuid: string) => {
        if (!canSend) return;
        const temporaryMessageForConvo = temporaryMessages[id];
        const temporaryMessage = temporaryMessageForConvo.find((m) => m.uuid === temporary_uuid);
        if (!temporaryMessage) return;
        dispatch(removeTemporaryMessage({
            conversationId: id,
            messageId: temporary_uuid
        }));
        sendMessage(temporaryMessage.text ?? "", temporaryMessage.files);
    }, [canSend, temporaryMessages, sendMessage]);

    const removeTemporaryMessageFromConvo = useCallback((id: string, temporary_uuid: string) => {
        if (!canSend) return;
        const temporaryMessageForConvo = temporaryMessages[id];
        const temporaryMessage = temporaryMessageForConvo.find((m) => m.uuid === temporary_uuid);
        if (!temporaryMessage) return;
        dispatch(removeTemporaryMessage({
            conversationId: id,
            messageId: temporary_uuid
        }));
    }, [canSend, temporaryMessages]);

    const sendReaction = useCallback(async (messageEntityUrn: string, emoji: string) => { 
        if (!client) return;
        if (!activeConversation) return;
        let success = false;
        try {
            dispatch(addEmojiReaction({
                conversationId: activeConversation,
                messageId: messageEntityUrn,
                emoji
            }));
            await client.addEmojiReaction(messageEntityUrn, emoji);
            success = true;
        } catch (error) {
            success = false;
        }
        if (!success) {
            dispatch(removeEmojiReaction({
                conversationId: activeConversation,
                messageId: messageEntityUrn,
                emoji
            }));
        }
        return success;
    }, [client]);

    const removeReaction = useCallback(async (messageEntityUrn: string, emoji: string) => { 
        if (!client) return;
        if (!activeConversation) return;
        let success = false;
        dispatch(removeEmojiReaction({
            conversationId: activeConversation,
            messageId: messageEntityUrn,
            emoji
        }));
        try {
            await client.removeEmojiReaction(messageEntityUrn, emoji);
            success = true;
        } catch (error) {
            success = false;
        }
        if (!success) {
            dispatch(addEmojiReaction({
                conversationId: activeConversation,
                messageId: messageEntityUrn,
                emoji
            }));
        }
        return success;
    }, [client]);

    const sendInvites = useCallback(async (invitees: LinkedInNormalizedParticipant[], message: string) => {
        if (!client) return;
        // for each invitee, check if there is an existing conversation with them
        // if there is send the invite message to them
        // otherwise start a new conversation with them
        // sleep for a random .5-1 second between each invite
        for (const invitee of invitees) {
            const conversation = await client.getConversationsByRecipients([invitee.hostIdentityUrn]);
            if (conversation.length > 0) {
                await client.sendToConversation(message, conversation[0].entityUrn);
            } else {
                await client.sendToNewThread(message, [invitee.hostIdentityUrn]);
            }
            await sleep(Math.random() * 500 + 500);
        }
    }, [client]);

    return {
        canSend,
        sendMessage,
        retryTemporaryMessage,
        removeTemporaryMessageFromConvo,
        sendReaction,
        removeReaction,
        sendInvites
    }
}