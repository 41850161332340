import React from 'react';
import { Box, IconButton, Divider } from '@mui/material';
import { Close as CloseIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Typography } from 'interfaces/typography';
import { useDispatch } from 'react-redux';
import { setProfilePanelOpen } from 'lib/redux/linkedin/navigate/slice';

const PANEL_WIDTH = 320;

const ProfilePanel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({ theme, open }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  width: PANEL_WIDTH,
  backgroundColor: theme.palette.background.paper,
  borderLeft: `1px solid ${theme.palette.divider}`,
  transform: open ? 'translateX(0)' : `translateX(${PANEL_WIDTH}px)`,
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  display: 'flex',
  flexDirection: 'column',
  zIndex: 2,
}));

const ProfileLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    opacity: 0.8,
  },
  '& svg': {
    fontSize: '14px',
  },
}));

interface ProfileDetailsPanelProps {
  open: boolean;
  name: string;
  pictureUrl?: string;
  profileUrl?: string;
  isLoading?: boolean;
}

export const ProfileDetailsPanel: React.FC<ProfileDetailsPanelProps> = ({
  open,
  name,
  pictureUrl,
  profileUrl,
  isLoading = true,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setProfilePanelOpen(false));
  };

  return (
    <ProfilePanel open={open}>
      <Box sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden'
      }}>
        {/* Header */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h3">Profile Details</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Profile Picture and Name */}
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 2
        }}>
          {pictureUrl ? (
            <img
              src={pictureUrl}
              alt={name}
              style={{
                width: 64,
                height: 64,
                borderRadius: '50%',
                objectFit: 'cover'
              }}
              referrerPolicy="no-referrer"
            />
          ) : (
            <Box sx={{
              width: 64,
              height: 64,
              borderRadius: '50%',
              bgcolor: 'grey.300',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography variant="h3" color="text.secondary">
                {name.charAt(0)}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="h3" style={{ marginBottom: '4px' }}>
              {name}
            </Typography>
            {profileUrl && (
              <ProfileLink href={profileUrl} target="_blank" rel="noopener noreferrer">
                <Typography variant="smallParagraph">View LinkedIn Profile</Typography>
                <OpenInNewIcon />
              </ProfileLink>
            )}
          </Box>
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Profile Content */}
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 1,
            textAlign: 'center',
            mt: 4
          }}>
            <Typography variant="h3" color="text.primary">
              Under Construction
            </Typography>
            <Typography variant="mediumParagraph" color="text.secondary">
              Check back next week for profile details
            </Typography>
          </Box>
        </Box>
      </Box>
    </ProfilePanel>
  );
}; 