import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const LoadingConversationBody: React.FC = React.memo(() => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      gap: 2, 
      p: 2,
      flexGrow: 1,
      minHeight: 0,
      overflow: 'auto'
    }}>
      {[...Array(5)].map((_, i) => (
        <Box key={i} sx={{ display: 'flex', gap: 2 }}>
          <Skeleton variant="circular" width={32} height={32} />
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="20%" sx={{ mb: 1 }} />
            <Skeleton variant="rounded" width="80%" height={40} />
          </Box>
        </Box>
      ))}
    </Box>
  );
}); 