import { PreviousCallSummary } from 'interfaces/services';
import { SESSION_METRICS_COLUMNS } from 'interfaces/db';

/**
 * Augments or generates a rep summary based on call metrics when no explicit summary is available
 * @param callInfo The call summary information
 * @param hasNotes Whether the call has notes
 * @param getResponseRegardless Whether to return a response even if hasNotes is true
 * @returns Generated or augmented summary string, or null if no summary could be generated
 */
export const augmentRepSummary = (
  callInfo: PreviousCallSummary, 
  hasNotes?: boolean, 
  getResponseRegardless?: boolean
): string | null => {
  let repSummary = callInfo.repSummary;
  const sessionMetric = callInfo.sessionMetric;   
  
  if (!repSummary && !callInfo.prospectSummary && (getResponseRegardless || !hasNotes)) {
    if (!sessionMetric) {
      repSummary = 'No response';
    } else if ((sessionMetric[SESSION_METRICS_COLUMNS.VOICEMAIL_DURATION] ?? 0) > 0) {
      if (sessionMetric[SESSION_METRICS_COLUMNS.REP_SPEECH_DURING_VOICEMAIL_DURATION] ?? 0 > 10) {
        repSummary = 'Left vm';
      } else {
        repSummary = 'Reached vm';
      }
    } else if ((sessionMetric[SESSION_METRICS_COLUMNS.TARGET_DURATION] ?? 0) > 0) {
      repSummary = 'Reached live prospect';
    } else if ((sessionMetric[SESSION_METRICS_COLUMNS.GATEKEEPER_DURATION] ?? 0) > 0) {
      repSummary = 'Reached gatekeeper';
    } else if (sessionMetric[SESSION_METRICS_COLUMNS.IS_ANSWERED] ?? false) {
      repSummary = 'Reached live person';
    } else {
      repSummary = 'No response';
    }
  }
  return repSummary;
}; 