import { Typography } from "interfaces/typography";
import React, { useState } from "react";
import { useSpring, animated, AnimationResult } from '@react-spring/web'
import { ThreeCircles } from "components/Loader";
import { EXTENSION_ID, MESSAGE_TYPES } from "cfg/endpoints";
import { WidgetType } from "../Widget";
import { Pulsating } from "./Pulsating";

export enum RequestState {
  DEFAULT='default',
  PENDING='pending',
  RECEIVED='received',
  TO_BE_REQUESTED_BY_USER='to_be_requested',
  REQUESTED_BY_USER='requested_by_user',
  ERROR='error'
}

type RequestProgressBar = {
    triggerAlert: (label: string, timeInMs: number, isSuccess: boolean) => void,
    copyToPlatform: boolean
    onRequest: () => void
    updateState: (state: RequestState) => void
    requestState: RequestState
    content?: string
    extensionId?: string
    widgetType: WidgetType
    noShowButtons?: boolean
    mainView?: boolean
    prospectPhoneValue?: string | null
}

const aiDefault = 
<svg width="100%" height="100%" viewBox="0 0 80 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.7389 51.3681V50.8681H15.2389H9.35628C7.48486 50.8681 5.93457 49.2902 5.93457 47.3162V23.005C5.93457 21.031 7.48486 19.4531 9.35628 19.4531H40.73C42.6014 19.4531 44.1517 21.031 44.1517 23.005V47.3162C44.1517 49.2902 42.6014 50.8681 40.73 50.8681H27.5472H27.4047L27.2837 50.9432L15.7389 58.1015V51.3681ZM40.73 47.8162H41.23V47.3162V23.005V22.505H40.73H9.35628H8.85628V23.005V47.3162V47.8162H9.35628H18.6606V51.8401V52.7382L19.4239 52.2652L26.6032 47.8162H40.73Z" fill="#5B657C" stroke="white"/>
<path d="M15.4883 29.3359H34.5969V32.8878H15.4883V29.3359ZM15.4883 37.4397H28.7143V40.9916H15.4883V37.4397Z" fill="#5B657C" stroke="white" strokeWidth="0.5"/>
<rect x="2.39111" y="16.9766" width="10.5217" height="11.8591" fill="white"/>
<path d="M7.17391 28.3415C10.3304 28.3415 12.913 25.6732 12.913 22.412C12.913 19.1507 10.3304 16.4824 7.17391 16.4824C4.01741 16.4824 1.43481 19.1507 1.43481 22.412C1.43481 25.6732 4.01741 28.3415 7.17391 28.3415ZM7.17391 15C11.1195 15 14.3478 18.3354 14.3478 22.412C14.3478 26.4885 11.1195 29.8239 7.17391 29.8239C3.22828 29.8239 4.1008e-05 26.4885 4.1008e-05 22.412C4.1008e-05 18.3354 3.22828 15 7.17391 15ZM10.7608 23.8202L10.2587 24.7838L6.45652 22.6343V18.706H7.5326V21.9672L10.7608 23.8202Z" fill="#5B657C"/>
<path d="M64.913 36.3681V35.8681H65.413H71.2956C73.167 35.8681 74.7173 34.2902 74.7173 32.3162V8.005C74.7173 6.03102 73.167 4.45312 71.2956 4.45312H39.9219C38.0504 4.45312 36.5001 6.03102 36.5001 8.005V32.3162C36.5001 34.2902 38.0504 35.8681 39.9219 35.8681H53.1047H53.2471L53.3682 35.9432L64.913 43.1015V36.3681ZM39.9219 32.8162H39.4219V32.3162V8.005V7.505H39.9219H71.2956H71.7956V8.005V32.3162V32.8162H71.2956H61.9913V36.8401V37.7382L61.2279 37.2652L54.0487 32.8162H39.9219Z" fill="#5B657C" stroke="white"/>
<path d="M46.0537 14.3359H65.1623V17.8878H46.0537V14.3359ZM46.0537 22.4397H59.2797V25.9916H46.0537V22.4397Z" fill="#5B657C" stroke="white" strokeWidth="0.5"/>
<rect x="66.6089" y="1.97656" width="10.5217" height="11.8591" fill="white"/>
<path d="M72.8262 13.3415C75.9827 13.3415 78.5653 10.6732 78.5653 7.41196C78.5653 4.1507 75.9827 1.48239 72.8262 1.48239C69.6697 1.48239 67.0871 4.1507 67.0871 7.41196C67.0871 10.6732 69.6697 13.3415 72.8262 13.3415ZM72.8262 0C76.7718 0 80.0001 3.33538 80.0001 7.41196C80.0001 11.4885 76.7718 14.8239 72.8262 14.8239C68.8806 14.8239 65.6523 11.4885 65.6523 7.41196C65.6523 3.33538 68.8806 0 72.8262 0ZM76.4131 8.82023L75.911 9.78379L72.1088 7.63432V3.70598H73.1849V6.96724L76.4131 8.82023Z" fill="#5B657C"/>
</svg>

export function Automation(props: RequestProgressBar) {
  function _renderAIAutomationBodyDefault(): JSX.Element {
    return (
      <div className='w-full flex flex-row items-center gap-3 justify-around h-full' style={{'padding': '5px', 'height': regularHeightPx}}>
      <div className='w-1/3 h-full'>
      {aiDefault}
      </div>
      <div className=' flex flex-auto text-center'>
      <Typography variant='mediumParagraph'>
        Check here for post-call automations
      </Typography>
      </div>
    </div>)
  }

  function _handleCopyToClipboard(): void {
    const summaryText = props.content?.trim()
    if (summaryText && summaryText.length) {
      window.navigator.clipboard.writeText(summaryText)
        .then(() => props.triggerAlert('Copied!', 3000, true))
        .catch(() => props.triggerAlert('Could not copy summary to clipboard', 3000, false))
    }
  }

  function _handleCopyToDialer(): void {
    const summaryText = props.content?.trim()
    if (summaryText && summaryText.length) {
      const message_type = MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_CALL_FINALIZATION
      chrome.runtime.sendMessage(props.extensionId ?? EXTENSION_ID, {
        'type': message_type,
        'prospect_phone_value': props.prospectPhoneValue,
        'summary': summaryText,
      }, (success) => {
        if (success) {
          props.triggerAlert('Copied!', 3000, true)
        } else {
          props.triggerAlert('Could not copy summary to clipboard', 3000, false)
        }
      })
    }
  }
  
  function _handleNotHelpful(): void {
    props.updateState(RequestState.DEFAULT)
    props.triggerAlert('Thanks for the feedback!', 3000, true)
  }


  function _renderMain()  {
    if (props.requestState === RequestState.DEFAULT) return _renderAIAutomationBodyDefault()
    else if (props.requestState === RequestState.RECEIVED && isCompleted && props.content) {
      return (
        <div className='gap-2 flex-col w-full h-full flex items-center justify-between' style={{'paddingTop': '5px', 'paddingBottom': '5px', 'paddingLeft': '10px', 'paddingRight': '10px', 'height': regularHeightPx, 'overflowY': 'hidden'}}>
          <div className='w-full gap-2 flex flex-col overflow-y-auto'>
            {props.content.split(/\r?\n/).map((value, idx: number, arr: string[]) => {
              if (value == '') return null
              return ( 
                <Typography variant='mediumParagraph'>
                  {value}
                </Typography>)
                })}
          </div>
          {props.noShowButtons ? null : <div className='gap-1 w-full flex justify-evenly sticky bottom-0'>
            <div onClick={() => props.copyToPlatform ? _handleCopyToDialer() : _handleCopyToClipboard()} className='cursor-pointer w-1/2 text-center ' style={{'backgroundColor': '#B7DEC0', 'borderRadius': '5px'}}>
              <Typography variant='mediumParagraph'>
                {props.copyToPlatform ? 'Copy to dialer' : 'Copy to clipboard'}
              </Typography>
            </div>
            <div onClick={() => _handleNotHelpful()} className='cursor-pointer w-1/2 text-center ' style={{'backgroundColor': '#F1E9E9', 'borderRadius': '5px'}}>
              <Typography variant='mediumParagraph'>
                Not helpful &#x1f44e;
              </Typography>
            </div>
          </div>}
        </div>
      )
    } 
     else {
        return <div className="w-full" style={{'height': regularHeightPx}}>
        </div>
     }
  }

  function _generateItemList(text: string): JSX.Element {
    return (<div className='flex flex-row gap-2 items-center w-full'>
    <div style={{'borderRadius': '50%', 'backgroundColor': 'black', 'width': '5px', 'height': '5px'}}>
     </div> 
     <div>
      <Typography>
        {text}
      </Typography>
     </div>
     <hr className="flex-grow"  style={{'backgroundColor': 'black', 'color': 'black'}}/>
  </div>)  
  }

  function _renderEmptyAICustomMetric(): JSX.Element {
    return (<div className="flex flex-col justify-around opacity-40 overflow-y-hidden w-full">
      {_generateItemList('Score:')}
      {_generateItemList('Reason:')}
      </div>)
  }

  function _renderEmptyAISummaryText(): JSX.Element {
    return (<div className="flex flex-col justify-around opacity-40 overflow-y-hidden w-full">
      {_generateItemList('Summary:')}
      {_generateItemList('Action Item:')}
      </div>)
  }

  function _renderEmptyAICoachingText(): JSX.Element {
    return (<div className="flex flex-col justify-around opacity-40 overflow-y-hidden w-full">
      {_generateItemList('Suggestion #1:')}
      {_generateItemList('Suggestion #2:')}
      </div>)
  }

  function _renderEmptyAIEmailText(): JSX.Element {
    return (<div className="flex flex-col justify-around opacity-100 overflow-y-hidden w-full">
      <div className="opacity-30">
        <Typography variant="smallParagraph">Hi Prospect,</Typography>
      </div>

      <div className="w-full flex flex-col gap-1">
      <hr style={{'backgroundColor': 'black', 'color': 'black'}}/>
      <hr style={{'backgroundColor': 'black', 'color': 'black'}}/>
      </div>
      
      <div className="opacity-30">
        <Typography variant="smallParagraph">
          Best, Rep
        </Typography>
      </div>
      <div className='flex flex-row gap-2 items-center w-full'>
        <Typography className="opacity-30" variant="smallParagraph">P.S.</Typography>
       <hr className="flex-grow"  style={{'backgroundColor': 'black', 'color': 'black'}}/>
      </div>  
      </div>)
  }

    const [isPaused, setIsPaused] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
  
    const widgetFooterHeight = 0
    const regularHeight = props.requestState === RequestState.RECEIVED ? ([WidgetType.CUSTOM_METRIC, WidgetType.AI_AUTOMATION_SUMMARY].includes(props.widgetType) ? 90 : props.widgetType === WidgetType.AI_AUTOMATION_COACHING ? 140 : 120): props.widgetType == WidgetType.AI_AUTOMATION_EMAIL ? 70 : 50
    const regularHeightPx = regularHeight + 'px'
    const height = (regularHeight + widgetFooterHeight).toString() + "px"
    const bgColor = 'rgb(60, 71, 88)'
    const textColor = 'white'
  
    const isEndingState = (props.requestState === RequestState.RECEIVED)
    const isStartingState = (props.requestState === RequestState.ERROR || props.requestState === RequestState.TO_BE_REQUESTED_BY_USER)
    const isPendingState = (props.requestState === RequestState.PENDING || props.requestState === RequestState.REQUESTED_BY_USER)
  
    // Define the animation using useSpring
    const progressBarAnimation = useSpring({
      from: { 'width': isCompleted ? '100%' : isEndingState ? '80%' : '20%'},
      to: { 'width': isEndingState || isCompleted ? '100%' : isPendingState ? '80%' : '20%'},
      config: { duration: isEndingState || isStartingState ? 500 : props.widgetType === WidgetType.AI_AUTOMATION_COACHING ? 10000 : 3000 },
      onResolve: (result: AnimationResult) => {
        if (!isEndingState && isPendingState && ((result.value as string) === '80%')) {
            setIsPaused(true)
          }
          // @ts-ignore
          if ((isEndingState && (result.value as string) === '100%')) {
            setIsPaused(false)
            setIsCompleted(true)
          }
      }
    });

  function _renderAnimatedElement() {
    return (
        <div className="relative" style={{'bottom': '100%'}}>
            <div className="absolute" style={{'height': regularHeightPx, 'zIndex': '9999', 'width': '100%'}}>
                <div className="w-full" style={{'boxShadow': props.mainView ? 'rgb(0 0 0 / 10%) 0px 4px 12px' : 'rgb(0 0 0 / 20%) 0px 3px 3px', 'borderRadius': '8px'}}>
                    <animated.div
                    style={{
                        position: 'relative',
                        boxShadow: isPendingState ? '0 8px 16px 0 rgba(0, 0, 0, 0.6), 0 -8px 16px 0 rgba(0, 0, 0, 0.6)' : 'initial',  
                        background: bgColor,
                        borderRadius: '8px',
                        height: height,
                        opacity: isCompleted ? 0 : 1,
                        pointerEvents: isCompleted ? 'none' : 'initial',
                        transition: 'opacity 1s linear',
                        ...progressBarAnimation
                    }}
                    ></animated.div>
            

                {!isStartingState ? null : 
                <div className="absolute" onClick={() => props.updateState(RequestState.REQUESTED_BY_USER)} style={{'cursor': 'pointer', 'left': "0%", 'top': '0px', 'width': '30%', 'height': height}}>
                  <Pulsating noPulsate={props.mainView} widgetType={props.widgetType} visible={true} color={bgColor} />
                </div>}
                {isCompleted || isStartingState ? null :  <div className="relative" style={{}}>
                    <div className="absolute" style={{
                        'zIndex': isPendingState ? '999' : '799',
                        'pointerEvents': 'none',
                        'width': "100%", 
                        'display': 'flex', 
                        'alignItems': 'center', 
                        'justifyContent': 'center', 
                        'height': height, 
                        'bottom': '0',
                        }}>
                        <Typography variant="largeParagraph" color={textColor}>
                            {props.widgetType === WidgetType.AI_AUTOMATION_EMAIL ? 'Generating your email...' : props.widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? 
                            'Generating your summary...' : props.widgetType === WidgetType.AI_AUTOMATION_COACHING  ? 'Generating your coaching...' : 'Generating your metrics...'}
                        </Typography>
                    </div>
                </div>}
                {isStartingState || isPendingState ? <div className="relative" >
                    <div className="absolute" style={{
                        'zIndex': '800',
                        'pointerEvents': 'none',
                        'left': '35%',
                        'width': "65%", 
                        'display': 'flex',
                        'height': height,
                        'opacity': isPendingState ? '0': '1',
                        'transition': ' c `opacity 0.1s linear',
                        'bottom': '0',
                        'padding': '10px'
                        }}>
                          {props.requestState === RequestState.ERROR ? 
                          <div className="flex flex-col justify-around opacity-100 overflow-y-hidden w-full">
                            <Typography variant="mediumParagraph">
                            Error. Please try again in a few seconds...
                            </Typography>
                          </div>
                          : props.widgetType === WidgetType.AI_AUTOMATION_SUMMARY ?  _renderEmptyAISummaryText()
                          : props.widgetType === WidgetType.AI_AUTOMATION_EMAIL ? _renderEmptyAIEmailText() 
                          : props.widgetType === WidgetType.AI_AUTOMATION_COACHING ? _renderEmptyAICoachingText() : _renderEmptyAICustomMetric()}
                    </div>
                </div> : null}
            
                {isPaused && !isEndingState && isPendingState ? 
                <div className="relative" style={{'bottom': '100%'}}>
                    <div className="absolute" style={{
                        'zIndex': '999',
                        'pointerEvents': 'none',
                        'width': "15%",
                        'left': '85%', 
                        'bottom': '0',
                        'display': 'flex', 
                        'justifyContent': 'center', 
                        'height': height, 
                        'color': 'white',
                        'alignItems': 'center'
                        }}>
                        <div style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'height': height, 'padding': '4px'}}>
                        <ThreeCircles color={bgColor} height={'50px'} width={"80%"}/>
                        </div>
                    </div>
                </div> : null}
                </div>
        </div>
        </div>
        )
  }

  const isInAnimatedSequence = props.requestState !== RequestState.DEFAULT

  function _renderMainDiv() {
    return (
      <div onClick={() => {
        }} className='w-full flex flex-col justify-end min-h-0' style={{'transition': 'all 1.5s linear', 'backgroundColor': '#FFFFFF', borderRadius: '10px', border: props.mainView ? 'none' : '1px solid rgba(91,101,123,0.25)'}}>
        {isInAnimatedSequence ?
         <div className="relative" style={{'pointerEvents': isEndingState ? 'none' : 'initial'}}>
            {_renderAnimatedElement()}
        </div> : null}
        {_renderMain()}
      </div>)
  }

  return _renderMainDiv()
  }