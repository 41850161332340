import { RootState } from "store"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useMemo } from "react"
import { ConversationId, InboxType, SOURCE_TYPE } from "../types"
import { addToSalesInbox,removeFromSalesInbox, updateSalesConversationReadState } from "../conversations/sales-nav-slice"
import { addToInbox, removeFromInbox, updateConversationReadState } from "../conversations/chat-slice"

export const useLinkedInMetadata = () => {
    const allMetadata = useSelector((state: RootState) => state.linkedinConversationStates.states)
    const allTags = useMemo(() => {
        return new Set(Object.values(allMetadata).map((v) => v.tags).flat())
    }, [allMetadata])

    const salesNavClient = useSelector((state: RootState) => state.linkedinClients.salesNavigatorClient)
    const client = useSelector((state: RootState) => state.linkedinClients.client)
    const dispatch = useDispatch()

    const markUnreadChat = useCallback(async (conversationId: ConversationId, read: boolean) => {
        if (!client) return
        try {
            await client.setConversationReadState(conversationId.id, read)
            if (read) {
                dispatch(removeFromInbox({
                    inboxType: InboxType.UNREAD,
                    id: conversationId.id
                }))
            } else {
                dispatch(addToInbox({
                    inboxType: InboxType.UNREAD,
                    conversation: conversationId
                }))
            }
            return true
        } catch (e) {
            console.error(e)
            return false
        }
    }, [client])

    const markUnreadSalesNav = useCallback(async (conversationId: ConversationId, read: boolean) => {
        if (!salesNavClient) return
        try {
            await salesNavClient.setConversationReadState(conversationId.id, read)
            if (read) {
                dispatch(removeFromSalesInbox({
                    inboxType: InboxType.UNREAD,
                    id: conversationId.id
                }))
            } else {
                dispatch(addToSalesInbox({
                    inboxType: InboxType.UNREAD,
                    conversation: conversationId
                }))
            }
            return true
        } catch (e) {
            console.error(e)
            return false
        }
    }, [salesNavClient])

    const updateReadState = useCallback((conversationId: ConversationId, read: boolean) => {
        if (conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR) {
            dispatch(updateSalesConversationReadState({
                id: conversationId.id,
                read
            }))
        } else {
            dispatch(updateConversationReadState({
                id: conversationId.id,
                read
            }))
        }
    }, [dispatch])

    const markReadState = useCallback(async (conversationId: ConversationId, read: boolean) => {
        updateReadState(conversationId, read)
        let result = conversationId.sourceType === SOURCE_TYPE.SALES_NAVIGATOR ? await markUnreadSalesNav(conversationId, read) : await markUnreadChat(conversationId, read)
        if (!result) updateReadState(conversationId, !read)
    }, [updateReadState, salesNavClient, markUnreadSalesNav, markUnreadChat])

    return { allTags, markReadState }
}