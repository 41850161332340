import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reloadSnippets, addSnippet, updateSnippet, deleteSnippet, setLoading } from 'lib/redux/linkedin/snippets/slice';
import { Snippet } from 'interfaces/db';
import { getServicesManager } from 'services';
import { Typography } from 'interfaces/typography';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Paper,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  Chip,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
  InputAdornment,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  useTheme,
  alpha
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  ContentCopy as CopyIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
  Refresh as RefreshIcon,
  FormatQuote as FormatQuoteIcon,
  InsertEmoticon as InsertEmoticonIcon,
  Public as PublicIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import { TRELLUS_TEAM_ID } from 'cfg/const';

export function SnippetManagement() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const snippets = useSelector((state: RootState) => state.linkedinSnippets?.snippets);
  const isLoading = useSelector((state: RootState) => state.linkedinSnippets?.loading);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSnippet, setCurrentSnippet] = useState<Snippet | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    text: '',
    isGlobal: false,
    isTeam: false
  });
  const [variableMenuAnchor, setVariableMenuAnchor] = useState<null | HTMLElement>(null);
  const [actionMenuAnchor, setActionMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedSnippetId, setSelectedSnippetId] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning'
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snippetToDelete, setSnippetToDelete] = useState<string | null>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const isGlobalAdmin = useSelector((state: RootState) => {
    const teamIds = state.adminWrite.value?.team_ids;
    return teamIds?.includes(TRELLUS_TEAM_ID) || false;
  });
  
  const isAdmin = useSelector((state: RootState) => {
    const teamIds = state.adminWrite.value?.team_ids;
    return teamIds ? teamIds.length > 0 : false;
  });

  // Load snippets if they're not already loaded
  useEffect(() => {
    if (snippets === null) {
      dispatch(setLoading(true));
      reloadSnippets(dispatch);
    }
  }, [snippets, dispatch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenDialog = (snippet?: Snippet) => {
    if (snippet) {
      setCurrentSnippet(snippet);
      setFormData({
        name: snippet.snippet_name,
        text: snippet.snippet_text,
        isGlobal: snippet.is_global,
        isTeam: snippet.team_id !== null
      });
    } else {
      setCurrentSnippet(null);
      setFormData({
        name: '',
        text: '',
        isGlobal: false,
        isTeam: false
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentSnippet(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'isGlobal' ? checked : name === 'isTeam' ? checked : value
    }));
  };

  const handleOpenVariableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setVariableMenuAnchor(event.currentTarget);
  };

  const handleCloseVariableMenu = () => {
    setVariableMenuAnchor(null);
  };

  const handleInsertVariable = () => {
    const textField = textFieldRef.current;
    if (!textField) return;

    const start = textField.selectionStart || 0;
    const end = textField.selectionEnd || 0;
    const text = formData.text;
    const newText = text.substring(0, start) + '{{name}}' + text.substring(end);
    
    setFormData(prev => ({ ...prev, text: newText }));
    setVariableMenuAnchor(null);

    setTimeout(() => {
      textField.focus();
      const newPosition = start + '{{name}}'.length;
      textField.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>, snippetId: string) => {
    event.stopPropagation();
    setActionMenuAnchor(event.currentTarget);
    setSelectedSnippetId(snippetId);
  };

  const handleCloseActionMenu = () => {
    setActionMenuAnchor(null);
    setSelectedSnippetId(null);
  };

  const handleSubmit = async () => {
    if (!formData.name.trim() || !formData.text.trim()) {
      setNotification({
        open: true,
        message: 'Please fill in all fields',
        severity: 'error'
      });
      return;
    }

    dispatch(setLoading(true));
    try {
      if (currentSnippet) {
        // Update existing snippet
        const updatedSnippet = await getServicesManager().updateSnippet(
          currentSnippet.snippet_id,
          formData.name,
          formData.text
        );
        if (updatedSnippet) {
          dispatch(updateSnippet(updatedSnippet));
          setNotification({
            open: true,
            message: 'Snippet updated successfully',
            severity: 'success'
          });
        } else {
          throw new Error('Failed to update snippet');
        }
      } else {
        // Create new snippet
        const newSnippet = await getServicesManager().createSnippet(
          formData.isTeam, // is_team
          formData.isGlobal, // is_global
          null, // user_group_id
          formData.name,
          formData.text
        );
        if (newSnippet) {
          dispatch(addSnippet(newSnippet));
          setNotification({
            open: true,
            message: 'Snippet created successfully',
            severity: 'success'
          });
        } else {
          throw new Error('Failed to create snippet');
        }
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving snippet:', error);
      setNotification({
        open: true,
        message: `Error: ${error instanceof Error ? error.message : 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteSnippet = async (snippetId: string) => {
    setSnippetToDelete(snippetId);
    setDeleteDialogOpen(true);
    handleCloseActionMenu();
  };

  const confirmDelete = async () => {
    if (!snippetToDelete) return;

    dispatch(setLoading(true));
    try {
      const success = await getServicesManager().deleteSnippet(snippetToDelete);
      if (success) {
        dispatch(deleteSnippet(snippetToDelete));
        setNotification({
          open: true,
          message: 'Snippet deleted successfully',
          severity: 'success'
        });
      } else {
        throw new Error('Failed to delete snippet');
      }
    } catch (error) {
      console.error('Error deleting snippet:', error);
      setNotification({
        open: true,
        message: `Error: ${error instanceof Error ? error.message : 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      dispatch(setLoading(false));
      setDeleteDialogOpen(false);
      setSnippetToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setSnippetToDelete(null);
  };

  const handleCopySnippet = (snippet: Snippet) => {
    navigator.clipboard.writeText(snippet.snippet_text);
    setNotification({
      open: true,
      message: 'Snippet copied to clipboard',
      severity: 'success'
    });
    handleCloseActionMenu();
  };

  const handleDuplicateSnippet = (snippet: Snippet) => {
    handleOpenDialog();
    setFormData({
      name: `${snippet.snippet_name} (Copy)`,
      text: snippet.snippet_text,
      isGlobal: false,
      isTeam: snippet.team_id !== null
    });
    handleCloseActionMenu();
  };

  const handleRefresh = () => {
    dispatch(setLoading(true));
    reloadSnippets(dispatch);
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  const renderSnippetText = (text: string) => {
    return text.split(/(\{\{name\}\})/).map((part, index) => {
      if (part === '{{name}}') {
        return (
          <Chip
            key={index}
            label="name"
            size="small"
            sx={{ 
              height: '20px',
              mx: 0.5,
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              fontWeight: 500,
              border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
              '& .MuiChip-label': {
                px: 1,
                fontSize: '0.75rem',
              }
            }}
          />
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  // Filter snippets based on search term and tab
  const filteredSnippets = snippets?.filter(snippet => {
    const matchesSearch = 
      snippet.snippet_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      snippet.snippet_text.toLowerCase().includes(searchTerm.toLowerCase());
    
    if (tabValue === 0) return matchesSearch; // All snippets
    if (tabValue === 1) return matchesSearch && snippet.is_global; // Global snippets
    if (tabValue === 2) return matchesSearch && !snippet.is_global && !snippet.team_id; // Personal snippets
    if (tabValue === 3) return matchesSearch && snippet.team_id; // Team snippets
    
    return matchesSearch;
  }) || [];

  const canEditSnippet = (snippet: Snippet) => {
    if (snippet.is_global) return isGlobalAdmin;
    if (snippet.team_id) return isAdmin;
    return true; // Personal snippets can always be edited by owner
  };

  const renderLoadingState = () => (
    <Grid container spacing={2}>
      {[1, 2, 3, 4, 5, 6].map((key) => (
        <Grid item xs={12} sm={6} md={4} key={key}>
          <Card 
            sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column',
              borderRadius: 1,
              border: '1px solid rgb(229, 231, 235)',
              boxShadow: 'none',
            }}
          >
            <CardContent sx={{ flexGrow: 1, p: 2 }}>
              <Box sx={{ 
                width: '60%', 
                height: 24, 
                backgroundColor: 'rgba(0, 0, 0, 0.04)', 
                mb: 2,
                borderRadius: 0.5
              }} />
              <Box sx={{ 
                p: 2, 
                borderRadius: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                border: '1px solid rgb(229, 231, 235)',
                minHeight: 80,
              }}>
                <Box sx={{ 
                  width: '100%', 
                  height: 12, 
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  mb: 1,
                  borderRadius: 0.5
                }} />
                <Box sx={{ 
                  width: '80%', 
                  height: 12, 
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: 0.5
                }} />
              </Box>
            </CardContent>
            <CardActions sx={{ 
              justifyContent: 'space-between', 
              p: 2,
              borderTop: '1px solid rgb(229, 231, 235)',
              backgroundColor: 'rgba(0, 0, 0, 0.02)'
            }}>
              <Box sx={{ 
                width: 60, 
                height: 24, 
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 0.5
              }} />
              <Box sx={{ 
                width: 24, 
                height: 24, 
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '50%'
              }} />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div className="h-full w-full bg-gray-50 p-3 sm:p-4 overflow-auto">
      <div className="space-y-3 mx-auto max-w-5xl">
        {/* Header */}
        <section className="flex justify-between items-center">
          <div>
            <Typography variant="h3">Snippet Management</Typography>
            <Typography variant="mediumParagraph" color="text.secondary">
              Create and manage reusable LinkedIn message templates
            </Typography>
          </div>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            sx={{ 
              borderRadius: 1,
              px: 2,
              py: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
              },
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: '0.875rem'
            }}
          >
            New Snippet
          </Button>
        </section>

        {/* Search and Filter */}
        <section className="relative z-10 rounded-lg border border-gray-200 bg-white p-3 shadow-sm">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              placeholder="Search snippets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                  fontSize: '0.875rem',
                  '& fieldset': {
                    borderColor: 'rgb(229, 231, 235)'
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgb(107, 114, 128)'
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgb(156, 163, 175)' }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setSearchTerm('')}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Tooltip title="Refresh snippets">
              <IconButton 
                onClick={handleRefresh} 
                disabled={isLoading}
                sx={{
                  border: '1px solid rgb(229, 231, 235)',
                  borderRadius: 1,
                  p: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                {isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </section>

        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            sx={{
              minHeight: 40,
              '& .MuiTab-root': {
                textTransform: 'none',
                minHeight: 40,
                fontSize: '0.875rem',
                fontWeight: 500,
                color: 'rgb(75, 85, 99)',
                '&.Mui-selected': {
                  color: 'rgb(17, 24, 39)'
                }
              }
            }}
          >
            <Tab 
              label="All Snippets" 
              icon={<FilterListIcon sx={{ fontSize: 18 }} />} 
              iconPosition="start"
            />
            <Tab 
              label="Global Snippets" 
              icon={<PublicIcon sx={{ fontSize: 18 }} />} 
              iconPosition="start"
            />
            <Tab 
              label="Personal Snippets" 
              icon={<PersonIcon sx={{ fontSize: 18 }} />} 
              iconPosition="start"
            />
            <Tab 
              label="Team Snippets" 
              icon={<GroupIcon sx={{ fontSize: 18 }} />} 
              iconPosition="start"
            />
          </Tabs>
        </Box>

        {/* Content */}
        {snippets === null ? (
          renderLoadingState()
        ) : filteredSnippets.length === 0 ? (
          <section className="relative z-10 rounded-lg border border-gray-200 bg-white p-8 text-center shadow-sm">
            <div className="mb-2">
              <InsertEmoticonIcon sx={{ fontSize: 36, color: 'rgb(156, 163, 175)' }} />
            </div>
            <div className="mb-1">
              <Typography variant="h4" color="rgb(75, 85, 99)">
                {searchTerm ? 'No snippets match your search' : 'No snippets found'}
              </Typography>
            </div>
            <div className="mb-3">
              <Typography variant="mediumParagraph" color="rgb(107, 114, 128)">
                {searchTerm ? 'Try a different search term' : 'Create your first snippet by clicking the "New Snippet" button'}
              </Typography>
            </div>
            {searchTerm && (
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={() => setSearchTerm('')}
                sx={{ 
                  borderRadius: 1,
                  textTransform: 'none',
                  borderColor: 'rgb(229, 231, 235)',
                  color: 'rgb(75, 85, 99)',
                  '&:hover': {
                    borderColor: 'rgb(107, 114, 128)',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                Clear Search
              </Button>
            )}
          </section>
        ) : (
          <Grid container spacing={2}>
            {filteredSnippets.map((snippet) => (
              <Grid item xs={12} sm={6} md={4} key={snippet.snippet_id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    borderRadius: '8px',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    cursor: canEditSnippet(snippet) ? 'pointer' : 'default',
                    border: '1px solid rgb(229, 231, 235)',
                    boxShadow: 'none',
                    backgroundColor: 'white',
                    '&:hover': canEditSnippet(snippet) ? {
                      borderColor: 'rgb(209, 213, 219)',
                      transform: 'translateY(-1px)',
                      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.05)'
                    } : {}
                  }}
                  onClick={() => canEditSnippet(snippet) ? handleOpenDialog(snippet) : null}
                >
                  <Box sx={{ 
                    position: 'absolute',
                    top: 12,
                    right: 12,
                    display: 'flex',
                    gap: 1,
                    zIndex: 1
                  }}>
                    {snippet.is_global && (
                      <Chip
                        icon={!canEditSnippet(snippet) ? <LockIcon sx={{ fontSize: 14 }} /> : <PublicIcon sx={{ fontSize: 14 }} />}
                        label="Global"
                        size="small"
                        sx={{ 
                          height: 24,
                          fontSize: '0.75rem',
                          backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          color: theme.palette.primary.main,
                          border: 'none',
                          fontWeight: 500
                        }}
                      />
                    )}
                    {snippet.team_id && (
                      <Chip
                        icon={!canEditSnippet(snippet) ? <LockIcon sx={{ fontSize: 14 }} /> : <GroupIcon sx={{ fontSize: 14 }} />}
                        label="Team"
                        size="small"
                        sx={{ 
                          height: 24,
                          fontSize: '0.75rem',
                          backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          color: theme.palette.primary.main,
                          border: 'none',
                          fontWeight: 500
                        }}
                      />
                    )}
                  </Box>
                  <CardContent sx={{ flexGrow: 1, p: 3, pt: 5 }}>
                    <Typography 
                      variant="h5" 
                      style={{ 
                        marginBottom: 16,
                        fontSize: '0.975rem',
                        fontWeight: 600,
                        color: 'rgb(17, 24, 39)'
                      }}
                    >
                      {snippet.snippet_name}
                    </Typography>
                    <Box 
                      sx={{ 
                        p: 2.5,
                        borderRadius: '6px',
                        backgroundColor: 'rgb(250, 250, 250)',
                        border: '1px solid rgb(229, 231, 235)',
                        minHeight: 80,
                        maxHeight: 160,
                        position: 'relative',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'rgb(229, 231, 235)',
                          borderRadius: '4px',
                        },
                        '&:hover': {
                          borderColor: canEditSnippet(snippet) ? 'rgb(209, 213, 219)' : 'rgb(229, 231, 235)',
                          '&::-webkit-scrollbar-thumb': {
                            background: 'rgb(209, 213, 219)',
                          }
                        }
                      }}
                    >
                      <Typography 
                        variant="mediumParagraph" 
                        style={{ 
                          color: 'rgb(55, 65, 81)',
                          lineHeight: 1.6,
                          fontSize: '0.875rem'
                        }}
                      >
                        {renderSnippetText(snippet.snippet_text)}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ 
                    justifyContent: 'space-between', 
                    px: 3,
                    py: 2,
                    borderTop: '1px solid rgb(243, 244, 246)',
                    backgroundColor: 'rgb(250, 250, 250)'
                  }}>
                    {canEditSnippet(snippet) ? (
                      <>
                        <Button 
                          size="small" 
                          startIcon={<EditIcon sx={{ fontSize: 16 }} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenDialog(snippet);
                          }}
                          sx={{
                            color: 'rgb(75, 85, 99)',
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                          }}
                        >
                          Edit
                        </Button>
                        <IconButton 
                          size="small"
                          onClick={(e) => handleOpenActionMenu(e, snippet.snippet_id)}
                          sx={{
                            color: 'rgb(107, 114, 128)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                          }}
                        >
                          <MoreVertIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      </>
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        width: '100%'
                      }}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 1,
                          color: 'rgb(156, 163, 175)'
                        }}>
                          <LockIcon sx={{ fontSize: 14 }} />
                          <Typography 
                            variant="smallParagraph" 
                            style={{ 
                              fontSize: '0.875rem',
                              fontWeight: 500
                            }}
                          >
                            View only
                          </Typography>
                        </Box>
                        <Button 
                          size="small" 
                          startIcon={<CopyIcon sx={{ fontSize: 16 }} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopySnippet(snippet);
                          }}
                          sx={{
                            marginLeft: 'auto',
                            color: 'rgb(75, 85, 99)',
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                          }}
                        >
                          Copy
                        </Button>
                      </Box>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Dialog */}
        <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: 1,
              border: '1px solid rgb(229, 231, 235)'
            }
          }}
        >
          <DialogTitle sx={{ 
            p: 2,
            borderBottom: '1px solid rgb(229, 231, 235)',
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4">
                {currentSnippet ? 'Edit Snippet' : 'Create New Snippet'}
              </Typography>
              <IconButton 
                onClick={handleCloseDialog}
                size="small"
                sx={{ color: 'rgb(107, 114, 128)' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ p: 2, pt: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ p: 2.5, display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                <TextField
                  name="name"
                  label="Template Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoFocus
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontSize: '0.875rem',
                      '& fieldset': {
                        borderColor: 'rgb(229, 231, 235)'
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgb(107, 114, 128)'
                      }
                    }
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <Typography variant="mediumParagraph" color="rgb(75, 85, 99)">
                      Message
                    </Typography>
                    <Button
                      size="small"
                      onClick={handleInsertVariable}
                      startIcon={<AddIcon sx={{ fontSize: 16 }} />}
                      sx={{
                        color: 'rgb(75, 85, 99)',
                        textTransform: 'none',
                        fontSize: '0.75rem',
                        height: 28,
                        px: 1.5,
                        border: '1px solid rgb(229, 231, 235)',
                        borderRadius: 2,
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.02)',
                          borderColor: 'rgb(107, 114, 128)'
                        }
                      }}
                    >
                      Prospect Name
                    </Button>
                  </Box>
                  <TextField
                    name="text"
                    value={formData.text}
                    onChange={handleInputChange}
                    fullWidth
                    variant="outlined"
                    size="small"
                    multiline
                    rows={6}
                    inputRef={textFieldRef}
                    placeholder="Write your message here..."
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        fontSize: '0.875rem',
                        backgroundColor: 'white',
                        '& fieldset': {
                          borderColor: 'rgb(229, 231, 235)'
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgb(107, 114, 128)'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'rgb(17, 24, 39)',
                          borderWidth: 1
                        }
                      }
                    }}
                  />
                  <div style={{ marginTop: 4 }}>
                    <Typography variant="smallParagraph" color="rgb(107, 114, 128)">
                      {'Use {{name}} to personalize your message with the prospect\'s name'}
                    </Typography>
                  </div>
                </Box>
              </Box>
              
              {!currentSnippet && (
                <Box sx={{ 
                  p: 2, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 1.5,
                  borderTop: '1px solid rgb(229, 231, 235)',
                  bgcolor: 'rgba(0, 0, 0, 0.02)'
                }}>
                  {isGlobalAdmin && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="isGlobal"
                          checked={formData.isGlobal}
                          onChange={handleInputChange}
                          size="small"
                          sx={{
                            width: 34,
                            height: 20,
                            mr: 1.5,
                            padding: 0,
                            '& .MuiSwitch-switchBase': {
                              padding: 0,
                              margin: '2px',
                              '&.Mui-checked': {
                                transform: 'translateX(14px)',
                                color: 'white',
                                '& + .MuiSwitch-track': {
                                  backgroundColor: 'rgb(17, 24, 39)',
                                  opacity: 1,
                                }
                              }
                            },
                            '& .MuiSwitch-thumb': {
                              width: 16,
                              height: 16
                            },
                            '& .MuiSwitch-track': {
                              borderRadius: 10,
                              opacity: 1,
                              backgroundColor: 'rgb(229, 231, 235)',
                            }
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                          <PublicIcon 
                            sx={{ 
                              fontSize: 18,
                              color: formData.isGlobal ? 'rgb(17, 24, 39)' : 'rgb(156, 163, 175)' 
                            }} 
                          />
                          <Typography variant="mediumParagraph" color={formData.isGlobal ? 'rgb(17, 24, 39)' : 'rgb(107, 114, 128)'} style={{ 
                            fontWeight: formData.isGlobal ? 500 : 400
                          }}>
                            Make this snippet global
                          </Typography>
                        </Box>
                      }
                      sx={{
                        mx: 0,
                        my: 0,
                        '&:hover': {
                          '& .MuiTypography-root': {
                            color: 'rgb(17, 24, 39)'
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'rgb(17, 24, 39)'
                          }
                        }
                      }}
                    />
                  )}
                  {isAdmin && !formData.isGlobal && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="isTeam"
                          checked={formData.isTeam}
                          onChange={handleInputChange}
                          size="small"
                          sx={{
                            width: 34,
                            height: 20,
                            mr: 1.5,
                            padding: 0,
                            '& .MuiSwitch-switchBase': {
                              padding: 0,
                              margin: '2px',
                              '&.Mui-checked': {
                                transform: 'translateX(14px)',
                                color: 'white',
                                '& + .MuiSwitch-track': {
                                  backgroundColor: 'rgb(17, 24, 39)',
                                  opacity: 1,
                                }
                              }
                            },
                            '& .MuiSwitch-thumb': {
                              width: 16,
                              height: 16
                            },
                            '& .MuiSwitch-track': {
                              borderRadius: 10,
                              opacity: 1,
                              backgroundColor: 'rgb(229, 231, 235)',
                            }
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                          <GroupIcon 
                            sx={{ 
                              fontSize: 18,
                              color: formData.isTeam ? 'rgb(17, 24, 39)' : 'rgb(156, 163, 175)' 
                            }} 
                          />
                          <Typography variant="mediumParagraph" color={formData.isTeam ? 'rgb(17, 24, 39)' : 'rgb(107, 114, 128)'} style={{ 
                            fontWeight: formData.isTeam ? 500 : 400
                          }}>
                            Make this snippet available to team
                          </Typography>
                        </Box>
                      }
                      sx={{
                        mx: 0,
                        my: 0,
                        '&:hover': {
                          '& .MuiTypography-root': {
                            color: 'rgb(17, 24, 39)'
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'rgb(17, 24, 39)'
                          }
                        }
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ 
            p: 2, 
            borderTop: '1px solid rgb(229, 231, 235)',
            gap: 1
          }}>
            <Button 
              onClick={handleCloseDialog}
              variant="outlined"
              sx={{ 
                color: 'rgb(75, 85, 99)',
                textTransform: 'none',
                borderColor: 'rgb(229, 231, 235)',
                '&:hover': {
                  borderColor: 'rgb(107, 114, 128)',
                  backgroundColor: 'transparent'
                }
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSubmit} 
              variant="contained" 
              disabled={isLoading}
              sx={{
                bgcolor: 'rgb(17, 24, 39)',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: 'rgb(31, 41, 55)'
                },
                '&:disabled': {
                  bgcolor: 'rgba(17, 24, 39, 0.5)'
                }
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : currentSnippet ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>

        <Menu
          anchorEl={actionMenuAnchor}
          open={Boolean(actionMenuAnchor)}
          onClose={handleCloseActionMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              border: '1px solid rgb(229, 231, 235)',
              borderRadius: 1,
              mt: 1,
              minWidth: 160,
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
            }
          }}
        >
          {selectedSnippetId && snippets && (
            <>
              <MenuItem 
                onClick={() => {
                  const snippet = snippets.find(s => s.snippet_id === selectedSnippetId);
                  if (snippet) handleCopySnippet(snippet);
                }}
                sx={{
                  py: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <CopyIcon fontSize="small" sx={{ mr: 1.5 }} />
                <Typography variant="mediumParagraph">Copy to clipboard</Typography>
              </MenuItem>
              <MenuItem 
                onClick={() => {
                  const snippet = snippets.find(s => s.snippet_id === selectedSnippetId);
                  if (snippet) handleDuplicateSnippet(snippet);
                }}
                sx={{
                  py: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <AddIcon fontSize="small" sx={{ mr: 1.5 }} />
                <Typography variant="mediumParagraph">Duplicate</Typography>
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem 
                onClick={() => {
                  if (selectedSnippetId) handleDeleteSnippet(selectedSnippetId);
                }}
                sx={{
                  py: 1,
                  color: 'rgb(239, 68, 68)',
                  '&:hover': {
                    backgroundColor: 'rgba(239, 68, 68, 0.04)'
                  }
                }}
              >
                <DeleteIcon fontSize="small" sx={{ mr: 1.5 }} />
                <Typography variant="mediumParagraph">Delete</Typography>
              </MenuItem>
            </>
          )}
        </Menu>

        {/* Notifications */}
        <Snackbar
          open={notification.open}
          autoHideDuration={4000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={handleCloseNotification} 
            severity={notification.severity}
            sx={{ 
              minWidth: '300px',
              borderRadius: 1,
              backgroundColor: notification.severity === 'success' ? 'rgb(22, 101, 52)' : 'rgb(153, 27, 27)',
              color: 'white',
              '.MuiAlert-icon': {
                color: 'white'
              },
              '.MuiAlert-action': {
                color: 'white',
                opacity: 0.8,
                '&:hover': {
                  opacity: 1
                }
              },
              boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
            }}
          >
            <Typography variant="mediumParagraph" style={{ color: 'white', fontWeight: 500 }}>
              {notification.message}
            </Typography>
          </Alert>
        </Snackbar>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={cancelDelete}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: 1,
              border: '1px solid rgb(229, 231, 235)',
              maxWidth: '400px'
            }
          }}
        >
          <DialogTitle sx={{ 
            p: 2.5,
            pb: 1,
            color: 'rgb(239, 68, 68)'
          }}>
            Delete Snippet
          </DialogTitle>
          <DialogContent sx={{ p: 2.5, pt: 1 }}>
            <Typography variant="mediumParagraph" color="rgb(75, 85, 99)">
              Are you sure you want to delete this snippet? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ 
            p: 2.5,
            pt: 1.5,
            gap: 1
          }}>
            <Button
              onClick={cancelDelete}
              variant="outlined"
              sx={{ 
                color: 'rgb(75, 85, 99)',
                textTransform: 'none',
                borderColor: 'rgb(229, 231, 235)',
                '&:hover': {
                  borderColor: 'rgb(107, 114, 128)',
                  backgroundColor: 'transparent'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: 'rgb(239, 68, 68)',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: 'rgb(220, 38, 38)'
                },
                '&:disabled': {
                  bgcolor: 'rgba(239, 68, 68, 0.5)'
                }
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}